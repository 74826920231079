import { createContext } from 'react';
import { decorate, observable, action, computed } from 'mobx';

import api from '../api';
import * as fn from '../utilities/_functions';

const PAGE_SIZE = 50;
const FULL_SEARCH_SIZE = 250;

export class InventorySearch {
    page = 1;
    pageSize = PAGE_SIZE;
    searchTerm = null;
    selectorId = null;
    brandId = null;
    selectors = [];
    products = [];
    display = 'InStock';
    total = null;
    isLoading = false;
    isSaving = false;
    isReady = false;

    cancelProductBrandSearchCount = null;
    cancelProductDelete = null;
    cancelProductFullSearch = null;
    cancelProductSearch = null;
    cancelProductSearchCount = null;
    cancelProductTypes = null;

    initialize = (display, pageSize = PAGE_SIZE, selectorId) => {
        const that = this;
        this.clear();
        this.display = display ? display : 'InStock';
        this.pageSize = pageSize;

        return new Promise((resolve, reject) => {
            api.ProductTypes.all((c) => { that.cancelProductTypes = c })
                .then(({ data }) => {
                    if (data && data.length > 0) {
                        action(() => {
                            that.selectors = data.sort((a, b) => a.displayOrder - b.displayOrder);
                            selectorId = selectorId && that.selectors.some(s => s.id === selectorId) ? selectorId : null;

                            if (selectorId) {
                                that.selectorId = selectorId;
                            } else {
                                const selector = that.selectors.filter(d => d.brands && d.brands.length > 0)[0];
                                that.selectorId = selector ? selector.id : that.selectors[0].id;
                            }
                        })();
                    }

                    that.execute(1)
                        .then(() => {
                            resolve();
                        })
                        .catch((error) => {
                            reject(error);
                        })
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isReady = true;
                })
        })
    }

    execute = (page, notify = true) => {
        const that = this;
        const offset = page ? ((page - 1) * this.pageSize) : 0;

        this.page = page;

        if (notify) {
            this.isLoading = true;
        }

        return new Promise((resolve, reject) => {
            const options = that.options;

            options.offset = offset;
            options.limit = this.pageSize;
            options.includeTotalCount = true;

            api.Inventory.list(options,
                (c) => { that.cancelProductSearch = c }
            )
                .then(({ data }) => {
                    that.total = data.total;
                    that.products = data && data.result && data.result.length > 0 ? data.result : [];
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    if (notify) {
                        that.isLoading = false;
                    }
                })
        })
    }

    refresh = () => {
        const that = this;

        return new Promise((resolve) => {
            api.ProductTypes.all((c) => { that.cancelProductTypes = c })
                .then(({ data }) => {
                    if (data && data.length > 0) {
                        const sorted = data.sort((a, b) => a.displayOrder - b.displayOrder);
                        // const selector = sorted.filter(d => d.brands && d.brands.length > 0)[0];

                        that.selectors = sorted;

                        // if (selector) {
                        //     that.selectorId = selector.id;
                        // }
                    }

                    that.execute(this.page, false);
                    resolve();
                })
        })
    }

    search = () => {
        const that = this;
        this.isLoading = true;

        return new Promise((resolve, reject) => {
            const showAll = that.searchTerm.trim().split(' ').length === 1;
            api.Inventory.fullSearch(that.searchTerm, that.pageSize, 0, showAll, true, false, that.selectedSelector.id, that.selectedSelector.inventory, that.display === 'InStock', (c) => { that.cancelProductFullSearch = c })
                .then(({ data }) => {
                    that.total = data.total;
                    that.products = data.result && data.result.length > 0 ? data.result : [];
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isLoading = false;
                })
        })
    }

    refreshTotal = (force = false) => {
        if (force === true) {
            for (let i = 0; i < this.selectors.length; i++) {
                this.selectors[i].total = null;
                this.selectors[i].totalQuantity = null;
                for (let j = 0; j < this.selectors[i].brands.length; j++) {
                    this.selectors[i].brands[j].total = null;
                    this.selectors[i].brands[j].quantity = null;
                }
            }
        }

        const that = this;
        const typeId = this.selectorId;
        const i = that.selectors.findIndex(s => s.id === typeId);
        const options = { parameters: this.getParameters(true) };

        options.includeTotalCount = true;
        options.includeResult = false;

        api.Inventory.list(options,
            (c) => { that.cancelProductSearchCount = c }
        )
            .then(({ data }) => {
                if (data && that.selectors[i]) {
                    that.selectors[i].total = data.total;
                    that.selectors[i].totalQuantity = data.totalQuantity;

                    const selectorIndex = that.selectors.findIndex(s => s.id === typeId);

                    if (that.selectors[selectorIndex] && that.selectors[selectorIndex].brands && that.selectors[selectorIndex].brands.length > 0) {
                        for (let j = 0; j < that.selectors[selectorIndex].brands.length; j++) {
                            const brandTotal = data.brandTotals && data.brandTotals.length > 0 ? data.brandTotals.filter(d => d.id === that.selectors[selectorIndex].brands[j].id)[0] : null;
                            const brandQuantity = data.brandQuantities && data.brandQuantities.length > 0 ? data.brandQuantities.filter(d => d.id === that.selectors[selectorIndex].brands[j].id)[0] : null;

                            that.selectors[selectorIndex].brands[j].total = brandTotal ? brandTotal.value : 0;
                            that.selectors[selectorIndex].brands[j].quantity = brandQuantity ? brandQuantity.value : 0;
                        }
                    }
                }
            })
    }

    delete = (productIds) => {
        const that = this;

        this.isSaving = true;

        return new Promise((resolve, reject) => {
            Promise.all(
                that.products.filter(p => productIds.some(i => i === p.id)).map(p => {
                    return api.Products.delete(p.id, (c) => { that.cancelProductDelete = c })
                })
            )
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isSaving = false;
                })
        })
    }

    clear = () => {
        this.page = 1;
        this.searchTerm = null;
        this.selectorId = null;
        this.brandId = null;
        this.selectors = [];
        this.products = [];
        this.display = 'InStock';
        this.total = null;
        this.isLoading = false;
        this.isSaving = false;
        this.isReady = false;

        if (fn.isFunction(this.cancelProductBrandSearchCount)) {
            this.cancelProductBrandSearchCount();
            this.cancelProductBrandSearchCount = null;
        }

        if (fn.isFunction(this.cancelProductDelete)) {
            this.cancelProductDelete();
            this.cancelProductDelete = null;
        }

        if (fn.isFunction(this.cancelProductFullSearch)) {
            this.cancelProductFullSearch();
            this.cancelProductFullSearch = null;
        }

        if (fn.isFunction(this.cancelProductSearch)) {
            this.cancelProductSearch();
            this.cancelProductSearch = null;
        }

        if (fn.isFunction(this.cancelProductSearchCount)) {
            this.cancelProductSearchCount();
            this.cancelProductSearchCount = null;
        }

        if (fn.isFunction(this.cancelProductTypes)) {
            this.cancelProductTypes();
            this.cancelProductTypes = null;
        }
    }

    getParameters = (excludeBrand = false) => {
        const parameters = [];

        if (this.selectedSelector) {
            parameters.push({
                field: 'TypeId',
                value: this.selectedSelector.id,
            });
            parameters.push({
                field: 'Inventory',
                value: this.selectedSelector.inventory,
            });

            if (this.selectedSelector.inventory === 'Physical' && this.display === 'InStock') {
                parameters.push({
                    field: 'IsOutOfStock', value: false,
                })
            }
        }

        if (this.brandId && !excludeBrand) {
            parameters.push({
                field: 'BrandId',
                value: this.brandId,
            });
        }

        return parameters;
    }

    get selectedSelector() {
        if (!this.selectorId) return null;
        return this.selectors.filter(s => s.id === this.selectorId)[0];
    }

    get selectedBrand() {
        if (!this.brandId) return null;
        return this.selectedSelector.brands.filter(b => b.id === this.brandId)[0];
    }

    get pageIndex() {
        return this.page - 1;
    }

    get pageCount() {
        return Math.ceil(this.total / this.pageSize);
    }

    get options() {
        return {
            parameters: this.getParameters(),
            sortByFields: [{
                field: 'Name',
                direction: 'ASC',
            }],
        }
    }
}

decorate(InventorySearch, {
    page: observable,
    pageSize: observable,
    searchTerm: observable,
    selectorId: observable,
    brandId: observable,
    selectors: observable.deep,
    products: observable.deep,
    total: observable,
    display: observable,
    isLoading: observable,
    isSaving: observable,
    isReady: observable,
    initialize: action,
    execute: action,
    refresh: action,
    search: action,
    refreshTotal: action,
    delete: action,
    clear: action,
    selectedSelector: computed,
    selectedBrand: computed,
    pageIndex: computed,
    pageCount: computed,
    options: computed,
})

export { PAGE_SIZE, FULL_SEARCH_SIZE };
export default createContext(new InventorySearch());