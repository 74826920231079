import React, { Component } from 'react';
import { Popup } from 'devextreme-react';

import EmailCommunication from './Email';
import SmsCommunication from './Sms';
import FaxCommunication from './Fax';
import BodyEnd from '../../_shared/BodyEnd';

import * as fn from '../../../../utilities/_functions';

import './_index.scss';

const COMMUNICATION_POPUP_ID = '__communication-popup';
const COMMUNICATION_MODAL_GRID_WIDTH = 960;

export default class CommunicationModal extends Component {
    constructor(props) {
        super(props);
        this.popupRef = React.createRef();
        this.state = {
            display: false,
            zIndex: null,
            mode: null,
            defaultValues: null,
            renderRecenter: false,
        };
        this.changeMode = this.changeMode.bind(this);
        this.handleModeChange = this.handleModeChange.bind(this);
        this.handleTemplateChange = this.handleTemplateChange.bind(this);
        this.handleSent = this.handleSent.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.renderContent = this.renderContent.bind(this);
    }

    show = (mode, defaultValues) => {
        const zIndex = fn.getHighestZIndex() + 1;
        this.setState({ display: true, zIndex: zIndex, mode: mode, defaultValues: defaultValues });
    }

    close = () => {
        this.handleClose();
    }

    changeMode = mode => {
        this.handleModeChange(mode);
    }

    recenter = () => {
        if (this.popupRef && this.popupRef.current) {
            this.popupRef.current.instance.option('position', {
                my: 'center',
                at: 'center',
                of: window,
            })
        }
    }

    handleModeChange = mode => {
        this.setState({ mode });
    }

    handleShowing = event => {
        const popup = document.getElementById(COMMUNICATION_POPUP_ID);
        if (popup) {
            // const { zIndex } = this.state;
            // popup.style.zIndex = zIndex;
        }
    }

    handleShown = event => {
        this.setState({ renderRecenter: true });
    }

    handleClose = event => {
        const { defaultMode } = this.state;

        if (this.props.onClose) { this.props.onClose(); }

        this.setState({ mode: defaultMode, display: false, zIndex: null, defaultValues: null, renderRecenter: false });
    }

    handleSent = (event, result) => {
        if (fn.isFunction(this.props.onSent)) {
            this.props.onSent(event, result);
        }

        this.handleClose(event);
    }

    handleTemplateChange = (event, result) => {
        if (fn.isFunction(this.props.onTemplateChange)) {
            this.props.onTemplateChange(event, result);
        }
    }

    get isVisible() {
        return this.state.display;
    }

    renderTitle = () => {
        return <div className='popup-title-draggable'></div>
    }

    renderContent = () => {
        const { mode, defaultValues } = this.state;

        if (!mode) return null;

        const contentProps = {
            secured: this.props.secured,
            emailToEnabled: fn.isNullOrUndefined(this.props) || fn.isNullOrUndefined(this.props.emailToEnabled) || this.props.emailToEnabled === true ? true : false,
            smsToEnabled: fn.isNullOrUndefined(this.props) || fn.isNullOrUndefined(this.props.smsToEnabled) || this.props.smsToEnabled === true ? true : false,
            faxToEnabled: fn.isNullOrUndefined(this.props) || fn.isNullOrUndefined(this.props.faxToEnabled) || this.props.faxToEnabled === true ? true : false,
            suggestionEnabled: fn.isNullOrUndefined(this.props) || fn.isNullOrUndefined(this.props.suggestionEnabled) || this.props.suggestionEnabled === true ? true : false,
            defaultValues: defaultValues,
            onModeChange: this.handleModeChange,
            onTemplateChange: this.handleTemplateChange,
            onSent: this.handleSent,
            onClose: this.handleClose
        };

        switch (mode.toLowerCase()) {
            case 'email':
                return <EmailCommunication {...contentProps} />;

            case 'sms':
                return <SmsCommunication {...contentProps} />;

            case 'fax':
                return <FaxCommunication {...contentProps} />;

            default:
                return;
        }
    }

    render() {
        return <>
            <Popup
                ref={this.popupRef}
                wrapperAttr={{ id: COMMUNICATION_POPUP_ID, class: 'dx-popup-communication' }}
                animation={{
                    show: {
                        type: 'pop',
                        duration: 200,
                        from: { opacity: 0, scale: 0 },
                        to: { opacity: 1, scale: 1 }
                    },
                    hide: {
                        type: 'pop',
                        duration: 200,
                        from: { opacity: 1, scale: 1 },
                        to: { opacity: 0, scale: 0 }
                    }
                }}
                visible={this.state.display}
                shading={true}
                shadingColor="rgba(0, 0, 0, 0.2)"
                width={COMMUNICATION_MODAL_GRID_WIDTH}
                height={'auto'}
                dragEnabled={true}
                dragOutsideBoundary={true}
                showTitle={true}
                titleRender={this.renderTitle}
                contentRender={this.renderContent}
                onShowing={this.handleShowing}
                onShown={this.handleShown}
                onHiding={this.handleClose}
            />
            {
                this.state.renderRecenter ?
                    <BodyEnd>
                        <button
                            type='button'
                            className='dx-popup-recenter'
                            style={{ zIndex: fn.getHighestZIndex() }}
                            title='Re-center'
                            onClick={() => { this.recenter() }}
                        >
                            <i className='fal fa-arrows'></i>
                        </button>
                    </BodyEnd> : null
            }
        </>
    }
}