import React, { Fragment, useContext, useEffect, useState, useRef } from 'react';
import { Observer } from 'mobx-react-lite';
import FadeIn from 'react-fade-in';
import { toast } from 'react-toastify';
import momentLocalizer from 'react-widgets-moment';
import { GlobalHotKeys } from 'react-hotkeys';
import moment from 'moment';

import BodyEnd from '../../_shared/BodyEnd';
import ConfirmModal from '../../_shared/ConfirmModal';
import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import RichTextEditor from '../../_shared/RichTextEditor';

import BookingUpdateStore from '../../../../stores/BookingUpdateStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';

import { quickDrawerFocus, renderQuickDrawerLoading } from '../../_shared/QuickDrawer';
import * as ErrorMessages from '../../../../constants/errorMessages';
import * as fn from '../../../../utilities/_functions';
import * as bh from '../../../../utilities/badgeHelper';
import * as ph from '../../../../utilities/personHelper';
import * as sys from '../../../../utilities/systemHelper';
import * as oh from '../../../../utilities/operationHelper';

import './DeleteBooking.scss';

moment.locale('en');
momentLocalizer();

function DeleteBooking(props) {
    const isMounted = useRef(true);
    const validateRef = useRef(null);
    const booking = useContext(BookingUpdateStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const [confirmDelete, setConfirmDelete] = useState(false);

    useEffect(() => {
        quickDrawerFocus(props.drawer);

        return () => {
            isMounted.current = false;
        }
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (booking.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleRequestByChange = event => {
        booking.cancelRequestBy = event.target.value;
        booking.hasUnsavedChanges = true;
    }

    const handleReasonChange = (content, delta, source, editor) => {
        const reason = editor.getText().replace(/(\r\n|\n|\r)/g, ' ').trim();
        const reasonHtml = (content === '<p><br></p>') ? null : content;

        booking.cancelRequestReason = reason;
        booking.cancelRequestReasonHtml = reasonHtml;
        booking.hasUnsavedChanges = true;
    }

    const handleSubmit = event => {
        event.preventDefault();
        if (isMounted.current) {
            if (fn.validateForm(validateRef.current)) {
                setConfirmDelete(true);
            }
        }
    }

    const handleConfirmDelete = event => {
        event.preventDefault();

        booking.delete()
            .then(data => {
                if (isMounted.current) {
                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                        props.onSuccess(event, { updated: true, data: data });
                    }
                }
            })
            .catch(error => {
                const msg = <p><strong>Cannot cancel booking.</strong>{error && error.response && error.response.data ? <>&nbsp;{error.response.data}</> : null}</p>;
                toast.error(() => msg, { position: 'top-center', style: { width: '380px' } });
            })

        setConfirmDelete(false);
    }

    const handleConfirmCancel = event => {
        event.preventDefault();
        setConfirmDelete(false);

        if (props.onCancel && fn.isFunction(props.onCancel)) {
            props.onCancel(event);
        }
    }

    const renderBookingDateTime = () => {
        if (!booking.data.start) return null;

        const start = moment(booking.data.start);
        const end = booking.data.end ? moment(booking.data.end) : null;
        const hasEndTime = !!end;
        const weekday = start.format('dddd');
        const day = start.format('D');
        const ordinal = start.format('Do').replace(day, '');
        const dateHtml = `${start.format('MMMM D')}<sup>${ordinal}</sup>${((start.year() !== moment().year()) ? `, ${start.format('YYYY')}` : '')}`;
        const startTimeHtml = `${start.format('h:mm')}${(!hasEndTime || start.format('a') !== end.format('a') ? ` ${start.format('a')}` : '')}`;
        const endTimeHtml = hasEndTime ? `${end.format('h:mm')} ${end.format('a')}` : '';

        return <ul className='list-inline no-style m-0'>
            <li className='list-inline-item m-0'>
                <div className='text'>
                    <small className='weekday'>{weekday}</small>
                    <span className='date' dangerouslySetInnerHTML={{ __html: dateHtml }}></span>
                </div>
            </li>
            <li className='list-inline-item my-0 mx-1'><small>@</small></li>
            <li className='list-inline-item m-0'>
                <div className='text'>
                    <span className='time' dangerouslySetInnerHTML={{ __html: startTimeHtml }}></span>
                </div>
            </li>
            <li className='list-inline-item my-0 mx-1'><small>to</small></li>
            <li className='list-inline-item m-0'>
                <div className='text'>
                    <span className='time' dangerouslySetInnerHTML={{ __html: endTimeHtml }}></span>
                </div>
            </li>
        </ul>
    }

    const renderResource = () => {
        const resource = booking.data.user;

        return <div
            className='profile-wrapper'
        >
            <div className='profile'>
                <span
                    className={`profile-image rounded-circle d-block fw-500` + (resource && !resource.profilePictureUri ? ` profile-initials bg-color${resource.color}-500` : '')}
                    style={resource && resource.profilePictureUri ? {
                        backgroundImage: `url(${resource.profilePictureUri})`,
                        backgroundSize: 'cover',
                    } : null}
                    title={resource ? resource.fullName : 'System'}>
                    {resource && !resource.profilePictureUri ? <div className='d-initials'>{resource.initials}</div> : null}
                </span>
            </div>
            <div className='description'>
                <div className='name'>{resource.fullName}</div>
                <div className='services'>
                    {
                        booking.data.services.map((s, si) => {
                            return <Fragment
                                key={`service_${si}`}
                            >
                                {bh.renderServiceCode(s, 'p-1 mr-1')}
                            </Fragment>
                        })
                    }
                </div>
            </div>
        </div>
    }

    const renderCustomer = () => {
        return <div>
            <div className='info'>
                {ph.getFullName(booking.data)}
            </div>
            <div className='info'>
                <a href={`mailto:${booking.data.emailAddress}`}
                >{booking.data.emailAddress}
                </a>
            </div>
            <div className='info'>
                <a href={`tel:${booking.data.phoneNumber}`}
                >{sys.getFormattedPhoneNumber(booking.data.phoneNumber)}
                </a>
            </div>
        </div>;
    }

    return <>
        <form ref={validateRef} onSubmit={handleSubmit}>
            <Observer>{() =>
                <>
                    {
                        (props.drawer === quickDrawer.drawerOpened) && !confirmDelete ?
                            <GlobalHotKeys
                                keyMap={{
                                    close: ['esc'],
                                }}
                                handlers={{
                                    close: event => {
                                        handleCancel(event)
                                    },
                                }}
                                allowChanges={true}
                            /> : null
                    }
                    <fieldset disabled={booking.isSaving}>
                        <div className='quick-drawer'>
                            <QuickDrawerHeader
                                drawer={props.drawer}
                                icon={oh.getIcon('appointment', 'delete')}
                                action='Delete'
                                category='Online Booking'
                                className='delete'
                                onCancel={handleCancel}
                            />
                            <div className='quick-drawer-body'>
                                {
                                    booking.isReady ?
                                        <FadeIn>
                                            <div className='delete-appointment body-content'>
                                                <section className='date-time'>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            {renderBookingDateTime()}
                                                        </div>
                                                    </div>
                                                </section>
                                                <section className='resource'>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='form-group mb-0'>
                                                                <label><small>With</small></label>
                                                                {renderResource()}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                                <section className='customer'>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='form-group mb-0'>
                                                                <label><small>For</small></label>
                                                                {renderCustomer()}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                                <section>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <Observer>{() =>
                                                                <div className={'form-group validate validate-required mb-0'}>
                                                                    <label className='required'><small>Cancel by</small></label>
                                                                    <div className='custom-control custom-radio mb-2'>
                                                                        <input
                                                                            id='requestByCustomer'
                                                                            type='radio'
                                                                            name='requestBy'
                                                                            value='Customer'
                                                                            className='custom-control-input'
                                                                            checked={booking.cancelRequestBy === 'Customer'}
                                                                            onChange={handleRequestByChange}
                                                                        />
                                                                        <label
                                                                            htmlFor='requestByCustomer'
                                                                            className='custom-control-label'
                                                                        >
                                                                            {
                                                                                booking.data ?
                                                                                    <>{ph.getFullName(booking.data, true)} <span className='text-smaller text-gray-700'>(Patient)</span></> : <>Patient</>
                                                                            }
                                                                        </label>
                                                                    </div>
                                                                    <div className='custom-control custom-radio mb-2'>
                                                                        <input
                                                                            id='requestByResource'
                                                                            type='radio'
                                                                            name='requestBy'
                                                                            value='Resource'
                                                                            className='custom-control-input'
                                                                            checked={booking.cancelRequestBy === 'Resource'}
                                                                            onChange={handleRequestByChange}
                                                                        />
                                                                        <label
                                                                            htmlFor='requestByResource'
                                                                            className='custom-control-label'
                                                                        >
                                                                            {
                                                                                booking.data ?
                                                                                    <>{ph.getFullName(booking.data.user, true)} <span className='text-smaller text-gray-700'>(Our office)</span></> : <>Doctor</>
                                                                            }
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            }</Observer>
                                                        </div>
                                                    </div>
                                                </section>
                                                <section>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className={'form-group'}>
                                                                <label><small>Cancel Reason</small></label>
                                                                <div className='input-group'>
                                                                    <Observer>{() =>
                                                                        <RichTextEditor
                                                                            mode='basic'
                                                                            value={booking.cancelRequestReasonHtml}
                                                                            disableTab={true}
                                                                            onChange={handleReasonChange}
                                                                        />
                                                                    }</Observer>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                        </FadeIn> : renderQuickDrawerLoading()
                                }
                            </div>
                            <Observer>{() =>
                                booking.isReady ?
                                    <div className='quick-drawer-action'>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className='float-right'>
                                                    <button
                                                        type='button'
                                                        className='btn btn-link btn-cancel mr-2'
                                                        onClick={props.onCancel}
                                                    >Cancel</button>
                                                    <Observer>{() =>
                                                        <button
                                                            data-delete-appointment
                                                            type='submit'
                                                            className='btn btn-danger'
                                                        >Delete</button>
                                                    }</Observer>
                                                </div>
                                            </div>
                                        </div>
                                    </div> : null
                            }</Observer>
                        </div>
                    </fieldset>
                </>
            }</Observer>
        </form>
        <BodyEnd>
            <ConfirmModal
                icon={<i className='fal fa-calendar-times text-danger mr-2'></i>}
                message={<>Continue to <span data-continue-delete-appointment className='fw-500'>&nbsp;delete {booking.originalData ? ph.getPreferredFirstName(booking.originalData.customer) : ''}'s booking</span>? </>}
                show={confirmDelete}
                onOption1Click={handleConfirmDelete}
                onCancel={handleConfirmCancel}
            />
        </BodyEnd>
    </>
}

export default DeleteBooking;