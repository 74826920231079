import React, { useContext, useState, useRef, useEffect } from 'react';
import { usePageVisibility } from 'react-page-visibility';
import { Link } from 'react-router-dom';
import { useObserver } from 'mobx-react-lite';
import { ScrollView } from 'devextreme-react';
import DataGrid, { Sorting, Scrolling, Column } from 'devextreme-react/data-grid';
import { toast } from 'react-toastify';
import moment from 'moment';

import NotAvailableModal from './NotAvailableModal';
import CommunicationModal from '../../communications/CommunicationModal/_index';
import { renderBalance, getPurchaseCss } from './_index';
import DisabledOverlay from '../../_shared/DisabledOverlay';
import LoadingOverlay from '../../_shared/LoadingOverlay';
import { quickDrawerFocus } from '../../_shared/QuickDrawer';


import useSignalR from '../../../hooks/useSignalR';
import PurchaseStore from '../../../../stores/PurchaseViewStore';
import PurchaseUpdateStore from '../../../../stores/PurchaseUpdateStore';
import WorkOrderCreateStore from '../../../../stores/WorkOrderCreateStore';
import CommunicationStore from '../../../../stores/CommunicationStore';
import FileViewerStore from '../../../../stores/FileViewerStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';

import * as AccessType from '../../../../constants/accessTypes';
import * as rts from '../../../../constants/routes';
import * as fn from '../../../../utilities/_functions';
import * as bh from '../../../../utilities/badgeHelper';
import * as ih from '../../../../utilities/invoiceHelper';
import * as ah from '../../../../utilities/accessHelper';
import * as oh from '../../../../utilities/operationHelper';
import * as sys from '../../../../utilities/systemHelper';
import { toJS } from 'mobx';

function ViewPurchaseContent(props) {
    const signalR = useSignalR();
    const isMounted = useRef(true);
    const focusTimer = useRef(null);
    const isPageVisible = useRef(null);
    const communicationModalRef = useRef(null);
    const pageVisibility = usePageVisibility();
    const purchase = useContext(PurchaseStore);
    const updatePurchase = useContext(PurchaseUpdateStore);
    const newWorkOrder = useContext(WorkOrderCreateStore);
    const communication = useContext(CommunicationStore);
    const fileViewer = useContext(FileViewerStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const [isGeneratingPrint, setIsGeneratingPrint] = useState(false);
    const [hasSaveConflicts, setHasSaveConflicts] = useState(false);
    const [forceReload, setForceReload] = useState(false);
    const [pendingPaymentIndex, setPendingPaymentIndex] = useState(0);

    useEffect(() => {
        signalR.on('WorkOrder', (updated) => {
            if (updated && purchase.data && purchase.data.id === updated.purchaseId) {
                if (isPageVisible.current) {
                    purchase.reload();
                } else if (isMounted.current) {
                    setForceReload(true);
                }
            }
        });
        signalR.on('Payment', (updated) => {
            if (updated && purchase.data && purchase.data.customerId === updated.customerId) {
                if (isPageVisible.current) {
                    purchase.reload();
                } else if (isMounted.current) {
                    setForceReload(true);
                }
            }
        });

        return () => {
            isMounted.current = false;
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
        }
    }, []) // eslint-disable-line

    useEffect(() => {
        if (pageVisibility && forceReload) {
            purchase.reload()
                .then(() => {
                    if (isMounted.current) {
                        setForceReload(false);
                    }
                })
        }
        isPageVisible.current = pageVisibility;
    }, [pageVisibility]) // eslint-disable-line

    const handleClose = () => {
        props.onClose();
    }

    const handleOpenRefundedPurchase = (event, id) => {
        purchase.load(id);
    }

    const handlePayment = () => {
        if (ah.check(AccessType.UPDATE_PURCHASE)) {
            updatePurchase.load(purchase.id)
                .then(() => {
                    if (isMounted.current) {
                        quickDrawer.activateQuickDrawer('purchase', 'payment', null, handlePaymentSuccess, handlePaymentCancel)
                            .then(drawer => {
                                if (isMounted.current) {
                                    focusTimer.current = setTimeout(() => {
                                        quickDrawerFocus(drawer);
                                    }, 100);
                                }
                            })
                    }
                });
        }
    }

    const handlePaymentSuccess = () => {
        purchase.reload()
            .then(() => {
                if (isMounted.current) {
                    updatePurchase.clear();
                    toast.dark(() => <p data-pymt-ctd>Payment created.</p>);
                }
            })
    }

    const handlePaymentCancel = () => {
        updatePurchase.clear();
    }

    const handleRefundPayment = () => {
        if (ah.check(AccessType.UPDATE_PURCHASE)) {
            updatePurchase.load(purchase.id)
                .then(() => {
                    if (isMounted.current) {
                        const extraProps = {
                            amount: updatePurchase.data.remainingBalance * -1.0,
                            paymentMethod: updatePurchase.data.payments.some(p => p.paymentMethod) ?
                                updatePurchase.data.payments.filter(p => p.paymentMethod)[0].paymentMethod : null
                        };

                        quickDrawer.activateQuickDrawer('purchase', 'refund-payment', extraProps, handleRefundPaymentSuccess, handleRefundPaymentCancel)
                            .then(drawer => {
                                if (isMounted.current) {
                                    focusTimer.current = setTimeout(() => {
                                        quickDrawerFocus(drawer);
                                    }, 100);
                                }
                            });
                    }
                })
        }
    }

    const handleRefundPaymentSuccess = () => {
        purchase.reload()
            .then(() => {
                if (isMounted.current) {
                    updatePurchase.clear();
                    toast.dark(() => <p data-refund-ctd>Refund created.</p>);
                }
            })
    }

    const handleRefundPaymentCancel = () => {
        updatePurchase.clear();
    }

    const handleComplete = event => {
        event.preventDefault();

        updatePurchase.clear();
        updatePurchase.load(purchase.id)
            .then(() => {
                if (isMounted.current) {
                    updatePurchase.complete(true)
                        .then(() => {
                            if (isMounted.current) {
                                purchase.reload();
                            }
                        })
                        .catch(() => {
                            if (isMounted.current) {
                                setHasSaveConflicts(true);
                            }
                        })
                }
            })
    }

    const handleModeChange = mode => {
        updatePurchase.load(purchase.id)
            .then(() => {
                if (mode === 'update') {
                    updatePurchase.resetDefaultTransactionItem();
                    // TODO: hack? resetDefaultTransactionItem set hasUnsavedChanges => true
                    updatePurchase.hasUnsavedChanges = false;
                }
            })
        props.onModeChange(mode);
    }

    const handleDelete = event => {
        event.preventDefault();
        updatePurchase.load(purchase.id)
            .then(() => {
                updatePurchase.delete(true)
                    .then(() => {
                        if (isMounted.current) {
                            purchase.clear();
                            if (fn.isFunction(props.onClose)) {
                                props.onClose();
                            }
                        }
                    })
            })
    }

    const handleUpdateCost = () => {
        if (ah.check(AccessType.UPDATE_PURCHASE)) {
            updatePurchase.load(purchase.id)
                .then(() => {
                    if (isMounted.current) {
                        quickDrawer.activateQuickDrawer('purchase', 'update-cost', null, handlePurchaseUpdateCostSuccess, handlePurchaseUpdateCostCancel)
                            .then(drawer => {
                                if (isMounted.current) {
                                    focusTimer.current = setTimeout(() => {
                                        quickDrawerFocus(drawer);
                                    }, 100);
                                }
                            });
                    }
                })
        }
    }

    const handlePurchaseUpdateCostSuccess = (result) => {
        if (result && result.updated) {
            purchase.reload()
                .then(() => {
                    if (isMounted.current) {
                        toast.dark(() => <p data-inv-cost-upated>Invoice cost updated.</p>);
                        updatePurchase.clear();
                    }
                });
        }
    }

    const handlePurchaseUpdateCostCancel = () => {
        updatePurchase.clear();
    }

    const handleRefundPurchase = () => {
        if (ah.check(AccessType.UPDATE_PURCHASE)) {
            updatePurchase.load(purchase.id)
                .then(() => {
                    if (isMounted.current) {
                        const extraProps = {
                            amount: (updatePurchase.data.totalPaid ? updatePurchase.data.totalPaid : 0) + (updatePurchase.data.totalRefunded ? updatePurchase.data.totalRefunded : 0),
                            paymentMethod: updatePurchase.data.payments.some(p => p.paymentMethod) ?
                                updatePurchase.data.payments.filter(p => p.paymentMethod)[0].paymentMethod : null
                        };

                        updatePurchase.refundPurchase(true)
                            .then(() => {
                                if (isMounted.current) {
                                    quickDrawer.activateQuickDrawer('purchase', 'refund-purchase', extraProps, handleRefundPurchaseSuccess, handleRefundPurchaseCancel, handleRefundPurchaseError)
                                        .then(drawer => {
                                            if (isMounted.current) {
                                                focusTimer.current = setTimeout(() => {
                                                    quickDrawerFocus(drawer);
                                                }, 100);
                                            }
                                        });
                                }
                            })
                    }
                })
        }
    }

    const handleRefundPurchaseSuccess = ({ requiredVerification, data }) => {
        if (requiredVerification) {
            quickDrawer.activateQuickDrawer('purchase', 'verify-returns', { action: 'refund' }, handleVerifyRefundReturnsSuccess, handleVerifyRefundReturnsCancel)
                .then(drawer => {
                    if (isMounted.current) {
                        focusTimer.current = setTimeout(() => {
                            quickDrawerFocus(drawer);
                        }, 100);
                    }
                });
        }
        else {
            purchase.reload()
                .then(() => {
                    if (isMounted.current) {
                        toast.dark(() => <p data-inv-refunded>Invoice refunded.</p>);
                        updatePurchase.clear();
                    }
                })
        }
    }

    const handleVerifyRefundReturnsSuccess = (result) => {
        if (result.updated) {
            purchase.reload()
                .then(() => {
                    if (isMounted.current) {
                        toast.dark(() => <p data-inv-refunded>Invoice refunded.</p>);
                        updatePurchase.clear();
                    }
                })
        } else {
            handleModeChange('view');
        }
    }

    const handleVerifyRefundReturnsCancel = event => {
        updatePurchase.clear();
        handleModeChange('view');
    }

    // const handleRefundPurchaseSuccess = () => {
    //     purchase.reload()
    //         .then(() => {
    //             if (isMounted.current) {
    //                 toast.dark(() => <p data-txn-refunded>Transaction refunded.</p>);
    //             }
    //         })
    // }

    const handleRefundPurchaseCancel = () => {
    }

    const handleRefundPurchaseError = () => {
    }

    const handleNoChargePurchase = () => {
        if (ah.check(AccessType.UPDATE_PURCHASE)) {
            updatePurchase.load(purchase.id)
                .then(() => {
                    if (isMounted.current) {
                        updatePurchase.noChargePurchase(true)
                            .then(() => {
                                if (isMounted.current) {
                                    quickDrawer.activateQuickDrawer('purchase', 'no-charge-purchase', null, handleNoChargePurchaseSuccess, handleNoChargePurchaseCancel)
                                        .then(drawer => {
                                            if (isMounted.current) {
                                                focusTimer.current = setTimeout(() => {
                                                    quickDrawerFocus(drawer);
                                                }, 100);
                                            }
                                        });
                                }
                            })
                    }
                })
        }
    }

    const handleNoChargePurchaseSuccess = () => {
        purchase.reload()
            .then(() => {
                if (isMounted.current) {
                    toast.dark(() => <p data-txn-upd>Transaction updated.</p>);
                }
            })
    }

    const handleNoChargePurchaseCancel = () => {
        updatePurchase.clear();
    }

    const handleUncollectiblePurchase = () => {
        if (ah.check(AccessType.UPDATE_PURCHASE)) {
            updatePurchase.load(purchase.id)
                .then(() => {
                    if (isMounted.current) {
                        updatePurchase.uncollectiblePurchase(true)
                            .then(() => {
                                if (isMounted.current) {
                                    quickDrawer.activateQuickDrawer('purchase', 'uncollectible-purchase', null, handleUncollectiblePurchaseSuccess, handleUncollectiblePurchaseCancel)
                                        .then(drawer => {
                                            if (isMounted.current) {
                                                focusTimer.current = setTimeout(() => {
                                                    quickDrawerFocus(drawer);
                                                }, 100);
                                            }
                                        });
                                }
                            })
                    }
                })
        }
    }

    const handleUncollectiblePurchaseSuccess = () => {
        purchase.reload()
            .then(() => {
                if (isMounted.current) {
                    toast.dark(() => <p data-txn-upd>Transaction updated.</p>);
                }
            })
    }

    const handleUncollectiblePurchaseCancel = () => {
        updatePurchase.clear();
    }

    const handleVoidPurchase = () => {
        if (ah.check(AccessType.UPDATE_PURCHASE)) {
            updatePurchase.load(purchase.id)
                .then(() => {
                    if (isMounted.current) {
                        updatePurchase.voidPurchase(true)
                            .then(() => {
                                if (isMounted.current) {
                                    quickDrawer.activateQuickDrawer('purchase', 'void-purchase', null, handleVoidPurchaseSuccess, handleVoidPurchaseCancel, handleVoidPurchaseError)
                                        .then(drawer => {
                                            if (isMounted.current) {
                                                focusTimer.current = setTimeout(() => {
                                                    quickDrawerFocus(drawer);
                                                }, 100);
                                            }
                                        });
                                }
                            })
                    }
                })
        }
    }

    const handleVoidPurchaseSuccess = ({ requiredVerification, data }) => {
        if (requiredVerification) {
            quickDrawer.activateQuickDrawer('purchase', 'verify-returns', { action: 'void' }, handleVerifyVoidReturnsSuccess, handleVerifyVoidReturnsCancel)
                .then(drawer => {
                    if (isMounted.current) {
                        focusTimer.current = setTimeout(() => {
                            quickDrawerFocus(drawer);
                        }, 100);
                    }
                });
        }
        else {
            purchase.reload()
                .then(() => {
                    if (isMounted.current) {
                        toast.dark(() => <p data-txn-voided>Transaction voided.</p>);
                        updatePurchase.clear();
                    }
                })
        }
    }

    const handleVoidPurchaseCancel = () => {
        updatePurchase.clear();
    }

    const handleVoidPurchaseError = () => {
    }

    const handleVerifyVoidReturnsSuccess = (result) => {
        if (result.updated) {
            purchase.reload()
                .then(() => {
                    if (isMounted.current) {
                        toast.dark(() => <p data-txn-voided>Transaction voided.</p>);
                        updatePurchase.clear();
                    }
                })
        } else {
            handleModeChange('view');
        }
    }

    const handleVerifyVoidReturnsCancel = event => {
        updatePurchase.clear();
        handleModeChange('view');
    }

    const handleWorkOrders = event => {
        if (purchase.data && purchase.data.workOrders && purchase.data.workOrders.length > 0) {
            quickDrawer.activateQuickDrawer('purchase', 'work-orders');
        }
        else {
            newWorkOrder.initialize(purchase.data.customerId, purchase.id)
                .then(() => {
                    quickDrawer.activateQuickDrawer('order', 'create', null, handleNewWorkOrderSuccess, handleNewWorkOrderCancel)
                        .then(drawer => {
                            if (isMounted.current) {
                                focusTimer.current = setTimeout(() => {
                                    quickDrawerFocus(drawer);
                                }, 100);
                            }
                        });
                })
        }
    }

    const handleNewWorkOrderSuccess = (event, result) => {
        if (result && result.updated) {
            if (result.data && result.data.length > 0) {
                toast.dark(() => <p data-wo-added>Work order(s) added.</p>);
            }
            else {
                toast.dark(() => <p data-wo-added>Work order added.</p>);
            }
        }
        newWorkOrder.clear();
    }

    const handleNewWorkOrderCancel = (event) => {
        newWorkOrder.clear();
    }

    const handlePaymentHistory = event => {
        quickDrawer.activateQuickDrawer('purchase', 'payment-history', null)
            .then(drawer => {
                if (isMounted.current) {
                    focusTimer.current = setTimeout(() => {
                        quickDrawerFocus(drawer);
                    }, 100);
                }
            });
    }

    const handleSaveConflictsCancel = () => {
        setHasSaveConflicts(false);
    }

    const handleCollectThisClick = (event, insurancePayment) => {
        if (insurancePayment) {
            const { id, data } = insurancePayment;

            updatePurchase.load(purchase.id);
            quickDrawer.activateQuickDrawer('purchase', 'private-insurance-payment', { id, data }, handleCollectThisSuccess, handleCollectThisCancel)
                .then(drawer => {
                    if (isMounted.current) {
                        focusTimer.current = setTimeout(() => {
                            quickDrawerFocus(drawer);
                        }, 100);
                    }
                });
        }
    }

    const handleCollectThisSuccess = () => {
        purchase.reload()
            .then(() => {
                if (isMounted.current) {
                    updatePurchase.clear();

                    if (fn.isFunction(props.onCancel)) {
                        props.onCancel();
                    }
                }
            })
    }

    const handleCollectThisCancel = () => {
        updatePurchase.clear();
    }

    const handleViewInvoice = event => {
        ih.view(purchase.data, fileViewer, setIsGeneratingPrint);
    }

    const handleDownloadInvoice = event => {
        ih.download(purchase.data, setIsGeneratingPrint);
    }

    const handleEmailInvoice = (event) => {
        ih.email(purchase.data, communication, setIsGeneratingPrint)
            .then(() => {
                communicationModalRef.current.show('email');
            })
    }

    const handlePrintInvoice = event => {
        ih.print(purchase.data, setIsGeneratingPrint);
    }

    const handleRegenerateInvoice = event => {
        ih.regenerate(purchase.data, fileViewer, handleRegeneratingPrint);
    }

    const handleRegeneratingPrint = (inProgress) => {
        if (!inProgress) {
            toast.dark(() => <p data-inv-regen>Invoice regenerated.</p>);
        }

        setIsGeneratingPrint(inProgress)
    }

    const handlePendingPaymentPrevious = () => {
        if (isMounted.current) {
            let tempPendingPaymentIndex = pendingPaymentIndex;

            tempPendingPaymentIndex = tempPendingPaymentIndex - 1;

            if (tempPendingPaymentIndex < 0) {
                tempPendingPaymentIndex = (purchase.data.pendingPayments.length - 1);
            }

            setPendingPaymentIndex(tempPendingPaymentIndex);
        }
    }

    const handlePendingPaymentNext = () => {
        if (isMounted.current) {
            let tempPendingPaymentIndex = pendingPaymentIndex;

            tempPendingPaymentIndex = tempPendingPaymentIndex + 1;

            if (tempPendingPaymentIndex === purchase.data.pendingPayments.length) {
                tempPendingPaymentIndex = 0;
            }

            setPendingPaymentIndex(tempPendingPaymentIndex);
        }
    }

    const handleCommunicationSent = (event, result) => {
        if (result && result.type) {
            toast.dark(() => <p data-comm-sent><span className='tt-capitalize'>{result.type}</span> {result.scheduledSendDateTimeLocal ? 'scheduled' : 'sent'}.</p>);
        }
        else {
            toast.dark(() => <p data-comm-sent>Communication sent.</p>);
        }
        communicationModalRef.current.close();
    }

    const renderWorkOrder = (item) => {
        if (!purchase || !purchase.data || !purchase.data.workOrders) return;

        const orders = purchase.data.workOrders.filter(o => !o.isExpired && !o.isDeactivated && o.items.some(i => i.transactionItemIds.some(id => id === item.id)));

        if (orders && orders.length > 0) {
            const order = orders.sort((a, b) => moment.utc(a.createdDateUtc).isSameOrBefore(moment.utc(b.createdDateUtc)) ? 1 : -1)[0];
            const firstTransactionItem = purchase.data.items.filter(ti => order.items.some(oi => oi.transactionItemIds.some(id => id === ti.id))).sort((a, b) => { return a.displayOrder - b.displayOrder })[0];

            if (firstTransactionItem.id === item.id) {
                return <span className='tt-normal text-nowrap'>(<Link to={`${rts.WorkOrders.View}/${order.id}`}>Work order # {order.number}</Link>)</span>
            }
        }
    }

    const renderStockIcon = (item) => {
        if (!purchase || !purchase.data || !item) return <></>;

        switch (purchase.data.status) {
            case 'Incomplete':
            case 'Completed':
                if (item.isStocked && item.isFromInventory) {
                    return <i className='fas fa-inbox-out text-success' title='Taken from stock'></i>;
                }
                break;

            case 'Refunded':
            case 'Voided':
                if (item.isReturnToInventory) {
                    if (item.isFromInventory) {
                        return <>
                            <i className='fas fa-inbox-out text-warning mr-2' title='Taken from stock'></i>
                            <i className='fas fa-inbox-in text-warning' title='Returned to stock'></i>
                        </>;
                    } else {
                        return <>
                            <i className='fas fa-inbox mr-1' title='Not taken from stock'></i>
                            <i className='fas fa-inbox-in text-warning' title='Returned to stock'></i>
                        </>;
                    }
                } else {
                    if (item.isFromInventory) {
                        return <>
                            <i className='fas fa-inbox-out text-danger mr-2' title='Taken from stock'></i>
                            <i className='fas fa-inbox text-danger' title='Not returned to stock'></i>
                        </>
                    }
                }
                break;

            default:
                break;
        }

        return <></>;
    }

    const renderTitle = () => {
        return <div className='popup-title'>
            <div className='actions left-actions'>
                <ul>
                    {renderInvoiceActions()}
                    {renderHistoryActions()}
                </ul>
            </div>
            <div className='actions right-actions'>
                <ul>
                    {renderPurchaseActions()}
                    <li>
                        <button type='button' className='btn btn-icon btn-close' onClick={handleClose}>
                            <i className='close-icon fal fa-times fs-xl'></i>
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    }

    const renderContent = () => {
        return <div data-view-purchase className='popup-body'>
            <ScrollView width='100%' height='100%'>
                {
                    purchase.data ?
                        <>
                            <div className='order-modal-header'>
                                <div className='order-header pt-2 px-4'>
                                    <div className='order-description'>
                                        <h3 className='purchase-title'>Invoice Detail</h3>
                                        <p>Invoice # <strong>{purchase.data.invoiceNumber}</strong></p>
                                        <div className={'order-status ' + getPurchaseCss(purchase)}>
                                            <p className='title'>Status</p>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>Invoice</td>
                                                        <td>
                                                            {bh.renderPurchaseBalanceStatus(purchase.data, 'text-truncate text-truncate-md fs-sm p-2')}
                                                        </td>
                                                    </tr>
                                                    {renderBalance(purchase)}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    {renderPendingPayments()}
                                    {
                                        purchase.data.shippingAddress ?
                                            <div className='order-extra'>
                                                <p className='title'>Shipping Address</p>
                                                <address>
                                                    {
                                                        purchase.data.shippingAddress && purchase.data.shippingAddress.addressLine1 ?
                                                            <p>{purchase.data.shippingAddress.addressLine1}</p> : null
                                                    }
                                                    {
                                                        purchase.data.shippingAddress && purchase.data.shippingAddress.addressLine2 ?
                                                            <p>{purchase.data.shippingAddress.addressLine2}</p> : null
                                                    }
                                                    {
                                                        purchase.data.shippingAddress && purchase.data.shippingAddress.country ?
                                                            <p>{purchase.data.shippingAddress.country}</p> : null
                                                    }
                                                </address>
                                            </div> : null
                                    }
                                    {
                                        purchase.data.customer ?
                                            <div className='order-extra'>
                                                <p className='title'>Billing Information</p>
                                                <>
                                                    <p className='name'>{purchase.data.customerName}</p>
                                                    {
                                                        purchase.data.billingAddress || purchase.data.customerEmail || purchase.data.customerPhoneNumber ?
                                                            <address>
                                                                {
                                                                    purchase.data.billingAddress && purchase.data.billingAddress.addressLine1 ?
                                                                        <p>{purchase.data.billingAddress.addressLine1}</p> : null
                                                                }
                                                                {
                                                                    purchase.data.billingAddress && purchase.data.billingAddress.addressLine2 ?
                                                                        <p>{purchase.data.billingAddress.addressLine2}</p> : null
                                                                }
                                                                {
                                                                    purchase.data.billingAddress && purchase.data.billingAddress.country ?
                                                                        <p>{purchase.data.billingAddress.country}</p> : null
                                                                }
                                                                {
                                                                    purchase.data.customerEmail ?
                                                                        <p><a href={`mailto:${purchase.data.customerEmail}`}>{purchase.data.customerEmail}</a></p> : null
                                                                }
                                                                {
                                                                    purchase.data.customerPhoneNumber ?
                                                                        <p><a href={`tel:${purchase.data.customerPhoneNumber}`}>{purchase.data.customerPhoneNumber}</a></p> : null
                                                                }
                                                            </address> : null
                                                    }
                                                </>
                                            </div> : null
                                    }
                                </div>
                                <div className='order-header pb-2 px-4'>
                                    <div className='order-description'>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td><span className='title'>Invoice Date</span></td>
                                                    <td>{(purchase.data.invoiceDate ? moment(purchase.data.invoiceDate) : (purchase.data.completedDateUtc ? moment.utc(purchase.data.completedDateUtc).local() : moment())).format('MMMM D, YYYY')}</td>
                                                </tr>
                                                <tr>
                                                    <td><span className='title'>Due Date</span></td>
                                                    <td>{(purchase.data.dueDate ? moment(purchase.data.dueDate) : moment()).format('MMMM D, YYYY')}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    {
                                        purchase.data.insuranceInformation && JSON.stringify(purchase.data.insuranceInformation) !== JSON.stringify({}) ?
                                            <div className='order-extra'></div> : null
                                    }
                                    {
                                        purchase.data.shippingAddress ?
                                            <div className='order-extra'></div> : null
                                    }
                                    {
                                        purchase.data.customer ?
                                            <>
                                                <div className='order-extra'>
                                                    <p className='title'>Licensed Practitioner</p>
                                                    <p>
                                                        {
                                                            purchase.data.user ?
                                                                <><strong>{purchase.data.user.fullName}</strong>{purchase.data.user.licenseNumber ? <span className='ml-1'>(No.: {purchase.data.user.licenseNumber})</span> : null}</> :
                                                                <span className='text-gray-600'>None</span>
                                                        }
                                                    </p>
                                                </div>
                                            </> : null
                                    }
                                </div>
                            </div>
                            <div className='order-detail'>
                                <DataGrid
                                    keyExpr='id'
                                    width='100%'
                                    activeStateEnabled={false}
                                    focusStateEnabled={false}
                                    hoverStateEnabled={false}
                                    dataSource={purchase.data.items}
                                    showBorders={false}
                                    showRowLines={true}
                                    showColumnLines={false}
                                    allowColumnReordering={false}
                                    allowColumnResizing={false}
                                >
                                    <Scrolling rowRenderingMode='standard'></Scrolling>
                                    <Sorting mode='none' />
                                    <Column
                                        dataField='descriptor'
                                        caption='Description'
                                        alignment='left'
                                        width='40%'
                                        cellRender={({ data }) => {
                                            return <>
                                                {data.transactionItemType !== 'Service' ? <strong className='quantity text-lowercase'>{data.quantity}x&nbsp;&nbsp;</strong> : null}{(data.isOverridden ? data.overriddenDescriptor : data.descriptor)}&nbsp;{renderWorkOrder(data)}
                                            </>
                                        }}
                                    />
                                    <Column
                                        dataField='isStocked'
                                        caption='Stock'
                                        alignment='center'
                                        width='12%'
                                        cellRender={({ data }) => {
                                            return renderStockIcon(data)
                                        }}
                                    />
                                    <Column
                                        dataField='unitPrice'
                                        caption='Unit Price'
                                        alignment='right'
                                        width='12%'
                                        cellRender={({ data }) => {
                                            return fn.formatCurrency(data.unitPrice)
                                        }}
                                    />
                                    <Column
                                        dataField='discountAmount'
                                        caption='Discount'
                                        alignment='right'
                                        width='12%'
                                        cellRender={({ data }) => {
                                            return <>{fn.formatCurrency((data.discountAmount * -1.0), '--')}{data.discountAmount && data.discountRate ? <small className='ml-1'>({fn.formatPercent(data.discountRate)})</small> : null}</>
                                        }}
                                    />
                                    <Column
                                        dataField='isTaxable'
                                        caption='Tax'
                                        alignment='right'
                                        width='12%'
                                        cellRender={({ data }) => {
                                            return <>{(data.isTaxable ? fn.formatPercent((data.taxRate1 + data.taxRate2 + data.taxRate3)) : '--')}</>
                                        }}
                                    />
                                    <Column
                                        dataField='price'
                                        caption='Total'
                                        alignment='right'
                                        width='12%'
                                        cellRender={({ data }) => {
                                            return fn.formatCurrency(data.price)
                                        }}
                                    />
                                </DataGrid>
                                <table className='table-order-detail-bottom'>
                                    <colgroup>
                                        <col style={{ width: '76%' }}></col>
                                        <col style={{ width: '12%' }}></col>
                                        <col style={{ width: '12%' }}></col>
                                    </colgroup>
                                    <tbody role='presentation'>
                                        <tr role='row' style={{ borderBottom: '1px solid #ddd' }}>
                                            <td rowSpan={4} style={{ verticalAlign: 'top', borderRight: '1px solid #ddd' }}>
                                                {
                                                    purchase.data.noteHtml ? <>
                                                        <strong className='d-block text-gray-500 text-uppercase mt-3'>Note:</strong>
                                                        <div className='p-2 html' dangerouslySetInnerHTML={{ __html: purchase.data.noteHtml }}></div>
                                                    </> : null
                                                }
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                <strong>Subtotal</strong>
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                <strong>{fn.formatCurrency(purchase.data.subTotal)}</strong>
                                            </td>
                                        </tr>
                                        <tr role='row' style={{ borderBottom: '1px solid #ddd' }}>
                                            <td style={{ textAlign: 'right' }}>
                                                <strong>Discount</strong>
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                {fn.formatCurrency(purchase.data.discountAmount * -1.0, '--')}
                                            </td>
                                        </tr>
                                        <tr role='row' style={{ borderBottom: '2px solid #ddd' }}>
                                            <td style={{ textAlign: 'right' }}>
                                                <strong>Tax</strong>
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                {fn.formatCurrency(purchase.data.totalTaxAmount)}
                                            </td>
                                        </tr>
                                        <tr role='row' style={{ borderBottom: '2px solid #ddd' }}>
                                            <td style={{ textAlign: 'right' }}>
                                                <strong>Total</strong>
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                <strong>{fn.formatCurrency(purchase.data.total)}</strong>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </> : null
                }
            </ScrollView>
            <NotAvailableModal display={hasSaveConflicts} onCancel={handleSaveConflictsCancel} />
        </div>
    }

    const renderPendingPayments = () => {
        if (isMounted.current && purchase && purchase.data && purchase.data.pendingPayments && purchase.data.pendingPayments.length > 0 && purchase.data.pendingPayments.length > pendingPaymentIndex) {
            const selectedPendingPayment = toJS(purchase.data.pendingPayments[pendingPaymentIndex]);
            const payment = purchase.data.payments && purchase.data.payments.some(p => p.id === selectedPendingPayment.id) ? purchase.data.payments.filter(p => p.id === selectedPendingPayment.id)[0] : null
            let title = 'Payment Information';
            let content;

            switch (selectedPendingPayment.paymentMethod) {
                case 'PrivateInsurance':
                    const { data: insuranceInformation } = selectedPendingPayment;
                    title = 'Insurance Information';
                    content = <>
                        <p className='name text-truncate text-truncate-lg'>{insuranceInformation.provider}</p>
                        {
                            insuranceInformation.policyHolderName ?
                                <p className='info'>{insuranceInformation.policyHolderName}</p> : null
                        }
                        {
                            insuranceInformation.memberId ||
                                insuranceInformation.planNumber ||
                                insuranceInformation.accountNumber ||
                                insuranceInformation.policyNumber ?
                                <div className='info'>{[
                                    insuranceInformation.memberId,
                                    insuranceInformation.planNumber,
                                    insuranceInformation.accountNumber,
                                    insuranceInformation.policyNumber
                                ].filter(i => !!i).join(' - ')}</div> : null
                        }
                        {
                            insuranceInformation.amount ?
                                <p className='info fw-500'>{fn.formatCurrency(insuranceInformation.amount)}</p> : null
                        }
                        {
                            payment && payment.isToBeCollected ?
                                <button
                                    type='button'
                                    className='btn btn-sm btn-link text-right p-0 text-success text-success-800-hover'
                                    onClick={(e) => handleCollectThisClick(e, selectedPendingPayment)}
                                >
                                    Mark as collected
                                </button> : null
                        }
                    </>;
                    break;

                case 'GovernmentProgram':
                    const { data: governmentProgramInformation } = selectedPendingPayment;
                    title = 'Government Program';
                    content = <>
                        <p className='name'>{governmentProgramInformation.name}{(governmentProgramInformation.code ? ` (${governmentProgramInformation.code})` : '')}</p>
                        {
                            governmentProgramInformation.amount ?
                                <p className='info fw-500'>{fn.formatCurrency(governmentProgramInformation.amount)}</p> : null
                        }
                        {
                            payment && payment.isToBeCollected ?
                                <button
                                    type='button'
                                    className='btn btn-sm btn-link text-right p-0 text-success text-success-800-hover'
                                    onClick={(e) => handleCollectThisClick(e, selectedPendingPayment)}
                                >
                                    Mark as collected
                                </button> : null
                        }
                    </>;
                    break;

                default:
                    break;
            }

            return <div className='order-extra'>
                <div className='d-flex w-100 align-items-center'>
                    {
                        purchase.data.pendingPayments.length > 1 ?
                            <button
                                type='button'
                                className='btn fs-110 p-0 h-auto line-height-0 mb-1 ml-n3 mr-2'
                                onClick={handlePendingPaymentPrevious}
                            >
                                <i className='fas fa-caret-left'></i>
                            </button> : null
                    }
                    <p className='title'>{title}</p>
                    {
                        purchase.data.pendingPayments.length > 1 ?
                            <button
                                type='button'
                                className='btn fs-110 p-0 h-auto line-height-0 mb-1 ml-2'
                                onClick={handlePendingPaymentNext}
                            >
                                <i className='fas fa-caret-right'></i>
                            </button> : null
                    }
                </div>
                {content}
            </div>
        }

        return null;
    }

    const renderHistoryActions = () => {
        if (purchase.data) {
            switch (purchase.data.status) {
                case 'Incomplete':
                    break;

                case 'Completed':
                case 'Refunded':
                case 'Uncollectible':
                case 'Voided':
                    return <>
                        <li>
                            <div className='btn-group'>
                                <button
                                    className='btn btn-icon'
                                    title='Transaction history'
                                    onClick={() => alert('Not ready')}
                                >
                                    <i className='fal fa-history'></i>
                                </button>
                                <button
                                    className='btn btn-icon'
                                    title={purchase.data.customerId ? 'Work order(s)' : 'Cannot create work order on anonymous sales'}
                                    disabled={!purchase.data.customerId || purchase.data.customer.isSystemCustomer}
                                    onClick={handleWorkOrders}
                                >
                                    <i className={oh.getIcon('work-order', 'default')}></i>
                                    {
                                        purchase.data && purchase.data.workOrders && purchase.data.workOrders.length > 0 ?
                                            <span className={`badge badge-icon ml-n1`}>{purchase.data.workOrders.filter(o => !o.isExpired && !o.isDeactivated).length}</span> : null
                                    }
                                </button>
                                <button
                                    className='btn btn-icon'
                                    title='Payment history'
                                    onClick={handlePaymentHistory}
                                >
                                    <i className={oh.getIcon('purchase', 'history')}></i>
                                    {
                                        purchase.data && purchase.data.payments && purchase.data.payments.length > 0 ?
                                            <span className={`badge badge-icon ml-n1`}>{purchase.data.payments.length}</span> : null
                                    }
                                </button>
                            </div>
                        </li>
                    </>;

                default:
                    break;
            }
        }
    }

    const renderInvoiceActions = () => {
        if (purchase.data) {
            switch (purchase.data.status) {
                case 'Incomplete':
                case 'Voided':
                    break;

                case 'Completed':
                case 'Refunded':
                case 'Uncollectible':
                    return <>
                        {
                            purchase.data.isPrinted ?
                                <li>
                                    <div className='btn-group'>
                                        <button
                                            className='btn btn-icon'
                                            title='Regenerate invoice'
                                            onClick={handleRegenerateInvoice}
                                        >
                                            <i className='fab fa-sync text-primary'></i>
                                        </button>
                                    </div>
                                </li> : null
                        }
                        <li>
                            <div className='btn-group'>
                                <button
                                    className='btn btn-icon'
                                    title='View invoice'
                                    onClick={handleViewInvoice}
                                >
                                    <i className='fal fa-file-search'></i>
                                </button>
                                <button
                                    className='btn btn-icon'
                                    title='Download invoice'
                                    onClick={handleDownloadInvoice}
                                >
                                    <i className='fal fa-file-download'></i>
                                </button>
                                {
                                    purchase.data && purchase.data.customer ?
                                        <>
                                            {/* <button
                                            className='btn btn-icon'
                                            title='Send invoice via SMS'
                                            onClick={() => alert('Not ready')}
                                        >
                                            <i className='fal fa-comments-alt'></i>
                                        </button> */}
                                            <button
                                                className='btn btn-icon'
                                                title='Email invoice'
                                                onClick={handleEmailInvoice}
                                            >
                                                <i className='fal fa-envelope'></i>
                                            </button>
                                        </> : null
                                }
                                <button
                                    className='btn btn-icon'
                                    title='Print invoice'
                                    onClick={handlePrintInvoice}
                                >
                                    <i className='fal fa-print'></i>
                                </button>
                            </div>
                        </li>
                    </>;

                default:
                    break;
            }
        }
    }

    const renderPurchaseActions = () => {
        if (purchase.data) {
            switch (purchase.data.status) {
                case 'Incomplete':
                    return <>
                        <li>
                            <div className='btn-group'>
                                <button
                                    type='button'
                                    className='btn btn-alt px-3 dropdown-toggle'
                                    data-toggle='dropdown'
                                    aria-haspopup='true'
                                    aria-expanded='false'
                                >
                                    <span className='mr-1'>Change</span>
                                </button>
                                <div className='dropdown-menu dropdown-menu-right'>
                                    <button
                                        type='button'
                                        className='dropdown-item'
                                        onClick={() => handleModeChange('update')}
                                    >
                                        Update
                                    </button>
                                    <button
                                        type='button'
                                        className='dropdown-item text-danger fw-500'
                                        onClick={handleDelete}
                                    >
                                        Delete transaction
                                    </button>
                                </div>
                            </div>
                        </li>
                        <li>
                            <button
                                type='button'
                                className='btn btn-success px-3'
                                onClick={e => handleComplete(e)}
                            >
                                Complete
                            </button>
                        </li>
                    </>;

                case 'Completed':
                    return !!purchase.data.refundPurchaseId ?
                        <>
                            <li>
                                <span className='fw-500 text-danger mr-2'>This invoice was refunded on {sys.getFormattedShortDate(moment.utc(purchase.data.refundedDateUtc).local())}.</span>
                            </li>
                            <li>
                                <button
                                    type='button'
                                    className='btn btn-danger px-3'
                                    onClick={(e) => { handleOpenRefundedPurchase(e, purchase.data.refundPurchaseId) }}
                                >
                                    Open Refunded Invoice
                                </button>
                            </li>
                        </> :
                        <>
                            {
                                purchase.data && purchase.data.remainingBalance > 0 && moment(purchase.data.invoiceDate).startOf('day').isSameOrBefore(moment().startOf('day')) ?
                                    <li>
                                        <button
                                            type='button'
                                            className='btn btn-alt px-3'
                                            onClick={handlePayment}
                                        >
                                            Payment
                                        </button>
                                    </li> : null
                            }
                            {
                                purchase.data && purchase.data.remainingBalance < 0 ?
                                    <li>
                                        <button
                                            type='button'
                                            className='btn btn-danger px-3'
                                            onClick={handleRefundPayment}
                                        >
                                            Refund Payment
                                        </button>
                                    </li> : null
                            }
                            <li>
                                <div className='btn-group'>
                                    <button
                                        type='button'
                                        className='btn btn-alt px-3 dropdown-toggle'
                                        data-toggle='dropdown'
                                        aria-haspopup='true'
                                        aria-expanded='false'
                                    >
                                        <span className='mr-1'>Change</span>
                                    </button>
                                    <div className='dropdown-menu dropdown-menu-right'>
                                        <button
                                            type='button'
                                            className='dropdown-item'
                                            onClick={() => handleModeChange('update')}
                                        >
                                            Reopen
                                        </button>
                                        {
                                            purchase.data.totalPaid !== 0 ?
                                                <>
                                                    {
                                                        purchase.data.remainingBalance >= 0 && purchase.data.balanceType !== 'OverPaid' ?
                                                            <button
                                                                type='button'
                                                                className='dropdown-item text-danger fw-500'
                                                                onClick={handleRefundPurchase}
                                                            >
                                                                Refund entire invoice
                                                            </button> : null
                                                    }
                                                    {
                                                        purchase.data.totalPaid >= 0 ?
                                                            <button
                                                                type='button'
                                                                className='dropdown-item text-danger fw-500'
                                                                onClick={handleRefundPayment}
                                                            >
                                                                Refund payment
                                                            </button> : null
                                                    }
                                                </> :
                                                <>
                                                    {
                                                        !purchase.data.isNoCharge && !purchase.data.isUncollectible ?
                                                            <>
                                                                <button
                                                                    type='button'
                                                                    className='dropdown-item'
                                                                    onClick={handleNoChargePurchase}
                                                                >
                                                                    No charge
                                                                </button>
                                                                {
                                                                    !purchase.data.payments || purchase.data.payments.length === 0 ?
                                                                        <button
                                                                            type='button'
                                                                            className='dropdown-item text-danger fw-500'
                                                                            onClick={handleVoidPurchase}
                                                                        >
                                                                            Void transaction
                                                                        </button> : null
                                                                }
                                                            </> : null
                                                    }
                                                </>
                                        }
                                        {
                                            !purchase.data.isNoCharge && !purchase.data.isUncollectible && purchase.data.remainingBalance > 0 && moment(purchase.data.dueDate).startOf('day').add(30, 'days').isBefore(moment().startOf('day')) ?
                                                <button
                                                    type='button'
                                                    className='dropdown-item'
                                                    onClick={handleUncollectiblePurchase}
                                                >
                                                    <span className='fw-500 text-danger'>Uncollectible</span>
                                                </button> : null
                                        }
                                        {
                                            ah.check(AccessType.UPDATE_PRODUCT) ?
                                                <>
                                                    <div className='dropdown-divider'></div>
                                                    <button
                                                        type='button'
                                                        className='dropdown-item'
                                                        onClick={handleUpdateCost}
                                                    >
                                                        Update Cost
                                                    </button>
                                                </> : null
                                        }
                                    </div>
                                </div>
                            </li>
                        </>

                case 'Refunded':
                    return <>
                        <li>
                            <span className='fw-500 text-danger mr-2'>This invoice was refunded on {sys.getFormattedShortDate(moment.utc(purchase.data.createdDateUtc).local())}.</span>
                        </li>
                        {
                            purchase.data.originalPurchaseId ?
                                <li>
                                    <button
                                        type='button'
                                        className='btn btn-danger px-3'
                                        onClick={(e) => { handleOpenRefundedPurchase(e, purchase.data.originalPurchaseId) }}
                                    >
                                        Open Original Invoice
                                    </button>
                                </li> : null
                        }
                    </>

                case 'Voided':
                case 'Uncollectible':
                default:
                    break;
            }
        }
    }

    return useObserver(() => <>
        {
            purchase.isReady ?
                <>
                    <LoadingOverlay
                        isLoading={isGeneratingPrint}
                        message={ih.PRINT_MESSAGE}
                    />
                    <DisabledOverlay
                        isLoading={!isGeneratingPrint && (purchase.isLoading || updatePurchase.isLoading || updatePurchase.isSaving)}
                    />
                    {renderTitle()}
                    {renderContent()}
                </> : <>
                    <div className='popup-title'></div>
                    <div className='popup-body'></div>
                </>
        }
        <CommunicationModal ref={communicationModalRef} onSent={handleCommunicationSent} />
    </>)
}

export default ViewPurchaseContent;