import React, { Fragment, useContext, useRef, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Observer } from 'mobx-react-lite';
import Slider from 'react-slick';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { GlobalHotKeys } from 'react-hotkeys';
import TimeAgo from 'react-timeago';
import { toast } from 'react-toastify';
import moment from 'moment';
import printJS from 'print-js';

import BodyEnd from './BodyEnd';
import CommunicationModal from '../communications/CommunicationModal/_index';

import CommunicationStore from '../../../stores/CommunicationStore';
import FileViewStore from '../../../stores/FileViewerStore';
import QuickDrawerStore from '../../../stores/QuickDrawerStore';

import * as fn from '../../../utilities/_functions';
import * as uh from '../../../utilities/userHelper';

import api from '../../../api';

import './FileViewer.scss'

const DOWNLOAD_FILE_LINK_ID = '__dfli_download_link_id__';

const triggerDownload = (base64, fileName, callback) => {
    if (callback && fn.isFunction(callback)) {
        callback();
    }

    const link = document.querySelector(`#${DOWNLOAD_FILE_LINK_ID}`);
    const extension = fileName.substring(fileName.lastIndexOf('.'));
    const downloadableFileName = `${fn.urlFriendly(fileName.replace(extension, ''))}${extension}`;

    link.setAttribute('href', base64);
    link.setAttribute('download', downloadableFileName);
    link.click();
}

const downloadFile = (type, fileName, key, base64, callback) => {
    if (base64) {
        triggerDownload(base64, fileName);
    } else {
        let request;

        if (type === 'attachment') { request = api.Attachments.download(key) }
        else if (type === 'printedDocument') { request = api.PrintedDocuments.download(key) }
        else if (type === 'previewDocument') { request = api.PreviewDocuments.download(key) }

        if (request) {
            request.then(({ data }) => {
                const reader = new FileReader();
                reader.readAsDataURL(data);
                reader.onloadend = () => {
                    const base64data = reader.result;
                    triggerDownload(base64data, fileName, callback);
                }
            })
        }
    }
}

const printFile = (blobUri, mimeType) => {
    let type = null;

    if (mimeType) {
        if (mimeType.startsWith('image')) {
            type = 'image';
        }
        else if (mimeType.indexOf('/pdf') > -1) {
            type = 'pdf';
        }
    }

    printJS(blobUri, type);
}

function FileViewer(props) {
    const isMounted = useRef(true);
    const sliderRef = useRef(null);
    const communicationRef = useRef(null);
    const location = useLocation();
    const communication = useContext(CommunicationStore);
    const fileViewer = useContext(FileViewStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const [rotateDegree, setRotateDegree] = useState(0);

    useEffect(() => {
        return () => {
            isMounted.current = false;
            fileViewer.clear();
        }
    }, []) // eslint-disable-line

    useEffect(() => {
        if (!location.hash || location.hash.length === 0) {
            fileViewer.clear();
        }
    }, [location]) // eslint-disable-line

    const handlePrevious = event => {
        sliderRef.current.slickPrev();
    }

    const handleNext = event => {
        sliderRef.current.slickNext();
    }

    const handleClose = event => {
        if (fn.isFunction(fileViewer.onCloseCallback)) {
            fileViewer.onCloseCallback();
        }

        fileViewer.clear();

        if (!quickDrawer.context1 || !quickDrawer.action1) {
            document.querySelector('html').classList.remove('no-scroll');
        }
    }

    const handleBeforeChange = (old, next) => {
        fileViewer.index = next;
    }

    const handleDownload = (event, { type, fileName, key, base64 }) => {
        event.stopPropagation();
        downloadFile(type, fileName, key, base64);
    }

    const handlePrint = (event, { mimeType, uri }) => {
        printFile(uri, mimeType);
    }

    const handleEmail = (event, file) => {
        communication.initialize('email', null, null, [{
            id: file.id,
            fileName: file.fileName,
            mimeType: file.mimeType,
            size: file.size,
            base64: file.base64,
            type: file.type
        }], fileViewer.customerId);

        communicationRef.current.show('email');
    }

    const handleFax = (event, file) => {
        communication.initialize('fax', null, null, [{
            id: file.id,
            fileName: file.fileName,
            mimeType: file.mimeType,
            size: file.size,
            base64: file.base64,
            type: file.type
        }], fileViewer.customerId);

        communicationRef.current.show('fax');
    }

    const handleCommunicationSent = (event, result) => {
        if (result && result.type) {
            toast.dark(() => <p data-comm-sent><span className='tt-capitalize'>{result.type}</span> {result.scheduledSendDateTimeLocal ? 'scheduled' : 'sent'}.</p>);
        }
        else {
            toast.dark(() => <p data-comm-sent>Communication sent.</p>);
        }
        communicationRef.current.close();
    }

    const handleRotate = () => {
        let tempRotateDegree = (rotateDegree || 0) + 90;

        if (tempRotateDegree >= 360) {
            tempRotateDegree = 0;
        }

        setRotateDegree(tempRotateDegree);
    }

    const renderFile = file => {
        if (file.isLoaded) {
            if (!file.hasError) {
                switch (file.mimeType) {
                    case 'application/pdf':
                        return <embed src={`${file.uri}`} type='application/pdf' width='100%' height='100%' />;

                    case 'image/jpeg':
                    case 'image/jpg':
                    case 'image/gif':
                    case 'image/png':
                    case 'image/bmp':
                        return <div className='file-image-wrapper'>
                            <TransformWrapper
                                wheel={{
                                    step: 50,
                                }}
                                options={{
                                    minScale: -1,
                                    maxScale: 8,
                                    centerContent: true
                                }}
                            >
                                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                    <>
                                        <div className='file-image-actions'>
                                            <button
                                                type='button'
                                                className='d-block btn btn-default btn-icon rounded-circle mb-2'
                                                onClick={zoomIn}
                                            >
                                                <i className='fal fa-plus'></i>
                                            </button>
                                            <button
                                                type='button'
                                                className='d-block btn btn-default btn-icon rounded-circle mb-4'
                                                onClick={zoomOut}
                                            >
                                                <i className='fal fa-minus'></i>
                                            </button>
                                            <button
                                                type='button'
                                                className='d-block btn btn-default btn-icon rounded-circle mb-4'
                                                onClick={handleRotate}
                                            >
                                                <i className='fal fa-redo'></i>
                                            </button>
                                            <button
                                                type='button'
                                                className='d-block btn btn-default btn-icon rounded-circle mb-2'
                                                onClick={resetTransform}
                                            >
                                                <i className='fal fa-sync'></i>
                                            </button>
                                        </div>
                                        <TransformComponent>
                                            <img src={file.uri} alt={file.fileName} style={{ transform: `rotate(${rotateDegree}deg)` }} />
                                        </TransformComponent>
                                    </>
                                )}
                            </TransformWrapper>
                        </div>

                    default:
                        return <div className='d-flex w-100 h-100 align-items-center justify-content-center'>
                            <div className='text-center'>
                                <div className='d-block fs-xl text-white'><strong>{file.fileName}</strong> cannot be previewed.</div>
                                <div className='d-block text-gray-700'>Size: {fn.toFileSize(file.size, true)}</div>
                                <button
                                    type='button'
                                    className='btn btn-info my-4'
                                    onClick={(e) => { handleDownload(e, file) }}
                                >Download</button>
                            </div>
                        </div>
                }
            } else {
                return <div className='d-flex w-100 h-100 align-items-center justify-content-center'>
                    <div className='text-center'>
                        <div className='d-block fs-xl text-white'><strong>{file.fileName}</strong> cannot be loaded.</div>
                        <div className='d-block text-gray-700'>Size: {fn.toFileSize(file.size, true)}</div>
                        <button
                            type='button'
                            className='btn btn-info my-4'
                            onClick={(e) => { handleDownload(e, file) }}
                        >Download</button>
                    </div>
                </div>
            }
        } else {
            return <div className='file-loading d-flex w-100 h-100 align-items-center justify-content-center'>
                <div className='spinner-border'></div>
            </div>
        }
    }

    return <Observer>{() => <>
        {
            fileViewer.isReady === true && !communication.isReady ?
                <GlobalHotKeys
                    keyMap={{
                        closeViewer: ['esc'],
                        previousFile: ['left'],
                        nextFile: ['right'],
                    }}
                    handlers={{
                        closeViewer: event => {
                            handleClose();
                        },
                        previousFile: event => {
                            handlePrevious();
                        },
                        nextFile: event => {
                            handleNext();
                        },
                    }}
                    allowChanges={true}
                /> : null
        }
        <div
            className={'panel panel-fullscreen file-viewer' + (fileViewer.isReady === true ? '' : ' d-none')}
            style={{ zIndex: fn.getHighestZIndex() }}
        >
            <div className='panel-hdr bg-alt-800'>
                <div className='flex-1'>
                    {
                        fileViewer.isReady && fn.isFunction(fileViewer.renderTitle) ?
                            fileViewer.renderTitle() :
                            <h3 className='title'>
                                {fileViewer.currentFile ? fileViewer.currentFile.fileName : ''}
                            </h3>
                    }
                </div>
                <div className='count flex-1'>
                    <strong className='d-block fs-md text-truncate'>
                        {fileViewer.currentFile ? fileViewer.currentFile.fileName : ''}
                        {
                            fileViewer.currentFile && fileViewer.currentFile.createdDateUtc ?
                                <small className='text-muted ml-1'>
                                    (<TimeAgo
                                        formatter={(value, unit, suffix) => { return fn.timeAgoPastFormatter(value, unit, suffix, 'second', 10, 'Just now') }}
                                        date={moment.utc(fileViewer.currentFile.createdDateUtc).local().toDate()}
                                        title={moment.utc(fileViewer.currentFile.createdDateUtc).local().format('dddd MMMM D, YYYY h:mm a')}
                                        minPeriod={60}
                                    /> by {
                                        fileViewer.currentFile.createdById ?
                                            uh.getDisplayShortNameById(fileViewer.currentFile.createdById) :
                                            (fileViewer.currentFile.createdBy ? fileViewer.currentFile.createdBy : 'System')})
                                </small> : null
                        }
                    </strong>
                    {
                        fileViewer.files && fileViewer.files.length > 0 ?
                            <span className='fs-sm text-gray-700'>{fileViewer.index + 1} of {fileViewer.files.length}</span> : null
                    }
                </div>
                <div className='panel-toolbar flex-1 justify-content-end'>
                    <div className='mr-4'>
                        <a
                            href={fileViewer.currentFile ? fileViewer.currentFile.uri : '/'}
                            target='_blank'
                            rel='noopener noreferrer'
                            className='btn btn-icon'
                            title={`Open ${fileViewer.currentFile ? fileViewer.currentFile.fileName : ''} in new window`}
                        >
                            <i className={'fal fa-external-link fs-xl'}></i>
                        </a>
                        <button
                            type='button'
                            className='btn btn-icon'
                            onClick={e => { handleDownload(e, fileViewer.currentFile) }}
                            title={`Download ${fileViewer.currentFile ? fileViewer.currentFile.fileName : ''}`}
                        >
                            <i className={'fal fa-download fs-xl'}></i>
                        </button>
                        <button
                            type='button'
                            className='btn btn-icon'
                            onClick={e => { handlePrint(e, fileViewer.currentFile) }}
                            title={`Print ${fileViewer.currentFile ? fileViewer.currentFile.fileName : ''}`}
                        >
                            <i className={'fal fa-print fs-xl'}></i>
                        </button>
                        {
                            fileViewer.customerId && fileViewer.canSend ?
                                <>
                                    <button
                                        type='button'
                                        className='btn btn-icon'
                                        onClick={e => { handleEmail(e, fileViewer.currentFile) }}
                                        title={`Email ${fileViewer.currentFile ? fileViewer.currentFile.fileName : ''}`}
                                    >
                                        <i className={'fal fa-envelope fs-xl'}></i>
                                    </button>
                                    <button
                                        type='button'
                                        className='btn btn-icon'
                                        onClick={e => { handleFax(e, fileViewer.currentFile) }}
                                        title={`Fax ${fileViewer.currentFile ? fileViewer.currentFile.fileName : ''}`}
                                    >
                                        <i className={'fal fa-fax fs-xl'}></i>
                                    </button>
                                </> : null
                        }
                    </div>
                    {
                        fileViewer.files && fileViewer.files.length > 1 ?
                            <>
                                <button
                                    type='button'
                                    className='btn btn-icon'
                                    onClick={handlePrevious}
                                    title='Previous'
                                >
                                    <i className={'fal fa-chevron-left fs-xl'}></i>
                                </button>
                                <button
                                    type='button'
                                    className='btn btn-icon'
                                    onClick={handleNext}
                                    title='Next'
                                >
                                    <i className={'fal fa-chevron-right fs-xl'}></i>
                                </button>
                            </> : null
                    }
                    <button
                        className='btn btn-icon'
                        onClick={handleClose}
                        title='Close'
                    >
                        <i className='fal fa-times fs-xl'></i>
                    </button>
                </div>
            </div>
            <div className='panel-container show'>
                <div className='panel-content'>
                    {
                        fileViewer.isReady && fileViewer.files && fileViewer.files.length > 0 ?
                            <Slider
                                ref={sliderRef}
                                dots={true}
                                infinite={true}
                                slidesToShow={1}
                                slidesToScroll={1}
                                arrows={false}
                                draggable={false}
                                initialSlide={fileViewer.initialIndex}
                                beforeChange={handleBeforeChange}
                            >
                                {
                                    fileViewer.files.map((f, fi) => {
                                        return <Fragment
                                            key={`file-view-${fi}`}
                                        >
                                            {renderFile(f)}
                                        </Fragment>
                                    })
                                }
                            </Slider> : null
                    }
                </div>
            </div>
        </div>
        <a id={DOWNLOAD_FILE_LINK_ID} href='/' style={{ position: 'absolute', top: -99999, opacity: 0 }}>Download</a>
        <BodyEnd>
            <CommunicationModal ref={communicationRef} onSent={handleCommunicationSent} />
        </BodyEnd>
    </>}</Observer>
}

export { triggerDownload, downloadFile, printFile };
export default FileViewer;