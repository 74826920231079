import { createContext } from 'react';
import { decorate, observable, action } from 'mobx';
import moment from 'moment';

import api from '../api';
import * as fn from '../utilities/_functions';

export class TimeslotScheduleCreate {
    userId = null;
    start = null;
    daysOfWeek = [];
    timeslots = [];
    isReady = false;
    isSaving = false;
    isLoading = false;
    hasUnsavedChanges = false;
    cancelTimeslotSchedule = null;
    cancelTimeslotScheduleUpdate = null;
    cancelTimeslotScheduleConflict = null;

    initialize = (userId, weekday, start) => {
        const that = this;

        this.clear();
        this.userId = userId;
        this.start = start.clone();
        this.daysOfWeek = [ weekday ];

        return new Promise((resolve, reject) => {
            that.refresh()
                .then(() => {
                    resolve();
                })
                .catch(error => {
                    reject(error);
                })
                .finally(() => {
                    that.isReady = true;
                })
        })
    }

    refresh = () => {
        const that = this;
        this.isLoading = true;

        return new Promise((resolve, reject) => {
            api.TimeslotSchedules.get(that.userId, that.daysOfWeek[0], (c) => { that.cancelTimeslotSchedule = c })
                .then(({ data }) => {
                    if (data && data.length > 0) {
                        that.timeslots = data.map(b => {
                            return {
                                noteHtml: b.noteHtml,
                                start: moment(b.start),
                                end: moment(b.end),
                                //services: b.services,
                                appointmentTypes: b.appointmentTypes,
                                //allowAllServices: !b.services.length,
                                allowAllAppointmentTypes: !b.appointmentTypes.length
                            }
                        });
                    }
                    else {
                        this.timeslots = [];
                    }
                    resolve();
                })
                .catch(error => {
                    reject(error);
                })
                .finally(() => {
                    that.isLoading = false;
                })
        })
    }

    save = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            // if something changed or if this is a brand new entry (hasUnsavedChanges would be false)
            if (that.hasUnsavedChanges) {
                if (!that.cancelTimeslotScheduleUpdate) {
                    api.TimeslotSchedules.save(
                        {
                            userId: this.userId,
                            weekdays: this.daysOfWeek,
                            timeslotSchedules: this.timeslots.map(b => ({
                                noteHtml: b.noteHtml,
                                start: b.start.format('HH:mm'),
                                end: b.end.format('HH:mm'),
                                //services: b.allowAllServices ? [] : b.services,
                                appointmentTypes: b.allowAllAppointmentTypes ? [] : b.appointmentTypes,
                            }))
                        }
                        , (c) => { that.cancelTimeslotScheduleUpdate = c })
                        .then(() => {
                            that.hasUnsavedChanges = false;
                            resolve();
                        })
                        .catch(error => {
                            reject(error);
                        })
                        .finally(() => {
                            that.cancelTimeslotScheduleUpdate = null;
                            that.isSaving = false;
                        })
                }
                else {
                    resolve();
                }
            } else {
                that.hasUnsavedChanges = false;
                that.isSaving = false;
                resolve();
            }
        })
    }

    clear = () => {
        this.userId = null;
        this.start = null;
        this.daysOfWeek = [];
        this.timeslots = [];
        this.isReady = false;
        this.isSaving = false;
        this.isLoading = false;
        this.hasUnsavedChanges = false;

        if (fn.isFunction(this.cancelTimeslotSchedule)) {
            this.cancelTimeslotSchedule();
            this.cancelTimeslotSchedule = null;
        }

        if (fn.isFunction(this.cancelTimeslotScheduleUpdate)) {
            this.cancelTimeslotScheduleUpdate();
            this.cancelTimeslotScheduleUpdate = null;
        }

        if (fn.isFunction(this.cancelTimeslotScheduleConflict)) {
            this.cancelTimeslotScheduleConflict();
            this.cancelTimeslotScheduleConflict = null;
        }
    }
}

decorate(TimeslotScheduleCreate, {
    userId: observable,
    daysOfWeek: observable.deep,
    timeslots: observable,
    isReady: observable,
    isSaving: observable,
    isLoading: observable,
    hasUnsavedChanges: observable,
    initialize: action,
    refresh: action,
    save: action,
    clear: action,
})

export default createContext(new TimeslotScheduleCreate());
