import moment, { isMoment } from 'moment';
import * as StorageKey from '../constants/storageKeys';
import * as TenantKey from '../constants/tenantSettingKeys';

import * as fh from './formatHelper';

export const configuration = {
    get preBetaRelease() {
        const isEnabled = getBooleanValue(TenantKey.PRE_BETA_RELEASE, false);
        return isEnabled;
    },
    customer: {
        get spokenLanguages() {
            const languages = getArrayValue(TenantKey.SPOKEN_LANGUAGES);
            return languages && languages.length > 0 ? languages : [];
        },
        get communicationLanguages() {
            const referenceData = JSON.parse(window.localStorage.getItem(StorageKey.REFERENCE_DATA));
            const supportLanguages = referenceData && referenceData.some(r => r.name === 'CommunicationLanguageType') ? referenceData.filter(r => r.name === 'CommunicationLanguageType')[0].options : [];
            const languages = supportLanguages.filter(l1 => getArrayValue(TenantKey.COMMUNICATION_LANGUAGES).some(l2 => l1.key === l2));
            return languages && languages.length > 0 ? languages : [];
        },
    },
    appointment: {
        get dayViewGotoOptions() {
            const options = getAppointmentGotoOptions(TenantKey.APPOINTMENTS_DAYVIEW_GOTO_OPTIONS);
            return options;
        },
        get weekViewGotoOptions() {
            const options = getAppointmentGotoOptions(TenantKey.APPOINTMENTS_WEEKVIEW_GOTO_OPTIONS);
            return options;
        },
        get isGroupAppointmentEnabled() {
            const isEnabled = getBooleanValue(TenantKey.GROUP_APPOINTMENTS_ENABLED, false);
            return isEnabled;
        }
    },
    purchase: {
        get governmentProgramCodes() {
            const governmentPrograms = getArrayValue(TenantKey.GOVERNMENT_PROGRAMS);
            return governmentPrograms && governmentPrograms.length > 0 ? governmentPrograms : [];
        },
        get isUnitPriceUpdateEnabled() {
            const isEnabled = getBooleanValue(TenantKey.PURCHASE_UNIT_PRICE_UPDATE_ENABLED, false);
            return isEnabled;
        },
    },
    exam: {
        get isPaperMigrationEnabled() {
            const isEnabled = getBooleanValue(TenantKey.PAPER_MIGRATION_ENABLED);
            return isEnabled === true;
        },
    },
    prescription: {
        get isDraftPrintEnabled() {
            const isEnabled = getBooleanValue(TenantKey.PRESCRIPTION_DRAFT_PRINT_ENABLED);
            return isEnabled === true;
        }
    },
    recall: {        
        get isEnabled() {
            const mode = getValue(TenantKey.RECALL_MODE);
            return !!mode && ['manual','endOfDay','automated'].some(m => m === mode)
        },
        get mode() {
            const mode = getValue(TenantKey.RECALL_MODE);
            return mode && ['manual','endOfDay','automated'].some(m => m === mode) ? mode : 'manual';
        },
        get yearsInRear() {
            const years = parseInt(getValue(TenantKey.RECALL_YEARS_IN_REAR), 10);
            return !isNaN(years) && years >= 0 ? years : 4;
        },
        get daysInAdvance() {
            const days = parseInt(getValue(TenantKey.RECALL_DAYS_IN_ADVANCED), 10);
            return !isNaN(days) && days >= 0 ? days : 0;
        },
        get defaultDelayInDays() {
            const days = parseInt(getValue(TenantKey.RECALL_SEND_DELAY), 10);
            return !isNaN(days) && days >= 0 ? days : 3;
        },
        get defaultDelaySendTime() {
            const time = getValue(TenantKey.RECALL_SEND_TIME);
            return time;
        },
        get maxAttempt() {
            const value = parseInt(getValue(TenantKey.RECALL_MAX_ATTEMPTS), 10);
            return value && !isNaN(value) ? value : 2;
        },
        getScheduledSendDateTimeLocal(localDate) {
            const mDate = isMoment(localDate) ? localDate.clone().startOf('day') : moment().startOf('day');
            const defaultDelayInDays = parseInt(getValue(TenantKey.RECALL_SEND_DELAY), 10);
            const defaultDelaySendTime = getValue(TenantKey.RECALL_SEND_TIME);
            const recallDay = mDate.clone().add(defaultDelayInDays, 'days');
            const recallTime = moment(defaultDelaySendTime, 'HH:mm');
            const scheduledSendDateTimeLocal = recallDay.set({ hour: recallTime.hour(), minute: recallTime.minute(), second: 0 });

            return scheduledSendDateTimeLocal;
        },
    },
    review: {        
        get isEnabled() {
            const mode = getValue(TenantKey.REVIEW_MODE);
            const url = getValue(TenantKey.REVIEW_URL);
            return !!mode && !!url && ['manual','endOfDay','automated'].some(m => m === mode)
        },
        get mode() {
            const mode = getValue(TenantKey.REVIEW_MODE);
            return mode && ['manual','endOfDay','automated'].some(m => m === mode) ? mode : 'disabled';
        },
        get url() {
            const url = getValue(TenantKey.REVIEW_URL);
            return url;
        },
        get defaultDelayInDays() {
            const days = parseInt(getValue(TenantKey.REVIEW_SEND_DELAY), 10);
            return !isNaN(days) && days >= 0 ? days : 3;
        },
        get defaultDelaySendTime() {
            const time = getValue(TenantKey.REVIEW_SEND_TIME);
            return time;
        },
        get sendMultiplePerPatient() {
            const sendMultiple = getBooleanValue(TenantKey.REVIEW_SEND_MULTIPLE_PER_PATIENT);
            return sendMultiple === true;
        },
        isWithinRange(localDate) {
            const mDate = isMoment(localDate) ? localDate.clone().startOf('day') : moment(localDate).startOf('day');
            const today = moment().startOf('day');

            return today.add(-1, 'months').isBefore(mDate);
        },
        getScheduledSendDateTimeLocal(localDate) {
            const mDate = isMoment(localDate) ? localDate.clone().startOf('day') : moment().startOf('day');
            const defaultDelayInDays = parseInt(getValue(TenantKey.REVIEW_SEND_DELAY), 10);
            const defaultDelaySendTime = getValue(TenantKey.REVIEW_SEND_TIME);
            const reviewDay = mDate.clone().add(defaultDelayInDays, 'days');
            const reviewTime = moment(defaultDelaySendTime, 'HH:mm');
            const scheduledSendDateTimeLocal = reviewDay.set({ hour: reviewTime.hour(), minute: reviewTime.minute(), second: 0 });

            return scheduledSendDateTimeLocal;
        }
    },
    endOfDay: {
        get isEnabled() {
            const isEnabled = getBooleanValue(TenantKey.END_OF_DAY_ENABLED);
            return isEnabled === true;
        },
        get summarySendTo() {
            const value = getValue(TenantKey.END_OF_DAY_SUMMARY_SEND_TO);
            return JSON.parse(value);
        },
        get associateSummarySendTo() {
            const value = getValue(TenantKey.END_OF_DAY_ASSOCIATE_SUMMARY_SEND_TO);
            return JSON.parse(value);
        },
    },
}

export const getFormattedPhoneNumber = (value) => {
    return getFormattedValue(TenantKey.PHONE_NUMBER_FORMAT, value, 'PhoneBrackets');
}

export const getFormattedLongDate = (value, includeTime = false) => {
    const date = getFormattedValue(TenantKey.LONG_DATE_FORMAT, value, 'ShortDayOfWeekDate');

    if (!!includeTime) {
        return `${date} @ ${value.format('h:mm a')}`;
    }

    return date;
}

export const getFormattedShortDate = (value, includeTime = false) => {
    const date = getFormattedValue(TenantKey.SHORT_DATE_FORMAT, value, 'DashYearMonthDay');

    if (!!includeTime) {
        return `${date} @ ${value.format('h:mm a')}`;
    }

    return date;
}

const getAppointmentGotoOptions = (key) => {
    const array = getArrayValue(key);
    const options = array && array.length > 0 ? array.map(a => {
        if (a) {
            const parts = a.split(',');

            if (parts && parts.length === 3) {
                let text;
                const number = parseInt(parts[0], 10);
                const unit = parts[1];
                const addDay = parseInt(parts[2], 10);
                const key = `_${number}${unit}`;

                if (!isNaN(number) && ['week', 'month', 'year'].some(u => unit === u) && !isNaN(addDay)) {
                    switch (unit.toLowerCase()) {
                        case 'week':
                            text = `${number} wk`;
                            break;

                        case 'month':
                            text = `${number} mo`;
                            break;

                        case 'year':
                            text = `${number} yr`;
                            break;

                        default:
                            break;
                    }

                    return { key, text, number, unit, addDay }
                }
            }
        }
        return null;
    }).filter(o => !!o) : null;

    return options && options.length > 0 ? options : [];
}

const getValue = (key, defaultValue) => {
    const settings = window.localStorage.getItem(StorageKey.SETTINGS) ? JSON.parse(window.localStorage.getItem(StorageKey.SETTINGS)) : [];
    const setting = settings.filter(setting => setting.key === key)[0];

    return setting && setting.value ? setting.value : defaultValue;
}

const getFormattedValue = (key, value, defaultFormat) => {
    const settings = window.localStorage.getItem(StorageKey.SETTINGS) ? JSON.parse(window.localStorage.getItem(StorageKey.SETTINGS)) : [];
    const formatSetting = settings.filter(setting => setting.key === key)[0];

    if (formatSetting) {
        return fh.apply(value, formatSetting.value, value);
    }

    return fh.apply(value, defaultFormat, value);
}

const getBooleanValue = (key, defaultValue) => {
    const settings = window.localStorage.getItem(StorageKey.SETTINGS) ? JSON.parse(window.localStorage.getItem(StorageKey.SETTINGS)) : [];
    const booleanSetting = settings.filter(setting => setting.key === key)[0];

    if (booleanSetting) {
        return booleanSetting.value === 'true';
    }

    return defaultValue;
}

const getArrayValue = (key) => {
    const settings = window.localStorage.getItem(StorageKey.SETTINGS) ? JSON.parse(window.localStorage.getItem(StorageKey.SETTINGS)) : [];
    const arraySetting = settings.filter(setting => setting.key === key)[0];

    if (arraySetting && arraySetting.value) {
        if (arraySetting.value.indexOf('|') >= 0) {
            return arraySetting.value.split('|');
        }
        else {
            return [arraySetting.value];
        }
    }

    return [];
}