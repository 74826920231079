import React, { useEffect, useContext, useState, useRef } from 'react';
import FadeIn from 'react-fade-in';
import { useObserver } from 'mobx-react-lite';
import { toJS } from 'mobx';
import MaskedInput from 'react-text-mask'
import { toast } from 'react-toastify';
import { GlobalHotKeys } from 'react-hotkeys';
import { Multiselect } from 'react-widgets'
import moment from 'moment';

import AddressInput from '../../_shared/AddressInput';
import BodyEnd from '../../_shared/BodyEnd';
import LoadingOverlay from '../../_shared/LoadingOverlay';
import ConfirmModal from '../../_shared/ConfirmModal';
import AddressBookModal from '../../_shared/AddressBookModal/_index';
import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import { quickDrawerFocus } from '../../_shared/QuickDrawer';

import CustomerCreateStore from '../../../../stores/CustomerCreateStore';
import NoteCreateStore from '../../../../stores/NoteCreateStore';
import AuthStore from '../../../../stores/AuthStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';
import CacheStore from '../../../../stores/CacheStore';

import api from '../../../../api';

import * as ErrorMessages from '../../../../constants/errorMessages';
import * as MaskKeys from '../../../../constants/maskKeys';
import * as fn from '../../../../utilities/_functions';
import * as ph from '../../../../utilities/personHelper';
import * as ch from '../../../../utilities/customerHelper';
import * as ah from '../../../../utilities/addressHelper';
import * as sys from '../../../../utilities/systemHelper';
import * as oh from '../../../../utilities/operationHelper';
import * as nh from '../../../../utilities/noteHelper';
import * as bh from '../../../../utilities/badgeHelper';

import './NewCustomer.scss';

function NewCustomer(props) {
    const isMounted = useRef(true);
    const validateRef = useRef(null);
    const focusTimer = useRef(null);
    const homeAddressRef = useRef(null);
    const phoneRef = useRef(null);
    const phoneTypeRef = useRef(null);
    const preferredContactMethodRef = useRef(null);
    const addressBookModalRef = useRef(null);
    const customer = useContext(CustomerCreateStore);
    const newNote = useContext(NoteCreateStore);
    const auth = useContext(AuthStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const cache = useContext(CacheStore);
    const [isNameOptionsExpanded, setIsNameOptionsExpanded] = useState(false);
    const [isOhipVerifyExpanded, setIsOhipVerifyExpanded] = useState(false);
    const [subsidizedServices, setSubsidizedServices] = useState([]);
    const [verifyServices, setVerifyServices] = useState([]);
    const [customerData, setCustomerData] = useState(null);
    const [isUploadingAttachments, setIsUploadingAttachments] = useState(false);
    const [confirmMissingContact, setConfirmMissingContact] = useState(false);
    const [checkForConflict, setCheckForConflict] = useState(false);
    const [conflicts, setConflicts] = useState(false);
    const [confirmConflicts, setConfirmConflicts] = useState(false);
    const [validatedHealthCard, setValidatedHealthCard] = useState(null);
    const [confirmPopulateCustomer, setConfirmPopulateCustomer] = useState(false);
    const [isValidatingHealthCard, setIsValidatingHealthCard] = useState(false);
    const [isValidatingEligibility, setIsValidatingEligibility] = useState(false);
    const [eligibles, setEligibles] = useState(null);
    const [ineligibles, setIneligibles] = useState(null);
    const [mohReady, setMohReady] = useState(null);
    const [primaryContact, setPrimaryContact] = useState(null);
    const [hasPhoneExtension, setHasPhoneExtension] = useState(false);

    useEffect(() => {
        api.Services.search({
            parameters: [{
                field: 'SubsidyCode',
                operator: '!=',
                value: null,
            }, {
                field: 'DeactivatedDateUtc',
                value: null,
            }],
            sortByFields: [{
                field: 'Code',
                direction: 'ASC',
            }],
            includeTotalCount: false
        })
            .then(({ data }) => {
                if (isMounted.current) {
                    setSubsidizedServices(data.result);
                }
            })

        if (props && props.extraProps) {
            if (props.extraProps.firstName) {
                customer.data.firstName = props.extraProps.firstName;
            }
            if (props.extraProps.lastName) {
                customer.data.lastName = props.extraProps.lastName;
            }
            if (props.extraProps.dateOfBirth) {
                customer.data.dateOfBirth = props.extraProps.dateOfBirth ? moment(props.extraProps.dateOfBirth).format('YYYY-MM-DD') : null;
            }
            if (props.extraProps.emailAddress) {
                customer.data.emailAddress = props.extraProps.emailAddress;
            }
            if (props.extraProps.phoneNumber) {
                customer.data.phoneNumber = props.extraProps.phoneNumber;
            }
            if (props.extraProps.sex) {
                customer.data.sexType = props.extraProps.sex;
            }
            if (props.extraProps.gender) {
                customer.data.genderType = props.extraProps.gender;
            }
            if (props.extraProps.pronoun) {
                customer.data.pronounType = props.extraProps.pronoun;
            }
            if (props.extraProps.healthCard) {
                setValidatedHealthCard(props.extraProps.healthCard);
                populateCustomer(props.extraProps.healthCard);

                customer.data.patientProfile.healthCardNumber = ph.formatHealthCard(props.extraProps.healthCard.healthCardNumber);
                customer.data.patientProfile.healthCardExpiryDate = props.extraProps.healthCard.expiryDate && moment(props.extraProps.healthCard.expiryDate).isValid() ? moment(props.extraProps.healthCard.expiryDate).format('YYYY-MM-DD') : null;
                customer.data.patientProfile.isVerified = props.extraProps.healthCard.isVerified;

                if (!!props.extraProps.healthCard.isVerified && !props.extraProps.healthCard.isValid) {
                    customer.data.patientProfile.verificationResponse = !!props.extraProps.healthCard.errorDescription ? props.extraProps.healthCard.errorDescription : 'Unknown reason.';
                }
                else {
                    customer.data.patientProfile.verificationResponse = null;
                }

                customer.hasUnsavedChanges = true;
                customer.hasPatientUnsavedChanges = true;

                checkConflicts();
            }
            if (props.extraProps.primaryContact) {
                setPrimaryContact(props.extraProps.primaryContact);
            }
        }

        return () => {
            isMounted.current = false;
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
        }
    }, []) // eslint-disable-line

    useEffect(() => {
        if (customer.data.salutationType || customer.data.preferredFirstName || customer.data.suffixType) {
            setIsNameOptionsExpanded(true);
            focusTimer.current = setTimeout(() => {
                quickDrawerFocus(props.drawer);
            }, 100);
            checkConflicts();
        } else {
            quickDrawerFocus(props.drawer);
        }
    }, [quickDrawer.drawerOpened]) // eslint-disable-line

    const checkConflicts = (force) => {
        if (checkForConflict || force) {
            customer.checkConflicts()
                .then(conflicts => {
                    if (isMounted.current) {
                        setConflicts(conflicts);
                        setCheckForConflict(false);
                    }
                })
        }
    }

    const checkEligibility = () => {
        if (isMounted.current) {
            setIsValidatingEligibility(true);

            api.ServiceEligibility.check({
                dateOfBirth: customer.data.dateOfBirth ? moment(customer.data.dateOfBirth) : null,
                healthCardNumber: customer.data.patientProfile && customer.data.patientProfile.healthCardNumber ? customer.data.patientProfile.healthCardNumber : null,
                appointmentDate: moment().startOf('day'),
                serviceIds: verifyServices.map(s => { return s.id })
            })
                .then(({ data }) => {
                    if (isMounted.current) {
                        const eligibleData = data && data.some(d => d.isPublicHealthChecked && d.isEligible) ? data.filter(d => d.isPublicHealthChecked && d.isEligible) : null;
                        const ineligibleData = data && data.some(d => !d.isPublicHealthChecked || !d.isEligible) ? data.filter(d => !d.isPublicHealthChecked || !d.isEligible) : null;
                        const isMohReady = data && !data.some(e => !e.isMohReady);

                        setEligibles(eligibleData);
                        setIneligibles(ineligibleData);
                        setMohReady(isMohReady);
                    }
                })
                .finally(() => {
                    if (isMounted.current) {
                        setIsValidatingEligibility(false);
                    }
                })
        }
    }

    const validateHealthCard = () => {
        if (customer.data.patientProfile && customer.data.patientProfile.healthCardNumber && (!validatedHealthCard || validatedHealthCard.healthCardNumber !== customer.data.patientProfile.healthCardNumber.replace(/[^a-zA-Z0-9]/g, ''))) {
            setIsValidatingHealthCard(true);

            api.Customers.healthCard({ healthCardNumber: customer.data.patientProfile.healthCardNumber })
                .then(({ data }) => {
                    if (isMounted.current) {
                        setValidatedHealthCard(data);

                        customer.data.patientProfile.isVerified = !!data.isVerified;

                        if (!!data.isVerified && !data.isValid) {
                            customer.data.patientProfile.verificationResponse = !!data.errorDescription ? data.errorDescription : 'Unknown reason.';
                        }
                        else {
                            customer.data.patientProfile.verificationResponse = null;
                        }

                        if (data.isVerified && data.isValid) {
                            customer.data.patientProfile.healthCardExpiryDate = data.expiryDate ? moment(data.expiryDate).format('YYYY-MM-DD') : null;
                            setConfirmPopulateCustomer(true);
                        }
                    }
                })
                .finally(() => {
                    if (isMounted.current) {
                        setIsValidatingHealthCard(false);
                    }
                })
        }
    }

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (customer.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleExpandNameOptions = event => {
        setIsNameOptionsExpanded(true);
        focusTimer.current = setTimeout(() => {
            quickDrawerFocus(props.drawer);
        }, 100)
    }

    const handleExpandVerifyOhipServices = event => {
        setIsOhipVerifyExpanded(true);
    }

    const handleFirstNameChange = event => {
        customer.data.firstName = event.target.value;
        customer.hasUnsavedChanges = true;
        setCheckForConflict(true);
    }

    const handleMiddleNameChange = event => {
        customer.data.middleName = event.target.value;
        customer.hasUnsavedChanges = true;
        setCheckForConflict(true);
    }

    const handleLastNameChange = event => {
        customer.data.lastName = event.target.value;
        customer.hasUnsavedChanges = true;
        setCheckForConflict(true);
    }

    const handlePreferredFirstNameChange = event => {
        customer.data.preferredFirstName = event.target.value;
        customer.hasUnsavedChanges = true;
        setCheckForConflict(true);
    }

    const handleGenderChange = event => {
        customer.data.genderType = event.target.value;
        customer.hasUnsavedChanges = true;

        if (customer.data.genderType === 'Other') {
            setTimeout(() => {
                const otherInput = document.querySelector('#new-customer-gender-other-input');
                if (otherInput) {
                    otherInput.focus();
                }
            }, 200);
        }
    }

    const handleGenderOtherChange = event => {
        customer.data.genderOther = event.target.value;
        customer.hasUnsavedChanges = true;
    }

    const handleGenderOtherClear = event => {
        customer.data.genderType = null;
        customer.data.genderOther = null;
        customer.hasUnsavedChanges = true;
    }

    const handlePronounChange = event => {
        customer.data.pronounType = event.target.value;
        customer.hasUnsavedChanges = true;

        if (customer.data.pronounType === 'Other') {
            setTimeout(() => {
                const otherInput = document.querySelector('#new-customer-pronoun-other-input');
                if (otherInput) {
                    otherInput.focus();
                }
            }, 200);
        }
    }

    const handlePronounOtherChange = event => {
        customer.data.pronounOther = event.target.value;
        customer.hasUnsavedChanges = true;
    }

    const handlePronounOtherClear = event => {
        customer.data.pronounType = null;
        customer.data.pronounOther = null;
        customer.hasUnsavedChanges = true;
    }

    const handleSpokenLanguageChange = event => {
        customer.data.spokenLanguage = event.target.value ? event.target.value : null;
        customer.hasUnsavedChanges = true;
    }

    const handleCommunicationLanguageChange = event => {
        customer.data.communicationLanguage = event.target.value ? event.target.value : null;
        customer.hasUnsavedChanges = true;
    }

    const handleEmailAddressChange = event => {
        customer.data.emailAddress = event.target.value;
        customer.hasUnsavedChanges = true;
        setCheckForConflict(true);
    }

    const handlePhoneNumberChange = event => {
        const newNumberOnly = event.target.value;
        const newNumber = `${newNumberOnly}${customer.data.phoneNumberExtension ? `x${customer.data.phoneNumberExtension}` : ''}`;

        customer.data.phoneNumber = newNumber;
        customer.data.phoneNumberOnly = newNumberOnly;
        customer.hasUnsavedChanges = true;

        setCheckForConflict(true);
    }

    const handlePhoneNumberExtensionChange = event => {
        const newExtension = event.target.value;
        const newNumber = `${customer.data.phoneNumberOnly}${newExtension ? `x${newExtension}` : ''}`;

        customer.data.phoneNumber = newNumber;
        customer.data.phoneNumberExtension = newExtension;
        customer.hasUnsavedChanges = true;

        setCheckForConflict(true);
    }

    const handlePhoneTypeChange = event => {
        const newType = event.target.value;

        customer.data.phoneType = newType;
        customer.hasUnsavedChanges = true;

        if (newType === 'Mobile') {
            setHasPhoneExtension(false);
        }
    }

    const handlePhoneHasExtensionChange = event => {
        const newHasExtension = !hasPhoneExtension;
        setHasPhoneExtension(newHasExtension);
    }

    const handleHealthCardChange = event => {
        customer.data.patientProfile.healthCardNumber = event.target.value;
        customer.data.patientProfile.healthCardExpiryDate = null;
        customer.hasUnsavedChanges = true;
        setCheckForConflict(true);
        setValidatedHealthCard(null);
    }

    const handleHealthCardBlur = event => {
        if (customer.data.patientProfile.healthCardNumber) {
            checkConflicts();
            checkEligibility();
            validateHealthCard();
        }
        else {
            setVerifyServices([]);
        }
    }

    const handleSubsidizedServiceSelection = (tag, metadata) => {
        switch (metadata.action) {
            case 'insert':
                if (!verifyServices.some(s => s.id === metadata.dataItem.id)) {
                    verifyServices.push({
                        id: metadata.dataItem.id,
                        code: metadata.dataItem.code,
                        name: metadata.dataItem.code,
                        duration: metadata.dataItem.duration,
                        isRepeatable: metadata.dataItem.isRepeatable,
                    });
                }
                break;

            case 'remove':
                const index = verifyServices.findIndex(s => s.id === metadata.dataItem.id);
                if (index !== -1) {
                    verifyServices.splice(index, 1);
                }
                break;

            default:
                break;
        }

        checkConflicts();
        checkEligibility();
    }

    const handleNewNote = event => {
        const data = toJS((customerData ? customerData : customer.data));
        newNote.initialize(data, 'customer', null, null, false);
        quickDrawer.activateQuickDrawer('note', 'create', null, handleNewNoteSuccess);
        setCustomerData(customer.data);
    }

    const handleNewNoteSuccess = result => {
        if (result && result.updated) {
            customer.data.notes.push(result.data);
            setCustomerData(customer.data);
        }
    }

    const handleAddressChange = (data) => {
        customer.data.address = data;
        customer.hasUnsavedChanges = true;
    }

    const handleAddPrimaryContact = event => {
        quickDrawer.activateQuickDrawer('customer', 'primary-contact-create', null, handleAddPrimaryContactSuccess, handleAddPrimaryContactCancel);
    }

    const handleAddPrimaryContactSuccess = (result) => {
        if (result && result.updated) {
            customer.data.primaryContactPerson.id = result.data.id;
            customer.data.primaryContactPerson.salutationType = result.data.salutationType;
            customer.data.primaryContactPerson.firstName = result.data.firstName;
            customer.data.primaryContactPerson.middleName = result.data.middleName;
            customer.data.primaryContactPerson.lastName = result.data.lastName;
            customer.data.primaryContactPerson.preferredFirstName = result.data.preferredFirstName;
            customer.data.primaryContactPerson.suffixType = result.data.suffixType;
            customer.data.primaryContactPerson.relationship = result.data.relationship;
            customer.data.primaryContactPerson.emailAddress = result.data.emailAddress;
            customer.data.primaryContactPerson.phoneNumber = result.data.phoneNumber;
            customer.data.primaryContactPerson.phoneType = result.data.phoneType;

            customer.data.primaryContactPerson.color = result.data.color;
            customer.data.primaryContactPerson.address = result.data.address;

            if (customer.data.address && !customer.data.address.streetNumber && !customer.data.address.streetName && result.data.address) {
                customer.data.address = result.data.address;
                homeAddressRef.current.setAddress(customer.data.address);
            }

            customer.data.primaryContactPerson.isReady = true;
            customer.hasUnsavedChanges = true;
        }
    }

    const handleAddPrimaryContactCancel = event => {
        customer.clearPrimaryContactPerson();
    }

    const handlePreferredContactMethodChange = event => {
        customer.data.preferredContactMethod = event.target.value;
        customer.hasUnsavedChanges = true;
    }

    const handleCommunicationConsentChange = event => {
        customer.hasUnsavedChanges = true;

        if (event.target.value === 'true') {
            customer.data.communicationConsent = true;
        }
        else if (event.target.value === 'false') {
            customer.data.communicationConsent = false;
        }
        else {
            customer.data.communicationConsent = null;
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        if (fn.validateForm(validateRef.current) && homeAddressRef.current.validate()) {
            if (customer.data.preferredContactMethod === 'SMS' && customer.data.phoneType !== 'Mobile') {
                phoneTypeRef.current.classList.add('has-error');
                preferredContactMethodRef.current.classList.add('has-error');
                toast.error(() => <p>Must provide mobile number for SMS.</p>, { position: 'top-center', style: { width: '380px' } });
            }
            else {
                customer.checkConflicts(true)
                    .then(conflicts => {
                        if (isMounted.current) {
                            if (!conflicts) {
                                phoneTypeRef.current.classList.remove('has-error');
                                preferredContactMethodRef.current.classList.remove('has-error');
                                handleCommitUpdate(event);
                            } else {
                                setConflicts(conflicts);
                                setConfirmConflicts(true);
                            }
                        }
                    })
            }
        }
    }

    const handleConfirmMissingContact = event => {
        handleCommitUpdate(event, true);
    }

    const handleCancelMissingContact = event => {
        setConfirmMissingContact(false);
    }

    const handleCommitUpdate = (event, overridden = false) => {
        if (!customer.data.firstName || !customer.data.lastName) {
            toast.error(() => ErrorMessages.GENERIC_FORM_ERROR_HTML, { position: 'top-center', style: { width: '380px' } });
        }
        else {
            if (overridden || customer.data.phoneNumber || customer.data.emailAddress) {
                setConfirmMissingContact(false);

                if (customer.saveToServer && customer.data.notes && customer.data.notes.length > 0 && customer.data.notes.some(n => n.attachments && n.attachments.length > 0)) {
                    setIsUploadingAttachments(true);
                }

                customer.save()
                    .then(data => {
                        if (isMounted.current) {
                            setIsUploadingAttachments(false);
                            if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                                props.onSuccess(event, { updated: true, data: data });
                            }
                        }
                    })
            } else {
                setConfirmMissingContact(true);
            }
        }
    }

    const handleConflictsConfirm = event => {
        handleCommitUpdate(event);
        setConfirmConflicts(false);
    }

    const handleConflictsCancel = event => {
        setConfirmConflicts(false);
    }

    const handlePopulateCustomerConfirm = event => {
        populateCustomer(validatedHealthCard);
        setConfirmPopulateCustomer(false);
        checkConflicts(true);
    }

    const handlePopulateCustomerCancel = event => {
        setConfirmPopulateCustomer(false);
    }

    const handleAddressBookOpen = (event) => {
        addressBookModalRef.current.show('ExternalDoctor');
    }

    const handleFamilyDoctorClinicSelected = async (rowInfo) => {
        if (rowInfo && rowInfo.selectedRowsData) {
            if (rowInfo.selectedRowsData[0].type === 'ExternalClinic') {
                customer.data.patientProfile.familyDoctorClinicId = rowInfo.selectedRowsData[0].id;
                customer.data.patientProfile.familyDoctorClinic = (await api.ExternalClinics.get(rowInfo.selectedRowsData[0].id)).data;
                customer.data.patientProfile.familyDoctorId = null;
            }
            else if (rowInfo.selectedRowsData[0].type === 'ExternalDoctor') {
                customer.data.patientProfile.familyDoctorId = rowInfo.selectedRowsData[0].id;
                customer.data.patientProfile.familyDoctor = (await api.ExternalDoctors.get(rowInfo.selectedRowsData[0].id)).data;
                customer.data.patientProfile.familyDoctorClinicId = rowInfo.selectedRowsData[0].secondaryId;
                customer.data.patientProfile.familyDoctorClinic = (await api.ExternalClinics.get(rowInfo.selectedRowsData[0].secondaryId)).data;
            }

            customer.hasPatientUnsavedChanges = true;
            addressBookModalRef.current.close();
        }
    }

    const handleFamilyDoctorClinicClear = () => {
        customer.data.patientProfile.familyDoctorClinicId = null;
        customer.data.patientProfile.familyDoctorClinic = null;
        customer.data.patientProfile.familyDoctorId = null;
        customer.data.patientProfile.familyDoctor = null;

        customer.hasPatientUnsavedChanges = true;
    }

    const handleSameAsPrimaryContactSpokenLanguage = () => {
        if (!primaryContact) return;
        handleSpokenLanguageChange({ target: { value: primaryContact.spokenLanguage } });
    }

    const handleSameAsPrimaryContactCommunicationLanguage = () => {
        if (!primaryContact) return;
        handleCommunicationLanguageChange({ target: { value: primaryContact.communicationLanguage } });
    }

    const handleSameAsPrimaryContactEmailAddress = () => {
        if (!primaryContact) return;
        handleEmailAddressChange({ target: { value: primaryContact.emailAddress } });
    }

    const handleSameAsPrimaryContactPhone = () => {
        if (!primaryContact) return;
        const phoneNumber = primaryContact.phoneNumber;
        const phoneType = primaryContact.phoneType;
        const preferredContactMethod = primaryContact.preferredContactMethod;
        const communicationConsent = !!primaryContact.communicationConsent ? primaryContact.communicationConsent.toString() : null;

        handlePhoneNumberChange({ target: { value: phoneNumber } });
        handlePhoneTypeChange({ target: { value: phoneType } });
        handlePreferredContactMethodChange({ target: { value: preferredContactMethod } });
        handleCommunicationConsentChange({ target: { value: communicationConsent } });
    }

    const handleSameAsPrimaryContactAddress = () => {
        if (!primaryContact) return;
        const address = JSON.parse(JSON.stringify(primaryContact.address))
        homeAddressRef.current.setAddress(address);
        customer.data.address = address;
        customer.hasUnsavedChanges = true;
    }

    const renderServices = () => {
        return subsidizedServices.map(s => {
            return {
                id: s.id,
                name: `${s.code} - ${s.name}`,
                code: s.code,
                colorHexValue: s.colorHexValue,
                duration: (s.defaultDurationInMinutes ? s.defaultDurationInMinutes : 0),
                isRepeatable: s.isRepeatable,
            }
        })
    }

    const renderSeeNotesButton = () => {
        return <>
            <button
                type='button'
                className='btn btn-icon'
                onClick={handleNewNote}
                title='Notes'
            >
                <i className='fal fa-comment-alt-lines'></i>
                {
                    customerData && customerData.notes && customerData.notes.length > 0 ?
                        <span className={`badge badge-icon`}>{customerData.notes.length}</span> : null
                }
            </button>
        </>
    }

    const populateCustomer = (healthCard) => {
        if (customer.data.firstName && healthCard.firstName && fn.similarity(customer.data.firstName, healthCard.firstName) < 0.6 && customer.data.firstName !== `${healthCard.firstName} ${healthCard.middleName}`.trim()) {
            customer.data.preferredFirstName = customer.data.firstName;
            setIsNameOptionsExpanded(true);
        }
        if (healthCard.firstName) {
            customer.data.firstName = healthCard.firstName;
        }
        if (healthCard.middleName) {
            customer.data.middleName = healthCard.middleName;
            setIsNameOptionsExpanded(true);
        }
        if (healthCard.lastName) {
            customer.data.lastName = healthCard.lastName;
        }
        if (healthCard.sex) {
            customer.data.sexType = healthCard.sex;
        }
        if (healthCard.dateOfBirth) {
            customer.data.dateOfBirth = moment(healthCard.dateOfBirth).format('YYYY-MM-DD');
        }
        if (customer.data.preferredFirstName === customer.data.firstName) {
            customer.data.preferredFirstName = null;
        }
    }

    const isEmailRequired = () => {
        return customer.data && customer.data.preferredContactMethod === 'Email';
    }

    const isPhoneRequired = () => {
        return customer.data &&
            (
                customer.data.preferredContactMethod === 'Phone' || customer.data.preferredContactMethod === 'SMS'
            );
    }

    return useObserver(() => <>
        <>
            {
                (props.drawer === quickDrawer.drawerOpened) && !confirmMissingContact && !confirmConflicts ?
                    <GlobalHotKeys
                        keyMap={{
                            close: ['esc'],
                        }}
                        handlers={{
                            close: event => {
                                handleCancel(event)
                            },
                        }}
                        allowChanges={true}
                    /> : null
            }
        </>
        <form ref={validateRef} onSubmit={handleSubmit}>
            <fieldset disabled={customer.isSaving || isValidatingHealthCard || isValidatingEligibility}>
                <div className='quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={oh.getIcon('customer', 'new')}
                        action='New'
                        category='Customer'
                        className='customers'
                        onCancel={handleCancel}
                    />
                    <div className='quick-drawer-body'>
                        <FadeIn>
                            <div className='new-customer body-content'>
                                {
                                    eligibles ?
                                        <section>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    {
                                                        mohReady === true ?
                                                            <div className='alert alert-info p-3 mb-0' role='alert'>
                                                                <strong className='d-block mb-2'>Information</strong>
                                                                <ul className='pl-3 mb-0'>
                                                                    {
                                                                        eligibles.map((i, ii) => {
                                                                            return <li key={`new_appointment_eligible_${ii}`}>{bh.renderServiceCode(i.service, 'float-left mr-1')} Eligibility confirmed.</li>
                                                                        })
                                                                    }
                                                                </ul>
                                                            </div> :
                                                            <>
                                                                {
                                                                    mohReady === false ?
                                                                        <div className={'alert alert-warning p-3 mb-0'} role='alert'>
                                                                            <strong className='d-block mb-2'>Warning</strong>
                                                                            <ul className='pl-3 mb-0'>
                                                                                <li><strong className='text-warning-900'>{auth.currentTenant.publicInsuranceUnitId} verification has not been setup.</strong></li>
                                                                                {
                                                                                    eligibles.filter(e => e.isAgeChecked).map((i, ii) => {
                                                                                        return <li key={`view_appointment_eligible_${ii}`}>{bh.renderServiceCode(i.service, 'float-left mr-1')} Age eligibility confirmed.</li>
                                                                                    })
                                                                                }
                                                                                {
                                                                                    conflicts || ineligibles || (validatedHealthCard && (!validatedHealthCard.isVerified || !validatedHealthCard.isValid)) ?
                                                                                        <>
                                                                                            {
                                                                                                !!validatedHealthCard ?
                                                                                                    <>
                                                                                                        {
                                                                                                            !validatedHealthCard.isVerified ?
                                                                                                                <>
                                                                                                                    <li><strong className='text-warning-900'>Health card not verified.</strong></li>
                                                                                                                    <li><strong className='text-warning-900'>{auth.currentTenant.publicInsuranceUnitId} verification has not been setup.</strong></li>
                                                                                                                </> : null
                                                                                                        }
                                                                                                        {
                                                                                                            !!validatedHealthCard.isVerified && !validatedHealthCard.isValid ?
                                                                                                                <>
                                                                                                                    <li>{(!!validatedHealthCard.errorCode ? `(Error: ${validatedHealthCard.errorCode}) ` : '')}{validatedHealthCard.errorDescription}</li>
                                                                                                                </> : null
                                                                                                        }
                                                                                                    </> : null
                                                                                            }
                                                                                            {
                                                                                                ineligibles ?
                                                                                                    <>
                                                                                                        {
                                                                                                            ineligibles.map((i, ii) => {
                                                                                                                return <li key={`new_appointment_ineligible_${ii}`}>{bh.renderServiceCode(i.service, 'float-left mr-1')} {i.earliestEligibleDate ? <>{i.ineligibilityCode ? `(Error: ${i.ineligibilityCode}) ` : ''}Not eligible until <span className='tt-underline'>{moment(i.earliestEligibleDate).format('YYYY-MM-DD')}</span>.</> : (i.ineligibilityReason ? `${(!!i.ineligibilityCode ? `(Error: ${i.ineligibilityCode}) ` : '')}${i.ineligibilityReason}` : 'Cannot verify.  Reason unknown.')}</li>
                                                                                                            })
                                                                                                        }
                                                                                                    </> : null
                                                                                            }
                                                                                            {
                                                                                                conflicts ? <>
                                                                                                    {
                                                                                                        conflicts.name ?
                                                                                                            <li>There is another customer with the same name.</li> : null
                                                                                                    }
                                                                                                    {
                                                                                                        conflicts.healthCard ?
                                                                                                            <li>There is another customer with the same health card.</li> : null
                                                                                                    }
                                                                                                </> : null
                                                                                            }
                                                                                        </> : null
                                                                                }
                                                                            </ul>
                                                                        </div> : null
                                                                }
                                                            </>
                                                    }
                                                </div>
                                            </div>
                                        </section> : null
                                }
                                {
                                    conflicts || ineligibles || (validatedHealthCard && (!validatedHealthCard.isVerified || !validatedHealthCard.isValid)) ?
                                        <section className='mb-4'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='alert alert-warning p-3 mb-0' role='alert'>
                                                        <strong className='d-block mb-2'>Warning</strong>
                                                        <ul className='pl-3 mb-0'>
                                                            {
                                                                !!validatedHealthCard ?
                                                                    <>
                                                                        {
                                                                            !validatedHealthCard.isVerified ?
                                                                                <>
                                                                                    <li><strong className='text-warning-900'>Health card not verified.</strong></li>
                                                                                    <li><strong className='text-warning-900'>{auth.currentTenant.publicInsuranceUnitId} verification has not been setup.</strong></li>
                                                                                </> : null
                                                                        }
                                                                        {
                                                                            !!validatedHealthCard.isVerified && !validatedHealthCard.isValid ?
                                                                                <>
                                                                                    <li>{(!!validatedHealthCard.errorCode ? `(Error: ${validatedHealthCard.errorCode}) ` : '')}{validatedHealthCard.errorDescription}</li>
                                                                                </> : null
                                                                        }
                                                                    </> : null
                                                            }
                                                            {
                                                                ineligibles ?
                                                                    <>
                                                                        {
                                                                            ineligibles.map((i, ii) => {
                                                                                return <li key={`new_appointment_ineligible_${ii}`}>{bh.renderServiceCode(i.service, 'float-left mr-1')} {i.earliestEligibleDate ? <>Not eligible until <span className='tt-underline'>{moment(i.earliestEligibleDate).format('YYYY-MM-DD')}</span>.</> : (i.ineligibilityReason ? `${(!!i.ineligibilityCode ? `(Error: ${i.ineligibilityCode}) ` : '')}${i.ineligibilityReason}` : 'Cannot verify.  Reason unknown.')}</li>
                                                                            })
                                                                        }
                                                                    </> : null
                                                            }
                                                            {
                                                                conflicts ? <>
                                                                    {
                                                                        conflicts.name ?
                                                                            <li>There is another customer with the same name.</li> : null
                                                                    }
                                                                    {
                                                                        conflicts.healthCard ?
                                                                            <li>There is another customer with the same health card.</li> : null
                                                                    }
                                                                </> : null
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </section> : null
                                }
                                <section>
                                    <div className='row'>
                                        <div className='col-7'>
                                            <div className={'form-group'}>
                                                <label htmlFor='new-customer-ohip-input'><small>Health Card Number</small></label>
                                                <MaskedInput
                                                    id='new-customer-ohip-input'
                                                    type='text'
                                                    className='form-control text-uppercase'
                                                    spellCheck={false}
                                                    mask={MaskKeys.OHIP_MASK}
                                                    guide={false}
                                                    maxLength='25'
                                                    autoComplete='off'
                                                    value={customer.data.patientProfile.healthCardNumber ? customer.data.patientProfile.healthCardNumber : ''}
                                                    onChange={handleHealthCardChange}
                                                    onBlur={handleHealthCardBlur}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-5'>
                                            <div className='form-group'>
                                                <label htmlFor='new-customer-ohip-expiry-input'><small>Expiry <span className='text-gray-500'>(yyyy-mm-dd)</span></small></label>
                                                <MaskedInput
                                                    id='new-customer-ohip-expiry-input'
                                                    type='text'
                                                    className='form-control'
                                                    spellCheck={false}
                                                    mask={MaskKeys.DATE_MASK}
                                                    pipe={MaskKeys.DATE_PIPE}
                                                    maxLength='25'
                                                    autoComplete='off'
                                                    disabled={validatedHealthCard && validatedHealthCard.isVerified}
                                                    value={customer.data.patientProfile.healthCardExpiryDate ? customer.data.patientProfile.healthCardExpiryDate : ''}
                                                    onChange={e => { customer.data.patientProfile.healthCardExpiryDate = e.target.value }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'row mt-2' + (isOhipVerifyExpanded ? ' d-none' : '')}>
                                        <div className='col-12'>
                                            <button
                                                type='button'
                                                className='btn btn-link p-0'
                                                disabled={!subsidizedServices || subsidizedServices.length === 0 || !validatedHealthCard || !validatedHealthCard.isVerified || !validatedHealthCard.isValid}
                                                onClick={() => { handleExpandVerifyOhipServices() }}
                                            >
                                                <i className='fal fa-angle-down mr-2'></i>Verify {auth.currentTenant.publicInsuranceUnitId} Service(s)
                                            </button>
                                        </div>
                                    </div>
                                </section>
                                <section className={isOhipVerifyExpanded ? '' : 'd-none'}>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className='form-group'>
                                                <label htmlFor='new-customer-ohip-verify-services'><small>Verify {auth.currentTenant.publicInsuranceUnitId} Service(s)</small></label>
                                                <Multiselect
                                                    allowCreate={false}
                                                    data={renderServices()}
                                                    valueField='id'
                                                    textField='name'
                                                    tagComponent={({ item }) => (
                                                        <span
                                                            className='tag'
                                                            style={{
                                                                backgroundColor: item.colorHexValue,
                                                                borderColor: item.colorHexValue,
                                                            }}
                                                        >
                                                            <strong>{item.code}</strong>
                                                        </span>
                                                    )}
                                                    onChange={handleSubsidizedServiceSelection}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section className={isNameOptionsExpanded ? '' : 'd-none'}>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <div className='form-group'>
                                                <label htmlFor='new-customer-salutation-type-select'><small>Prefix</small></label>
                                                <select
                                                    id='new-customer-salutation-type-select'
                                                    className='custom-select form-control'
                                                    value={customer.data.salutationType ? customer.data.salutationType : ''}
                                                    onChange={e => { customer.data.salutationType = e.target.value }}
                                                >
                                                    <option value=''></option>
                                                    {
                                                        cache.getReferenceDataOptions('SalutationType').map((option, di) => {
                                                            return <option key={`salutation_type_${di}`} value={option.key}>{option.value}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <div className='form-group validate validate-required'>
                                                <label className='required' htmlFor='new-customer-firstname-input'><small>Given Name</small></label>
                                                <input
                                                    id='new-customer-firstname-input'
                                                    type='text'
                                                    className='form-control'
                                                    spellCheck={false}
                                                    maxLength='50'
                                                    autoComplete='off'
                                                    value={customer.data.firstName ? customer.data.firstName : ''}
                                                    onChange={handleFirstNameChange}
                                                    onBlur={checkConflicts}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <div className='form-group validate validate-required'>
                                                <label className='required' htmlFor='new-customer-lastname-input'><small>Last Name</small></label>
                                                <input
                                                    id='new-customer-lastname-input'
                                                    type='text'
                                                    className='form-control'
                                                    maxLength='50'
                                                    spellCheck={false}
                                                    autoComplete='off'
                                                    value={customer.data.lastName ? customer.data.lastName : ''}
                                                    onChange={handleLastNameChange}
                                                    onBlur={checkConflicts}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'row mt-2' + (isNameOptionsExpanded ? ' d-none' : '')}>
                                        <div className='col-12'>
                                            <button
                                                type='button'
                                                className='btn btn-link p-0'
                                                onClick={() => { handleExpandNameOptions() }}
                                            >
                                                <i className='fal fa-angle-down mr-2'></i>Expand name options
                                            </button>
                                        </div>
                                    </div>
                                </section>
                                <section className={isNameOptionsExpanded ? '' : 'd-none'}>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <div className='form-group'>
                                                <label htmlFor='new-customer-middlename-input'><small>Middle Name</small></label>
                                                <input
                                                    id='new-customer-middlename-input'
                                                    type='text'
                                                    className='form-control'
                                                    maxLength='50'
                                                    spellCheck={false}
                                                    autoComplete='off'
                                                    value={customer.data.middleName ? customer.data.middleName : ''}
                                                    onChange={handleMiddleNameChange}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <div className='form-group'>
                                                <label htmlFor='new-customer-preferred-firstname-input'><small>Preferred Name</small></label>
                                                <input
                                                    id='new-customer-preferred-firstname-input'
                                                    type='text'
                                                    className='form-control'
                                                    spellCheck={false}
                                                    maxLength='50'
                                                    autoComplete='off'
                                                    value={customer.data.preferredFirstName ? customer.data.preferredFirstName : ''}
                                                    onChange={handlePreferredFirstNameChange}
                                                    onBlur={checkConflicts}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section className={isNameOptionsExpanded ? '' : 'd-none'}>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <div className='form-group'>
                                                <label htmlFor='new-customer-suffix-type-select'><small>Suffix</small></label>
                                                <select
                                                    id='new-customer-suffix-type-select'
                                                    className='custom-select form-control'
                                                    value={customer.data.suffixType ? customer.data.suffixType : ''}
                                                    onChange={e => { customer.data.suffixType = e.target.value }}
                                                >
                                                    <option value=''></option>
                                                    {
                                                        cache.getReferenceDataOptions('SuffixType').map((option, di) => {
                                                            return <option key={`suffix_type_${di}`} value={option.key}>{option.value}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <div className='form-group'>
                                                <label className='d-block' htmlFor='new-customer-sex'><small>Sex</small></label>
                                                <select
                                                    id='new-customer-sex'
                                                    className='custom-select form-control'
                                                    value={customer.data.sexType ? customer.data.sexType : ''}
                                                    onChange={e => { customer.data.sexType = e.target.value }}
                                                >
                                                    <option value=''></option>
                                                    {
                                                        cache.getReferenceDataOptions('SexType').map((option, di) => {
                                                            return <option key={`sex_type_${di}`} value={option.key}>{option.value}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <div className='form-group'>
                                                <label className='d-block' htmlFor='new-customer-gender'><small>Gender</small></label>
                                                <select
                                                    id='new-customer-gender'
                                                    className={'custom-select form-control' + (customer.data.genderType === 'Other' ? ' d-none' : '')}
                                                    value={customer.data.genderType ? customer.data.genderType : ''}
                                                    onChange={handleGenderChange}
                                                >
                                                    <option value=''></option>
                                                    <option value='Male'>{ph.getGenderDescription('Male')}</option>
                                                    <option value='Female'>{ph.getGenderDescription('Female')}</option>
                                                    <option value='NonBinary'>{ph.getGenderDescription('NonBinary')}</option>
                                                    <option value='Other'>{ph.getGenderDescription('Other')}</option>
                                                </select>
                                                <div className={'relative' + (customer.data.genderType !== 'Other' ? ' d-none' : '')}>
                                                    <input
                                                        id='new-customer-gender-other-input'
                                                        type='text'
                                                        className={'form-control pr-6'}
                                                        spellCheck={false}
                                                        maxLength='50'
                                                        autoComplete='off'
                                                        value={customer.data.genderOther ? customer.data.genderOther : ''}
                                                        onChange={handleGenderOtherChange}
                                                    />
                                                    <button
                                                        type='button'
                                                        className='btn btn-icon-only position-absolute absolute-right absolute-bottom'
                                                        onClick={handleGenderOtherClear}
                                                    >
                                                        <i className='fal fa-times text-danger'></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <div className='form-group'>
                                                <label className='d-block' htmlFor='new-customer-pronoun'><small>Pronoun</small></label>
                                                <select
                                                    id='new-customer-pronoun'
                                                    className={'custom-select form-control' + (customer.data.pronounType === 'Other' ? ' d-none' : '')}
                                                    value={customer.data.pronounType ? customer.data.pronounType : ''}
                                                    onChange={handlePronounChange}
                                                >
                                                    <option value=''></option>
                                                    <option value='HeHim'>{ph.getPronounDescription('HeHim')}</option>
                                                    <option value='SheHer'>{ph.getPronounDescription('SheHer')}</option>
                                                    <option value='TheyThem'>{ph.getPronounDescription('TheyThem')}</option>
                                                    <option value='HeThey'>{ph.getPronounDescription('HeThey')}</option>
                                                    <option value='SheThey'>{ph.getPronounDescription('SheThey')}</option>
                                                    <option value='AeAer'>{ph.getPronounDescription('AeAer')}</option>
                                                    <option value='FaeFaer'>{ph.getPronounDescription('FaeFaer')}</option>
                                                    <option value='EyEm'>{ph.getPronounDescription('EyEm')}</option>
                                                    <option value='PerPer'>{ph.getPronounDescription('PerPer')}</option>
                                                    <option value='VeVer'>{ph.getPronounDescription('VeVer')}</option>
                                                    <option value='XeXem'>{ph.getPronounDescription('XeXem')}</option>
                                                    <option value='ZeHir'>{ph.getPronounDescription('ZeHir')}</option>
                                                    <option value='Other'>{ph.getPronounDescription('Other')}</option>
                                                </select>
                                                <div className={'relative' + (customer.data.pronounType !== 'Other' ? ' d-none' : '')}>
                                                    <input
                                                        id='new-customer-pronoun-other-input'
                                                        type='text'
                                                        className={'form-control pr-6'}
                                                        spellCheck={false}
                                                        maxLength='50'
                                                        autoComplete='off'
                                                        value={customer.data.pronounOther ? customer.data.pronounOther : ''}
                                                        onChange={handlePronounOtherChange}
                                                    />
                                                    <button
                                                        type='button'
                                                        className='btn btn-icon-only position-absolute absolute-right absolute-bottom'
                                                        onClick={handlePronounOtherClear}
                                                    >
                                                        <i className='fal fa-times text-danger'></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <div className='form-group validate validate-date'>
                                                <label htmlFor='new-customer-dob-input'><small>Date of Birth <span className='text-gray-500'>(yyyy-mm-dd)</span></small></label>
                                                <div className='input-group'>
                                                    <MaskedInput
                                                        id='new-customer-dob-input'
                                                        type='text'
                                                        className='form-control'
                                                        spellCheck={false}
                                                        mask={MaskKeys.DATE_MASK}
                                                        pipe={MaskKeys.DATE_PIPE}
                                                        maxLength='25'
                                                        autoComplete='off'
                                                        value={customer.data.dateOfBirth ? customer.data.dateOfBirth : ''}
                                                        onChange={e => { customer.data.dateOfBirth = e.target.value }}
                                                    />
                                                    <div
                                                        className='input-group-append'
                                                        style={{ minWidth: '50px' }}
                                                    >
                                                        <span
                                                            className='input-group-text'
                                                            style={{ minWidth: '50px' }}
                                                        >
                                                            {ph.getAge(customer.data.dateOfBirth)}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <label><small>Family doctor</small></label>
                                            {
                                                customer.data.patientProfile && customer.data.patientProfile.familyDoctorClinic ?
                                                    <>
                                                        <div className='d-flex w-100 border border-1 py-2 px-3 text-gray-700'>
                                                            <div className='flex-1'>
                                                                {
                                                                    customer.data.patientProfile.familyDoctor ?
                                                                        <div>
                                                                            <strong className='d-inline-block'>
                                                                                {customer.data.patientProfile.familyDoctor.fullName}
                                                                            </strong>
                                                                            {
                                                                                customer.data.patientProfile.familyDoctorClinic ?
                                                                                    <small className='ml-2'>({customer.data.patientProfile.familyDoctorClinic.name})</small> : null
                                                                            }
                                                                        </div> : null
                                                                }
                                                                {
                                                                    customer.data.patientProfile.familyDoctorClinic && !customer.data.patientProfile.familyDoctor ?
                                                                        <strong className='d-block'>{customer.data.patientProfile.familyDoctorClinic.name}</strong> : null
                                                                }
                                                                {
                                                                    customer.data.patientProfile.familyDoctorClinic.address ?
                                                                        <div className='text-gray-600 address-sameline'>{ah.getAddressHtml(customer.data.patientProfile.familyDoctorClinic.address)}</div> : null
                                                                }
                                                                {
                                                                    customer.data.patientProfile.familyDoctorClinic.emailAddress ?
                                                                        <div><a href={`mailto:${customer.data.patientProfile.familyDoctorClinic.emailAddress.toLowerCase()}`}>{customer.data.patientProfile.familyDoctorClinic.emailAddress.toLowerCase()}</a></div> : null
                                                                }
                                                                {
                                                                    customer.data.patientProfile.familyDoctorClinic.phoneNumber ?
                                                                        <span className='mr-2'>P:<a className='ml-1' href={`tel:${customer.data.patientProfile.familyDoctorClinic.phoneNumber}`}>{sys.getFormattedPhoneNumber(customer.data.patientProfile.familyDoctorClinic.phoneNumber)}</a></span> : null
                                                                }
                                                                {
                                                                    customer.data.patientProfile.familyDoctorClinic.faxNumber ?
                                                                        <span>F:<span className='ml-1'>{sys.getFormattedPhoneNumber(customer.data.patientProfile.familyDoctorClinic.faxNumber)}</span></span> : null
                                                                }
                                                            </div>
                                                            <div className='ml-2'>
                                                                <button
                                                                    type='button'
                                                                    className='btn btn-icon-only p-0'
                                                                    onClick={handleFamilyDoctorClinicClear}
                                                                >
                                                                    <i className='fal fa-times text-danger'></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </> :
                                                    <div>
                                                        <button type='button' className='btn btn-link p-0' onClick={handleAddressBookOpen}>Pick from address book</button>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </section>
                                <section>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <label><small>Primary Contact</small></label>
                                            {
                                                customer.data.primaryContactPerson && customer.data.primaryContactPerson.isReady ?
                                                    <div
                                                        className='profile-wrapper pb-0'
                                                    >
                                                        <div className='profile d-flex align-items-center'>
                                                            <span
                                                                className={'profile-image profile-image-md profile-initials rounded-circle d-flex fw-500' + (customer.data.primaryContactPerson.color ? ` ${ch.getProfileColor(customer.data.primaryContactPerson)}` : ' bg-gray-700')}
                                                                title={ph.getFullName(customer.data.primaryContactPerson)}
                                                            >
                                                                {ph.getInitials(customer.data.primaryContactPerson)}
                                                            </span>
                                                        </div>
                                                        <span className='description'>
                                                            <span>{ph.getFullName(customer.data.primaryContactPerson)}{(customer.data.primaryContactPerson.relationship ? <span className='ml-1'>(<span className='fw-500 fs-90 text-success-700'>{customer.data.primaryContactPerson.relationship}</span>)</span> : <></>)}</span>
                                                            {
                                                                customer.data.primaryContactPerson.address && customer.data.primaryContactPerson.address.country ? <span className='fs-95 info text-truncate text-truncate-xl'>{customer.data.primaryContactPerson.address.fullLine}</span> : null
                                                            }
                                                            {
                                                                customer.data.primaryContactPerson.emailAddress ? <span className='fs-90 text-info'>{customer.data.primaryContactPerson.emailAddress}</span> : null
                                                            }
                                                            {
                                                                customer.data.primaryContactPerson.phoneNumber ? <span className='fs-90 text-info'>{sys.getFormattedPhoneNumber(customer.data.primaryContactPerson.phoneNumber)}</span> : null
                                                            }
                                                            <span className='mt-h fs-90'>(<button type='button' className='btn btn-link fs-90 p-0' onClick={() => { customer.clearPrimaryContactPerson() }}>Clear</button>)</span>
                                                        </span>
                                                    </div> :
                                                    <div>
                                                        <button
                                                            type='button'
                                                            className='btn btn-link btn-sm p-0'
                                                            onClick={handleAddPrimaryContact}
                                                        >
                                                            Set primary contact
                                                        </button>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </section>
                                <section>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className={'form-group validate validate-email' + (isEmailRequired() ? ' validate-required' : '')}>
                                                <label className={isEmailRequired() ? 'required' : ''} htmlFor='new-customer-email-input'><small>Email</small></label>
                                                <MaskedInput
                                                    id='new-customer-email-input'
                                                    type='text'
                                                    className='form-control'
                                                    spellCheck={false}
                                                    mask={MaskKeys.EMAIL_MASK}
                                                    maxLength='150'
                                                    autoComplete='off'
                                                    value={customer.data.emailAddress ? customer.data.emailAddress : ''}
                                                    onChange={handleEmailAddressChange}
                                                    onBlur={checkConflicts}
                                                />
                                                {
                                                    primaryContact && primaryContact.emailAddress ?
                                                        <div className='text-right'>
                                                            <button
                                                                type='button'
                                                                className='btn btn-link p-0 mt-2'
                                                                onClick={handleSameAsPrimaryContactEmailAddress}
                                                            >
                                                                Same as {ph.getPreferredFirstName(primaryContact)}
                                                            </button>
                                                        </div> : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section>
                                    <div className='row'>
                                        <div className={hasPhoneExtension ? 'col-5' : 'col-8'}>
                                            <div ref={phoneRef} className={'form-group validate validate-phone' + (isPhoneRequired() ? ' validate-required' : '')}>
                                                <label className={isPhoneRequired() ? 'required' : ''} htmlFor='new-customer-phone-input'><small>Phone</small></label>
                                                <MaskedInput
                                                    id='new-customer-phone-input'
                                                    type='text'
                                                    className='form-control'
                                                    spellCheck={false}
                                                    mask={MaskKeys.PHONE_MASK}
                                                    autoComplete='off'
                                                    value={customer.data.phoneNumberOnly ? customer.data.phoneNumberOnly : ''}
                                                    onChange={handlePhoneNumberChange}
                                                    onBlur={checkConflicts}
                                                />
                                            </div>
                                        </div>
                                        <div className={'col-3' + (hasPhoneExtension ? '' : ' d-none')}>
                                            <div className='form-group'>
                                                <label htmlFor='update-personal-primary-phone-ext-input'><small>Ext.</small></label>
                                                <MaskedInput
                                                    id='new-customer-phone-ext-input'
                                                    type='text'
                                                    className='form-control'
                                                    spellCheck={false}
                                                    mask={MaskKeys.PHONE_EXT_MASK}
                                                    autoComplete='off'
                                                    maxLength={5}
                                                    value={customer.data.phoneNumberExtension ? customer.data.phoneNumberExtension : ''}
                                                    onChange={handlePhoneNumberExtensionChange}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-4'>
                                            <div ref={phoneTypeRef} className={'form-group' + (isPhoneRequired() ? ' validate validate-required' : '')}>
                                                <label className={isPhoneRequired() ? 'required' : ''} htmlFor='new-customer-phone-type-select'><small>Type</small></label>
                                                <select
                                                    id='new-customer-phone-type-select'
                                                    className='custom-select form-control'
                                                    value={customer.data.phoneType ? customer.data.phoneType : ''}
                                                    onChange={handlePhoneTypeChange}
                                                >
                                                    <option value=''></option>
                                                    {
                                                        cache.getReferenceDataOptions('PhoneType').map((option, di) => {
                                                            return <option key={`phone_type_${di}`} value={option.key}>{option.value}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        customer.data.phoneType !== 'Mobile' || (primaryContact && primaryContact.phoneNumber) ?
                                            <div className='row'>
                                                {
                                                    customer.data.phoneType !== 'Mobile' ?
                                                        <div className='col-6'>
                                                            <div className='custom-control custom-checkbox ml-1 mt-2'>
                                                                <input
                                                                    id='new-customer-phone-ext-check'
                                                                    type='checkbox'
                                                                    name='newCustomerPhoneExtensionCheck'
                                                                    className='custom-control-input'
                                                                    checked={hasPhoneExtension}
                                                                    onChange={handlePhoneHasExtensionChange}
                                                                />
                                                                <label
                                                                    htmlFor='new-customer-phone-ext-check'
                                                                    className='custom-control-label d-block'
                                                                >
                                                                    Extension
                                                                </label>
                                                            </div>
                                                        </div> : null
                                                }
                                                {
                                                    primaryContact && primaryContact.phoneNumber ?
                                                        <div className={'text-right ' + (customer.data.phoneType !== 'Mobile' ? 'col-6' : 'col-12')}>
                                                            <button
                                                                type='button'
                                                                className='btn btn-link p-0 mt-2'
                                                                onClick={handleSameAsPrimaryContactPhone}
                                                            >
                                                                Same as {ph.getPreferredFirstName(primaryContact)}
                                                            </button>
                                                        </div> : null
                                                }
                                            </div> : null
                                    }
                                </section>
                                <section>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <div ref={preferredContactMethodRef} className={'form-group validate'}>
                                                <label htmlFor='new-customer-preferred-contact-method-select'><small>Preferred Contact Method</small></label>
                                                <select
                                                    id='new-customer-preferred-contact-method-select'
                                                    className='custom-select form-control'
                                                    value={customer.data.preferredContactMethod ? customer.data.preferredContactMethod : ''}
                                                    onChange={handlePreferredContactMethodChange}
                                                >
                                                    <option value=''></option>
                                                    {
                                                        cache.getReferenceDataOptions('ContactMethodType').map((option, di) => {
                                                            return <option key={`contact_method_type_${di}`} value={option.key}>{option.value}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <div className={'form-group'}>
                                                <label htmlFor='new-customer-communication-consent-select'><small>Communication Consent</small></label>
                                                <select
                                                    id='new-customer-communication-consent-select'
                                                    className='custom-select form-control'
                                                    value={!!customer.data.communicationConsent ? customer.data.communicationConsent.toString() : ''}
                                                    onChange={handleCommunicationConsentChange}
                                                >
                                                    <option></option>
                                                    <option value='true'>Opted-in</option>
                                                    <option value='false'>Opted-out</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                {
                                    sys.configuration.customer.spokenLanguages.length > 0 || sys.configuration.customer.communicationLanguages.length > 0 ?
                                        <section>
                                            <div className='row'>
                                                {
                                                    sys.configuration.customer.spokenLanguages.length > 0 ?
                                                        <div className='col-6'>
                                                            <div className='form-group'>
                                                                <label htmlFor='new-spoken-language-select'><small>Spoken Language</small></label>
                                                                <select
                                                                    id='new-spoken-language-select'
                                                                    className='custom-select form-control'
                                                                    value={customer.data.spokenLanguage ? customer.data.spokenLanguage : ''}
                                                                    onChange={handleSpokenLanguageChange}
                                                                >
                                                                    <option value=''>English</option>
                                                                    {
                                                                        sys.configuration.customer.spokenLanguages.map((l, li) => {
                                                                            return <option key={`new-customer-spoken-language-${li}`} value={l}>{l}</option>
                                                                        })
                                                                    }
                                                                </select>
                                                                {
                                                                    primaryContact && primaryContact.spokenLanguage ?
                                                                        <button
                                                                            type='button'
                                                                            className='btn btn-link p-0 mt-2'
                                                                            onClick={handleSameAsPrimaryContactSpokenLanguage}
                                                                        >
                                                                            Same as {ph.getPreferredFirstName(primaryContact)}
                                                                        </button> : null
                                                                }
                                                            </div>
                                                        </div> : null
                                                }
                                                {
                                                    sys.configuration.customer.communicationLanguages.length > 0 ?
                                                        <div className='col-6'>
                                                            <div className='form-group'>
                                                                <label htmlFor='new-communication-language-select'><small>Communication Language</small></label>
                                                                <select
                                                                    id='new-communication-language-select'
                                                                    className='custom-select form-control'
                                                                    value={customer.data.communicationLanguage ? customer.data.communicationLanguage : ''}
                                                                    onChange={handleCommunicationLanguageChange}
                                                                >
                                                                    <option value=''>English</option>
                                                                    {
                                                                        sys.configuration.customer.communicationLanguages.map((l, li) => {
                                                                            return <option key={`new-customer-communication-language-${li}`} value={l.key}>{l.value}</option>
                                                                        })
                                                                    }
                                                                </select>
                                                                {
                                                                    primaryContact && primaryContact.communicationLanguage ?
                                                                        <div className='w-100 text-right'>
                                                                            <button
                                                                                type='button'
                                                                                className='btn btn-link p-0 mt-2'
                                                                                onClick={handleSameAsPrimaryContactCommunicationLanguage}
                                                                            >
                                                                                Same as {ph.getPreferredFirstName(primaryContact)}
                                                                            </button>
                                                                        </div> : null
                                                                }
                                                            </div>
                                                        </div> : null
                                                }
                                            </div>
                                        </section> : null
                                }
                                <section>
                                    <AddressInput
                                        ref={homeAddressRef}
                                        address={(customer.data && customer.data.address ? customer.data.address : {})}
                                        label={'Home address'}
                                        defaultCountryCode={(auth.currentUser && auth.currentTenant && auth.currentTenant.address ? auth.currentTenant.address.countryCode : null)}
                                        onChange={handleAddressChange}
                                    />
                                    {
                                        primaryContact && primaryContact.address ?
                                            <div className='row'>
                                                <div className='col-12 text-right'>
                                                    <button
                                                        type='button'
                                                        className='btn btn-link p-0 mt-2'
                                                        onClick={handleSameAsPrimaryContactAddress}
                                                    >
                                                        Same as {ph.getPreferredFirstName(primaryContact)}
                                                    </button>
                                                </div>
                                            </div> : null
                                    }
                                </section>
                            </div>
                        </FadeIn>
                    </div>
                    <div className='quick-drawer-action pl-3'>
                        <div className='row'>
                            <div className='col-4'>
                                {renderSeeNotesButton()}
                            </div>
                            <div className='col-8'>
                                <div className='float-right'>
                                    <button data-cancel-save-customer
                                        type='button'
                                        className='btn btn-link btn-cancel mr-2'
                                        onClick={handleCancel}
                                    >Cancel</button>
                                    <button data-submit-save-customer
                                        type='submit'
                                        className='btn btn-success'
                                    >Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
        <BodyEnd>
            <LoadingOverlay isLoading={isUploadingAttachments} message={nh.UPLOAD_MESSAGE} />
            <ConfirmModal
                icon={<i className={oh.getIcon('customer', 'new') + ' text-warning mr-2'}></i>}
                message={<><span data-warn-no-contact-info className='fw-500'>No contact information</span>&nbsp;entered.&nbsp;Continue?</>}
                show={confirmMissingContact}
                option1ClassName='btn btn-warning shadow-0 bootbox-accept'
                onOption1Click={handleConfirmMissingContact}
                onCancel={handleCancelMissingContact}
            />
            <ConfirmModal
                icon={<i className={`${oh.getIcon('customer', 'new')} text-warning mr-2`}></i>}
                message={<><strong data-warn-duplicate-detected>Potential duplicate</strong>&nbsp;customer detected.  Continue?</>}
                option1ClassName={'btn btn-warning shadow-0 bootbox-accept'}
                show={confirmConflicts}
                onOption1Click={handleConflictsConfirm}
                onCancel={handleConflictsCancel}
            />
            <ConfirmModal
                icon={<i className={`${oh.getIcon('customer', 'patient')} text-info mr-2`}></i>}
                message={<><strong>Health card verified</strong>. Update customer's information?</>}
                option1ClassName={'btn btn-info shadow-0 bootbox-accept'}
                show={confirmPopulateCustomer}
                onOption1Click={handlePopulateCustomerConfirm}
                onCancel={handlePopulateCustomerCancel}
            />
            <AddressBookModal
                ref={addressBookModalRef}
                shading={true}
                dragEnabled={false}
                allowedTypes={['ExternalClinic', 'ExternalDoctor']}
                onSelected={handleFamilyDoctorClinicSelected}
            />
        </BodyEnd>
    </>)
}

export default NewCustomer;