import React, { useEffect, useContext, useRef, useState } from 'react';
import { Observer } from 'mobx-react-lite';
import { Multiselect } from 'react-widgets'
import uuid from 'react-uuid';

import BodyEnd from '../../_shared/BodyEnd';
import LoadingOverlay from '../../_shared/LoadingOverlay';
import CommunicationScheduleModal from '../CommunicationScheduleModal/_index';

import CommunicationStore from '../../../../stores/CommunicationStore';

import * as ErrorMessages from '../../../../constants/errorMessages';
import * as fn from '../../../../utilities/_functions';
import * as ah from '../../../../utilities/addressHelper';
import * as sys from '../../../../utilities/systemHelper';

import api from '../../../../api';

function SmsCommunication(props) {
    const isMounted = useRef(true);
    const focusTimer = useRef(null);
    const searchTimer = useRef(null);
    const modalBodyRef = useRef(null);
    const validateRef = useRef(null);
    const scheduleModalRef = useRef(null);
    const cancelSearch = useRef(null);
    const communication = useContext(CommunicationStore);

    const [isLoading, setIsLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState(null);
    const [contacts, setContacts] = useState([]);
    const [selectedContacts, setSelectedContacts] = useState([]);
    const [message, setMessage] = useState(null);

    useEffect(() => {
        firstElementFocus();

        return () => {
            isMounted.current = false;
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
            if (searchTimer.current) { clearTimeout(searchTimer.current); searchTimer.current = null; }
            if (cancelSearch.current && fn.isFunction(cancelSearch.current)) { cancelSearch.current(); cancelSearch.current = null; }
            communication.clear();
        }
    }, []) // eslint-disable-line

    useEffect(() => {
        setDefaultContacts();

        if (communication.isReady && communication.templates && communication.templates.length > 0) {
            if (communication.selectedTemplate) {
                setMessage(`${communication.selectedTemplate.renderedTemplate}`);
            }
        }
    }, [communication.isReady]) // eslint-disable-line

    const setDefaultContacts = () => {
        if (communication.defaultContact && communication.defaultContact.number) {
            const { defaultContact } = communication;
            const selectedContact = {
                id: uuid(),
                name: defaultContact.name ? defaultContact.name : 'New',
                value: defaultContact.number ? defaultContact.number : null,
                isNew: false,
            };
            const newSelectedContacts = [selectedContact];

            setSelectedContacts(newSelectedContacts);
        }
    }

    const firstElementFocus = () => {
        const found = modalBodyRef && modalBodyRef.current ? modalBodyRef.current.querySelectorAll('input[type="text"]:not([disabled])') : null;

        if (found && found.length > 0) {
            const notEmpty = Array.from(found).filter(el => !el.value);

            focusTimer.current = setTimeout(() => {
                if (notEmpty && notEmpty.length > 0) {
                    notEmpty[0].focus();
                } else {
                    found[0].focus();
                }
            }, 100);
        }
    }

    const handleTemplateChange = event => {
        const selectedTemplateId = event.target.value;

        communication.updateSelectedTemplateId(selectedTemplateId)
            .then(() => {
                if (communication.selectedTemplate && communication.selectedTemplate.renderedTemplate) {
                    setMessage(`${communication.selectedTemplate.renderedTemplate}`);
                }
                else {
                    setMessage(null);
                }
            })

    }

    const handleMobileToSearch = (searchTerm, metadata) => {
        setSearchTerm(searchTerm);
        setContacts([]);

        if (searchTimer.current) {
            clearTimeout(searchTimer.current);
            searchTimer.current = null;
        }

        if (searchTerm && searchTerm.length >= 2) {
            searchTimer.current = setTimeout(() => {
                setIsLoading(true);

                if (fn.isFunction(cancelSearch.current)) { cancelSearch.current(); cancelSearch.current = null; }

                api.AddressBookLookUp.fullSearch(searchTerm, null, 'Mobile', communication.customerId, 10, 0, false, true, false, (c) => { cancelSearch.current = c })
                    .then(({ data }) => {
                        if (isMounted.current) {
                            let filteredResult = data.result && data.result.length > 0 ? data.result.filter(r => r.contactType === 'Mobile') : [];

                            if (filteredResult && filteredResult.length > 0 && fn.regexTester.phone.test(searchTerm)) {
                                filteredResult = filteredResult.filter(r => r.value.toLowerCase() === searchTerm.toLowerCase());
                            }

                            setContacts((filteredResult && filteredResult.length > 0 ? filteredResult : []));
                        }
                    })
                    .finally(() => {
                        setIsLoading(false);
                    })
            }, 500);
        } else {
            if (isMounted.current) {
                setContacts(contacts);
            }
        }
    }

    const handleMobileToCreate = (searchTerm) => {
        if (fn.regexTester.phone.test(searchTerm)) {
            const mobile = searchTerm.toLowerCase();

            if (!selectedContacts.some(c => c.value === mobile)) {
                const newContact = {
                    id: uuid(),
                    name: 'New',
                    value: mobile,
                    isNew: true,
                };
                const newSelectedContacts = [...selectedContacts, newContact];

                setSelectedContacts(newSelectedContacts);

                if (searchTimer.current) {
                    clearTimeout(searchTimer.current);
                    searchTimer.current = null;
                }
            }
        }
    }

    const handleMobileToChange = (dataItems, metadata) => {
        setSelectedContacts(dataItems);
    }

    const handleMessageChange = (event) => {
        var value = event.target.value;
        setMessage(value);
        communication.hasUnsavedChanges = true;
    }

    const handleClose = event => {
        if (fn.isFunction(props.onClose)) {
            if (communication.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    communication.clear();
                    communication.hasUnsavedChanges = false;
                    props.onClose(event);
                }
            } else {
                props.onClose(event);
            }
        }
    }

    const handleStartSchedule = event => {
        scheduleModalRef.current.show({ dateTimeLocal: communication.scheduledSendDateTimeLocal, type: 'SMS' });
    }

    const handleScheduleSelect = (event, dateTime) => {
        communication.scheduledSendDateTimeLocal = dateTime;
        communication.hasUnsavedChanges = true;
    }

    const handleScheduleCancel = event => {
        communication.scheduledSendDateTimeLocal = null;
        communication.hasUnsavedChanges = true;
    }

    const handleScheduleReason = event => {
        scheduleModalRef.current.show({ dateTimeLocal: communication.scheduledSendDateTimeLocal, type: 'SMS', mode: 'reason' });
    }

    const handleScheduled = (event, reason) => {
        communication.scheduledSendReason = reason ? reason : null;
        handleSend(event);
    }

    const handleSend = event => {
        if (fn.validateForm(validateRef.current)) {
            const option = {
                type: 'SMS',
                to: selectedContacts,
                smsOption: {
                    message: message,
                }
            };
            communication.send(option, true)
                .then((data) => {
                    if (fn.isFunction(props.onSent)) {
                        option.response = data;
                        props.onSent(event, option);
                    }
                })
                .finally(() => {
                    if (fn.isFunction(props.onClose)) {
                        props.onClose(event);
                    }
                })
        }
    }

    const renderTitle = () => {
        return <div className='popup-title'>
            <div className='actions left-actions pt-1'>
                <ul className='ml-2'>
                    <li>
                        <h4 className='mb-0'>
                            <>Sending {(communication.selectedTemplate ? communication.selectedTemplate.name.toLowerCase() : 'SMS')} ...</>
                        </h4>
                    </li>
                </ul>
            </div>
            <div className='actions right-actions'>
                <ul>
                    <li>
                        <div className='btn-group'>
                            <button
                                data-send-email
                                type='button'
                                className={'btn px-3 ' + (communication.scheduledSendDateTimeLocal ? 'btn-warning' : 'btn-success')}
                                disabled={communication.isSaving}
                                title={communication.scheduledSendDateTimeLocal ? `Scheduled to send on ${sys.getFormattedShortDate(communication.scheduledSendDateTimeLocal, true)}` : null}
                                onClick={(e) => { communication.scheduledSendDateTimeLocal ? handleScheduleReason(e) : handleSend(e) }}
                            >
                                {communication.scheduledSendDateTimeLocal ? <>Send later</> : <>Send</>}
                            </button>
                            <button
                                type='button'
                                className={'btn dropdown-toggle px-2 ' + (communication.scheduledSendDateTimeLocal ? 'btn-warning' : 'btn-success')}
                                disabled={communication.isSaving}
                            >
                            </button>
                            <div className='dropdown-menu dropdown-menu-right min-width-auto'>
                                <button
                                    type='button'
                                    className='dropdown-item'
                                    onClick={handleStartSchedule}
                                >
                                    {communication.scheduledSendDateTimeLocal ? <>Select another date / time</> : <>Schedule send</>}
                                </button>
                                {
                                    communication.scheduledSendDateTimeLocal ?
                                        <button
                                            type='button'
                                            className='dropdown-item text-danger text-danger-700-hover'
                                            onClick={handleScheduleCancel}
                                        >
                                            <strong>Cancel schedule send</strong>
                                        </button> : null
                                }
                            </div>
                        </div>
                    </li>
                    <li>
                        <button
                            type='button'
                            className='btn btn-icon btn-close'
                            onClick={handleClose}
                        >
                            <i className='close-icon fal fa-times fs-xl'></i>
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    }

    const renderMobileRecipientMultiSelect = () => {
        return <Observer>{() => <>
            <Multiselect
                allowCreate={true}
                busy={isLoading}
                data={contacts}
                valueField='id'
                textField='name'
                filter={false}
                disabled={!props.smsToEnabled}
                itemComponent={({ item }) => (
                    <>
                        <div
                            className='w-100 p-2'
                        >
                            <div className='d-flex flex-row'>
                                <div className='d-flex flex-column justify-content-center pr-2'>
                                    <span
                                        className={`profile-image profile-image-md profile-initials rounded-circle d-flex m-auto bg-gray-700 fw-500`}
                                        title={(item.name ? item.name : item.companyName)}
                                    >
                                        <span className='fs-80'>{getInitials(item.name ? item.name : item.companyName)}</span>
                                    </span>
                                </div>
                                <div className='d-flex flex-1 flex-column justify-content-center text-dark external-appointment-detail'>
                                    <div
                                        className='fs-lg fw-500 line-height-1 pt-1'
                                    >
                                        <small className={'d-block text-gray-700 mb-o'}>
                                            {
                                                item.name ?
                                                    <span className='fw-500'>
                                                        {item.name}
                                                        {
                                                            item.companyName ?
                                                                <small className='ml-1'>({item.companyName})</small> : null
                                                        }
                                                    </span> :
                                                    <span className='fw-500'>{item.companyName}</span>
                                            }
                                        </small>
                                        {
                                            item.address && item.address.country ?
                                                <small className='d-block text-gray-600 address-sameline mb-o'>{ah.getAddressHtml(item.address)}</small> : null
                                        }
                                        <small className='d-block text-info fw-500'>Mobile: {item.value}</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                tagComponent={({ item }) => (
                    <span className={'tag ' + (item.isNew ? 'bg-gray-800' : 'bg-primary-700')}>
                        <strong>{item.name ? item.name : item.companyName} - {item.value}</strong>
                    </span>
                )}
                messages={{
                    createOption: () => (
                        searchTerm && fn.regexTester.phone.test(searchTerm) ?
                            <>Send SMS to <strong>{searchTerm}</strong></> :
                            <>Search by <strong>name</strong> or <strong>mobile number</strong></>
                    ),
                    emptyList: '',
                    emptyFilter: ''
                }}
                value={selectedContacts}
                onCreate={handleMobileToCreate}
                onSearch={handleMobileToSearch}
                onChange={handleMobileToChange}
            />
        </>}</Observer>
    }

    const renderContent = () => {
        return <>
            <div className='popup-body'>
                <form ref={validateRef}>
                    <fieldset disabled={communication.isSaving}>
                        <div className={'h-100'}>
                            {
                                communication.scheduledSendDateTimeLocal ?
                                    <section className='pt-4 px-4'>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <p className='fw-500 text-warning-700 mb-0'>Scheduled to send on {sys.getFormattedLongDate(communication.scheduledSendDateTimeLocal, true)}.</p>
                                            </div>
                                        </div>
                                    </section> : null
                            }
                            <section className='p-4'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className='form-group mb-4 validate validate-required'>
                                            <label htmlFor='sms-send-to' className='required'><small>To</small></label>
                                            {renderMobileRecipientMultiSelect()}
                                        </div>
                                    </div>
                                </div>
                                {
                                    communication.templates && communication.templates.length > 0 ?
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className='form-group mb-4'>
                                                    <label htmlFor='sms-template'><small>Template</small></label>
                                                    <select
                                                        id='sms-template'
                                                        className='custom-select form-control'
                                                        value={communication.selectedTemplate ? communication.selectedTemplate.id : ''}
                                                        onChange={handleTemplateChange}
                                                    >
                                                        <option value=''>(Write your own SMS message)</option>
                                                        {
                                                            communication.templates ?
                                                                communication.templates.map((t, ti) => {
                                                                    return <option
                                                                        key={`sms_template_${ti}`}
                                                                        value={t.id}
                                                                    >{t.name}</option>
                                                                }) : null
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div> : null
                                }
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className='form-group mb-2 sms-message validate validate-required'>
                                            <label className='required' htmlFor='sms-message'><small>Message</small></label>
                                            <textarea
                                                className='form-control'
                                                rows={10}
                                                value={message ? message : ''}
                                                onChange={handleMessageChange}
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </fieldset>
                </form>
            </div>
        </>
    }

    const getInitials = (name) => {
        if (!name) return 'X';

        const result = name.replace('Dr. ', '').replace(/[^a-zA-Z ]/g, '');

        if (!result) return name[0].toUpperCase();

        const parts = result.split(' ');

        switch (parts.length) {
            case 0:
                return name[0].toUpperCase();

            case 1:
                return result[0].toUpperCase();

            case 2:
                return `${parts[0][0]}${parts[1][0]}`.toUpperCase();

            default:
                return `${parts[0][0]}${parts[(parts.length - 1)][0]}`.toUpperCase();
        }
    }

    return <>
        <Observer>{() =>
            <>
                {
                    communication.isReady ?
                        <>
                            <Observer>{() => <LoadingOverlay isLoading={communication.isSaving} />}</Observer>
                            {renderTitle()}
                            {renderContent()}
                        </> :
                        <>
                            <div className='popup-title'></div>
                            <div className='popup-body'></div>
                        </>
                }
                <BodyEnd>
                    <CommunicationScheduleModal ref={scheduleModalRef} onSelect={handleScheduleSelect} onScheduled={handleScheduled} />
                </BodyEnd>
            </>
        }</Observer>
    </>
}

export default SmsCommunication;