import React, { useEffect, useContext, useState, useRef } from 'react';
import { toJS } from 'mobx';
import { Observer } from 'mobx-react-lite';
import { ScrollView } from 'devextreme-react';
import DataGrid, { Sorting, RowDragging, Column } from 'devextreme-react/data-grid';
import DateBox from 'devextreme-react/date-box';
import MaskedInput from 'react-text-mask';
import { DropdownList } from 'react-widgets'
import { toast } from 'react-toastify';
import { GlobalHotKeys } from 'react-hotkeys';
import moment from 'moment';

import BodyEnd from '../../_shared/BodyEnd';
import ConfirmModal from '../../_shared/ConfirmModalComponent';
import { renderBalance, getPurchaseCss } from './_index';
import NotAvailableModal from './NotAvailableModal';
import RichTextEditor from '../../_shared/RichTextEditor';
import FilterCustomer from './FilterCustomer';
import LoadingOverlay from '../../_shared/LoadingOverlay'
import { quickDrawerFocus } from '../../_shared/QuickDrawer';
import ItemSelector from './ItemSelector/_index';

import PurchaseViewStore from '../../../../stores/PurchaseViewStore';
import PurchaseUpdateStore from '../../../../stores/PurchaseUpdateStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';
import CacheStore from '../../../../stores/CacheStore';

import api from '../../../../api';
import * as MaskKeys from '../../../../constants/maskKeys';
import * as ErrorMessages from '../../../../constants/errorMessages';
import * as fn from '../../../../utilities/_functions';
import * as ch from '../../../../utilities/customerHelper';
import * as bh from '../../../../utilities/badgeHelper';
import * as ph from '../../../../utilities/personHelper';
import * as sys from '../../../../utilities/systemHelper';

function UpdatePurchase(props) {
    const isMounted = useRef(true);
    const focusTimer = useRef(null);
    const searchTimer = useRef(null);
    const orderRef = useRef(null);
    const confirmModalRef = useRef(null);
    const newTransactionItemTimer = useRef(null);
    const newTransactionSubItemTimer = useRef(null);
    const productSelectorRef = useRef(null);
    const billingInformationRef = useRef(null);
    const purchaseView = useContext(PurchaseViewStore);
    const purchase = useContext(PurchaseUpdateStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const cache = useContext(CacheStore);
    const [newCustomerData, setNewCustomerData] = useState(null);
    const [existingCustomerData, setExistingCustomerData] = useState(null);
    const [customerSearchResult, setCustomerSearchResult] = useState(null);
    const [hasSaveConflicts, setHasSaveConflicts] = useState(false);
    const [isProductSelectorActive, setIsProductSelectorActive] = useState(false);
    const [productSelectorType, setProductSelectorType] = useState(null);
    const [productSelectorMulti, setProductSelectorMulti] = useState(true);

    useEffect(() => {
        return () => {
            isMounted.current = false;
            if (searchTimer.current) { clearTimeout(searchTimer.current); searchTimer.current = null; }
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
            if (newTransactionItemTimer.current) { clearTimeout(newTransactionItemTimer.current); newTransactionItemTimer.current = null; }
            if (newTransactionSubItemTimer.current) { clearTimeout(newTransactionSubItemTimer.current); newTransactionSubItemTimer.current = null; }
        }
    }, []) // eslint-disable-line

    const renderTitle = () => {
        return <div className='popup-title'>
            <div className='actions left-actions'>
                {
                    !purchase.isNew ?
                        <ul>
                            <li>
                                <button type='button' className='btn btn-icon' onClick={e => handleGoBack(e)}>
                                    <i className='fal fa-arrow-left'></i>
                                </button>
                            </li>
                        </ul> : null
                }
            </div>
            <div className='actions right-actions'>
                <ul>
                    <Observer>{() => <>{renderPurchaseActions()}</>}</Observer>
                    <li>
                        <button type='button' className='btn btn-icon btn-close' onClick={e => handleClose(e)}>
                            <i className='close-icon fal fa-times fs-xl'></i>
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    }

    const renderContent = () => {
        return <div className='popup-body'>
            <ScrollView width='100%' height='100%'>
                {
                    purchase.data ?
                        <>
                            <div className='order-modal-header'>
                                <div className='order-header pt-2 px-4'>
                                    <div className='order-description'>
                                        <h3 className='purchase-title'>Invoice Detail</h3>
                                        <p>Invoice # <strong>{purchase.data.invoiceNumber}</strong></p>
                                        <div className={'order-status ' + getPurchaseCss(purchase)}>
                                            <p className='title'>Status</p>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>Invoice</td>
                                                        <td>
                                                            {bh.renderPurchaseBalanceStatus(purchase.data, 'text-truncate text-truncate-md fs-sm p-2')}
                                                        </td>
                                                    </tr>
                                                    {renderBalance(purchase)}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <Observer>{() =>
                                        <>
                                            {
                                                purchase.data.insuranceInformation && JSON.stringify(purchase.data.insuranceInformation) !== JSON.stringify({}) ?
                                                    <div className='order-extra'>
                                                        <p className='title'>Insurance Information</p>
                                                        <p className='name text-truncate text-truncate-lg'>{purchase.data.insuranceInformation.provider}</p>
                                                        {
                                                            purchase.data.insuranceInformation.policyHolderName ?
                                                                <p className='info'>{purchase.data.insuranceInformation.policyHolderName}</p> : null
                                                        }
                                                        {
                                                            purchase.data.insuranceInformation.memberId ||
                                                                purchase.data.insuranceInformation.planNumber ||
                                                                purchase.data.insuranceInformation.accountNumber ||
                                                                purchase.data.insuranceInformation.policyNumber ?
                                                                <div className='info'>{[
                                                                    purchase.data.insuranceInformation.memberId,
                                                                    purchase.data.insuranceInformation.planNumber,
                                                                    purchase.data.insuranceInformation.accountNumber,
                                                                    purchase.data.insuranceInformation.policyNumber
                                                                ].filter(i => i !== null).join(' - ')}</div> : null
                                                        }
                                                        {
                                                            purchase.data.insuranceInformation.amount ?
                                                                <p className='info fw-500'>{fn.formatCurrency(purchase.data.insuranceInformation.amount)}</p> : null
                                                        }
                                                    </div> : null
                                            }
                                        </>
                                    }</Observer>
                                    <Observer>{() =>
                                        <>
                                            {
                                                purchase.data.shippingAddress ?
                                                    <div className='order-extra'>
                                                        <p className='title'>Shipping Address</p>
                                                        <address>
                                                            {
                                                                purchase.data.shippingAddress && purchase.data.shippingAddress.addressLine1 ?
                                                                    <p>{purchase.data.shippingAddress.addressLine1}</p> : null
                                                            }
                                                            {
                                                                purchase.data.shippingAddress && purchase.data.shippingAddress.addressLine2 ?
                                                                    <p>{purchase.data.shippingAddress.addressLine2}</p> : null
                                                            }
                                                            {
                                                                purchase.data.shippingAddress && purchase.data.shippingAddress.country ?
                                                                    <p>{purchase.data.shippingAddress.country}</p> : null
                                                            }
                                                        </address>
                                                    </div> : null
                                            }
                                        </>
                                    }</Observer>
                                    <Observer>{() =>
                                        <>
                                            <div className='order-extra'>
                                                {
                                                    purchase.data.customer ?
                                                        <div className='billing-wrapper'>
                                                            {
                                                                purchase.data.status === 'Incomplete' ?
                                                                    <button
                                                                        type='button'
                                                                        className='btn btn-link p-0 title text-info-hover cursor-pointer'
                                                                        onClick={handleBillingInformationClear}
                                                                    >
                                                                        Billing Information<span className='ml-2'><i className='fal fa-pen'></i></span>
                                                                    </button> :
                                                                    <p className='title'>Insurance Information</p>
                                                            }
                                                            <p className='name'>{purchase.data.customerName}</p>
                                                            {
                                                                purchase.data.billingAddress || purchase.data.customerEmail || purchase.data.customerPhoneNumber ?
                                                                    <address>
                                                                        {
                                                                            purchase.data.billingAddress && purchase.data.billingAddress.addressLine1 ?
                                                                                <p>{purchase.data.billingAddress.addressLine1}</p> : null
                                                                        }
                                                                        {
                                                                            purchase.data.billingAddress && purchase.data.billingAddress.addressLine2 ?
                                                                                <p>{purchase.data.billingAddress.addressLine2}</p> : null
                                                                        }
                                                                        {
                                                                            purchase.data.billingAddress && purchase.data.billingAddress.country ?
                                                                                <p>{purchase.data.billingAddress.country}</p> : null
                                                                        }
                                                                        {
                                                                            purchase.data.customerEmail ?
                                                                                <p><a href={`mailto:${purchase.data.customerEmail}`}>{purchase.data.customerEmail}</a></p> : null
                                                                        }
                                                                        {
                                                                            purchase.data.customerPhoneNumber ?
                                                                                <p><a href={`tel:${purchase.data.customerPhoneNumber}`}>{purchase.data.customerPhoneNumber}</a></p> : null
                                                                        }
                                                                    </address> : null
                                                            }
                                                        </div> :
                                                        <div className='billing-wrapper'>
                                                            <p className='title'>Billing Information</p>
                                                            <div ref={billingInformationRef} className='dropdown'>
                                                                <FilterCustomer
                                                                    id='purchase-customer-input'
                                                                    delay={500}
                                                                    onChange={handleCustomerSearchChange}
                                                                />
                                                                <ul className='dropdown-menu'>
                                                                    {
                                                                        customerSearchResult && customerSearchResult.length > 0 ?
                                                                            customerSearchResult.slice(0, 5).map((s, si) => {
                                                                                return <li
                                                                                    key={`search_result_${si}`}
                                                                                    className={'dropdown-menu-item'}>
                                                                                    <div
                                                                                        className='profile-wrapper'
                                                                                        onClick={() => handleCustomerSearchClick(s)}
                                                                                    >
                                                                                        <div className='profile d-flex flex-column justify-content-center align-content-center'>
                                                                                            <span
                                                                                                className={`profile-image profile-image-md profile-initials rounded-circle fs-xs text-white ${ch.getProfileColor(s)}`}
                                                                                                title={ph.getPreferredFullName(s)}
                                                                                            >
                                                                                                {s.initials}
                                                                                            </span>
                                                                                        </div>
                                                                                        <div className='description'>
                                                                                            <div
                                                                                                className='fw-500 text-gray-700'
                                                                                                title={s.dateOfBirth ? `DOB: ${moment(s.dateOfBirth).format('YYYY-MM-DD')}` : null}
                                                                                            >
                                                                                                {ph.getLastFirstName(s, true)}
                                                                                                {
                                                                                                    s.dateOfBirth || s.sex || s.gender || s.pronoun ?
                                                                                                        <small className='ml-2 text-gray'>({`${ph.getAge(s.dateOfBirth)} ${ph.getSexGenderPronounDisplay(s)}`.trim()})</small> : null
                                                                                                }
                                                                                            </div>
                                                                                            {
                                                                                                s.address && s.address.country ?
                                                                                                    <div className='fs-95 info text-truncate text-truncate-xl'>{s.address.fullLine}</div> : null
                                                                                            }
                                                                                            {
                                                                                                s.dateOfBirth ?
                                                                                                    <div className='fs-90 info text-truncate text-truncate-xl'>DOB: {fn.formatDate(s.dateOfBirth)}</div> : null
                                                                                            }
                                                                                            {
                                                                                                s.emailAddress ?
                                                                                                    <div className='fs-90 text-info text-truncate text-truncate-xl'>{s.emailAddress}</div> : null
                                                                                            }
                                                                                            {
                                                                                                s.primaryPhoneNumber ?
                                                                                                    <div className='fs-90 text-info text-truncate text-truncate-xl'>{sys.getFormattedPhoneNumber(s.primaryPhoneNumber.number)}</div> : null
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                            }) : null
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                        </>
                                    }</Observer>
                                </div>
                                <div className='order-header pb-2 px-4'>
                                    <div className='order-description'>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td><span className='title'>Invoice Date</span></td>
                                                    <td className='invoice-date'>
                                                        <Observer>{() =>
                                                            <DateBox
                                                                id='purchase-invoice-date'
                                                                type='date'
                                                                displayFormat={'MMMM d, yyyy'}
                                                                useMaskBehavior={false}
                                                                showClearButton={false}
                                                                className='dx-datebox-sm'
                                                                min={moment().add(-60, 'days').startOf('day').toDate()}
                                                                max={moment().add(60, 'days').startOf('day').toDate()}
                                                                value={(purchase.data.invoiceDate ? moment(purchase.data.invoiceDate) : (purchase.data.completedDateUtc ? moment.utc(purchase.data.completedDateUtc).local() : moment())).toDate()}
                                                                onValueChanged={handleInvoiceDateChange}
                                                            >
                                                            </DateBox>
                                                        }</Observer>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><span className='title'>Due Date</span></td>
                                                    <td className='due-date'>
                                                        <Observer>{() =>
                                                            <DateBox
                                                                id='purchase-due-date'
                                                                type='date'
                                                                displayFormat={'MMMM d, yyyy'}
                                                                useMaskBehavior={false}
                                                                showClearButton={false}
                                                                className='dx-datebox-sm'
                                                                min={(purchase.data.invoiceDate ? moment(purchase.data.invoiceDate) : (purchase.data.completedDateUtc ? moment.utc(purchase.data.completedDateUtc).local() : moment())).toDate()}
                                                                value={(purchase.data.dueDate ? moment(purchase.data.dueDate) : moment()).toDate()}
                                                                onValueChanged={handleDueDateChange}
                                                            >
                                                            </DateBox>
                                                        }</Observer>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    {
                                        purchase.data.insuranceInformation && JSON.stringify(purchase.data.insuranceInformation) !== JSON.stringify({}) ?
                                            <div className='order-extra'></div> : null
                                    }
                                    {
                                        purchase.data.shippingAddress ?
                                            <div className='order-extra'></div> : null
                                    }
                                    {
                                        purchase.data.customer && !purchase.data.isCustomerLoading ?
                                            <>
                                                <div className='order-extra'>
                                                    <p className='title'>Licensed Practitioner</p>
                                                    <div className='practitioners-wrapper'>
                                                        <Observer>{() =>
                                                            <DropdownList
                                                                data={purchase.licensedUsers ? purchase.licensedUsers : []}
                                                                valueField='id'
                                                                value={purchase.data.userId}
                                                                itemComponent={({ item }) => (
                                                                    <div>
                                                                        <span className='description' dangerouslySetInnerHTML={{ __html: (item.fullName ? item.fullName : '&nbsp;') }}></span>
                                                                        {
                                                                            purchase.data.customerPreviousExam && purchase.data.customerPreviousExam.userId === item.id ?
                                                                                <span className='badge border border-info-700 bg-white text-info-700 fs-85 float-right'>Last seen</span> : null
                                                                        }
                                                                    </div>
                                                                )}
                                                                valueComponent={({ item }) => (
                                                                    item ?
                                                                        <div>
                                                                            <span className='description'><strong>{item.fullName}</strong></span>
                                                                            {
                                                                                purchase.data.customerPreviousExam && purchase.data.customerPreviousExam.userId === item.id ?
                                                                                    <span className='badge border border-info-700 bg-white text-info-700 fs-85 float-right'>Last seen</span> : null
                                                                            }
                                                                        </div> : null
                                                                )}
                                                                onChange={handleLicensedPractitionerChange}
                                                            />
                                                        }</Observer>
                                                    </div>
                                                </div>
                                            </> : null
                                    }
                                </div>
                            </div>
                            <div className='order-detail update'>
                                <Observer>{() =>
                                    <DataGrid
                                        ref={orderRef}
                                        keyExpr='id'
                                        width='100%'
                                        activeStateEnabled={false}
                                        focusStateEnabled={false}
                                        hoverStateEnabled={false}
                                        autoNavigateToFocusedRow={false}
                                        dataSource={purchase.data.items}
                                        showBorders={false}
                                        showRowLines={true}
                                        showColumnLines={false}
                                        allowColumnReordering={false}
                                        allowColumnResizing={false}
                                    >
                                        <RowDragging
                                            container={'#purchase-update-draggable'}
                                            allowReordering={true}
                                            onReorder={handleReorder}
                                            onDragStart={handleReorderDragStart}
                                            onDragEnd={handleReorderDragEnd}
                                            showDragIcons={true}
                                        />
                                        <Sorting mode='none' />
                                        <Column
                                            dataField='descriptor'
                                            caption='Description'
                                            alignment='left'
                                            width='34%'
                                            cellRender={({ data }) => {
                                                return <>
                                                    <div className='d-flex'>
                                                        <Observer>{() =>
                                                            <input
                                                                type='number'
                                                                className='form-control form-control-sm px-2 quantity-input'
                                                                min={0}
                                                                value={data.quantity ? data.quantity : ''}
                                                                disabled={data.transactionItemType === 'Service' || data.unitPrice <= 0}
                                                                onChange={e => { handleQuantityChange(e, data) }}
                                                                onBlur={e => { handleQuantityBlur(e, data) }}
                                                            />
                                                        }</Observer>
                                                        <div className='quantity-label'>x</div>
                                                        <Observer>{() => <>
                                                            {
                                                                data.isOverridden ?
                                                                    <input
                                                                        type='text'
                                                                        className='form-control form-control-sm px-2 descriptor-input'
                                                                        value={data.overriddenDescriptor ? data.overriddenDescriptor : ''}
                                                                        autoComplete='off'
                                                                        onFocus={handleHighlight}
                                                                        onChange={e => { handleOverriddenDescriptorChange(e, data) }}
                                                                        onBlur={handleDefaultBlur}
                                                                    /> :
                                                                    <input
                                                                        type='text'
                                                                        className='form-control form-control-sm px-2 descriptor-input'
                                                                        value={data.descriptor ? data.descriptor : ''}
                                                                        autoComplete='off'
                                                                        disabled={data.referenceId}
                                                                        onFocus={handleHighlight}
                                                                        onChange={e => { handleDescriptorChange(e, data) }}
                                                                        onBlur={handleDefaultBlur}
                                                                    />
                                                            }
                                                        </>
                                                        }</Observer>
                                                    </div>
                                                </>
                                            }}
                                        />
                                        <Column
                                            dataField='isStocked'
                                            caption='Stock'
                                            alignment='center'
                                            width='10%'
                                            cellRender={({ data }) => {
                                                return <Observer>{() =>
                                                    data.isStocked ?
                                                        <button
                                                            type='button'
                                                            className='btn btn-icon btn-icon-only'
                                                            disabled={!data.isNew || data.isOutOfStock}
                                                            onClick={data.isNew ? (e) => { handleIsFromInventoryChange(e, data) } : null}
                                                        >
                                                            {
                                                                data.isFromInventory ?
                                                                    <i className='fas fa-inbox-out text-success' title='Take from inventory'></i> :
                                                                    <i className='fal fa-inbox text-gray-500' title={`Don't take from inventory`}></i>
                                                            }
                                                        </button> : null
                                                }</Observer>
                                            }}
                                        />
                                        <Column
                                            dataField='unitPrice'
                                            caption='Unit Price'
                                            alignment='right'
                                            width='12%'
                                            cellRender={({ data }) => {
                                                return <Observer>{() =>
                                                    !sys.configuration.purchase.isUnitPriceUpdateEnabled && data.referenceId ?
                                                        <span className='pr-2'>{fn.formatCurrency(data.unitPrice)}</span> :
                                                        <MaskedInput
                                                            id={`amount-${data.id}`}
                                                            className='form-control form-control-sm px-2 currency-input text-right'
                                                            mask={MaskKeys.CURRENCY_MASK}
                                                            value={fn.isNullOrUndefined(data.unitPrice) ? null : data.unitPrice}
                                                            onFocus={handleHighlight}
                                                            onChange={e => { handleUnitPriceChange(e, data) }}
                                                            onBlur={handleDefaultBlur}
                                                        />
                                                }</Observer>
                                            }}
                                        />
                                        <Column
                                            dataField='discountAmount'
                                            caption='Discount'
                                            alignment='right'
                                            width='12%'
                                            cellRender={({ data }) => {
                                                return <Observer>{() =>
                                                    <>
                                                        <input
                                                            id={`discount-${data.id}`}
                                                            type='text'
                                                            className='form-control form-control-sm px-2 currency-input'
                                                            autoComplete='off'
                                                            value={getDiscountDisplay(data)}
                                                            onFocus={handleHighlight}
                                                            onChange={e => { handleDiscountAmountChange(e, data) }}
                                                            onBlur={e => { handleDiscountAmountBlur(e, data) }}
                                                        />
                                                    </>
                                                }</Observer>
                                            }}
                                        />
                                        <Column
                                            dataField='isTaxable'
                                            caption='Tax'
                                            alignment='center'
                                            width='8%'
                                            cellRender={({ data }) => {
                                                return <Observer>{() =>
                                                    <>
                                                        <div className='custom-control custom-checkbox'>
                                                            <input
                                                                id={`apply-tax-${data.id}`}
                                                                type='checkbox'
                                                                className='custom-control-input'
                                                                checked={!!data.isTaxable}
                                                                onChange={e => { handleApplyTaxChange(e, data) }}
                                                            />
                                                            <label
                                                                htmlFor={`apply-tax-${data.id}`}
                                                                className='custom-control-label'
                                                            >
                                                            </label>
                                                        </div>
                                                    </>
                                                }</Observer>
                                            }}
                                        />
                                        <Column
                                            dataField='price'
                                            caption='Total'
                                            alignment='right'
                                            width='12%'
                                            cellRender={({ data }) => {
                                                return <Observer>{() => <>{fn.formatCurrency(data.price)}</>}</Observer>
                                            }}
                                        />
                                        <Column
                                            dataField=''
                                            caption=''
                                            alignment='right'
                                            width='12%'
                                            cellRender={({ data }) => {
                                                return <Observer>{() => <>
                                                    {
                                                        purchase.isDefaultTransactionItem(data) ?
                                                            <>
                                                                <button
                                                                    type='button'
                                                                    className='btn btn-icon btn-icon-only py-2 px-1 pr-2'
                                                                    onClick={(e) => handleProductSelectorStart(e, 'TransactionItem')}
                                                                >
                                                                    <i className='fal fa-search' />
                                                                </button>
                                                            </> :
                                                            <>
                                                                {
                                                                    data.type && data.type.toLowerCase() !== 'custom' ?
                                                                        <>
                                                                            {
                                                                                data.isOverridden ?
                                                                                    <button
                                                                                        type='button'
                                                                                        className='btn btn-icon btn-icon-only py-2 px-1 pr-2'
                                                                                        onClick={(e) => handleIsOverriddenChange(e, data, false)}
                                                                                    >
                                                                                        <i className='fas fa-edit text-danger' />
                                                                                    </button> :
                                                                                    <button
                                                                                        type='button'
                                                                                        className='btn btn-icon btn-icon-only py-2 px-1 pr-2'
                                                                                        onClick={(e) => handleIsOverriddenChange(e, data, true)}
                                                                                    >
                                                                                        <i className='fal fa-edit' />
                                                                                    </button>
                                                                            }
                                                                        </> : null
                                                                }
                                                                <button
                                                                    type='button'
                                                                    className='btn btn-icon btn-icon-only py-2 px-1 pr-2'
                                                                    onClick={(e) => handleProductSelectorStart(e, 'TransactionItem', data)}
                                                                >
                                                                    <i className='fal fa-exchange text-info' />
                                                                </button>
                                                            </>
                                                    }
                                                    <button
                                                        type='button'
                                                        className='btn btn-icon btn-icon-only py-2 px-0'
                                                        onClick={e => { handleTransactionItemRemove(e, data) }}
                                                    >
                                                        <i className='fal fa-times text-danger' />
                                                    </button>
                                                </>}</Observer>
                                            }}
                                        />
                                    </DataGrid>
                                }</Observer>
                                <table className='table-order-detail-bottom'>
                                    <colgroup>
                                        <col style={{ width: '43px' }}></col>
                                        <col style={{ width: '68%' }}></col>
                                        <col style={{ width: '8%' }}></col>
                                        <col style={{ width: '12%' }}></col>
                                        <col style={{ width: '12%' }}></col>
                                    </colgroup>
                                    <tbody role='presentation'>
                                        <tr role='row' style={{ borderBottom: '1px solid #ddd' }}>
                                            <td colSpan={2} rowSpan={4} style={{ verticalAlign: 'top', borderRight: '1px solid #ddd' }}>
                                                <strong className='d-block text-gray-500 text-uppercase mt-3'>Note:</strong>
                                                <RichTextEditor
                                                    className='quill-no-border ml-n2'
                                                    mode='none'
                                                    disableTab={true}
                                                    value={purchase.data.noteHtml ? purchase.data.noteHtml : ''}
                                                    onChange={handleNoteChange}
                                                />
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                <strong>Subtotal</strong>
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                <strong>{fn.formatCurrency(purchase.data.subTotal)}</strong>
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                            </td>
                                        </tr>
                                        <tr role='row' style={{ borderBottom: '1px solid #ddd' }}>
                                            <td style={{ textAlign: 'right' }}>
                                                <strong>Discount</strong>
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                {fn.formatCurrency(purchase.data.discountAmount * -1.0, '--')}
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                            </td>
                                        </tr>
                                        <tr role='row' style={{ borderBottom: '2px solid #ddd' }}>
                                            <td style={{ textAlign: 'right' }}>
                                                <strong>Tax</strong>
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                {fn.formatCurrency(purchase.data.totalTaxAmount)}
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                            </td>
                                        </tr>
                                        <tr role='row' style={{ borderBottom: '2px solid #ddd' }}>
                                            <td style={{ textAlign: 'right' }}>
                                                <strong>Total</strong>
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                <strong>{fn.formatCurrency(purchase.data.total)}</strong>
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </> : null
                }
            </ScrollView>
        </div>
    }


    const handleGoBack = () => {
        if (purchase.hasUnsavedChanges) {
            if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                purchaseView.load(purchase.id)
                    .then(() => {
                        if (isMounted.current) {
                            props.onModeChange('view');
                        }
                    })
            }
        } else {
            purchaseView.load(purchase.id)
                .finally(() => {
                    if (isMounted.current) {
                        props.onModeChange('view');
                    }
                })
        }
    }

    const handleSave = () => {
        if (purchase.hasExpiringWorkOrders) {
            confirmModalRef.current.show({
                icon: <i className='fal fa-inbox text-warning mr-2'></i>,
                message: <>This change will affect the existing work order(s).  Continue?</>,
                option1ClassName: 'btn btn-warning shadow-0 bootbox-accept',
                onOption1Click: handleConfirmUpdateWorkOrder,
                onCancel: handleCancelUpdateWorkOrder
            })
        } else {
            handleSaveConfirmed();
        }
    }

    const handleSaveConfirmed = () => {
        purchase.save(true)
            .then((data) => {
                if (isMounted.current) {
                    if (data && data.requiredVerification) {
                        quickDrawer.activateQuickDrawer('purchase', 'verify-returns', null, handleVerifyReturnsSuccess, handleVerifyReturnsCancel)
                            .then(drawer => {
                                if (isMounted.current) {
                                    focusTimer.current = setTimeout(() => {
                                        quickDrawerFocus(drawer);
                                    }, 100);
                                }
                            });
                    } else {
                        handleSuccess(null, true);
                        purchaseView.load(purchase.id)
                            .then(() => {
                                if (isMounted.current) {
                                    props.onModeChange('view');
                                }
                            })
                            .finally(() => {
                                purchase.clear();
                            });
                    }
                }
            })
            .catch(() => {
                toast.error(() => <p><strong>Oops, something went wrong.</strong>&nbsp;Cannot update invoice.  Please try again.</p>, { position: 'top-center', style: { width: '380px' } });
            })
    }

    const handleContinueLater = event => {
        purchase.save(true)
            .then(({ id }) => {
                if (isMounted.current) {
                    handleSuccess(event, true);
                    purchaseView.load(id)
                        .then(() => {
                            if (isMounted.current) {
                                props.onModeChange('view');
                            }
                        })
                        .finally(() => {
                            purchase.clear();
                        });
                }
            })
    }

    const handleConfirmComplete = event => {
        if (!purchase.data.customerId) {
            const walkInCustomers = cache.getReferenceDataOptions('SystemCustomers');
            const walkInCustomer = walkInCustomers && walkInCustomers.length > 0 ? cache.getReferenceDataOptions('SystemCustomers')[0] : null;

            if (!!walkInCustomer) {
                confirmModalRef.current.show({
                    icon: <i className='fal fa-shopping-bag text-warning mr-2'></i>,
                    message: <>Is this a&nbsp;<span className='fw-500 tt-underline'>walk-in customer</span>?</>,
                    option1Text: 'Yes',
                    option1ClassName: 'btn btn-warning shadow-0 bootbox-accept',
                    onOption1Click: (e) => { handleConfirmedWalkInPurchase(e, walkInCustomer) },
                    cancelText: 'No',
                    onCancel: (e) => { handleConfirmedWalkInPurchase(e) }
                })
            }
            else {
                handleComplete();
            }
        }
        else {
            handleComplete();
        }
    }

    const handleConfirmedWalkInPurchase = (event, walkInCustomer) => {
        purchase.clearCustomer();
        purchase.data.customerId = walkInCustomer ? walkInCustomer.value : null;
        purchase.data.customerName = walkInCustomer ? walkInCustomer.key : null;
        purchase.hasUnsavedChanges = true;
        confirmModalRef.current.close();

        if (!!purchase.data.customerId) {
            purchase.save(true)
                .then(() => {
                    handleComplete(event);
                })
        }
    }

    const handleComplete = event => {
        if (!!purchase.data.customerId) {
            purchase.save(true)
                .then(() => {
                    if (isMounted.current) {
                        purchase.complete(true)
                            .then((id) => {
                                if (isMounted.current) {
                                    handleSuccess(event, true);
                                    purchaseView.load(id)
                                        .then(() => {
                                            if (isMounted.current) {
                                                props.onModeChange('view');
                                            }
                                        })
                                        .finally(() => {
                                            purchase.clear();
                                        });
                                }
                            })
                    }
                })
                .catch(() => {
                    setHasSaveConflicts(true);
                })
        }
        else {
            toast.error(() => ErrorMessages.GENERIC_FORM_ERROR_HTML, { position: 'top-center', style: { width: '380px' } });
            billingInformationRef.current.classList.add('validate', 'has-error');
        }
    }

    const handleClose = event => {
        if (fn.isFunction(props.onClose)) {
            if (purchase.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    // restore original version
                    purchase.reloadFromCache();
                    props.onClose(event);
                }
            } else {
                props.onClose(event);
            }
        }
    }

    const handleSuccess = (event, updated) => {
        if (props.onSuccess && fn.isFunction(props.onSuccess)) {
            const data = {
                id: purchase.id,
                customerId: purchase.data.customerId,
                appointmentId: purchase.data.referenceId,
            };

            props.onSuccess(event, { updated: updated, data: data });
        }
    }

    const handleLicensedPractitionerChange = ({ id }) => {
        purchase.data.userId = id;
        purchase.hasUnsavedChanges = true;
    }

    const handleInvoiceDateChange = element => {
        const value = element.value;

        purchase.data.invoiceDate = value ? moment(value).startOf('date') : moment().startOf('day');

        if (purchase.data.invoiceDate.isAfter(purchase.data.dueDate)) {
            purchase.data.dueDate = purchase.data.invoiceDate.clone();
        }

        purchase.hasUnsavedChanges = true;
    }

    const handleDueDateChange = element => {
        const value = element.value;

        purchase.data.dueDate = value ? moment(value).startOf('date') : moment().startOf('day');
        purchase.hasUnsavedChanges = true;
    }

    const handleBillingInformationClear = () => {
        purchase.clearCustomer();
        purchase.hasUnsavedChanges = true;
    }

    const handleCustomerSearchChange = (event, term) => {
        if (newCustomerData || existingCustomerData) {
            setNewCustomerData(null);
            setExistingCustomerData(null);
        }

        if (searchTimer.current) {
            clearTimeout(searchTimer.current);
        }

        if (term && term.length >= 2) {
            api.Customers.fullSearch(term, false, true, 5)
                .then(({ data }) => {
                    if (isMounted.current) {
                        setCustomerSearchResult(data.result);
                    }
                })
        } else {
            setCustomerSearchResult(null);
        }
    }

    const handleRefreshCustomerName = () => {
        purchase.isLoading = true;

        api.Customers.get(purchase.data.customerId)
            .then(({ data }) => {
                if (isMounted.current) {
                    if (data) {
                        purchase.data.customerName = ph.getFirstLastName(data, true);
                        purchase.data.billingAddress = data.address;
                        purchase.data.customerEmail = data.emailAddress;
                        purchase.data.customerPhoneNumber = data.phoneNumber;
                    }

                    purchase.hasUnsavedChanges = true;
                }
            }).finally(() => {
                purchase.isLoading = false;
            });
    }

    const handleCustomerSearchClick = customer => {
        purchase.setCustomer(customer);
        purchase.hasUnsavedChanges = true;

        setNewCustomerData(null);
        setExistingCustomerData(null);
        setCustomerSearchResult(null);
    }

    const handleVerifyReturnsSuccess = (result) => {
        if (result.updated) {
            handleSuccess(null, true);
            purchaseView.load(result.data.id)
                .then(() => {
                    if (isMounted.current) {
                        props.onModeChange('view');
                        toast.dark(() => <p data-item-returned>Item(s) returned.</p>);
                    }
                })
                .finally(() => {
                    purchase.clear();
                });
        } else {
            props.onModeChange('view');
        }
    }

    const handleVerifyReturnsCancel = () => {
        purchase.returnDestinations.clear();
        purchase.verifyReturnItems.clear();
    }

    const handleDefaultBlur = () => {
        if (purchase.resetDefaultTransactionItem()) {
            orderRef.current.instance.refresh();
        }
    }

    const handleQuantityChange = (event, { id, isFromInventory, maxQuantity }) => {
        const quantity = parseInt(event.target.value, 10);

        if (isNaN(quantity)) {
            purchase.updateQuantity(id, 0);
        }
        else if (isFromInventory && quantity > maxQuantity) {
            toast.dismiss();
            toast.error(() => <p>There is only {maxQuantity} item(s) of this product in your inventory.</p>, { position: 'top-center' });
        }
        else {
            purchase.updateQuantity(id, quantity);
        }
    }

    const handleQuantityBlur = (event, { id, defaultQuantity, quantity }) => {
        if (!quantity || quantity === 0) {
            purchase.updateQuantity(id, (defaultQuantity ? defaultQuantity : 1));
        }
        handleDefaultBlur();
    }

    const handleDescriptorChange = (event, { id }) => {
        const descriptor = event.target.value;
        purchase.updateDescriptor(id, descriptor);
    }

    const handleOverriddenDescriptorChange = (event, { id }) => {
        const descriptor = event.target.value;
        purchase.updateOverriddenDescriptor(id, descriptor);
    }

    const handleIsFromInventoryChange = (event, item) => {
        const isFromInventory = !item.isFromInventory;
        purchase.updateIsFromInventory(item.id, isFromInventory);

        if (isFromInventory && item.quantity > item.maxQuantity) {
            purchase.updateQuantity(item.id, item.maxQuantity);
        }
    }

    const handleHighlight = (event) => {
        event.target.setSelectionRange(0, 9999);
    }

    const handleUnitPriceChange = (event, { id }) => {
        if (event.target.value) {
            const amount = parseFloat(fn.parseCurrency(event.target.value));
            purchase.updateUnitPrice(id, amount);
        } else {
            purchase.updateUnitPrice(id, null);
        }
    }

    const handleDiscountAmountChange = (event, { id }) => {
        if (event.target.value) {
            purchase.updateDiscount(id, event.target.value);
        } else {
            purchase.updateDiscount(id, null);
        }
    }

    const handleDiscountAmountBlur = (event, { id, discount }) => {
        if (discount) {
            if (fn.regexTester.currency.test(discount)) {
                const amount = parseFloat(fn.parseCurrency(discount.replace('$', '').replace(/\.+$/, '')));
                purchase.updateIsCustomDiscount(id, true);
                purchase.updateDiscountAmount(id, amount);
            }
            else if (fn.regexTester.discountRate.test(discount)) {
                const discountRate = parseFloat(discount.replace('%', '')) / 100.0;
                purchase.updateIsCustomDiscount(id, false);
                purchase.updateDiscountRate(id, discountRate);
            }
            else {
                purchase.updateIsCustomDiscount(id, false);
                purchase.updateDiscountRate(id, null);
                purchase.updateDiscountAmount(id, null);
            }
        }

        handleDefaultBlur();
    }

    const handleApplyTaxChange = (event, { id }) => {
        const applyTax = event.target.checked;
        purchase.updateApplyTax(id, applyTax);
    }

    const handleTransactionItemRemove = (event, item) => {
        purchase.removeTransactionItem(item);
        purchase.resetDefaultTransactionItem();
        orderRef.current.instance.refresh();
    }

    const handleNoteChange = (content, delta, source, editor) => {
        const note = editor.getText().replace(/(\r\n|\n|\r)/g, ' ').trim();
        const noteHtml = (content === '<p><br></p>') ? null : content;

        purchase.data.note = note;
        purchase.data.noteHtml = noteHtml;
        purchase.hasUnsavedChanges = true;
    }

    const handleSaveConflictsCancel = () => {
        setHasSaveConflicts(false);
    }

    const handleIsOverriddenChange = (event, { id }, isOverridden) => {
        purchase.updateIsOverridden(id, isOverridden);
        purchase.hasUnsavedChanges = true;
    }

    const handleProductSelectorStart = (event, type, item) => {
        const reference = item ? item.id : null;
        const multiSelect = !reference;
        const selectedItems = toJS(purchase.data.items.filter(i => !!i.referenceId));
        const selectedList = [];
        const itemType = item ? item.type : null;
        const productType = itemType === 'Product' && item.product ? item.product.typeId : null;

        if (selectedItems && selectedItems.length > 0) {
            for (let s = 0; s < selectedItems.length; s++) {
                const quantity = selectedItems[s].quantity ? selectedItems[s].quantity : 1;
                for (let q = 0; q < quantity; q++) {
                    selectedList.push({
                        type: selectedItems[s].referenceType,
                        id: selectedItems[s].referenceId
                    })
                }
            }
        }

        setProductSelectorType(type);
        setProductSelectorMulti(multiSelect);
        setIsProductSelectorActive(true);

        productSelectorRef.current.show(selectedList, reference, itemType, productType);
    }

    const handleProductSelectorSuccess = (event, result) => {
        let added = false;

        switch (productSelectorType) {
            case 'TransactionItem':
                if (result.reference) {
                    purchase.replaceTransactionItem(result.reference, result.data);
                }
                else {
                    purchase.addNewTransactionItem(result.data);
                }
                added = true;
                break;

            default:
                break;
        }

        if (added) {
            toast.dark(() => <div className='d-flex py-1'><span className='d-inline-block text-truncate line-height mr-1' style={{ maxWidth: '150px' }}>{result.data.name}</span><span className='d-inline-block text-truncate line-height'>added.</span></div>);
        }

        purchase.resetDefaultTransactionItem();
        orderRef.current.instance.refresh();
    }

    const handleProductSingleSelectorSuccess = (event, result) => {
        handleProductSelectorSuccess(event, result);
        setIsProductSelectorActive(false);
        setProductSelectorType(null);

        productSelectorRef.current.close();
    }

    const handleProductMultiSelectorSuccess = (event, result) => {
        handleProductSelectorSuccess(event, result);
    }

    const handleProductSelectorClose = () => {
        setIsProductSelectorActive(false);
        setProductSelectorType(null);
    }

    const handleReorder = (event) => {
        const { toIndex, fromIndex } = event;
        const reorderedItems = toJS([...(purchase.data.items)]);

        reorderedItems.splice(fromIndex, 1);
        reorderedItems.splice(toIndex, 0, toJS(purchase.data.items[fromIndex]));

        for (let i = 0; i < reorderedItems.length; i++) {
            reorderedItems[i].displayOrder = i * 10;
        }

        purchase.data.items = reorderedItems.sort((a, b) => { return a.displayOrder - b.displayOrder });
    }

    const handleReorderDragStart = (e) => {
        if (purchase.isDefaultTransactionItem(e.itemData)) e.cancel = true;
    }

    const handleReorderDragEnd = (e) => {
        if (purchase.isDefaultTransactionItem(e.itemData)) e.cancel = true;
    }

    const handleConfirmUpdateWorkOrder = (event) => {
        handleSaveConfirmed();
        confirmModalRef.current.close();
    }

    const handleCancelUpdateWorkOrder = (event) => {
        confirmModalRef.current.close();
    }

    const getDiscountDisplay = (item) => {
        if (item) {
            if (item.discount) {
                return item.discount ? item.discount : '';
            }
            else if (!!item.isCustomDiscount) {
                return item.discountAmount ? fn.formatCurrency(item.discountAmount) : '';
            }
            else if (item.discountRate) {
                return item.discountRate ? fn.formatPercent(item.discountRate) : '';
            }
        }

        return ''
    }

    const renderPurchaseActions = () => {
        switch (purchase.data.status) {
            case 'Incomplete':
                return <>
                    {
                        purchase.data.customer ?
                            <li>
                                <button
                                    type='button'
                                    className='btn btn-light px-3'
                                    onClick={e => handleContinueLater(e)}
                                    disabled={!purchase.validItems || purchase.validItems.length === 0}
                                >
                                    Continue Later
                                </button>
                            </li> : null
                    }
                    <li>
                        <button
                            type='button'
                            className='btn btn-success px-3'
                            onClick={e => handleConfirmComplete(e)}
                            disabled={!purchase.validItems || purchase.validItems.length === 0}
                        >
                            Complete
                        </button>
                    </li>
                </>;

            case 'Completed':
                return <>
                    <li>
                        <button
                            type='button'
                            className='btn btn-light px-3'
                            onClick={handleRefreshCustomerName}
                        >
                            Refresh Customer Info
                        </button>
                    </li>
                    <li>
                        <button
                            type='button'
                            className='btn btn-success px-3'
                            disabled={!purchase.validItems || purchase.validItems.length === 0}
                            onClick={handleSave}
                        >
                            Save
                        </button>
                    </li>
                </>

            case 'Refunded':
            case 'Voided':
            default:
                break;
        }
    }

    return <>
        <Observer>{() =>
            <>
                {
                    !isProductSelectorActive ?
                        <GlobalHotKeys
                            keyMap={{
                                close: ['esc'],
                            }}
                            handlers={{
                                close: event => {
                                    handleClose(event)
                                },
                            }}
                            allowChanges={true}
                        /> : null
                }
            </>
        }</Observer>
        <Observer>{() =>
            <>
                {
                    purchase.isReady ?
                        <>
                            <Observer>{() => <LoadingOverlay isLoading={purchase.isLoading || purchase.isSaving} />}</Observer>
                            {renderTitle()}
                            {renderContent()}
                            <NotAvailableModal display={hasSaveConflicts} onCancel={handleSaveConflictsCancel} />
                        </> : <>
                            <div className='popup-title'></div>
                            <div className='popup-body'></div>
                        </>
                }
            </>
        }</Observer>
        <BodyEnd>
            <ItemSelector
                ref={productSelectorRef}
                onSelect={productSelectorMulti ? handleProductMultiSelectorSuccess : handleProductSingleSelectorSuccess}
                onClose={handleProductSelectorClose}
            />
            <div id='purchase-update-draggable' />
        </BodyEnd>
        <BodyEnd>
            <ConfirmModal ref={confirmModalRef} />
        </BodyEnd>
    </>
}

export default UpdatePurchase;