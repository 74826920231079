import React, { useEffect, useContext, useState, useRef } from 'react';
import FadeIn from 'react-fade-in';
import { Observer } from 'mobx-react-lite';
import { GlobalHotKeys } from 'react-hotkeys';
import { toast } from 'react-toastify';
import momentLocalizer from 'react-widgets-moment';
import moment from 'moment';

import BodyEnd from '../../_shared/BodyEnd';
import ConfirmModal from '../../_shared/ConfirmModalComponent';
import CommunicationModal from '../../communications/CommunicationModal/_index';
import { quickDrawerFocus, renderQuickDrawerLoading } from '../../_shared/QuickDrawer';
import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import RichTextEditor from '../../_shared/RichTextEditor';

import CommunicationStore from '../../../../stores/CommunicationStore';
import WorkOrderUpdateStore from '../../../../stores/WorkOrderUpdateStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';

import * as ErrorMessages from '../../../../constants/errorMessages';
import * as fn from '../../../../utilities/_functions';
import * as ph from '../../../../utilities/personHelper';
import * as bh from '../../../../utilities/badgeHelper';
import * as oh from '../../../../utilities/operationHelper';
import * as wch from '../../../../utilities/workOrderCommunicationHelper';

import './UpdateWorkOrderStatus.scss';

moment.locale('en');
momentLocalizer();

function UpdateWorkOrderStatus(props) {
    const isMounted = useRef(true);
    const validateRef = useRef(null);
    const afterCommunicationStatusRef = useRef(null);
    const updatedDataRef = useRef(null);
    const confirmModalRef = useRef(null);
    const communicationModalRef = useRef(null);
    const quickDrawer = useContext(QuickDrawerStore);
    const communication = useContext(CommunicationStore);
    const workOrderUpdate = useContext(WorkOrderUpdateStore);
    const [originalData, setOriginalData] = useState(null);

    useEffect(() => {
        if (isMounted.current && workOrderUpdate.data) {
            setOriginalData(workOrderUpdate.data);
            workOrderUpdate.data.updatedStatus = workOrderUpdate.data.status;
            quickDrawerFocus(props.drawer);
        }

        return () => {
            isMounted.current = false;
            workOrderUpdate.clearUpdatedStatus();
        }
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (workOrderUpdate.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleNewStatusChange = event => {
        workOrderUpdate.updatedStatus = event.target.value;
        workOrderUpdate.hasUnsavedChanges = true;
    }

    const handleReasonChange = (content, delta, source, editor) => {
        const reason = editor.getText().replace(/(\r\n|\n|\r)/g, ' ').trim();
        const reasonHtml = (content === '<p><br></p>') ? null : content;

        workOrderUpdate.updatedReason = reason;
        workOrderUpdate.updatedReasonHtml = reasonHtml;
        workOrderUpdate.hasUnsavedChanges = true;
    }

    const handleSubmit = event => {
        event.preventDefault();

        if (fn.validateForm(validateRef.current)) {
            workOrderUpdate.status()
                .then(data => {
                    if (isMounted.current) {
                        workOrderUpdate.clearUpdatedStatus();
                        updatedDataRef.current = data;

                        afterCommunicationStatusRef.current = wch.hasAutomatedCommunication(data.status);

                        if (data && afterCommunicationStatusRef.current) {
                            const action = 'WorkOrderReady';
                            const tags = workOrderUpdate.data.items.map(i => { return i.productType });

                            workOrderUpdate.data.status = data.status;

                            confirmModalRef.current.show({
                                icon:  <i className='fal fa-inbox text-work-order-500 mr-2'></i>,
                                message: <>Send&nbsp;<span className='fw-500'>Work Order is Ready</span>&nbsp;to {(workOrderUpdate && workOrderUpdate.data && workOrderUpdate.data.customer ? ph.getPreferredFirstLastName(workOrderUpdate.data.customer) : '')}? </>,
                                option1ClassName: 'btn btn-work-order shadow-0 bootbox-accept',
                                option1Text: 'Email',
                                option1Disabled: (!workOrderUpdate.data.customer || !workOrderUpdate.data.customer.defaultEmailAddressContact),
                                option2ClassName: 'btn btn-secondary shadow-0 bootbox-accept',
                                option2Text: 'SMS',
                                option2Disabled: (!workOrderUpdate.data || !workOrderUpdate.data.customer.defaultMobileNumberContact),
                                onOption1Click: (e) => { handleCommunicationSend(e, 'email', action, tags) },
                                onOption2Click: (e) => { handleCommunicationSend(e, 'sms', action, tags) },
                                onCancel: handleCommunicationCancel
                            })
                        }
                        else {
                            afterCommunicationStatusRef.current = null;

                            if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                                props.onSuccess(event, updatedDataRef.current);
                                updatedDataRef.current = null;
                            }
                        }
                    }
                })
                .catch(() => {
                    if (isMounted.current) {
                        workOrderUpdate.data.status = originalData.status;
                    }
                })
        }
    }

    const handleCommunicationSend = (event, type, action, tags) => {
        let defaultContact;

        if (type) {
            switch (type.toLowerCase()) {
                case 'email':
                    defaultContact = workOrderUpdate.data.customer.defaultEmailAddressContact;
                    break;

                case 'sms':
                    defaultContact = workOrderUpdate.data.customer.defaultMobileNumberContact;
                    break;

                default:
                    break;
            }
        }

        communication.initialize(
            type,
            action,
            workOrderUpdate.data.id,
            [],
            workOrderUpdate.data.customerId,
            defaultContact,
            null,
            null,
            tags,
        )
            .then(() => {
                communicationModalRef.current.show(type);
                confirmModalRef.current.close();
            })
    }

    const handleCommunicationCancel = (event) => {
        confirmModalRef.current.close();

        if (props.onSuccess && fn.isFunction(props.onSuccess)) {
            props.onSuccess(event, updatedDataRef.current);
            updatedDataRef.current = null;
        }
    }

    const handleCommunicationClose = (event) => {
        afterCommunicationStatusRef.current = null;

        if (props.onSuccess && fn.isFunction(props.onSuccess)) {
            props.onSuccess(event, updatedDataRef.current);
            updatedDataRef.current = null;
        }
    }

    const handleCommunicationSent = (event, result) => {
        if (result && result.type) {
            toast.dark(() => <p data-comm-sent><span className='tt-capitalize'>{result.type}</span> {result.scheduledSendDateTimeLocal ? 'scheduled' : 'sent'}.</p>);
        }
        else {
            toast.dark(() => <p data-comm-sent>Communication sent.</p>);
        }

        if (afterCommunicationStatusRef.current && workOrderUpdate.data && !workOrderUpdate.data.statusHistory.some(h => h.from === workOrderUpdate.data.status && h.to === afterCommunicationStatusRef.current)) {
            workOrderUpdate.updatedStatus = afterCommunicationStatusRef.current;
            workOrderUpdate.hasUnsavedChanges = true;
            workOrderUpdate.status();
        }

        afterCommunicationStatusRef.current = null;
        communicationModalRef.current.close();

        if (props.onSuccess && fn.isFunction(props.onSuccess)) {
            props.onSuccess(event, updatedDataRef.current);
            updatedDataRef.current = null;
        }
    }

    return <>
        <Observer>{() =>
            <>
                {
                    (props.drawer === quickDrawer.drawerOpened) ?
                        <GlobalHotKeys
                            keyMap={{
                                close: ['esc'],
                            }}
                            handlers={{
                                close: event => {
                                    handleCancel(event)
                                },
                            }}
                            allowChanges={true}
                        /> : null
                }
            </>
        }</Observer>
        <form ref={validateRef} onSubmit={handleSubmit}>
            <Observer>{() =>
                <fieldset disabled={workOrderUpdate.isSaving}>
                    <div className='quick-drawer'>
                        <QuickDrawerHeader
                            drawer={props.drawer}
                            icon={oh.getIcon('work-order', 'status')}
                            action='Update'
                            category='Work Order Status'
                            className='order'
                            onCancel={props.onCancel}
                        />
                        <div className='quick-drawer-body'>
                            {
                                workOrderUpdate.data ?
                                    <FadeIn>
                                        <div className='body-content'>
                                            <section className='mb-4'>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <Observer>{() => <>
                                                            <div className='d-flex flex-row'>
                                                                <div className='fs-md fw-500 d-block text-gray-700 text-truncate text-truncate-lg'>
                                                                    {
                                                                        workOrderUpdate.data.items && workOrderUpdate.data.items[0] && workOrderUpdate.data.items[0].transactionItems && workOrderUpdate.data.items[0].transactionItems.length > 1 ?
                                                                            <span className='mr-1'>{workOrderUpdate.data.items[0].transactionItems.length}x</span> : null
                                                                    }
                                                                    {workOrderUpdate.data.items[0] ? workOrderUpdate.data.items[0].descriptor : ''}
                                                                </div>
                                                                <div className='ml-auto'>
                                                                    {bh.renderWorkOrderRedo(workOrderUpdate.data, 'p-1 mr-1')}{bh.renderWorkOrderStatus(workOrderUpdate.data, 'p-1')}
                                                                </div>
                                                            </div>
                                                            {
                                                                workOrderUpdate.data.items.length > 1 ?
                                                                    workOrderUpdate.data.items.slice(1).map((i, ii) => {
                                                                        return <div
                                                                            key={`order-${ii}`}
                                                                            className='fs-xs d-flex'>
                                                                            {
                                                                                i.transactionItems && i.transactionItems.length > 1 ?
                                                                                    <div
                                                                                        className='mr-1'
                                                                                    >
                                                                                        {i.transactionItems.length}x
                                                                                    </div> : null
                                                                            }
                                                                            {i.descriptor}
                                                                        </div>
                                                                    }) : null
                                                            }
                                                        </>}</Observer>
                                                    </div>
                                                </div>
                                            </section>
                                            <section>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <div className='form-group mb-0 validate validate-required'>
                                                            <label className='required'><small>Status</small></label>
                                                            <Observer>{() =>
                                                                <select
                                                                    className='custom-select form-control'
                                                                    value={workOrderUpdate.updatedStatus ? workOrderUpdate.updatedStatus : ''}
                                                                    onChange={handleNewStatusChange}
                                                                >
                                                                    <option value=''></option>
                                                                    <option value='Incomplete'>Incomplete</option>
                                                                    <option value='Submitted'>Submitted</option>
                                                                    <option value='PartiallyReceived'>Partially Received</option>
                                                                    <option value='Ready'>Ready</option>
                                                                    <option value='CustomerNotified'>Customer Notified</option>
                                                                    <option value='Completed'>Completed</option>
                                                                    <option value='OnHold'>On Hold</option>
                                                                    <option value='Abandoned'>Abandoned</option>
                                                                </select>
                                                            }</Observer>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <section>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <div className='form-group'>
                                                            <label><small>Note</small></label>
                                                            <Observer>{() =>
                                                                <RichTextEditor
                                                                    mode='none'
                                                                    value={workOrderUpdate.updatedReasonHtml}
                                                                    disableTab={true}
                                                                    onChange={handleReasonChange}
                                                                />
                                                            }</Observer>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </FadeIn> : renderQuickDrawerLoading()
                            }
                        </div>
                        <Observer>{() =>
                            workOrderUpdate.data ?
                                <div className='quick-drawer-action'>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className='float-right'>
                                                <button
                                                    type='button'
                                                    className='btn btn-link btn-cancel mr-2'
                                                    onClick={props.onCancel}
                                                >Cancel</button>
                                                <button
                                                    type='submit'
                                                    className='btn btn-success'
                                                >Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div> : null
                        }</Observer>
                    </div>
                </fieldset>
            }</Observer>
            <Observer>{() =>
                <BodyEnd>
                    <ConfirmModal ref={confirmModalRef} />
                    <CommunicationModal ref={communicationModalRef} onClose={handleCommunicationClose} onSent={handleCommunicationSent} />
                </BodyEnd>
            }</Observer>
        </form>
    </>
}

export default UpdateWorkOrderStatus;