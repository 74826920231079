import React, { useEffect, useContext, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import DateBox from 'devextreme-react/date-box';
import moment from 'moment';

import AuthStore from '../../../../stores/AuthStore';

import * as ErrorMessages from '../../../../constants/errorMessages';
import * as fn from '../../../../utilities/_functions';

function PickerCommunicationSchedule(props) {
    const isMounted = useRef(true);
    const dateRef = useRef(null);
    const timeRef = useRef(null);
    const validateRef = useRef(null);
    const auth = useContext(AuthStore);
    const [date, setDate] = useState(null);
    const [time, setTime] = useState(null);
    const [minDate, setMinDate] = useState(null);
    const [maxDate, setMaxDate] = useState(null);
    const [minTime, setMinTime] = useState(null);

    useEffect(() => {
        if (isMounted.current) {
            const today = moment().startOf('day');
            const newMinDate = moment().startOf('day');
            const nowBuffered = moment().add(30, 'minutes');

            if (!today.isSame(nowBuffered.startOf('day')) || nowBuffered.hour() < 23) {
                newMinDate.add(1, 'days');
            }

            setMinDate(newMinDate.clone().toDate());
            setMaxDate(newMinDate.clone().add(3, 'months').toDate());

            if (props.scheduledSendDateTimeLocal) {
                setDate(props.scheduledSendDateTimeLocal.clone().format('YYYY-MM-DD'));
                setTime(props.scheduledSendDateTimeLocal.clone().format('HH:mm:ss'));
            }
        }

        return () => {
            isMounted.current = false;
        }
    }, []) // eslint-disable-line

    const handleClose = event => {
        if (fn.isFunction(props.onClose)) {
            props.onClose(event);
        }
    }

    const handlePickerDateChange = element => {
        const value = element.value;
        const mDate = value ? moment(value) : null;

        if (isMounted.current) {
            if (mDate) {
                const today = moment().startOf('day');
                const nowBuffered = moment().add(30, 'minutes');
                const nextHour = nowBuffered.hour() + 1;
                const newMinTime = mDate.isSame(today) ? mDate.clone().set({ hour: nextHour, minute: 0, second: 0, millisecond: 0 }) : null;
                const defaultTime = mDate.isSame(today) ? `${(nextHour < 10 ? `0${nextHour}` : `${nextHour}`)}:00:00` : '08:00:00';

                setDate(mDate.format('YYYY-MM-DD'));
                setTime(defaultTime);
                setMinTime((newMinTime ? newMinTime.toDate() : null));
            }
            else {
                setDate(null);
                setTime(null);
                setMinTime(null);
            }
        }
    }

    const handlePickerTimeChange = element => {
        const value = element.value;
        const mTime = value ? moment(value) : null;

        if (isMounted.current) {
            if (mTime) {
                setTime(mTime.format('HH:mm:ss'));
            }
            else {
                setTime(null);
            }
        }
    }

    const handlePickerSelect = (event) => {
        event.preventDefault();
        if (process.env.NODE_ENV === 'development' || (fn.validateForm(validateRef.current) && dateRef.current.instance.option('isValid') && timeRef.current.instance.option('isValid'))) {
            const dateTime = moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm:ss');
            handleDateTimeSelect(event, dateTime);
        }
        else {
            toast.error(() => ErrorMessages.GENERIC_FORM_ERROR_HTML, { position: 'top-center' });
        }
    }

    const handleDateTimeSelect = (event, dateTime) => {
        if (fn.isFunction(props.onSelect)) {
            props.onSelect(event, dateTime);
        }
    }

    const renderTitle = () => {
        return <div className='popup-title'>
            <div className='actions left-actions pt-1'>
                <ul className='ml-2'>
                    <li>
                        <h4 className='mb-0'>
                            Schedule send
                        </h4>
                        {
                            auth.currentTenant && auth.currentTenant.timeZoneName ?
                                <span className='text-gray-600'>{auth.currentTenant.timeZoneName}</span> : null
                        }
                    </li>
                </ul>
            </div>
            <div className='actions right-actions'>
                <ul>
                    <li>
                        <button
                            type='button'
                            className='btn btn-icon btn-close'
                            onClick={handleClose}
                        >
                            <i className='close-icon fal fa-times fs-xl'></i>
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    }

    const renderContent = () => {
        return <>
            <div className='popup-body'>
                <form ref={validateRef} onSubmit={handlePickerSelect}>
                    <div className='picker-controls'>
                        <div className='row'>
                            <div className='col-6'>
                                <div className='form-group validate validate-required'>
                                    <label className='required' htmlFor='communication-schedule-date'><small>Date</small></label>
                                    <DateBox
                                        ref={dateRef}
                                        id='communication-schedule-date'
                                        type='date'
                                        displayFormat={'yyyy-MM-dd'}
                                        useMaskBehavior={true}
                                        showClearButton={true}
                                        min={minDate}
                                        max={maxDate}
                                        value={date ? moment(date, 'YYYY-MM-DD').toDate() : null}
                                        onValueChanged={handlePickerDateChange}
                                    >
                                    </DateBox>
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className='form-group validate validate-required'>
                                    <label className='required' htmlFor='communication-schedule-time'><small>Time</small></label>
                                    <DateBox
                                        ref={timeRef}
                                        id='communication-schedule-time'
                                        type='time'
                                        displayFormat={'hh:mm a'}
                                        useMaskBehavior={true}
                                        showClearButton={true}
                                        disabled={!date}
                                        interval={60}
                                        min={minTime}
                                        value={date && time ? moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm:ss').toDate() : null}
                                        onValueChanged={handlePickerTimeChange}
                                    >
                                    </DateBox>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='picker-actions'>
                        <button
                            type='submit'
                            className={date && time ? 'text-success text-success-700-hover' : 'text-gray-600 text-gray-700-hover'}
                        >
                            <strong className='fs-md'>
                                {
                                    date && time ?
                                        <>{`Schedule to send on ${moment(date, 'YYYY-MM-DD').format('ddd, MMM Do, YYYY')} @ ${moment(time, 'HH:mm:ss').format('h:mm a')}`}</> :
                                        <>Select a specific date / time</>
                                }
                            </strong>
                        </button>
                        {
                            props.scheduledSendDateTimeLocal ?
                                <button
                                    type='button'
                                    className='border-top text-danger text-danger-700-hover'
                                    onClick={(e) => { handleDateTimeSelect(e, null) }}
                                >
                                    <strong className='fs-md'>Cancel schedule send</strong>
                                </button> : null
                        }
                    </div>
                </form>
            </div>
        </>
    }

    return <>
        {renderTitle()}
        {renderContent()}
    </>
}

export default PickerCommunicationSchedule;