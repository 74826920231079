import { createContext } from 'react';
import { toJS, decorate, observable, action, computed } from 'mobx';
import moment, { isMoment } from 'moment';

import * as fn from '../utilities/_functions';
import * as ah from '../utilities/appointmentHelper';
import api from '../api';

export class GroupAppointmentUpdate {
    groupId = null;
    groupAppointment = null;
    data = null;
    originalData = null;
    stageId = null;
    appointments = null;
    originalAppointments = null;
    customerServices = [];
    rescheduleRequestBy = null;
    rescheduleRequestReason = null;
    rescheduleRequestReasonHtml = null;
    cancelRequestBy = null;
    cancelRequestReason = null;
    cancelRequestReasonHtml = null;
    hasUnsavedChanges = false;
    isLoading = false;
    isSaving = false;
    isReady = false;
    cancelGroupAppointmentGet = null;
    cancelGroupAppointmentStage = null;
    cancelGroupAppointmentTimeline = null;
    cancelGroupAppointmentStatus = null;
    cancelGroupAppointmentDelete = null;
    cancelGroupAppointmentUpdate = null;
    cancelPatientProfile = null;

    initialize = (groupId, groupAppointment) => {
        const that = this;

        this.clear();
        this.groupId = groupId;
        this.isReady = false;

        return new Promise((resolve, reject) => {
            that.refresh(groupAppointment)
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isReady = true;
                })
        })
    }

    stage = (groupId) => {
        const that = this;

        this.clear();
        this.stageId = groupId;
        this.isReady = false;
        this.isLoading = true;

        return new Promise((resolve, reject) => {
            api.GroupAppointments.get(
                that.stageId,
                (c) => { this.cancelGroupAppointmentStage = c; }
            )
                .then(({ data }) => {
                    if (data) {                        
                        that.setGroupAppointmentData(data);
                    }

                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isLoading = false;
                })
        })
    }

    staged = () => {
        if (this.stageId) {
            this.groupId = this.stageId;
            this.isReady = true;
        }

        return Promise.resolve();
    }

    refresh = (groupAppointment) => {
        const that = this;

        return new Promise((resolve, reject) => {
            if (groupAppointment) {
                that.setGroupAppointmentData(groupAppointment);
                resolve();
            }
            else {
                that.isLoading = true;
                api.GroupAppointments.get(
                    that.groupId,
                    (c) => { this.cancelGroupAppointmentGet = c; }
                )
                    .then(({ data }) => {
                        that.setGroupAppointmentData(data);
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    })
                    .finally(() => {
                        that.isLoading = false;
                    })
            }
        })
    }

    groupTimeline = (timelineItemId, notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            const timeline = toJS(that.data.timeline.filter(s => s.performedDateUtc));
            const updatedTimeline = timeline ? timeline.map(s => { return s.id; }) : [];
            const index = updatedTimeline.findIndex(s => s === timelineItemId);

            if (index < 0) {
                updatedTimeline.push(timelineItemId);
            } else {
                updatedTimeline.splice(index, 1);
            }

            api.GroupAppointments.update(
                that.groupId,
                {
                    customerServices: that.appointments.map(a => {
                        return {
                            customerId: a.customerId,
                            services: a.services,
                        }
                    }),
                    timelineItems: updatedTimeline,
                },
                (c) => { this.cancelGroupAppointmentTimeline = c; }
            )
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isSaving = false;
                })
        })
    }

    timeline = (appointmentId, timelineItemId, notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            const appointment = that.appointments.filter(a => a.id === appointmentId)[0];
            const timeline = appointment.timeline.filter(s => s.performedDateUtc);
            const updatedTimeline = timeline ? timeline.map(s => { return s.id; }) : [];
            const index = updatedTimeline.findIndex(s => s === timelineItemId);

            if (index < 0) {
                updatedTimeline.push(timelineItemId);
            } else {
                updatedTimeline.splice(index, 1);
            }

            api.Appointments.update(
                appointmentId,
                { timelineItems: updatedTimeline, },
                (c) => { this.cancelGroupAppointmentTimeline = c; }
            )
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isSaving = false;
                })
        })
    }

    status = (newStatus, notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            api.GroupAppointments.status(
                that.groupId,
                { newStatus: newStatus, },
                (c) => { this.cancelGroupAppointmentStatus = c; }
            )
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isSaving = false;
                })
        })
    }

    loadPatientProfiles = () => {
        const that = this;
        this.isLoading = true;

        return new Promise((resolve, reject) => {
            const patientProfileIds = that.appointments.some(a => a.customer && a.customer.patientProfileId) ? that.appointments.filter(a => a.customer && a.customer.patientProfileId).map(a => { return a.customer.patientProfileId }) : null;
            if (patientProfileIds && patientProfileIds.length > 0) {
                api.PatientProfiles.search({
                    parameters: [
                        { field: 'Id', value: patientProfileIds.join(','), operator: 'Contains' },
                    ],
                    includeTotalCount: false,
                    loadProperties: true,
                })
                    .then(({ data }) => {
                        if (data && data.length > 0) {
                            if (that.appointments && that.appointments.length > 0) {
                                for (let ai = 0; ai < that.appointments.length; ai++) {
                                    const patientProfile = data.filter(d => d.id === that.appointments[ai].customer.patientProfileId)[0];

                                    if (patientProfile) {
                                        that.appointments[ai].customer.patientProfile = patientProfile;
                                    }
                                }
                            }
                            if (that.customerServices && that.customerServices.length > 0) {
                                for (let ci = 0; ci < that.customerServices.length; ci++) {
                                    const patientProfile = data.filter(d => d.id === that.customerServices[ci].customer.patientProfileId)[0];

                                    if (patientProfile) {
                                        that.appointments[ci].customer.patientProfile = patientProfile;
                                    }
                                }
                            }
                        }
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    })
                    .finally(() => {
                        that.isLoading = false;
                    })
            } else {
                resolve();
            }
        })
    }

    save = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            if (that.hasUnsavedChanges) {
                api.GroupAppointments.update(
                    that.groupId,
                    {
                        start: isMoment(that.data.start) ? that.data.start.clone().format('YYYY-MM-DDTHH:mm') : moment(that.data.start).format('YYYY-MM-DDTHH:mm'),
                        duration: that.data.duration,
                        userId: that.data.userId,
                        primaryContactId: that.data.primaryContactId,
                        customerServices: that.customerServices,
                        rescheduleRequestBy: that.isReschedule ? that.rescheduleRequestBy : null,
                        rescheduleRequestReason: that.isReschedule ? that.rescheduleRequestReason : null,
                        rescheduleRequestReasonHtml: that.isReschedule ? that.rescheduleRequestReasonHtml : null,
                    },
                    (c) => { this.cancelGroupAppointmentUpdate = c; }
                )
                    .then(() => {
                        that.hasUnsavedChanges = false;
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    })
                    .finally(() => {
                        that.isSaving = false;
                    })
            } else {
                that.isSaving = false;
                that.hasUnsavedChanges = false;
                resolve();
            }
        })
    }

    delete = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            api.GroupAppointments.delete(
                that.groupId,
                {
                    cancelRequestBy: that.cancelRequestBy,
                    cancelRequestReason: that.cancelRequestReason,
                    cancelRequestReasonHtml: that.cancelRequestReasonHtml,
                },
                (c) => { this.cancelGroupAppointmentDelete = c; }
            )
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isSaving = false;
                })
        })
    }

    checkConflicts = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            const options = {
                id: null,
                groupId: that.groupId,
                customerIds: that.appointments.map(a => { return a.customerId }),
                start: that.data.start,
                duration: that.data.duration,
                userId: that.data.userId,
            }

            ah.checkConflictsForGroup(options, true)
                .then((data) => {
                    resolve(data);
                })
                .catch(error => {
                    reject();
                })
                .finally(() => {
                    that.isSaving = false;
                })
        })
    }

    checkEligibility = (notify, appointmentId) => {
        if (!!notify) {
            this.isSaving = true;
        }

        return !!appointmentId ? this.checkEligibilityForAppointment(appointmentId) : this.checkEligibilityForGroup();
    }

    checkEligibilityForAppointment = (appointmentId) => {
        const that = this;

        return new Promise((resolve, reject) => {
            const ai = that.appointments.findIndex(a => a.id === appointmentId);

            if (ai > -1) {
                ah.checkEligibility(that.appointments[ai].customer, that.appointments[ai])
                    .then((data) => {
                        const ci = that.customerServices.findIndex(c => c.appointmentId === appointmentId);

                        if (data && data.length > 0) {
                            for (let di = 0; di < data.length; di++) {
                                if (data[di].service.isSubsidized) {
                                    const aii = that.appointments[ai].services.findIndex(s => s.code === data[di].code);
                                    const cii = that.customerServices[ci].services.findIndex(s => s.code === data[di].code);

                                    if (aii > -1) {
                                        that.appointments[ai].services[aii].isEligible = data[di].isEligible;
                                        that.appointments[ai].services[aii].lastEligibilityCheckedDateUtc = moment().utc();
                                        that.appointments[ai].services[aii].ineligibilityCode = data[di].ineligibilityCode;
                                        that.appointments[ai].services[aii].ineligibilityReason = data[di].ineligibilityReason;
                                        that.appointments[ai].services[aii].earliestEligibleDate = data[di].earliestEligibleDate ? moment(data[di].earliestEligibleDate) : null;
                                    }
                                    if (cii > -1) {
                                        that.customerServices[ci].services[cii].isEligible = data[di].isEligible;
                                        that.customerServices[ci].services[cii].lastEligibilityCheckedDateUtc = moment().utc();
                                        that.customerServices[ci].services[cii].ineligibilityCode = data[di].ineligibilityCode;
                                        that.customerServices[ci].services[cii].ineligibilityReason = data[di].ineligibilityReason;
                                        that.customerServices[ci].services[cii].earliestEligibleDate = data[di].earliestEligibleDate ? moment(data[di].earliestEligibleDate) : null;
                                    }
                                }
                            }
                        }
                        else {
                            if (that.appointments[ai].services && that.appointments[ai].services.length > 0) {
                                for (let asi = 0; asi < that.appointments.services.length; asi++) {
                                    that.appointments[ai].services[asi].isEligible = null;
                                    that.appointments[ai].services[asi].lastEligibilityCheckedDateUtc = null;
                                    that.appointments[ai].services[asi].ineligibilityCode = null;
                                    that.appointments[ai].services[asi].ineligibilityReason = null;
                                    that.appointments[ai].services[asi].earliestEligibleDate = null;
                                }
                            }
                            if (that.customerServices[ci].services && that.customerServices[ci].services.length > 0) {
                                for (let csi = 0; csi < that.customerServices.services.length; csi++) {
                                    that.customerServices[ci].services[csi].isEligible = null;
                                    that.customerServices[ci].services[csi].lastEligibilityCheckedDateUtc = null;
                                    that.customerServices[ci].services[csi].ineligibilityCode = null;
                                    that.customerServices[ci].services[csi].ineligibilityReason = null;
                                    that.customerServices[ci].services[csi].earliestEligibleDate = null;
                                }
                            }
                        }
                        resolve(data);
                    })
                    .catch(error => {
                        reject();
                    })
                    .finally(() => {
                        that.isSaving = false;
                    })
            }
            else {
                return resolve();
            }
        })
    }

    checkEligibilityForGroup = () => {
        const that = this;

        return new Promise((resolve, reject) => {
            const options = that.customerServices.filter(c => !!c.customer && !!c.services && c.services.length > 0).map(a => {
                return {
                    customer: a.customer,
                    data: {
                        start: that.data.start,
                        services: a.services
                    }
                }
            })

            if (options && options.length > 0 && that.appointments && that.appointments.length > 0) {
                ah.checkEligibilityForGroup(options)
                    .then((data) => {
                        if (data && data.length > 0) {
                            for (let di = 0; di < data.length; di++) {
                                if (data[di].service && data[di].service.isSubsidized) {
                                    const ai = that.appointments.findIndex(a => !!a.customer && !!data[di].customer && a.customer.id === data[di].customer.id && a.services && a.services.length > 0);
                                    const ci = that.customerServices.findIndex(c => !!c.customer && !!data[di].customer && c.customer.id === data[di].customer.id && c.services && c.services.length > 0);

                                    if (ai > -1) {
                                        const aii = that.appointments[ai].services.findIndex(s => s.code === data[di].code);

                                        if (aii > -1) {
                                            that.appointments[ai].services[aii].isEligible = data[di].isEligible;
                                            that.appointments[ai].services[aii].lastEligibilityCheckedDateUtc = moment().utc();
                                            that.appointments[ai].services[aii].ineligibilityCode = data[di].ineligibilityCode;
                                            that.appointments[ai].services[aii].ineligibilityReason = data[di].ineligibilityReason;
                                            that.appointments[ai].services[aii].earliestEligibleDate = data[di].earliestEligibleDate ? moment(data[di].earliestEligibleDate) : null;
                                        }
                                    }

                                    if (ci > -1) {
                                        const cii = that.customerServices[ci].services.findIndex(s => s.code === data[di].code);

                                        if (cii > -1) {
                                            that.customerServices[ci].services[cii].isEligible = data[di].isEligible;
                                            that.customerServices[ci].services[cii].lastEligibilityCheckedDateUtc = moment().utc();
                                            that.customerServices[ci].services[cii].ineligibilityCode = data[di].ineligibilityCode;
                                            that.customerServices[ci].services[cii].ineligibilityReason = data[di].ineligibilityReason;
                                            that.customerServices[ci].services[cii].earliestEligibleDate = data[di].earliestEligibleDate ? moment(data[di].earliestEligibleDate) : null;
                                        }
                                    }
                                }
                            }
                        } else {
                            if (that.appointments && that.appointments.length > 0) {
                                for (let asi = 0; asi < that.appointments.length; asi++) {
                                    if (that.appointments[asi].services && that.appointments[asi].services.length > 0) {
                                        for (let sii = 0; sii < that.appointments[asi].services.length; sii++) {
                                            that.appointments[asi].services[sii].isEligible = null;
                                            that.appointments[asi].services[sii].lastEligibilityCheckedDateUtc = null;
                                            that.appointments[asi].services[sii].ineligibilityCode = null;
                                            that.appointments[asi].services[sii].ineligibilityReason = null;
                                            that.appointments[asi].services[sii].earliestEligibleDate = null;
                                        }
                                    }
                                }
                            }
                            if (that.customerServices && that.customerServices.length > 0) {
                                for (let csi = 0; csi < that.customerServices.length; csi++) {
                                    if (that.customerServices[csi].services && that.customerServices[csi].services.length > 0) {
                                        for (let sii = 0; sii < that.customerServices[csi].services.length; sii++) {
                                            that.customerServices[csi].services[sii].isEligible = null;
                                            that.customerServices[csi].services[sii].lastEligibilityCheckedDateUtc = null;
                                            that.customerServices[csi].services[sii].ineligibilityCode = null;
                                            that.customerServices[csi].services[sii].ineligibilityReason = null;
                                            that.customerServices[csi].services[sii].earliestEligibleDate = null;
                                        }
                                    }
                                }
                            }
                        }
                        resolve(data);
                    })
                    .catch(error => {
                        reject(error);
                    })
                    .finally(() => {
                        that.isSaving = false;
                    })
            }
            else {
                this.isSaving = false;
                resolve(null);
            }
        })
    }

    addNewCustomerService = () => {
        this.customerServices.push(this.getNewCustomerService());
    }

    getNewCustomerService = () => {
        return {
            appointmentId: null,
            customerId: null,
            customer: null,
            relationship: null,
            services: [],
        };
    }

    setGroupAppointmentData = (groupAppointment) => {
        action(e => {
            if (groupAppointment) {
                this.appointments = groupAppointment.appointments;
                this.originalAppointments = groupAppointment.appointments;
                this.customerServices = groupAppointment.customerServices;
                this.setData(groupAppointment.appointments);
                this.setOriginalData(groupAppointment.appointments);
            }

            this.groupAppointment = groupAppointment;
        })();
    }

    setData = (appointments) => {
        if (appointments && appointments.length > 0) {
            if (appointments.some(a => a.primaryContactId === a.customerId)) {
                this.data = appointments.filter(a => a.primaryContactId === a.customerId)[0]
            }
            else {
                this.data = appointments[0];
            }
        }
        else {
            this.data = null;
        }
    }

    setOriginalData = (originalAppointments) => {
        if (originalAppointments && originalAppointments.length > 0) {
            if (originalAppointments.some(a => a.primaryContactId === a.customerId)) {
                this.originalData = originalAppointments.filter(a => a.primaryContactId === a.customerId)[0]
            }
            else {
                this.originalData = originalAppointments[0];
            }
        }
        else {
            this.originalData = null;
        }
    }

    clear = () => {
        this.groupId = null;
        this.groupAppointment = null;
        this.data = null;
        this.originalData = null;
        this.stageId = null;
        this.appointments = null;
        this.originalAppointments = null;
        this.customerServices.clear();
        this.rescheduleRequestBy = null;
        this.rescheduleRequestReason = null;
        this.rescheduleRequestReasonHtml = null;
        this.cancelRequestBy = null;
        this.cancelRequestReason = null;
        this.cancelRequestReasonHtml = null;
        this.repeatUpdateFollowing = false;
        this.repeatUpdateUntil = null;
        this.repeatUpdateRemaining = null;
        this.deleteAndExtend = null;
        this.hasUnsavedChanges = false;
        this.isLoading = false;
        this.isSaving = false;
        this.isReady = false;

        if (fn.isFunction(this.cancelGroupAppointmentGet)) {
            this.cancelGroupAppointmentGet();
            this.cancelGroupAppointmentGet = null;
        }

        if (fn.isFunction(this.cancelGroupAppointmentStage)) {
            this.cancelGroupAppointmentStage();
            this.cancelGroupAppointmentStage = null;
        }

        if (fn.isFunction(this.cancelGroupAppointmentTimeline)) {
            this.cancelGroupAppointmentTimeline();
            this.cancelGroupAppointmentTimeline = null;
        }

        if (fn.isFunction(this.cancelGroupAppointmentStatus)) {
            this.cancelGroupAppointmentStatus();
            this.cancelGroupAppointmentStatus = null;
        }

        if (fn.isFunction(this.cancelGroupAppointmentDelete)) {
            this.cancelGroupAppointmentDelete();
            this.cancelGroupAppointmentDelete = null;
        }

        if (fn.isFunction(this.cancelGroupAppointmentUpdate)) {
            this.cancelGroupAppointmentUpdate();
            this.cancelGroupAppointmentUpdate = null;
        }

        if (fn.isFunction(this.cancelPatientProfile)) {
            this.cancelPatientProfile();
            this.cancelPatientProfile = null;
        }
    }

    get start() {
        if (!this.data) return null;
        return moment(this.data.start);
    }

    get startUtc() {
        if (!this.data) return null;
        return moment.utc(this.data.startUtc);
    }

    get end() {
        if (!this.data) return null;
        return moment(this.data.end);
    }

    get endUtc() {
        if (!this.data) return null;
        return moment(this.data.endUtc);
    }

    get isReschedule() {
        if (!this.data) return false;

        return this.data.start !== this.originalData.start ||
            this.data.startUtc !== this.originalData.startUtc ||
            this.data.userId !== this.originalData.userId;
    }

    get isStaging() {
        return !this.groupId && this.stageId;
    }

    get isStaged() {
        return this.groupId && this.stageId && this.groupId === this.stageId;
    }
}

decorate(GroupAppointmentUpdate, {
    groupId: observable,
    groupAppointment: observable,
    data: observable,
    originalData: observable,
    stageId: observable,
    appointments: observable,
    originalAppointments: observable,
    customerServices: observable,
    rescheduleRequestBy: observable,
    rescheduleRequestReason: observable,
    rescheduleRequestReasonHtml: observable,
    cancelRequestBy: observable,
    cancelRequestReason: observable,
    cancelRequestReasonHtml: observable,
    repeatUpdateFollowing: observable,
    repeatUpdateUntil: observable,
    repeatUpdateRemaining: observable,
    deleteAndExtend: observable,
    hasUnsavedChanges: observable,
    isLoading: observable,
    isSaving: observable,
    isReady: observable,
    initialize: action,
    stage: action,
    staged: action,
    refresh: action,
    groupTimeline: action,
    timeline: action,
    status: action,
    loadPatientProfiles: action,
    save: action,
    delete: action,
    clear: action,
    checkConflicts: action,
    checkEligibility: action,
    start: computed,
    startUtc: computed,
    end: computed,
    endUtc: computed,
    isReschedule: computed,
    isStaging: computed,
    isStaged: computed,
})

export default createContext(new GroupAppointmentUpdate());