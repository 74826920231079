import React, { useContext, useEffect, useState, useRef } from 'react';
import FadeIn from 'react-fade-in';
import MaskedInput from 'react-text-mask'
import { Observer } from 'mobx-react-lite';
import { GlobalHotKeys } from 'react-hotkeys';
import { toast } from 'react-toastify';

import BodyEnd from '../../_shared/BodyEnd';
import ConfirmModal from '../../_shared/ConfirmModal';
import { quickDrawerFocus, renderQuickDrawerLoading } from '../../_shared/QuickDrawer';
import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import RichTextEditor from '../../_shared/RichTextEditor';

import PurchaseUpdateStore from '../../../../stores/PurchaseUpdateStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';
import CacheStore from '../../../../stores/CacheStore';

import * as ErrorMessages from '../../../../constants/errorMessages';
import * as MaskKeys from '../../../../constants/maskKeys';
import * as fn from '../../../../utilities/_functions';
import * as oh from '../../../../utilities/operationHelper';
import * as ph from '../../../../utilities/personHelper';
import * as ah from '../../../../utilities/addressHelper';
import * as ch from '../../../../utilities/customerHelper';
import * as ih from '../../../../utilities/invoiceHelper';
import * as bh from '../../../../utilities/badgeHelper';

import './RefundPurchase.scss';

function RefundPurchase(props) {
    const isMounted = useRef(true);
    const validateRef = useRef(null);
    const focusTimer = useRef(null);
    const purchase = useContext(PurchaseUpdateStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const cache = useContext(CacheStore);
    const [confirmRefund, setConfirmRefund] = useState(false);

    useEffect(() => {
        if (props.extraProps) {
            purchase.refundPayments.clear();
            purchase.refundPayments.push({
                amount: props.extraProps.amount ? props.extraProps.amount : null,
                refundMethod: cache.referenceData && props.extraProps.paymentMethod && cache.getReferenceDataOptions('RefundMethodType').some(r => r.key === props.extraProps.paymentMethod) ?
                    props.extraProps.paymentMethod : null
            })
        }

        focusTimer.current = setTimeout(() => {
            quickDrawerFocus(props.drawer);
        }, 100)

        return () => {
            isMounted.current = false;

            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
        }
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (purchase.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleRefundAmountChange = (event, index) => {
        const amount = event.target.value;
        purchase.refundPayments[index].amount = fn.parseCurrency(amount);
        purchase.hasUnsavedChanges = true;
    }

    const handleRefundMethodChange = (event, index) => {
        const refundMethod = event.target.value;
        purchase.refundPayments[index].refundMethod = refundMethod;
        purchase.hasUnsavedChanges = true;
    }

    const handleAddRefundPayment = () => {
        const remainingBalance = getRemainingBalance();

        purchase.refundPayments.push({
            amount: remainingBalance,
            refundMethod: null
        });
    }

    const handleRemoveRefundPayment = (event, index) => {
        purchase.refundPayments.splice(index, 1);
    }

    const handleReturnDestinationChange = (event, { groupId }) => {
        const value = event.target.value;
        purchase.updateReturnDestination(groupId, value);
        purchase.hasUnsavedChanges = true;
    }

    const getReturnDestination = ({ groupId, isFromInventory }) => {
        let index = purchase.returnDestinations.findIndex(r => r.groupId === groupId);

        if (index < 0) {
            if (isFromInventory) {
                purchase.updateReturnDestination(groupId, 'Inventory');
            } else {
                purchase.updateReturnDestination(groupId, '');
            }
            index = 0;
        }

        purchase.hasUnsavedChanges = true;

        return purchase.returnDestinations[index].returnDestination;
    }

    const handleReasonChange = (content, delta, source, editor) => {
        const text = editor.getText().replace(/(\r\n|\n|\r)/g, ' ').trim().substring(0, 200);
        const html = content;

        purchase.refundReason = (text ? text : null);
        purchase.refundReasonHtml = ((html === '<p><br></p>') ? null : html);
        purchase.hasUnsavedChanges = true;
    }

    const handleSubmit = event => {
        event.preventDefault();

        if (fn.validateForm(validateRef.current)) {
            const remainingBalance = getRemainingBalance();

            if (remainingBalance === 0.0) {
                setConfirmRefund(true);
            }
            else {
                toast.error(() => <p>Remaining balance must be $0.00 to proceed.</p>, { position: 'top-center' });
            }
        }
    }

    const handleConfirmRefund = event => {
        purchase.refundPurchase(true)
            .then((data) => {
                if (isMounted.current) {
                    setConfirmRefund(false);
                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                        props.onSuccess(event, { requiredVerification: data.requiredVerification, data: data });
                    }
                }
            })
    }

    const handleConfirmCancel = event => {
        setConfirmRefund(false);
    }

    const getRemainingBalance = () => {
        if (!purchase.data) return null;
        return purchase.data.totalPaid - purchase.refundPayments.map(p => { return fn.parseCurrency(p.amount, 0.0) }).reduce((accumulator, currentValue) => accumulator + currentValue, 0.0);
    }

    return <>
        <Observer>{() =>
            <>
                {
                    (props.drawer === quickDrawer.drawerOpened) && !confirmRefund ?
                        <GlobalHotKeys
                            keyMap={{
                                close: ['esc'],
                            }}
                            handlers={{
                                close: event => {
                                    handleCancel(event)
                                },
                            }}
                            allowChanges={true}
                        /> : null
                }
            </>
        }</Observer>
        <form ref={validateRef} onSubmit={handleSubmit}>
            <Observer>{() =>
                <fieldset disabled={purchase.isSaving}>
                    <div className='refund-purchase-container quick-drawer'>
                        <QuickDrawerHeader
                            drawer={props.drawer}
                            icon={oh.getIcon('purchase', 'refund-purchase')}
                            action='Purchase'
                            category='Refund Invoice'
                            className='delete'
                            onCancel={handleCancel}
                        />
                        <div className='quick-drawer-body'>
                            {
                                purchase.data ?

                                    <FadeIn>
                                        <div className='body-content'>
                                            <Observer>{() =>
                                                <>
                                                    <section>
                                                        <div className='row'>
                                                            <div className='col-6'>
                                                                <h3 className='purchase-title'>Invoice Detail</h3>
                                                                <p>Invoice # <strong>{purchase.data.invoiceNumber}</strong></p>
                                                            </div>
                                                            <div className='col-6'>
                                                                <div className='text-right'>
                                                                    {bh.renderPurchaseBalanceStatus(purchase.data, 'text-truncate text-truncate-md fs-sm p-2')}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                    <Observer>{() =>
                                                        <>
                                                            {
                                                                purchase.data.customer ?
                                                                    <section className='customer'>
                                                                        <div className='row'>
                                                                            <div className='col-12'>
                                                                                <label><small>Billing Information</small></label>
                                                                            </div>
                                                                        </div>
                                                                        <div className='row'>
                                                                            <div className='col-12'>
                                                                                <Observer>{() =>
                                                                                    <div
                                                                                        className='profile-wrapper'
                                                                                    >
                                                                                        <div className='profile'>
                                                                                            <span
                                                                                                className={`profile-image profile-initials rounded-circle d-flex text-white ${ch.getProfileColor(purchase.data.customer)} fw-500`}
                                                                                                title={purchase.data.customer.fullName}
                                                                                            >
                                                                                                {purchase.data.customer.initials}
                                                                                            </span>
                                                                                        </div>
                                                                                        <div className='description flex-1'>
                                                                                            <div className='name'>{ph.getFullName(purchase.data.customer, true)}
                                                                                                {
                                                                                                    purchase.data.customer.dateOfBirth || purchase.data.customer.sex || purchase.data.customer.gender || purchase.data.customer.pronoun ?
                                                                                                        <small className='text-nowrap ml-2'>({`${ph.getAge(purchase.data.customer.dateOfBirth)} ${ph.getSexGenderPronounDisplay(purchase.data.customer)}`.trim()})</small> : null
                                                                                                }
                                                                                            </div>
                                                                                            {
                                                                                                purchase.data.billingAddress ?
                                                                                                    <div className='info'>{ah.getAddressHtml(purchase.data.billingAddress)}</div> : null
                                                                                            }
                                                                                            {
                                                                                                purchase.data.customerEmail ?
                                                                                                    <div className='info'>
                                                                                                        <a
                                                                                                            href={`mailto:${purchase.data.customerEmail}`}
                                                                                                        >{purchase.data.customerEmail}
                                                                                                        </a>
                                                                                                    </div> : null
                                                                                            }
                                                                                            {
                                                                                                purchase.data.customerPhoneNumber ?
                                                                                                    <div className='info'>
                                                                                                        <a
                                                                                                            href={`tel:${purchase.data.customerPhoneNumber}`}
                                                                                                        >{purchase.data.customerPhoneNumber}
                                                                                                        </a>
                                                                                                    </div> : null
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                }</Observer>
                                                                            </div>
                                                                        </div>
                                                                    </section> : null
                                                            }
                                                        </>
                                                    }</Observer>
                                                    {
                                                        purchase.verifyReturnItems && purchase.verifyReturnItems.length > 0 ?
                                                            <div className='validate validate-required'>
                                                                <section className='mb-0'>
                                                                    <div className='row'>
                                                                        <div className='col-12'>
                                                                            <label className='required'><small>Return item(s)</small></label>
                                                                        </div>
                                                                    </div>
                                                                </section>
                                                                {
                                                                    purchase.verifyReturnItems.map((i, ii) => {
                                                                        return <section
                                                                            key={`return-item-${ii}`}
                                                                            className={'product-info' + (ii === 0 ? ' mt-0' : '')}
                                                                        >
                                                                            <div className='row'>
                                                                                <div className='col-12'>
                                                                                    <ul className='title'>
                                                                                        <li className='quantity'>
                                                                                            <h4>
                                                                                                <strong>{i.quantity}<small>x</small></strong>
                                                                                            </h4>
                                                                                        </li>
                                                                                        <li className='detail has-quantity'>
                                                                                            <span className='text-gray'>{i.referenceType}</span>
                                                                                            <h4 className='m-0 text-truncate text-truncate-xxl'><strong>{i.descriptor}</strong></h4>
                                                                                            <small className='brand'><strong>{i.brandName}</strong> ({i.supplierName})</small>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                            <div className='row'>
                                                                                <div className='col-12'>
                                                                                    <div className='ml-4 mt-2'>
                                                                                        <Observer>{() =>
                                                                                            <>
                                                                                                {
                                                                                                    i.isFromInventory ?
                                                                                                        <span className='fs-sm text-success'><strong>Note:</strong> This item was taken from inventory</span> :
                                                                                                        <span className='fs-sm text-danger'><strong>Note:</strong> This item was not taken from inventory</span>
                                                                                                }
                                                                                                <select
                                                                                                    className='custom-select form-control mt-2'
                                                                                                    value={getReturnDestination(i)}
                                                                                                    onChange={e => { handleReturnDestinationChange(e, i) }}
                                                                                                >
                                                                                                    <option value=''>No action required</option>
                                                                                                    <option value='Inventory'>Return to inventory</option>
                                                                                                    <option value='Supplier'>Return to supplier</option>
                                                                                                    <option value='Disposed'>Dispose</option>
                                                                                                </select>
                                                                                            </>
                                                                                        }</Observer>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </section>
                                                                    })
                                                                }
                                                            </div> : null
                                                    }
                                                    <section>
                                                        <div className='row'>
                                                            <div className='col-4'>
                                                                <label><small>Invoice Total</small></label>
                                                                <Observer>{() =>
                                                                    <strong className='d-block fs-xl'>{fn.formatCurrency(purchase.data.total)}</strong>
                                                                }</Observer>
                                                            </div>
                                                            <div className='col-4'>
                                                                <label><small>Total Paid</small></label>
                                                                <Observer>{() =>
                                                                    <strong className='d-block fs-xl'>{fn.formatCurrency(purchase.data.totalPaid)}</strong>
                                                                }</Observer>
                                                            </div>
                                                            <div className='col-4'>
                                                                <label><small className={(getRemainingBalance() > 0 ? ' text-danger' : '')}>Remaining Balance</small></label>
                                                                <Observer>{() =>
                                                                    <strong className={'d-block fs-xl' + (getRemainingBalance() > 0 ? ' text-danger' : '')}>{fn.formatCurrency(getRemainingBalance())}</strong>
                                                                }</Observer>
                                                            </div>
                                                        </div>
                                                    </section>
                                                    <section>
                                                        <div className='row'>
                                                            <div className='col-4'>
                                                                <label htmlFor='refund-payment-amount' className='required'><small>Amount</small></label>
                                                                <Observer>{() =>
                                                                    <>
                                                                        {
                                                                            purchase.refundPayments.map((p, pi) => {
                                                                                return <div key={`refund_payment_amount_${pi}`} className='validate validate-required'>
                                                                                    <MaskedInput
                                                                                        id='refund-payment-amount'
                                                                                        className='form-control currency-input mb-2'
                                                                                        mask={MaskKeys.CURRENCY_MASK}
                                                                                        value={p.amount}
                                                                                        onChange={e => { handleRefundAmountChange(e, pi) }}
                                                                                    />
                                                                                </div>
                                                                            })
                                                                        }
                                                                    </>
                                                                }</Observer>
                                                            </div>
                                                            <div className='col-8'>
                                                                <label htmlFor='refund-payment-refunded-by' className='required'><small>Refunded by</small></label>
                                                                <Observer>{() =>
                                                                    <>
                                                                        {
                                                                            purchase.refundPayments.map((p, pi) => {
                                                                                return <div key={`refund_payment_method_${pi}`} className='d-flex align-items-center validate validate-required'>
                                                                                    <select
                                                                                        id='refund-payment-refunded-by'
                                                                                        className='custom-select form-control mb-2'
                                                                                        value={p.refundMethod ? p.refundMethod : ''}
                                                                                        onChange={e => { handleRefundMethodChange(e, pi) }}
                                                                                    >
                                                                                        <option value=''></option>
                                                                                        {
                                                                                            cache.getReferenceDataOptions('RefundMethodType').map((option, di) => {
                                                                                                return <option key={`refund_method_type_${di}`} value={option.key}>{ih.getRefundMethodDescription(option)}</option>
                                                                                            })
                                                                                        }
                                                                                    </select>
                                                                                    {
                                                                                        purchase.refundPayments.length > 1 ?
                                                                                            <button
                                                                                                type='button'
                                                                                                className='btn btn-icon ml-2 p-0'
                                                                                                onClick={(e) => { handleRemoveRefundPayment(e, pi) }}
                                                                                            >
                                                                                                <i className='fal fa-times fs-lg text-danger'></i>
                                                                                            </button> : null
                                                                                    }
                                                                                </div>
                                                                            })
                                                                        }
                                                                    </>
                                                                }</Observer>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                <button type='button' className='btn btn-link p-0 mb-2 fs-sm' onClick={handleAddRefundPayment}>Add refund</button>
                                                            </div>
                                                        </div>
                                                    </section>
                                                    <section>
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                <div className='form-group validate validate-required'>
                                                                    <label className='required'><small>Refund Reason</small></label>
                                                                    <div className='input-group'>
                                                                        <Observer>{() =>
                                                                            <RichTextEditor
                                                                                mode='none'
                                                                                disableTab={true}
                                                                                value={purchase.refundReasonHtml}
                                                                                onChange={handleReasonChange}
                                                                            />
                                                                        }</Observer>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                </>
                                            }</Observer>
                                        </div>
                                    </FadeIn> : renderQuickDrawerLoading()
                            }
                        </div>
                        <div className='quick-drawer-action'>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='float-right'>
                                        <button
                                            type='button'
                                            className='btn btn-link btn-cancel mr-2'
                                            onClick={handleCancel}
                                        >Cancel</button>
                                        <Observer>{() =>
                                            <button
                                                type='submit'
                                                className='btn btn-danger'
                                            >Continue</button>
                                        }</Observer>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            }</Observer>
        </form>
        <Observer>{() =>
            <BodyEnd>
                <ConfirmModal
                    icon={<i className={'text-danger mr-2 ' + (oh.getIcon('purchase', 'refund-purchase'))}></i>}
                    message={<>Continue to refund <span className='fw-500'>&nbsp; invoice # {purchase.data ? purchase.data.invoiceNumber : ''}</span>? </>}
                    descriptionClassName='warning-danger warning-flashing'
                    show={confirmRefund}
                    onOption1Click={handleConfirmRefund}
                    onCancel={handleConfirmCancel}
                />
            </BodyEnd>
        }</Observer>
    </>
}

export default RefundPurchase;