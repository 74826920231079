import React, { useContext, useEffect, useState, useRef } from 'react';
import { Observer } from 'mobx-react-lite';
import { toJS } from 'mobx';
import FadeIn from 'react-fade-in';
import momentLocalizer from 'react-widgets-moment';
import { GlobalHotKeys } from 'react-hotkeys';
import { DateTimePicker, Multiselect } from 'react-widgets'
import moment, { isMoment } from 'moment';

import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import { quickDrawerFocus } from '../../_shared/QuickDrawer';

import TimeslotScheduleCreateStore from '../../../../stores/TimeslotScheduleCreateStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';
import SettingStore from '../../../../stores/SettingStore';

import * as fn from '../../../../utilities/_functions';
import * as oh from '../../../../utilities/operationHelper';

import './NewTimeslotSchedule.scss';
import api from '../../../../api';
moment.locale('en');
momentLocalizer();

function NewTimeslotSchedule(props) {
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const propWeekday = props.extraProps.date.format('dddd');
    const [selectedDays, setSelectedDays] = useState([propWeekday]);

    const timeslotSchedule = useContext(TimeslotScheduleCreateStore);
    const setting = useContext(SettingStore);
    const [isReady, setIsReady] = useState(false);
    //const [services, setServices] = useState([]);
    const [appointmentTypes, setAppointmentTypes] = useState([]);
    const isMounted = useRef(true);
    const validateRef = useRef(null);
    const quickDrawer = useContext(QuickDrawerStore);

    const getTime = (dt) => {
        if (dt && isMoment(dt)) {
            const time = dt.clone();
            const startTime = moment().startOf('day').hours(time.hour()).minutes(time.minute());
            return startTime;
        }
        return null;
    }

    const loadData = async () => {
        // const serviceResp = await api.Services.all();
        // if (serviceResp.data) {
        //     const bookableServices = serviceResp.data.filter(d => !!d.isBookable);
        //     if (bookableServices && bookableServices.length > 0) {
        //         setServices(bookableServices);
        //     }
        // }
        const apptTypeResp = await api.AppointmentTypes.all();
        if (apptTypeResp.data) {
            setAppointmentTypes(apptTypeResp.data);
        }
        if (isMounted.current) {
            setIsReady(true);
        }
        timeslotSchedule.initialize(props.extraProps.userId, propWeekday, props.extraProps.start);
    }

    useEffect(() => {
        quickDrawerFocus(props.drawer);
        loadData();

        return () => {
            isMounted.current = false;
        }
    }, []) // eslint-disable-line

    // const renderServices = () => {
    //     return services.map(s => {
    //         return {
    //             id: s.id,
    //             name: `${s.code} - ${s.name}`,
    //             code: s.code,
    //             colorHexValue: s.colorHexValue,
    //             duration: (s.defaultDurationInMinutes ? s.defaultDurationInMinutes : 0),
    //             isRepeatable: s.isRepeatable,
    //         }
    //     })
    // }

    const renderAppointmentTypes = () => {
        return appointmentTypes.map(a => {
            return {
                id: a.id,
                name: `${a.name}`,
                colorHexValue: '#888'
            }
        })
    }

    const handleSelectedDaysChange = (day) => {
        setSelectedDays((prevSelectedDays) =>
            prevSelectedDays.includes(day)
                ? prevSelectedDays.filter((d) => d !== day)
                : [...prevSelectedDays, day]
        );
    };

    // const handleServiceSelection = (timeslot, tag, metadata) => {
    //     switch (metadata.action) {
    //         case 'insert':
    //             if (!timeslot.services.some(s => s.id === metadata.dataItem.id)) {
    //                 timeslot.services.push({
    //                     id: metadata.dataItem.id,
    //                     code: metadata.dataItem.code,
    //                     name: metadata.dataItem.name
    //                 });
    //             }
    //             break;

    //         case 'remove':
    //             const index = timeslot.services.findIndex(s => s.id === metadata.dataItem.id);
    //             if (index !== -1) {
    //                 timeslot.services.splice(index, 1);
    //             }
    //             break;

    //         default:
    //             break;
    //     }
    //     timeslotSchedule.hasUnsavedChanges = true;
    // }

    const handleAppointmentTypesSelection = (timeslot, tag, metadata) => {
        switch (metadata.action) {
            case 'insert':
                if (!timeslot.appointmentTypes.some(s => s.id === metadata.dataItem.id)) {
                    timeslot.appointmentTypes.push({
                        id: metadata.dataItem.id,
                        name: metadata.dataItem.name
                    });
                }
                break;

            case 'remove':
                const index = timeslot.appointmentTypes.findIndex(s => s.id === metadata.dataItem.id);
                if (index !== -1) {
                    timeslot.appointmentTypes.splice(index, 1);
                }
                break;

            default:
                break;
        }
        timeslotSchedule.hasUnsavedChanges = true;
    }

    const handleGoBack = () => {
        if (fn.validateForm(validateRef.current)) {
            if (fn.isFunction(props.onCancel)) {
                props.onCancel();
            }
        }
    }

    const handleSubmit = event => {
        event.preventDefault();
        //const updated = timeslotSchedule.hasUnsavedChanges;

        timeslotSchedule.daysOfWeek = selectedDays;
        timeslotSchedule.save()
            .then(() => {
                if (isMounted.current) {
                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                        props.onSuccess(event, { });
                    }
                }
            })
    }

    const handleRemoveTimeslot = (event, index) => {
        timeslotSchedule.timeslots.splice(index, 1);
        timeslotSchedule.hasUnsavedChanges = true;
    }

    const handleTimeslotNoteChange = (event, index) => {
        const value = event.target.value;
        timeslotSchedule.timeslots[index].noteHtml = value || null;
        timeslotSchedule.hasUnsavedChanges = true;
    }

    const handleTimeslotStartTimePickerChange = (value, index) => {
        const durationBeforeChange = timeslotSchedule.timeslots[index].end.diff(timeslotSchedule.timeslots[index].start, 'minutes');

        const startTime = moment(value).format('h:mm a');
        const newStart = moment(`${moment(value).format('YYYY-MM-DD')} ${startTime}`, 'YYYY-MM-DD h:mm a');
        timeslotSchedule.timeslots[index].start = newStart;
        timeslotSchedule.timeslots[index].end = newStart.clone().add(durationBeforeChange, 'minutes');
        timeslotSchedule.hasUnsavedChanges = true;
    }

    // const handleAcceptAllServiceChange = (event, index) => {
    //     timeslotSchedule.timeslots[index].allowAllServices = !timeslotSchedule.timeslots[index].allowAllServices;
    //     timeslotSchedule.hasUnsavedChanges = true;
    // }

    const handleAcceptAllAppointmentTypeChange = (event, index) => {
        timeslotSchedule.timeslots[index].allowAllAppointmentTypes = !timeslotSchedule.timeslots[index].allowAllAppointmentTypes;
        timeslotSchedule.hasUnsavedChanges = true;
    }

    const handleTimeslotEndTimePickerChange = (value, index) => {
        const endTime = moment(value).format('h:mm a');
        const newEnd = moment(`${moment(value).format('YYYY-MM-DD')} ${endTime}`, 'YYYY-MM-DD h:mm a');
        timeslotSchedule.timeslots[index].end = newEnd;
        timeslotSchedule.hasUnsavedChanges = true;
    }

    const handleAddTimeslot = () => {
        // find the last timeslot and append to it
        if (!timeslotSchedule.timeslots || !timeslotSchedule.timeslots.length) {
            const duration = moment.duration(setting.appointmentsSlotSize);
            timeslotSchedule.timeslots.push({
                start: timeslotSchedule.start.clone(),
                end: timeslotSchedule.start.clone().add(duration),
                //allowAllServices: true,
                allowAllAppointmentTypes: true,
                //services: [],
                appointmentTypes: [],
            });

            console.log('AFER ADD!!!!', toJS(timeslotSchedule.timeslots));
        }
        else {
            const prevTimeslot = timeslotSchedule.timeslots.slice(-1)[0];
            const prevSlotDuration = prevTimeslot.end.diff(prevTimeslot.start, 'minutes');
            timeslotSchedule.timeslots.push({
                start: prevTimeslot.end.clone(),
                end: prevTimeslot.end.clone().add(prevSlotDuration, 'minutes'),
                note: prevTimeslot.note,
                //allowAllServices: prevTimeslot.allowAllServices,
                allowAllAppointmentTypes: prevTimeslot.allowAllAppointmentTypes,
                //services: JSON.parse(JSON.stringify(prevTimeslot.services)),
                appointmentTypes: JSON.parse(JSON.stringify(prevTimeslot.appointmentTypes)),
            });
        }

        timeslotSchedule.hasUnsavedChanges = true;
    }

    return <>
        <Observer>{() =>
            <>
                {
                    (props.drawer === quickDrawer.drawerOpened) ?
                        <GlobalHotKeys
                            keyMap={{
                                close: ['esc'],
                            }}
                            handlers={{
                                close: event => {
                                    handleGoBack(event)
                                },
                            }}
                            allowChanges={true}
                        /> : null
                }
            </>
        }</Observer>
        <form ref={validateRef} onSubmit={handleSubmit} >
            <fieldset>
                <div className='quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={oh.getIcon('service', 'update')}
                        action='Update'
                        category={`Timeslots`}
                        className='timeslot'
                        onCancel={handleGoBack}
                    />
                    <div className='quick-drawer-body'>
                        <Observer>{() =>
                            isReady && <FadeIn>
                                <div className='new-timeslot body-content'>
                                    <Observer>{() =>
                                        <section className='timeslots'>
                                            <div className='row days-of-week'>
                                                {daysOfWeek.map((day) => (
                                                    <div className='col'>
                                                        <div key={day} className='custom-control custom-checkbox mb-2'>
                                                            <input
                                                                id={'schedule-day-of-week-' + day}
                                                                type="checkbox"
                                                                className='custom-control-input'
                                                                checked={selectedDays.includes(day)}
                                                                onChange={() => handleSelectedDaysChange(day)}
                                                            />
                                                            <label
                                                                htmlFor={'schedule-day-of-week-' + day}
                                                                className='custom-control-label'
                                                            >
                                                                <br /><small>{day.substring(0, 2).toUpperCase()}</small>
                                                            </label>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className='row mt-5'>
                                                <div className='col-12'>
                                                    <p>
                                                        <button
                                                            type='button'
                                                            className='btn btn-link btn-icon-left btn-sm'
                                                            onClick={handleAddTimeslot}
                                                        >
                                                            <i className='fal fa-plus-circle'></i> Add timeslot
                                                        </button>
                                                    </p>
                                                    <label><small>Timeslots</small></label>
                                                    {
                                                        timeslotSchedule.timeslots && timeslotSchedule.timeslots.length > 0 ?
                                                            timeslotSchedule.timeslots.map((slot, ti) => {
                                                                console.log('SLOT!!!!', toJS(slot));
                                                                return <div class='mb-5' key={`slot_${ti}`}>
                                                                    <ul className='list-inline no-style m-0 mb-1'>
                                                                        <li className='list-inline-item m-0 mr-1 number'><strong>{ti + 1}.</strong>&nbsp;</li>
                                                                        <li className='list-inline-item m-0 title'>
                                                                            <input
                                                                                type='text'
                                                                                className='form-control form-control-sm'
                                                                                placeholder='Note'
                                                                                maxLength={150}
                                                                                value={(slot.noteHtml || '')}
                                                                                onChange={e => { handleTimeslotNoteChange(e, ti) }}
                                                                            />
                                                                        </li>
                                                                        <li className='list-inline-item m-0 ml-2'>
                                                                            <button
                                                                                type='button'
                                                                                className='btn btn-link btn-icon btn-sm'
                                                                                onClick={e => { handleRemoveTimeslot(e, ti) }}
                                                                            >
                                                                                <i className='fal fa-minus-circle text-danger'></i>
                                                                            </button>
                                                                        </li>
                                                                    </ul>
                                                                    <ul className='list-inline no-style m-0 mb-2'>
                                                                        <li className='list-inline-item m-0 mr-1 number'></li>
                                                                        <li className='list-inline-item m-0 time'>
                                                                            <DateTimePicker
                                                                                value={getTime(slot.start).toDate()}
                                                                                step={15}
                                                                                date={false}
                                                                                time={true}
                                                                                onChange={value => { handleTimeslotStartTimePickerChange(value, ti) }}
                                                                            />
                                                                        </li>
                                                                        <li className='list-inline-item m-0 mx-2'>to</li>
                                                                        <li className='list-inline-item m-0 time'>
                                                                            <DateTimePicker
                                                                                value={getTime(slot.end).toDate()}
                                                                                step={15}
                                                                                date={false}
                                                                                time={true}
                                                                                onChange={value => { handleTimeslotEndTimePickerChange(value, ti) }}
                                                                            />
                                                                        </li>
                                                                    </ul>
                                                                    {/* <div className='custom-control custom-switch'>
                                                                        <input
                                                                            id={'timeslot-allow-all-services-' + ti}
                                                                            type='checkbox'
                                                                            name={'timeslot-allow-all-services' + ti}
                                                                            className='custom-control-input'
                                                                            checked={slot.allowAllServices}
                                                                            onChange={e => handleAcceptAllServiceChange(e, ti)}
                                                                        />
                                                                        <label htmlFor={'timeslot-allow-all-services-' + ti} className='custom-control-label'>
                                                                            {
                                                                                slot.allowAllServices ?
                                                                                    <strong>Accept All Services</strong> :
                                                                                    <strong><span className='fw-sm'>Accept Only Selected Services</span></strong>
                                                                            }
                                                                        </label>
                                                                    </div> */}
                                                                    {
                                                                        // !slot.allowAllServices ?
                                                                        //     <div className='form-group mb-0 validate validate-required'>
                                                                        //         <label className='required' htmlFor='new-appointment-services'><small>Services</small></label>
                                                                        //         <Multiselect
                                                                        //             data-appointment-services
                                                                        //             allowCreate={false}
                                                                        //             data={renderServices()}
                                                                        //             defaultValue={slot.services}
                                                                        //             valueField='id'
                                                                        //             textField='name'
                                                                        //             tagComponent={({ item }) => (
                                                                        //                 <span
                                                                        //                     className='tag'
                                                                        //                     style={{
                                                                        //                         backgroundColor: item.colorHexValue,
                                                                        //                         borderColor: item.colorHexValue,
                                                                        //                     }}
                                                                        //                 >
                                                                        //                     <strong>{item.code}</strong>
                                                                        //                 </span>
                                                                        //             )}
                                                                        //             onChange={(tag, metadata) => handleServiceSelection(slot, tag, metadata)}
                                                                        //         />
                                                                        //     </div> : null
                                                                    }
                                                                    <div className='custom-control custom-switch mt-2'>
                                                                        <input
                                                                            id={'timeslot-allow-all-appointment-types-' + ti}
                                                                            type='checkbox'
                                                                            name={'timeslot-allow-all-appointment-types-' + ti}
                                                                            className='custom-control-input'
                                                                            checked={slot.allowAllAppointmentTypes}
                                                                            onChange={e => handleAcceptAllAppointmentTypeChange(e, ti)}
                                                                        />
                                                                        <label htmlFor={'timeslot-allow-all-appointment-types-' + ti} className='custom-control-label'>
                                                                            {
                                                                                slot.allowAllAppointmentTypes ?
                                                                                    <strong>Accept All Online Booking Appointment Types</strong> :
                                                                                    <strong><span className='fw-sm'>Accept Only Selected Online Booking Appointment Types</span></strong>
                                                                            }
                                                                        </label>
                                                                    </div>
                                                                    {
                                                                        !slot.allowAllAppointmentTypes ?
                                                                            <div className='form-group mb-0 validate validate-required'>
                                                                                <label className='required' htmlFor='new-appointment-appointment-types'><small>Online Booking Appointment Types</small></label>
                                                                                <Multiselect
                                                                                    data-appointment-appointment-types
                                                                                    allowCreate={false}
                                                                                    data={renderAppointmentTypes()}
                                                                                    defaultValue={slot.appointmentTypes}
                                                                                    valueField='id'
                                                                                    textField='name'
                                                                                    tagComponent={({ item }) => (
                                                                                        <span
                                                                                            className='tag'
                                                                                            style={{
                                                                                                backgroundColor: item.colorHexValue,
                                                                                                borderColor: item.colorHexValue,
                                                                                            }}
                                                                                        >
                                                                                            <strong>{item.name}</strong>
                                                                                        </span>
                                                                                    )}
                                                                                    onChange={(tag, metadata) => handleAppointmentTypesSelection(slot, tag, metadata)}
                                                                                />
                                                                            </div> : null
                                                                    }

                                                                    <p></p>
                                                                </div>
                                                            }) :
                                                            <p className='text-gray-700 mb-2'><em>No timeslots have been scheduled</em></p>
                                                    }
                                                </div>
                                            </div>
                                        </section>
                                    }</Observer>
                                </div>
                            </FadeIn>
                        }</Observer>
                    </div>
                    <div className='quick-drawer-action'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='float-right'>
                                    <button
                                        type='button'
                                        className='btn btn-link btn-warn mr-2'
                                        onClick={(handleGoBack)}
                                    >Cancel</button>
                                    {/* <button
                                        type='button'
                                        className='btn btn-danger mr-2'
                                        onClick={handleGoBack}
                                    >Delete</button> : */}
                                    <button
                                        type='button'
                                        className='btn btn-link btn-success mr-2'
                                        onClick={(handleSubmit)}
                                    >Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form >
    </>
}

export default NewTimeslotSchedule;