import React, { useEffect, useContext, useRef, useState } from 'react';
import moment from 'moment';

import AuthStore from '../../../../stores/AuthStore';

import * as fn from '../../../../utilities/_functions';

function PresetCommunicationSchedule(props) {
    const isMounted = useRef(true);
    const auth = useContext(AuthStore);
    const [isTodayValid, setIsTodayValid] = useState(true);
    const [daysOffset, setDaysOffset] = useState(0);

    useEffect(() => {
        const today = moment().startOf('day');
        const nowBuffered = moment().add(30, 'minutes');
        const nextTime = props.presets.filter(d => nowBuffered.isBefore(d))[0];
        const dayDiff = nextTime.diff(today, 'days');

        if (isMounted.current && dayDiff !== 0) {
            setIsTodayValid(false);
            setDaysOffset(1);
        }

        return () => {
            isMounted.current = false;
        }
    }, []) // eslint-disable-line

    const handleClose = event => {
        if (fn.isFunction(props.onClose)) {
            props.onClose(event);
        }
    }

    const handleDaysOffsetChange = (event, value) => {
        if (isMounted.current) {
            setDaysOffset(value);
        }
    }

    const handlePicker = (event) => {
        if (fn.isFunction(props.changeMode)) {
            props.changeMode('picker');
        }
    }

    const handlePresetSelect = (event, recommendation) => {
        if (fn.isFunction(props.onSelect)) {
            props.onSelect(event, recommendation);
        }
    }

    const renderTitle = () => {
        return <div className='popup-title'>
            <div className='actions left-actions pt-1'>
                <ul className='ml-2'>
                    <li>
                        <h4 className='mb-0'>
                            Schedule send
                        </h4>
                        {
                            auth.currentTenant && auth.currentTenant.timeZoneName ?
                                <span className='text-gray-600'>{auth.currentTenant.timeZoneName}</span> : null
                        }
                    </li>
                </ul>
            </div>
            <div className='actions right-actions'>
                <ul>
                    <li>
                        <button
                            type='button'
                            className='btn btn-icon btn-close'
                            onClick={handleClose}
                        >
                            <i className='close-icon fal fa-times fs-xl'></i>
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    }

    const renderContent = () => {
        const now = moment();
        const today = now.clone().startOf('day');
        const nowBuffered = now.clone().add(30, 'minutes');
        const compareDay = daysOffset === 0 ? nowBuffered.clone() : today.clone().add(daysOffset, 'days');
        const recommendations = props.presets.filter(d => compareDay.isBefore(d)).slice(0, 3);

        return <>
            <div className='popup-body'>
                <ul className='nav nav-tabs nav-tabs-clean' role='tablist'>
                    {
                        isTodayValid ?
                            <li className='nav-item'>
                                <button
                                    type='button'
                                    className={'nav-link bg-transparent py-0 px-4 ' + (daysOffset === 0 ? 'text-primary font-weight-bold active' : 'fw-400 text-muted')} data-toggle='tab' role='tab'
                                    onClick={(e) => { handleDaysOffsetChange(e, 0) }}
                                >
                                    Later today
                                </button>
                            </li> : null
                    }
                    <li className='nav-item'>
                        <button
                            type='button'
                            className={'nav-link bg-transparent py-0 px-4 ' + (daysOffset === 1 ? 'text-primary font-weight-bold active' : 'fw-400 text-muted')} data-toggle='tab' role='tab'
                            onClick={(e) => { handleDaysOffsetChange(e, 1) }}
                        >
                            Tomorrow
                        </button>
                    </li>
                    <li className='nav-item'>
                        <button
                            type='button'
                            className={'nav-link bg-transparent py-0 px-4 ' + (daysOffset === 3 ? 'text-primary font-weight-bold active' : 'fw-400 text-muted')} data-toggle='tab' role='tab'
                            onClick={(e) => { handleDaysOffsetChange(e, 3) }}
                        >
                            3 days later
                        </button>
                    </li>
                    <li className='nav-item'>
                        <button
                            type='button'
                            className={'nav-link bg-transparent py-0 px-4 ' + (daysOffset === 7 ? 'text-primary font-weight-bold active' : 'fw-400 text-muted')} data-toggle='tab' role='tab'
                            onClick={(e) => { handleDaysOffsetChange(e, 7) }}
                        >
                            A week later
                        </button>
                    </li>
                </ul>
                <div className='preset-recommendations'>
                    {
                        recommendations.map((r, ri) => {
                            return <button
                                key={`communication-schedule-preset-${ri}`}
                                type='button'
                                className={r.isSame(props.scheduledSendDateTimeLocal) ? 'bg-primary' : null}
                                onClick={(e) => { handlePresetSelect(e, r) }}
                            >
                                <span className='d-inline-block w-50'>
                                    <strong className={'fs-md ' + (r.isSame(props.scheduledSendDateTimeLocal) ? 'text-white' : 'text-gray-700')}>{props.getDayDescription(r)}</strong>
                                </span>
                                <span className='d-inline-block w-50 text-right'>
                                    <span className={'fs-md ' + (r.isSame(props.scheduledSendDateTimeLocal) ? 'text-white' : 'text-gray-600')}>{props.getTimeDescription(r)}</span>
                                </span>
                            </button>
                        })
                    }
                </div>
                <div className='preset-actions'>
                    <button
                        type='button'
                        className='text-gray-600 text-gray-700-hover'
                        onClick={handlePicker}
                    >
                        <strong className='fs-md'>Select a specific date time</strong>
                    </button>
                    {
                        props.scheduledSendDateTimeLocal ?
                            <button
                                type='button'
                                className='border-top text-danger text-danger-700-hover'
                                onClick={(e) => { handlePresetSelect(e, null) }}
                            >
                                <strong className='fs-md'>Cancel schedule send</strong>
                            </button> : null
                    }
                </div>
            </div>
        </>
    }

    return <>
        {renderTitle()}
        {renderContent()}
    </>
}

export default PresetCommunicationSchedule;