import React, { useEffect, useContext, useRef, useState } from 'react';

import AuthStore from '../../../../stores/AuthStore';

import * as fn from '../../../../utilities/_functions';

function ReasonCommunicationSchedule(props) {
    const isMounted = useRef(true);
    const noteRef = useRef(null);
    const focusTimer = useRef(null);
    const auth = useContext(AuthStore);
    const [reason, setReason] = useState('');

    useEffect(() => {
        if (isMounted.current) {
            focusTimer.current = setTimeout(() => {
                if (noteRef.current) {
                    noteRef.current.focus();
                }
            }, 300);
        }

        return () => {
            isMounted.current = false;
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
        }
    }, []) // eslint-disable-line

    const handleClose = event => {
        if (fn.isFunction(props.onClose)) {
            props.onClose(event);
        }
    }

    const handleReasonChange = event => {
        const value = event.target.value;
        setReason(value);
    }

    const handleScheduleSend = event => {
        if (fn.isFunction(props.onScheduled)) {
            props.onScheduled(event, reason);
        }

        handleClose(event);
    }

    const renderTitle = () => {
        return <div className='popup-title'>
            <div className='actions left-actions pt-1'>
                <ul className='ml-2'>
                    <li>
                        <h4 className='mb-0'>
                            Schedule send
                        </h4>
                        {
                            auth.currentTenant && auth.currentTenant.timeZoneName ?
                                <span className='text-gray-600'>{auth.currentTenant.timeZoneName}</span> : null
                        }
                    </li>
                </ul>
            </div>
            <div className='actions right-actions'>
                <ul>
                    <li>
                        <button
                            type='button'
                            className='btn btn-icon btn-close'
                            onClick={handleClose}
                        >
                            <i className='close-icon fal fa-times fs-xl'></i>
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    }

    const renderContent = () => {
        return <>
            <div className='popup-body'>
                <div className='reason-controls'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='form-group'>
                                <strong className='text-gray-600'>(Optional) Leave notes for other team member of this {props.type ? props.type : 'schedule send'}</strong>
                                <div className='pt-2'>
                                    <textarea
                                        ref={noteRef}
                                        className='form-control'
                                        rows={3}
                                        value={reason}
                                        onChange={handleReasonChange}
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12'>
                            <button
                                type='button'
                                className='mt-4 btn btn-success px-3 w-100'
                                onClick={handleScheduleSend}
                            >
                                Schedule {props.type ? `this ${props.type}` : 'send'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }

    return <>
        {renderTitle()}
        {renderContent()}
    </>
}

export default ReasonCommunicationSchedule;