import React, { Component } from 'react';

import * as fn from '../../../utilities/_functions';

export default class CustomerFilter extends Component {
    constructor(props) {
        super(props);
        this.searchTimer = React.createRef(null);
        this.delay = React.createRef(null);
        this.inputRef = React.createRef(null);
        this.state = {
            searchTerm: null,
            value: null,
            isLoading: false,
        };
        this.clear = this.clear.bind(this);
        this.setValue = this.setValue.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.delay.current = this.props.delay ? this.props.delay : 2000;
    }

    componentWillUnmount() {
        if (this.searchTimer.current) { clearTimeout(this.searchTimer.current); this.searchTimer.current = null; }
    }

    isLoading = (isLoading) => {
        this.setState({ isLoading: isLoading });
    }

    handleChange = event => {
        const that = this;

        const term = event.target.value;

        this.setState({ searchTerm: term, value: null });

        if (this.searchTimer.current) {
            clearTimeout(this.searchTimer.current);
        }

        this.searchTimer.current = setTimeout(() => {
            if (fn.isFunction(that.props.onChange)) {
                that.props.onChange(event, term);
            }
        }, this.delay.current);
    }

    handleKeyDown = event => {
        if (fn.isFunction(this.props.onKeyDown)) {
            this.props.onKeyDown(event);
        }
    }

    setValue = (value) => {
        this.setState({ searchTerm: value, value: value });
        const that = this;
        this.searchTimer.current = setTimeout(() => {
            if (fn.isFunction(that.props.onChange)) {
                that.props.onChange({}, value);
            }
        }, this.delay.current);
    }

    clear = () => {
        this.setState({ searchTerm: null, value: null });
    }

    get element() {
        return this.inputRef.current;
    }

    render() {
        return <>
            <input
                id={this.props.id ? this.props.id : null}
                ref={this.inputRef}
                type='text'
                className='form-control mb-0 dropdown-toggle'
                spellCheck={false}
                autoComplete='off'
                disabled={this.state.isLoading || (this.props.disabled === true)}
                value={this.state.value ? this.state.value : (this.state.searchTerm ? this.state.searchTerm : '')}
                data-validator-required={!!this.state.value}
                onChange={this.handleChange}
                onKeyDown={this.handleKeyDown}
            />
        </>
    }
}
