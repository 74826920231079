import React, { useContext, useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import FadeIn from 'react-fade-in';
import { Observer } from 'mobx-react-lite';
import momentLocalizer from 'react-widgets-moment';
import { GlobalHotKeys } from 'react-hotkeys';
import TimeAgo from 'react-timeago';
import { toast } from 'react-toastify';
import moment from 'moment';

import BodyEnd from '../../_shared/BodyEnd';
import PretestModal from '../PretestModal/_index';
import { quickDrawerFocus, renderQuickDrawerLoading } from '../../_shared/QuickDrawer';
import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';

import PretestCreateStore from '../../../../stores/PretestCreateStore';
import PretestUpdateStore from '../../../../stores/PretestUpdateStore';
import PretestViewStore from '../../../../stores/PretestViewStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';
import useSignalR from '../../../hooks/useSignalR';

import api from '../../../../api';

import * as rts from '../../../../constants/routes';
import * as fn from '../../../../utilities/_functions';
import * as ph from '../../../../utilities/personHelper';
import * as ah from '../../../../utilities/addressHelper';
import * as ch from '../../../../utilities/customerHelper';
import * as sys from '../../../../utilities/systemHelper';
import * as oh from '../../../../utilities/operationHelper';
import * as dh from '../../../../utilities/deviceHelper';

import './NewPretest.scss';

moment.locale('en');
momentLocalizer();

function NewPretest(props) {
    const isMounted = useRef(true);
    const validateRef = useRef(null);
    const pretestModalTimer = useRef(null);
    const pretestModalRef = useRef(null);
    const newPretest = useContext(PretestCreateStore);
    const updatePretest = useContext(PretestUpdateStore);
    const viewPretest = useContext(PretestViewStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const signalR = useSignalR();
    const [recentPretests, setRecentPretests] = useState([]);
    const [pretestDisplay, setPretestDisplay] = useState(false);
    const [pretestReady, setPretestReady] = useState(false);
    const [isManualInput, setIsManualInput] = useState(false);
    const [attachedDevices, setAttachedDevices] = useState(null);
    const [pretestDevice, setPretestDevice] = useState(null);

    useEffect(() => {
        const customerId = props.extraProps && props.extraProps.customer ? props.extraProps.customer.id : null;
        const appointmentId = props.extraProps && props.extraProps.appointment ? props.extraProps.appointment.id : null;
        const timelineItemId = props.extraProps && props.extraProps.timelineItem ? props.extraProps.timelineItem.id : null;
        const attachedDeviceObjs = dh.getAttachedDevices();

        setAttachedDevices(attachedDeviceObjs);
        setIsManualInput((!attachedDeviceObjs || !attachedDeviceObjs.devices || attachedDeviceObjs.devices.length === 0));
        
        signalR.on('GroupAppointment', (updated) => {
            if (updated && updated.appointmentIds && updated.appointmentIds.length > 0  && updated.appointmentIds.some(a => appointmentId === a)) {
                if (isMounted.current) {
                    loadRecentPretests();
                }
            }
        });        
        signalR.on('Appointment', (updated) => {
            if (updated && appointmentId === updated.id) {
                if (isMounted.current) {
                    loadRecentPretests();
                }
            }
        });
        signalR.on('Pretest', (updated) => {
            if (updated && customerId === updated.customerId) {
                if (isMounted.current) {
                    loadRecentPretests();
                }
            }
        });

        if (isMounted.current && !newPretest.isReady) {
            newPretest.initialize(customerId, appointmentId, timelineItemId);
            loadRecentPretests();
        }
        quickDrawerFocus(props.drawer);

        return () => {
            isMounted.current = false;

            newPretest.clear();
            viewPretest.clear();
            updatePretest.clear();
            if (pretestModalTimer.current) { clearTimeout(pretestModalTimer.current); pretestModalTimer.current = null; }
        }
    }, []) // eslint-disable-line

    const loadRecentPretests = () => {
        if (props.extraProps.customer && props.extraProps.appointment) {
            api.Pretests.search({
                parameters: [
                    { field: 'DeactivatedDateUtc', value: null },
                    { field: 'CustomerId', value: props.extraProps.customer.id },
                ],
                offset: 0,
                limit: 5,
                sortByFields: [{ field: 'CreatedDateUtc', direction: 'DESC', }],
                includeTotalCount: false,
            })
                .then(({ data }) => {
                    if (isMounted.current) {
                        setRecentPretests(data.result);
                    }
                })
        }
    }

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            props.onCancel();
        }
    }

    const handleAttachedDevicesSuccess = () => {
        setAttachedDevices(dh.getAttachedDevices());
    }

    const handleStartModal = (event, typeIds, device) => {
        newPretest.data.typeIds = typeIds;

        pretestModalRef.current.changeMode('Create');
        setPretestDisplay(true);
        setPretestDevice(device);

        pretestModalTimer.current = setTimeout(() => {
            if (isMounted.current) {
                setPretestReady(true);
            }
        }, 100);
    }

    const handleStartModalSuccess = (event, result) => {
        if (isMounted.current) {
            if (result && result.updated) {
                const appointmentId = props.extraProps && props.extraProps.appointment ? props.extraProps.appointment.id : null;

                if (appointmentId) {
                    toast.dark(() => <p data-appt-upd>Appointment updated.</p>);
                }
            }

            newPretest.data.pretestData.clear();
            setPretestDisplay(false);
        }
    }

    const handleViewModal = (event, { id }) => {
        if (isMounted.current) {
            setPretestDisplay(true);
            setPretestDevice(null);
            viewPretest.initialize(id)
                .then(() => {
                    if (isMounted.current) {
                        pretestModalRef.current.changeMode('View');
                        setPretestReady(true);
                    }
                })
        }
    }

    const handleModalClose = event => {
        newPretest.data.pretestData.clear();
        setPretestReady(false);
        pretestModalTimer.current = setTimeout(() => {
            if (isMounted.current) {
                setPretestDisplay(false);
            }
            viewPretest.clear();
        }, 100);
    }

    return <>
        <Observer>{() =>
            <>
                {
                    (props.drawer === quickDrawer.drawerOpened) && !pretestDisplay ?
                        <GlobalHotKeys
                            keyMap={{
                                close: ['esc'],
                            }}
                            handlers={{
                                close: event => {
                                    handleCancel(event)
                                },
                            }}
                            allowChanges={true}
                        /> : null
                }
            </>
        }</Observer>
        <form ref={validateRef}>
            <Observer>{() =>
                <fieldset disabled={newPretest.isSaving}>
                    <div className='quick-drawer'>
                        <QuickDrawerHeader
                            drawer={props.drawer}
                            icon={oh.getIcon('pretest', 'default')}
                            action='Start'
                            category='Preliminary Testing'
                            className='pretests'
                            onCancel={handleCancel}
                        />
                        <div className='quick-drawer-body'>
                            {
                                newPretest.isReady ?
                                    <FadeIn>
                                        <div className='new-pretest body-content'>
                                            <Observer>{() =>
                                                props.extraProps.customer ?
                                                    <section>
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                <div className='form-group mb-0'>
                                                                    <label><small>For</small></label>
                                                                    <div
                                                                        className='profile-wrapper pr-0'
                                                                    >
                                                                        <div className='profile'>
                                                                            <span
                                                                                className={`profile-image profile-initials rounded-circle d-flex text-white ${ch.getProfileColor(props.extraProps.customer)} fw-500`}
                                                                                title={ph.getFullName(props.extraProps.customer)}
                                                                            >
                                                                                {props.extraProps.customer.initials}
                                                                            </span>
                                                                        </div>
                                                                        <div className='description flex-1'>
                                                                            <Link
                                                                                to={`${rts.Customers.Home}/${props.extraProps.customer.id}`}
                                                                                className='name text-gray-700 text-info-hover'
                                                                                onClick={quickDrawer.deactivateAll}
                                                                            >
                                                                                {ph.getFullName(props.extraProps.customer, true)}
                                                                                {
                                                                                    props.extraProps.customer.dateOfBirth || props.extraProps.customer.sex || props.extraProps.customer.gender || props.extraProps.customer.pronoun ?
                                                                                        <small className='text-nowrap ml-2'>({`${ph.getAge(props.extraProps.customer.dateOfBirth, moment((props.extraProps.appointment ? props.extraProps.appointment.start : null)))} ${ph.getSexGenderPronounDisplay(props.extraProps.customer)}`.trim()})</small> : null
                                                                                }
                                                                            </Link>
                                                                            {
                                                                                props.extraProps.customer.address && props.extraProps.customer.address.country ?
                                                                                    <div className='info'>{ah.getAddressHtml(props.extraProps.customer.address)}</div> : null
                                                                            }
                                                                            {
                                                                                props.extraProps.customer.emailAddress ?
                                                                                    <div className='info'>
                                                                                        <a
                                                                                            href={`mailto:${props.extraProps.customer.emailAddress}`}
                                                                                        >{props.extraProps.customer.emailAddress}
                                                                                        </a>
                                                                                    </div> : null
                                                                            }
                                                                            {
                                                                                props.extraProps.customer.phoneNumber ?
                                                                                    <div className='info'>
                                                                                        <a
                                                                                            href={`tel:${props.extraProps.customer.phoneNumber}`}
                                                                                        >{sys.getFormattedPhoneNumber(props.extraProps.customer.phoneNumber)}
                                                                                        </a>
                                                                                    </div> : null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section> : null
                                            }</Observer>
                                            <Observer>{() => <>
                                                <section className='type mx-n3 mt-2'>
                                                    <div className='row mx-n3'>
                                                        <div className='col-12'>
                                                            <ul className='nav nav-tabs nav-tabs-clean' role='tablist'>
                                                                <li className='nav-item'>
                                                                    <button
                                                                        type='button'
                                                                        className={'nav-link bg-transparent py-0 fs-sm' + (!isManualInput ? ' text-primary font-weight-bold active' : ' fw-400 text-muted')} data-toggle='tab' role='tab'
                                                                        onClick={() => { setIsManualInput(false) }}
                                                                    >
                                                                        Read from Device
                                                                    </button>
                                                                </li>
                                                                <li className='nav-item'>
                                                                    <button
                                                                        data-pretest-manual-input
                                                                        type='button'
                                                                        className={'nav-link bg-transparent py-0 fs-sm' + (isManualInput ? ' text-primary font-weight-bold active' : ' fw-400 text-muted')} data-toggle='tab' role='tab'
                                                                        onClick={() => { setIsManualInput(true) }}
                                                                    >
                                                                        Manual input
                                                                    </button>
                                                                </li>
                                                            </ul>
                                                            <div className='d-flex flex-column'>
                                                                <div className='flex-1'>
                                                                    {
                                                                        isManualInput ?
                                                                            <div className='pt-2'>
                                                                                {
                                                                                    newPretest.pretestTypes.map((t, ti) => {
                                                                                        return <div
                                                                                            key={`pretest-type-${ti}`}
                                                                                            className='d-flex'
                                                                                        >
                                                                                            <div
                                                                                                className='d-flex flex-column flex-1 justify-content-center'
                                                                                            >
                                                                                                <div className='text-truncate text-truncate-xl fs-lg fw-500 py-2 pl-6'>
                                                                                                    {t.name}
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                className='d-flex flex-column justify-content-center'
                                                                                            >
                                                                                                <div
                                                                                                    className='px-3'
                                                                                                >
                                                                                                    <button
                                                                                                        data-pretest-type={t.name}
                                                                                                        type='button'
                                                                                                        className='btn btn-sm btn-info'
                                                                                                        onClick={(e) => { handleStartModal(e, [t.id]) }}
                                                                                                    >
                                                                                                        <i className='fas fa-chevron-right'></i>
                                                                                                    </button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    })
                                                                                }
                                                                            </div> :
                                                                            <>
                                                                                {
                                                                                    attachedDevices && attachedDevices.devices && attachedDevices.devices.length ? <>
                                                                                        {
                                                                                            attachedDevices.devices.map((d, di) => {
                                                                                                return <div
                                                                                                    key={`pretest-device-${di}`}
                                                                                                    className='d-flex'
                                                                                                >
                                                                                                    <div
                                                                                                        className='d-flex flex-column flex-1 justify-content-center'
                                                                                                    >
                                                                                                        <div className='text-truncate text-truncate-xl fs-lg fw-500 py-2 pl-6'>
                                                                                                            {d.name}
                                                                                                            {
                                                                                                                d.descriptionHtml ?
                                                                                                                    <div
                                                                                                                        className='text-truncate text-truncate-xl fs-sm text-gray-600 html'
                                                                                                                        dangerouslySetInnerHTML={{ __html: d.descriptionHtml }}
                                                                                                                    /> : null
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className='d-flex flex-column justify-content-center'
                                                                                                    >
                                                                                                        <div
                                                                                                            className='px-3'
                                                                                                        >
                                                                                                            <button
                                                                                                                type='button'
                                                                                                                className='btn btn-sm btn-info'
                                                                                                                onClick={(e) => { handleStartModal(e, newPretest.pretestTypes.filter(t => t.logicalDeviceId === d.logicalDeviceId && t.industryDeviceId === d.industryDeviceId).map(t => { return t.id }), d) }}
                                                                                                            >
                                                                                                                <i className='fas fa-chevron-right'></i>
                                                                                                            </button>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            })
                                                                                        }
                                                                                    </> : <div className='d-flex'>
                                                                                        <div className='d-flex flex-column flex-1 justify-content-center align-items-center p-4'>
                                                                                            {
                                                                                                attachedDevices.lastUpdated ?
                                                                                                    <div className='text-center mb-4'>
                                                                                                        <h5 className='mb-0'>No devices detected</h5>
                                                                                                        <span className='text-muted'>Last checked: {fn.formatDate(moment(attachedDevices.lastUpdated))}</span>
                                                                                                    </div> : null

                                                                                            }
                                                                                            <dh.DetectAttachedDevicesButton className='btn btn-sm btn-primary' onSuccess={handleAttachedDevicesSuccess} />
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                            </>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </>}</Observer>
                                            <Observer>{() =>
                                                <>
                                                    {
                                                        recentPretests && recentPretests.length > 0 ?
                                                            <section>
                                                                <div className='row'>
                                                                    <div className='col-12'>
                                                                        <div className='form-group'>
                                                                            <label className='mb-2'><small>Last 5 test(s)</small></label>
                                                                            {
                                                                                recentPretests.map((p, pi) => {
                                                                                    return <div
                                                                                        key={`recent_pretest_test_${pi}`}
                                                                                        className='d-flex py-1'
                                                                                    >
                                                                                        <div className='flex-1 pl-2'>
                                                                                            <button
                                                                                                type='button'
                                                                                                className='btn btn-link fw-500 p-0'
                                                                                                onClick={(e) => { handleViewModal(e, p) }}
                                                                                            >{p.pretestType.name}</button>
                                                                                        </div>
                                                                                        <div>
                                                                                            {
                                                                                                moment().startOf('day').isSame(moment.utc(p.createdDateUtc).local().startOf('day')) ?
                                                                                                    <TimeAgo
                                                                                                        className='text-muted fs-xs'
                                                                                                        formatter={(value, unit, suffix) => { return fn.timeAgoPastFormatter(value, unit, suffix, 'second', 10, 'Just now') }}
                                                                                                        date={moment.utc(p.createdDateUtc).local().toDate()}
                                                                                                        title={sys.getFormattedLongDate(moment.utc(p.createdDateUtc).local(), true)}
                                                                                                        minPeriod={60}
                                                                                                    /> :
                                                                                                    <span
                                                                                                        className='text-muted fs-xs'
                                                                                                        title={sys.getFormattedLongDate(moment.utc(p.createdDateUtc).local(), true)}
                                                                                                    >
                                                                                                        {sys.getFormattedLongDate(moment.utc(p.createdDateUtc).local())}
                                                                                                    </span>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </section> : null
                                                    }
                                                </>
                                            }</Observer>
                                        </div>
                                    </FadeIn> : renderQuickDrawerLoading()
                            }
                        </div>
                        <div className='quick-drawer-action'>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='float-right'>
                                        <button
                                            data-quickdraw-pretest-done
                                            type='button'
                                            className='btn btn-link btn-cancel'
                                            onClick={handleCancel}
                                        >
                                            Done
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            }</Observer>
        </form>
        {
            <BodyEnd>
                <PretestModal ref={pretestModalRef} display={pretestDisplay} ready={pretestReady} defaultMode='Create' appointment={props.extraProps.appointment} device={pretestDevice} onSuccess={handleStartModalSuccess} onClose={handleModalClose} />
            </BodyEnd>
        }
    </>
}

export default NewPretest;