import React, { useEffect, useContext, useRef, useState } from 'react';
import { Observer } from 'mobx-react-lite';
import FadeIn from 'react-fade-in';
import { Multiselect } from 'react-widgets'
import uuid from 'react-uuid';

import BodyEnd from '../../_shared/BodyEnd';
import LoadingOverlay from '../../_shared/LoadingOverlay';
import HtmlEditor from '../../_shared/HtmlEditor';
import CommunicationScheduleModal from '../CommunicationScheduleModal/_index';

import CommunicationStore from '../../../../stores/CommunicationStore';

import * as ErrorMessages from '../../../../constants/errorMessages';
import * as fn from '../../../../utilities/_functions';
import * as ah from '../../../../utilities/addressHelper';
import * as sys from '../../../../utilities/systemHelper';

import api from '../../../../api';

function FaxCommunication(props) {
    const isMounted = useRef(true);
    const focusTimer = useRef(null);
    const searchTimer = useRef(null);
    const modalBodyRef = useRef(null);
    const validateRef = useRef(null);
    const scheduleModalRef = useRef(null);
    const cancelSearch = useRef(null);
    const communication = useContext(CommunicationStore);

    const [isLoading, setIsLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState(null);
    const [contacts, setContacts] = useState([]);
    const [selectedContacts, setSelectedContacts] = useState([]);
    const [includeCoverPage, setIncludeCoverPage] = useState(false);
    const [sender, setSender] = useState(null);
    const [recipient, setRecipient] = useState(null);
    const [subject, setSubject] = useState(null);
    const [messageHtml, setMessageHtml] = useState(null);

    useEffect(() => {
        if (props.defaultValues) {
            if (props.defaultValues.subject) {
                setSubject(props.defaultValues.subject);
            }
            if (props.defaultValues.includeCoverPage) {
                setIncludeCoverPage(props.defaultValues.includeCoverPage);
            }
            if (props.defaultValues.sender) {
                setSender(props.defaultValues.sender);
            }
            if (props.defaultValues.recipient) {
                setRecipient(props.defaultValues.recipient);
            }
        }

        firstElementFocus();
        return () => {
            isMounted.current = false;
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
            if (searchTimer.current) { clearTimeout(searchTimer.current); searchTimer.current = null; }
            if (cancelSearch.current && fn.isFunction(cancelSearch.current)) { cancelSearch.current(); cancelSearch.current = null; }
            communication.clear();
        }
    }, []) // eslint-disable-line

    useEffect(() => {
        setDefaultContacts();
    }, [communication.isReady]) // eslint-disable-line

    const setDefaultContacts = () => {
        if (communication.defaultContact && communication.defaultContact.fax) {
            const { defaultContact } = communication;
            const selectedContact = {
                id: uuid(),
                name: defaultContact.name ? defaultContact.name : 'New',
                value: defaultContact.fax ? defaultContact.fax : null,
                isNew: false,
            };
            const newSelectedContacts = [selectedContact];

            setSelectedContacts(newSelectedContacts);
        }
    }

    const firstElementFocus = () => {
        const found = modalBodyRef && modalBodyRef.current ? modalBodyRef.current.querySelectorAll('input[type="text"]:not([disabled])') : null;

        if (found && found.length > 0) {
            const notEmpty = Array.from(found).filter(el => !el.value);

            focusTimer.current = setTimeout(() => {
                if (notEmpty && notEmpty.length > 0) {
                    notEmpty[0].focus();
                } else {
                    found[0].focus();
                }
            }, 100);
        }
    }

    const handleFaxToSearch = (searchTerm, metadata) => {
        setSearchTerm(searchTerm);

        if (searchTimer.current) {
            clearTimeout(searchTimer.current);
            searchTimer.current = null;
        }

        if (searchTerm && searchTerm.length >= 2) {
            searchTimer.current = setTimeout(() => {
                setIsLoading(true);

                if (fn.isFunction(cancelSearch.current)) { cancelSearch.current(); cancelSearch.current = null; }

                api.AddressBookLookUp.fullSearch(searchTerm, null, 'Fax', communication.customerId, 10, 0, false, true, false, (c) => { cancelSearch.current = c })
                    .then(({ data }) => {
                        if (isMounted.current) {
                            const filteredResult = data.result && data.result.length > 0 ? data.result.filter(r => r.contactType === 'Fax') : [];
                            setContacts((filteredResult && filteredResult.length > 0 ? filteredResult : []));
                        }
                    })
                    .finally(() => {
                        setIsLoading(false);
                    })
            }, 500);
        } else {
            if (isMounted.current) {
                setContacts([]);
            }
        }
    }

    const handleFaxToCreate = (searchTerm) => {
        if (fn.regexTester.phone.test(searchTerm)) {
            const fax = fn.formatPhone(searchTerm);

            if (!selectedContacts.some(c => c.value === fax)) {
                const newContact = {
                    id: uuid(),
                    name: 'New',
                    value: fax,
                    isNew: true,
                };
                const newSelectedContacts = [...selectedContacts, newContact];

                setSelectedContacts(newSelectedContacts);

                if (searchTimer.current) {
                    clearTimeout(searchTimer.current);
                    searchTimer.current = null;
                }
            }
        }
    }

    const handleFaxToChange = (dataItems, metadata) => {
        setSelectedContacts(dataItems);
    }

    const handleCoverPageChange = (event) => {
        setIncludeCoverPage(!includeCoverPage);
    }

    const handleSenderChange = (event) => {
        const sender = event.target.value;
        setSender(sender);
    }

    const handleRecipientChange = (event) => {
        const recipient = event.target.value;
        setRecipient(recipient);
    }

    const handleSubjectChange = (event) => {
        const subject = event.target.value;
        setSubject(subject);
    }

    const handleMessageChange = (e) => {
        const newMessageHtml = e.value;
        setMessageHtml(newMessageHtml);
    }

    const handleClose = event => {
        if (fn.isFunction(props.onClose)) {
            if (communication.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    communication.clear();
                    communication.hasUnsavedChanges = false;
                    props.onClose(event);
                }
            } else {
                props.onClose(event);
            }
        }
    }

    const handleStartSchedule = event => {
        scheduleModalRef.current.show({ dateTimeLocal: communication.scheduledSendDateTimeLocal, type: 'fax' });
    }

    const handleScheduleSelect = (event, dateTime) => {
        communication.scheduledSendDateTimeLocal = dateTime;
        communication.hasUnsavedChanges = true;
    }

    const handleScheduleCancel = event => {
        communication.scheduledSendDateTimeLocal = null;
        communication.hasUnsavedChanges = true;
    }

    const handleScheduleReason = event => {
        scheduleModalRef.current.show({ dateTimeLocal: communication.scheduledSendDateTimeLocal, type: 'fax', mode: 'reason' });
    }

    const handleScheduled = (event, reason) => {
        communication.scheduledSendReason = reason ? reason : null;
        handleSend(event);
    }

    const handleSend = event => {
        if (fn.validateForm(validateRef.current)) {
            const option = {
                type: 'Fax',
                to: selectedContacts,
                faxOption: {
                    includeCoverPage: includeCoverPage,
                    sender: sender,
                    recipient: recipient,
                    subject: subject,
                    messageHtml: messageHtml,
                }
            };
            communication.send(option, true)
                .then((data) => {
                    if (fn.isFunction(props.onSent)) {
                        option.response = data;
                        props.onSent(event, option);
                    }
                })
                .finally(() => {
                    if (fn.isFunction(props.onClose)) {
                        props.onClose(event);
                    }
                })
        }
    }

    const renderTitle = () => {
        return <div className='popup-title'>
            <div className='actions left-actions pt-1'>
                <ul className='ml-2'>
                    <li>
                        <h4 className='mb-0'>
                            {
                                props.secured === true ?
                                    <>Sending <strong className='text-danger'>Secure Fax</strong>...</> :
                                    <>Send Fax...</>
                            }
                        </h4>
                    </li>
                </ul>
            </div>
            <div className='actions right-actions'>
                <ul>
                    <li>
                        <div className='btn-group'>
                            <button
                                data-send-email
                                type='button'
                                className={'btn px-3 ' + (communication.scheduledSendDateTimeLocal ? 'btn-warning' : 'btn-success')}
                                disabled={communication.isSaving}
                                title={communication.scheduledSendDateTimeLocal ? `Scheduled to send on ${sys.getFormattedShortDate(communication.scheduledSendDateTimeLocal, true)}` : null}
                                onClick={(e) => { communication.scheduledSendDateTimeLocal ? handleScheduleReason(e) : handleSend(e) }}
                            >
                                {communication.scheduledSendDateTimeLocal ? <>Send later</> : <>Send</>}
                            </button>
                            <button
                                type='button'
                                className={'btn dropdown-toggle px-2 ' + (communication.scheduledSendDateTimeLocal ? 'btn-warning' : 'btn-success')}
                                disabled={communication.isSaving}
                            >
                            </button>
                            <div className='dropdown-menu dropdown-menu-right min-width-auto'>
                                <button
                                    type='button'
                                    className='dropdown-item'
                                    onClick={handleStartSchedule}
                                >
                                    {communication.scheduledSendDateTimeLocal ? <>Select another date / time</> : <>Schedule send</>}
                                </button>
                                {
                                    communication.scheduledSendDateTimeLocal ?
                                        <button
                                            type='button'
                                            className='dropdown-item text-danger text-danger-700-hover'
                                            onClick={handleScheduleCancel}
                                        >
                                            <strong>Cancel schedule send</strong>
                                        </button> : null
                                }
                            </div>
                        </div>
                    </li>
                    <li>
                        <button
                            type='button'
                            className='btn btn-icon btn-close'
                            onClick={handleClose}
                        >
                            <i className='close-icon fal fa-times fs-xl'></i>
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    }

    const renderContent = () => {
        return <div className='popup-body'>
            <section className='p-4'>
                <FadeIn>
                    <form ref={validateRef.current}>
                        <fieldset disabled={communication.isSaving}>
                            {
                                communication.scheduledSendDateTimeLocal ?
                                    <section className='pb-4'>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <p className='fw-500 text-warning-700 mb-0'>Scheduled to send on {sys.getFormattedLongDate(communication.scheduledSendDateTimeLocal, true)}.</p>
                                            </div>
                                        </div>
                                    </section> : null
                            }
                            {
                                props.secured === true ?
                                    <section>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <p className='fw-500 text-danger'><i className='fas fa-lock mr-2'></i> This fax will be protected by <a href='https://www.mfax.io/hipaa-compliant-fax' target='_blank' rel='noopener noreferrer'>mFax</a> (HIPAA compliant provider).</p>
                                            </div>
                                        </div>
                                    </section> : null
                            }
                            <section>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className='form-group mb-4 validate validate-required'>
                                            <label htmlFor='fax-send-to' className='required'><small>Send fax to</small></label>
                                            <Observer>{() => <Multiselect
                                                allowCreate={true}
                                                busy={isLoading}
                                                data={contacts}
                                                valueField='id'
                                                textField='name'
                                                filter={false}
                                                disabled={!props.faxToEnabled}
                                                itemComponent={({ item }) => (
                                                    <>
                                                        <div
                                                            className='w-100 p-2'
                                                        >
                                                            <div className='d-flex flex-row'>
                                                                <div className='d-flex flex-column justify-content-center pr-2'>
                                                                    <span
                                                                        className={`profile-image profile-image-md profile-initials rounded-circle d-flex m-auto bg-gray-700 fw-500`}
                                                                        title={(item.name ? item.name : item.companyName)}
                                                                    >
                                                                        <span className='fs-80'>{getInitials(item.name ? item.name : item.companyName)}</span>
                                                                    </span>
                                                                </div>
                                                                <div className='d-flex flex-1 flex-column justify-content-center text-dark external-appointment-detail'>
                                                                    <div
                                                                        className='fs-lg fw-500 line-height-1 pt-1'
                                                                    >
                                                                        <small className={'d-block text-gray-700 mb-o'}>
                                                                            {
                                                                                item.name ?
                                                                                    <span className='fw-500'>
                                                                                        {item.name}
                                                                                        {
                                                                                            item.companyName ?
                                                                                                <small className='ml-1'>({item.companyName})</small> : null
                                                                                        }
                                                                                    </span> :
                                                                                    <span className='fw-500'>{item.companyName}</span>
                                                                            }
                                                                        </small>
                                                                        {
                                                                            item.address && item.address.country ?
                                                                                <small className='d-block text-gray-600 address-sameline mb-o'>{ah.getAddressHtml(item.address)}</small> : null
                                                                        }
                                                                        <small className='d-block text-info fw-500'>Fax: {item.value}</small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                                tagComponent={({ item }) => (
                                                    <span className={'tag ' + (item.isNew ? 'bg-gray-800' : 'bg-primary-700')}>
                                                        <strong>{item.name ? item.name : item.companyName} - {item.value}</strong>
                                                    </span>
                                                )}
                                                messages={{
                                                    createOption: () => (
                                                        searchTerm && fn.regexTester.phone.test(searchTerm) ?
                                                            <>Send fax to <strong>{searchTerm}</strong></> :
                                                            <>Search by <strong>name</strong> or <strong>fax number</strong></>
                                                    ),
                                                    emptyList: '',
                                                    emptyFilter: ''
                                                }}
                                                value={selectedContacts}
                                                onCreate={handleFaxToCreate}
                                                onSearch={handleFaxToSearch}
                                                onChange={handleFaxToChange}
                                            />}</Observer>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className='form-group mb-4'>
                                            <label className='required'><small>File(s)</small></label>
                                            <div className='files'>
                                                {
                                                    includeCoverPage ?
                                                        <span
                                                            className='file-icon file-icon-text text-primary-700 border-primary-700 text-truncate text-truncate-sm'
                                                            title={'Fax Cover Page'}
                                                        >
                                                            <i className='fal fa-file'></i> Fax Cover Page
                                                        </span> : null
                                                }
                                                {
                                                    communication.files && communication.files.length > 0 ?
                                                        communication.files.map((f, fi) => {
                                                            return <span
                                                                key={`fax_file_${fi}`}
                                                                className='file-icon file-icon-text text-primary-700 border-primary-700 text-truncate text-truncate-sm'
                                                                title={f.fileName}
                                                            >
                                                                <i className='fal fa-file'></i> {f.fileName}
                                                            </span>
                                                        }) : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className='form-group'>
                                            <div className='custom-control custom-checkbox'>
                                                <input
                                                    id='fax-include-cover-page'
                                                    type='checkbox'
                                                    name='fax-include-cover-page'
                                                    className='custom-control-input'
                                                    checked={includeCoverPage}
                                                    onChange={handleCoverPageChange}
                                                />
                                                <label
                                                    htmlFor='fax-include-cover-page'
                                                    className='custom-control-label'
                                                >
                                                    Include a fax cover page
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            {
                                includeCoverPage ?
                                    <FadeIn>
                                        <section className='border-top pt-4 mt-4'>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <div className='form-group mb-4'>
                                                        <label htmlFor='fax-from'><small>Sender</small></label>
                                                        <input
                                                            id='fax-from'
                                                            type='text'
                                                            className='form-control'
                                                            maxLength='150'
                                                            spellCheck={false}
                                                            autoComplete='off'
                                                            value={sender ? sender : ''}
                                                            onChange={handleSenderChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-6'>
                                                    <div className='form-group mb-4'>
                                                        <label htmlFor='fax-from'><small>Recipient</small></label>
                                                        <input
                                                            id='fax-to'
                                                            type='text'
                                                            className='form-control'
                                                            maxLength='150'
                                                            spellCheck={false}
                                                            autoComplete='off'
                                                            disabled={selectedContacts && selectedContacts.length > 1}
                                                            value={recipient ? recipient : ''}
                                                            onChange={handleRecipientChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label htmlFor='fax-subject'><small>Subject</small></label>
                                                        <input
                                                            id='fax-subject'
                                                            type='text'
                                                            className='form-control'
                                                            maxLength='255'
                                                            autoComplete='off'
                                                            value={subject ? subject : ''}
                                                            onChange={handleSubjectChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-2 fax-message'>
                                                        <label htmlFor='fax-message'><small>Message</small></label>
                                                        <HtmlEditor
                                                            height='180px'
                                                            disableTab={true}
                                                            onValueChanged={handleMessageChange}
                                                        >
                                                        </HtmlEditor>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </FadeIn> : null
                            }
                        </fieldset>
                    </form>
                </FadeIn>
            </section>
        </div>
    }

    const getInitials = (name) => {
        if (!name) return 'X';

        const result = name.replace('Dr. ', '').replace(/[^a-zA-Z ]/g, '');

        if (!result) return name[0].toUpperCase();

        const parts = result.split(' ');

        switch (parts.length) {
            case 0:
                return name[0].toUpperCase();

            case 1:
                return result[0].toUpperCase();

            case 2:
                return `${parts[0][0]}${parts[1][0]}`.toUpperCase();

            default:
                return `${parts[0][0]}${parts[(parts.length - 1)][0]}`.toUpperCase();
        }
    }

    return <>
        <Observer>{() =>
            <>
                {
                    communication.isReady ?
                        <>
                            <Observer>{() => <LoadingOverlay isLoading={communication.isSaving} />}</Observer>
                            {renderTitle()}
                            {renderContent()}
                        </> :
                        <>
                            <div className='popup-title'></div>
                            <div className='popup-body'></div>
                        </>
                }
                <BodyEnd>
                    <CommunicationScheduleModal ref={scheduleModalRef} onSelect={handleScheduleSelect} onScheduled={handleScheduled} />
                </BodyEnd>
            </>
        }</Observer>
    </>
}

export default FaxCommunication;