import { createContext } from 'react';
import { decorate, observable, action } from 'mobx';

import api from '../api';
import * as fn from '../utilities/_functions';

export class BookingUpdate {
    data = null;
    isSaving = false;
    isReady = false;
    cancelRequestBy = null;
    cancelRequestReason = null;
    cancelRequestReasonHtml = null;

    hasUnsavedChanges = false;
    cancelBooking = null;
    cancelBookingDelete = null;

    initialize = (data) => {
        this.clear();
        this.data = data;
        this.isReady = true;
    }

    delete = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            api.Bookings.delete(
                that.data.id,
                {
                    cancelRequestBy: that.cancelRequestBy,
                    cancelRequestReason: that.cancelRequestReason,
                    cancelRequestReasonHtml: that.cancelRequestReasonHtml,
                },
                (c) => { this.cancelBookingDelete = c; }
            )
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isSaving = false;
                })
        })
    }

    clear = () => {
        this.data = null;
        this.isSaving = false;
        this.isReady = false;

        if (fn.isFunction(this.cancelBookingDelete)) {
            this.cancelBookingDelete();
            this.cancelBookingDelete = null;
        }
    }
}

decorate(BookingUpdate, {
    data: observable,
    cancelRequestBy: observable,
    cancelRequestReason: observable,
    cancelRequestReasonHtml: observable,
    isSaving: observable,
    initialize: action,
    delete: action,
    clear: action,
})

export default createContext(new BookingUpdate());