import { createContext } from 'react';
import { decorate, action, reaction, observable } from 'mobx';

import api from '../api';
import * as UserSettingKeys from '../constants/userSettingKeys';
import { CURRENT_USER, EXPIRES_AT, SETTINGS } from '../constants/storageKeys';
import * as fn from '../utilities/_functions';

export class Setting {
    _settings = [];
    // Appointments
    appointmentsCalendarMode = null;
    appointmentsSlotSize = '00:15:00';
    // Exams
    examsCalendarMode = null;
    examsSlotSize = '00:10:00';
    examsStartNewWindow = false;
    examsCompareSync = true;
    // Schedules
    isSchedulesSidebarOpened = false;
    isSchedulesCalendarSidebarOpened = false;
    schedulesCalendarMode = null;
    // Global
    isSidebarOpened = false;
    showCreatedByYouTasks = true;

    cancelSettingUpdate = null;

    appointmentCalendarModeDisposer = null;
    appointmentSlotSizeDisposer = null;
    examsCalendarModeDisposer = null;
    examsSlotSizeDisposer = null;
    examsStartNewWindowDisposer = null;
    examsCompareSyncDisposer = null;
    isSchedulesCalendarSidebarOpenedDisposer = null;
    schedulesCalendarModeDisposer = null;
    sidebarDisposer = null;
    showCreatedByYouTasksDisposer = null;

    constructor() {
        // Appointments
        this.appointmentCalendarModeDisposer = reaction(
            () => this.appointmentsCalendarMode, (appointmentsCalendarMode) => {
                this.setSetting(UserSettingKeys.APPOINTMENTS_CALENDAR_MODE, appointmentsCalendarMode, /^(day|week)$/i);
            }
        )

        this.appointmentSlotSizeDisposer = reaction(
            () => this.appointmentsSlotSize, (appointmentsSlotSize) => {
                this.setSetting(UserSettingKeys.APPOINTMENTS_SLOT_SIZE, appointmentsSlotSize, fn.regexTester.timeSlot);
            }
        )

        // Exams
        this.examsCalendarModeDisposer = reaction(
            () => this.examsCalendarMode, (examsCalendarMode) => {
                this.setSetting(UserSettingKeys.EXAMS_CALENDAR_MODE, examsCalendarMode, /^(day|week)$/i);
            }
        )

        this.examsSlotSizeDisposer = reaction(
            () => this.examsSlotSize, (examsSlotSize) => {
                this.setSetting(UserSettingKeys.EXAMS_SLOT_SIZE, examsSlotSize, fn.regexTester.timeSlot);
            }
        )

        this.examsStartNewWindowDisposer = reaction(
            () => this.examsStartNewWindow, (examsStartNewWindow) => {
                this.setSetting(UserSettingKeys.EXAMS_START_NEW_WINDOW, examsStartNewWindow, fn.regexTester.boolean);
            }
        )

        this.examsCompareSyncDisposer = reaction(
            () => this.examsCompareSync, (examsCompareSync) => {
                this.setSetting(UserSettingKeys.EXAMS_COMPARE_SYNC, examsCompareSync, fn.regexTester.boolean);
            }
        )

        // Schedules
        this.isSchedulesCalendarSidebarOpenedDisposer = reaction(
            () => this.isSchedulesCalendarSidebarOpened, (isSchedulesCalendarSidebarOpened) => {
                this.setSetting(UserSettingKeys.IS_SCHEDULES_CALENDAR_SIDEBAR_OPENED, isSchedulesCalendarSidebarOpened, fn.regexTester.boolean);
            }
        )

        this.schedulesCalendarModeDisposer = reaction(
            () => this.schedulesCalendarMode, (schedulesCalendarMode) => {
                this.setSetting(UserSettingKeys.SCHEDULES_CALENDAR_MODE, schedulesCalendarMode, /^(day|week)$/i);
            }
        )

        // Global
        this.sidebarDisposer = reaction(
            () => this.isSidebarOpened, (isSidebarOpened) => {
                this.setSetting(UserSettingKeys.IS_SIDEBAR_OPENED, isSidebarOpened, fn.regexTester.boolean);
            }
        )

        this.showCreatedByYouTasksDisposer = reaction(
            () => this.showCreatedByYouTasks, (showCreatedByYouTasks) => {
                this.setSetting(UserSettingKeys.SHOW_CREATED_BY_YOU_TASKS, showCreatedByYouTasks, fn.regexTester.boolean);
            }
        )
        this.initialize();
    }

    getIsExpired = () => {
        let isExpired = true;
        const expiresAtJson = window.localStorage.getItem(EXPIRES_AT);

        if (expiresAtJson) {
            const expiresAt = JSON.parse(expiresAtJson);
            isExpired = new Date().getTime() >= expiresAt;
        }

        return isExpired;
    }

    getCurrentUser = () => {
        return JSON.parse(window.localStorage.getItem(CURRENT_USER));
    }

    getSetting = (key, defaultValue, regex) => {
        const filteredSettings = this._settings.filter(setting => setting.key === key);
        let settingValue = filteredSettings && filteredSettings.length > 0 ? filteredSettings[0].value : defaultValue;
        window.localStorage.setItem(key, JSON.stringify(settingValue));

        if (regex && !regex.test(settingValue)) {
            window.localStorage.setItem(key, defaultValue);
            settingValue = defaultValue;
        }

        return settingValue;
    }

    initialize = () => {
        this._settings = window.localStorage.getItem(SETTINGS) ? JSON.parse(window.localStorage.getItem(SETTINGS)) : [];

        // Appointments
        this.appointmentsCalendarMode = this.getSetting(UserSettingKeys.APPOINTMENTS_CALENDAR_MODE, 'day', /^(day|week)$/i);
        this.appointmentsSlotSize = this.getSetting(UserSettingKeys.APPOINTMENTS_SLOT_SIZE, '00:15:00', fn.regexTester.timeSlot);

        // Exams
        this.examsCalendarMode = this.getSetting(UserSettingKeys.EXAMS_CALENDAR_MODE, 'day', /^(day|week)$/i);
        this.examsSlotSize = this.getSetting(UserSettingKeys.EXAMS_SLOT_SIZE, '00:10:00', fn.regexTester.timeSlot);
        this.examsStartNewWindow = fn.toBoolean(this.getSetting(UserSettingKeys.EXAMS_START_NEW_WINDOW, false, fn.regexTester.boolean));
        this.examsCompareSync = fn.toBoolean(this.getSetting(UserSettingKeys.EXAMS_COMPARE_SYNC, true, fn.regexTester.boolean));

        // Schedules
        this.isSchedulesCalendarSidebarOpened = fn.toBoolean(this.getSetting(UserSettingKeys.IS_SCHEDULES_CALENDAR_SIDEBAR_OPENED, false, fn.regexTester.boolean));
        this.schedulesCalendarMode = this.getSetting(UserSettingKeys.SCHEDULES_CALENDAR_MODE, 'week', /^(day|week)$/i);

        // Global
        this.isSidebarOpened = fn.toBoolean(this.getSetting(UserSettingKeys.IS_SIDEBAR_OPENED, false, fn.regexTester.boolean));
        this.showCreatedByYouTasks = fn.toBoolean(this.getSetting(UserSettingKeys.SHOW_CREATED_BY_YOU_TASKS, true, fn.regexTester.boolean));

        return Promise.resolve();
    }

    setSetting = (key, value, regex) => {
        let valid = !regex || regex.test(value);

        if (valid) {
            let found = false;
            let settings = JSON.parse(JSON.stringify(this._settings));
            const currentUser = this.getCurrentUser();
            const isExpired = this.getIsExpired();

            for (var i = 0; i < settings.length; i++) {
                if (settings[i].key === key) {
                    found = true;

                    if (settings[i].value !== value) {
                        settings[i].value = value;
                    }
                }
            }

            if (!found) {
                settings.push({ key: key, value: value });
            }

            window.localStorage.setItem(SETTINGS, JSON.stringify(settings));

            if (!isExpired && currentUser) {
                api.Settings.save(currentUser.id, { type: 'User', key: key, value: value, referenceId: currentUser.id }, (c) => { this.cancelSettingUpdate = c });
            }
        }
    }

    clear = () => {
        this._settings = [];
        this.appointmentsCalendarMode = null;
        this.appointmentsSlotSize = '00:15:00';
        this.examsCalendarMode = null;
        this.examsSlotSize = '00:10:00';
        this.examsStartNewWindow = false;
        this.examsCompareSync = true;
        this.isSchedulesSidebarOpened = false;
        this.isSchedulesCalendarSidebarOpened = false;
        this.schedulesCalendarMode = null;
        this.isSidebarOpened = false;
        this.showCreatedByYouTasks = true;

        if (fn.isFunction(this.cancelSettingUpdate)) {
            this.cancelSettingUpdate();
            this.cancelSettingUpdate = null;
        }

        if (fn.isFunction(this.appointmentCalendarModeDisposer)) {
            this.appointmentCalendarModeDisposer();
            this.appointmentCalendarModeDisposer = null;
        }

        if (fn.isFunction(this.appointmentSlotSizeDisposer)) {
            this.appointmentSlotSizeDisposer();
            this.appointmentSlotSizeDisposer = null;
        }

        if (fn.isFunction(this.examsCalendarModeDisposer)) {
            this.examsCalendarModeDisposer();
            this.examsCalendarModeDisposer = null;
        }

        if (fn.isFunction(this.examsSlotSizeDisposer)) {
            this.examsSlotSizeDisposer();
            this.examsSlotSizeDisposer = null;
        }

        if (fn.isFunction(this.examsStartNewWindowDisposer)) {
            this.examsStartNewWindowDisposer();
            this.examsStartNewWindowDisposer = null;
        }

        if (fn.isFunction(this.examsCompareSyncDisposer)) {
            this.examsCompareSyncDisposer();
            this.examsCompareSyncDisposer = null;
        }

        if (fn.isFunction(this.isSchedulesCalendarSidebarOpenedDisposer)) {
            this.isSchedulesCalendarSidebarOpenedDisposer();
            this.isSchedulesCalendarSidebarOpenedDisposer = null;
        }

        if (fn.isFunction(this.schedulesCalendarModeDisposer)) {
            this.schedulesCalendarModeDisposer();
            this.schedulesCalendarModeDisposer = null;
        }

        if (fn.isFunction(this.sidebarDisposer)) {
            this.sidebarDisposer();
            this.sidebarDisposer = null;
        }

        if (fn.isFunction(this.showCreatedByYouTasksDisposer)) {
            this.showCreatedByYouTasksDisposer();
            this.showCreatedByYouTasksDisposer = null;
        }
    }
}

decorate(Setting, {
    setSetting: action,
    settings: observable,
    // Appointments
    appointmentsCalendarMode: observable,
    appointmentsSlotSize: observable,
    // Exams
    examsCalendarMode: observable,
    examsSlotSize: observable,
    examsStartNewWindow: observable,
    examsCompareSync: observable,
    // Schedules
    isSchedulesCalendarSidebarOpened: observable,
    schedulesCalendarMode: observable,
    // Global
    isSidebarOpened: observable,
    showCreatedByYouTasks: observable,
    clear: action,
})

export default createContext(new Setting());