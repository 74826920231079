import React, { useContext, useEffect, useState, useRef } from 'react';
import { Observer } from 'mobx-react-lite';
import FadeIn from 'react-fade-in';
import { toJS } from 'mobx';
import moment, { isMoment } from 'moment';
import momentLocalizer from 'react-widgets-moment';
import { GlobalHotKeys } from 'react-hotkeys';
import { DateTimePicker, DropdownList, Multiselect } from 'react-widgets'

import BodyEnd from '../../_shared/BodyEnd';
import ConfirmModal from '../../_shared/ConfirmModal';
import LoadingOverlay from '../../_shared/LoadingOverlay';
import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import CustomerFilter from '../../_shared/CustomerFilter';
import { quickDrawerFocus } from '../../_shared/QuickDrawer';

import AppointmentCreateStore from '../../../../stores/AppointmentCreateStore';
import CustomerCreateStore from '../../../../stores/CustomerCreateStore';
import CustomerUpdateStore from '../../../../stores/CustomerUpdateStore';
import BookingUpdateStore from '../../../../stores/BookingUpdateStore';
import AuthStore from '../../../../stores/AuthStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';
import CacheStore from '../../../../stores/CacheStore';

import api from '../../../../api';

import * as ErrorMessages from '../../../../constants/errorMessages';
import * as fn from '../../../../utilities/_functions';
import * as ph from '../../../../utilities/personHelper';
import * as ah from '../../../../utilities/addressHelper';
import * as ch from '../../../../utilities/customerHelper';
import * as sys from '../../../../utilities/systemHelper';
import * as oh from '../../../../utilities/operationHelper';
import * as bh from '../../../../utilities/badgeHelper';
import * as nh from '../../../../utilities/noteHelper';
import * as bk from '../../../../utilities/onlineBookingHelper';

import './ConvertBookingToAppointment.scss';

moment.locale('en');
momentLocalizer();

function ConvertBookingToAppointment(props) {
    const isMounted = useRef(true);
    const validateRef = useRef(null);
    const searchTimer = useRef(null);
    const startTimePickerTimer = useRef(null);
    const endTimePickerTimer = useRef(null);
    const newAppointmentCustomerFilterRef = useRef(null);
    const cache = useContext(CacheStore);
    const appointment = useContext(AppointmentCreateStore);
    const newCustomer = useContext(CustomerCreateStore);
    const updateCustomer = useContext(CustomerUpdateStore);
    const updateBooking = useContext(BookingUpdateStore);
    const auth = useContext(AuthStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const [isReady, setIsReady] = useState(false);
    const [services, setServices] = useState([]);
    const [datePickerOpen, setDatePickerOpen] = useState(false);
    const [startTimePickerOpen, setStartTimePickerOpen] = useState(false);
    const [endTimePickerOpen, setEndTimePickerOpen] = useState(false);
    const [newCustomerData, setNewCustomerData] = useState(null);
    const [existingCustomerData, setExistingCustomerData] = useState(null);
    const [customerSearchResult, setCustomerSearchResult] = useState('');
    const [appointmentNotes, setAppointmentNotes] = useState([]);
    const [isUploadingAttachments, setIsUploadingAttachments] = useState(false);
    const [conflicts, setConflicts] = useState(false);
    const [confirmConflicts, setConfirmConflicts] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [eligibles, setEligibles] = useState(null);
    const [ineligibles, setIneligibles] = useState(null);
    const [mohReady, setMohReady] = useState(null);
    const [confirmIneligibles, setConfirmIneligibles] = useState(false);

    useEffect(() => {
        quickDrawerFocus(props.drawer);
        checkConflicts();

        if (props.extraProps && props.extraProps.booking) {
            //appointment.data.services = props.extraProps.booking.services;
            appointment.data.duration = props.extraProps.booking.duration;
            appointment.booking = props.extraProps.booking;
            console.log('appointment.booking!!!!!', toJS(appointment.booking));
        }

        if (props.extraProps && props.extraProps.customer) {
            if (!props.extraProps.customer.notes) {
                props.extraProps.customer.notes = [];
            }
            setExistingCustomerData(toJS(props.extraProps.customer));
            appointment.hasUnsavedChanges = true;
        }

        if (appointment.stageCustomer) {
            if (!appointment.stageCustomer.notes) {
                appointment.stageCustomer.notes = [];
            }
            setExistingCustomerData(toJS(appointment.stageCustomer));
            appointment.hasUnsavedChanges = true;
        }

        api.Services.all()
            .then(({ data }) => {
                if (isMounted.current) {
                    if (data) {
                        const bookableServices = data.filter(d => !!d.isBookable);
                        if (bookableServices && bookableServices.length > 0) {
                            setServices(bookableServices);
                        }
                    }
                }
            })
            .finally(() => {
                if (isMounted.current) {
                    setIsReady(true);
                }
                newAppointmentCustomerFilterRef.current.setValue(bk.getBookingPrimaryContact(appointment.booking).fullName);
            })

        return () => {
            isMounted.current = false;
            if (searchTimer.current) { clearTimeout(searchTimer.current); searchTimer.current = null; }
            if (startTimePickerTimer.current) { clearTimeout(startTimePickerTimer.current); startTimePickerTimer.current = null; }
            if (endTimePickerTimer.current) { clearTimeout(endTimePickerTimer.current); endTimePickerTimer.current = null; }
        }
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (appointment.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const checkConflicts = () => {
        appointment.checkConflicts()
            .then(data => {
                if (isMounted.current) {
                    setConflicts(data);
                }
            })
    }

    const checkEligibility = () => {
        const customer = getCustomer();

        if (customer && appointment.data.services && appointment.data.services.length > 0) {
            appointment.checkEligibility(customer)
                .then(data => {
                    if (isMounted.current) {
                        const eligibleData = data && data.some(d => d.isEligible) ? data.filter(d => d.isEligible) : null;
                        const ineligibleData = data && data.some(d => !d.isEligible) ? data.filter(d => !d.isEligible) : null;
                        const isMohReady = data && !data.some(e => !e.isMohReady);

                        setEligibles(eligibleData);
                        setIneligibles(ineligibleData);
                        setMohReady(isMohReady);
                    }
                })
        } else {
            setEligibles(null);
            setIneligibles(null);
            setMohReady(null);
        }
    }

    const handleResourceChange = ({ id }) => {
        appointment.data.userId = id;
        appointment.hasUnsavedChanges = true;
        checkConflicts();
    }

    const handleDatePickerDisplay = event => {
        setStartTimePickerOpen(false);
        setEndTimePickerOpen(false);
        if (!datePickerOpen) {
            setDatePickerOpen('date');
        }
    }

    const handleDatePickerChange = value => {
        const newStart = moment(`${moment(value).format('YYYY-MM-DD')} ${appointment.data.start.format('h:mm a')}`, 'YYYY-MM-DD h:mm a');
        appointment.data.start = newStart;
        appointment.hasUnsavedChanges = true;
        checkConflicts();
        checkEligibility();
        setDatePickerOpen(false);
    }

    const handleStartTimePickerDisplay = event => {
        setDatePickerOpen(false);
        setEndTimePickerOpen(false);
        if (!startTimePickerOpen) {
            setStartTimePickerOpen('time');
            startTimePickerTimer.current = setTimeout(() => {
                const picker = document.querySelector('#startTimePicker .rw-state-selected');
                if (picker) {
                    picker.focus();
                }  // UI HACK: need to pass focus to the selected item otherwise mouse scroll and then click will cause focus to trigger.
            }, 300)
        }
    }

    const handleStartTimePickerChange = value => {
        const newStart = moment(`${appointment.data.start.format('YYYY-MM-DD')} ${moment(value).format('h:mm a')}`, 'YYYY-MM-DD h:mm a');
        appointment.data.start = newStart;
        appointment.hasUnsavedChanges = true;
        checkConflicts();
        setStartTimePickerOpen(false);
    }

    const handleEndTimePickerDisplay = event => {
        setDatePickerOpen(false);
        setStartTimePickerOpen(false);
        if (!endTimePickerOpen) {
            setEndTimePickerOpen('time');
            endTimePickerTimer.current = setTimeout(() => {
                const picker = document.querySelector('#endTimePicker .rw-state-selected');
                if (picker) {
                    picker.focus();
                }  // UI HACK: need to pass focus to the selected item otherwise mouse scroll and then click will cause focus to trigger.
            }, 300)
        }
    }

    const handleEndTimePickerChange = value => {
        const start = appointment.data.start.clone();
        const newEnd = moment(`${appointment.end.format('YYYY-MM-DD')} ${moment(value).format('h:mm a')}`, 'YYYY-MM-DD h:mm a');
        const duration = newEnd.diff(start, 'minutes');
        appointment.data.duration = duration;
        appointment.hasUnsavedChanges = true;
        checkConflicts();
        setEndTimePickerOpen(false);
    }

    const handleNewCustomer = event => {
        if (!newCustomer.isReady) {
            newCustomer.initialize(false);
        }

        const bookingData = bk.getBookingPrimaryContact(appointment.booking);
        const extraProps = toJS(bookingData);

        setExistingCustomerData(null);
        setCustomerSearchResult(null);
        setAppointmentNotes([]);
        quickDrawer.activateQuickDrawer('customer', 'create', extraProps, handleNewCustomerSuccess, handleNewCustomerCancel);
    }

    const handleNewCustomerSuccess = customer => {
        setNewCustomerData(customer.data);
        setExistingCustomerData(null);
        setCustomerSearchResult(null);
        setAppointmentNotes([]);
        appointment.hasUnsavedChanges = true;
    }

    const handleNewCustomerCancel = event => {
        if (newCustomerData) {
            newCustomer.data = newCustomerData;
        } else {
            newCustomer.clear();
        }
    }

    const handleUpdateExistingCustomer = event => {
        if (existingCustomerData) {
            updateCustomer.initialize(existingCustomerData.id);
            quickDrawer.activateQuickDrawer('customer', 'personal', null, handleExistingCustomerSuccess, handleExistingCustomerCancel);
        }
    }

    const handleExistingCustomerSuccess = ({ updated }) => {
        if (updated) {
            api.Customers.get(existingCustomerData.id)
                .then(({ data }) => {
                    handleCustomerSearchClick(data);
                })
            appointment.hasUnsavedChanges = true;
        }
        updateCustomer.clear();
    }

    const handleExistingCustomerCancel = event => {
        if (newCustomerData) {
            newCustomer.data = newCustomerData;
        } else {
            updateCustomer.clear();
        }
    }

    const handleCustomerSearchChange = (event, term) => {
        if (newCustomerData || existingCustomerData) {
            setNewCustomerData(null);
            setExistingCustomerData(null);
            setAppointmentNotes([]);
        }

        if (searchTimer.current) {
            clearTimeout(searchTimer.current);
        }

        if (term && term.length >= 2) {
            api.Customers.fullSearch(term, false, false, 5)
                .then(({ data }) => {
                    if (isMounted.current) {
                        setCustomerSearchResult(data.result);
                    }
                })
        } else {
            setCustomerSearchResult(null);
        }
        setEligibles(null);
        setIneligibles(null);
    }

    const handleCustomerSearchClick = customer => {
        const requests = [];

        newAppointmentCustomerFilterRef.current.isLoading(true);
        setCustomerSearchResult(null);

        api.Customers.get(customer.id)
            .then(({ data: customerData }) => {
                requests.push(
                    api.Notes.search({
                        parameters: [
                            {
                                field: 'CustomerId',
                                value: customer.id,
                            },
                            {
                                field: 'DeactivatedDateUtc',
                                value: null,
                            },
                        ],
                    }));

                if (customer.patientProfileId) {
                    requests.push(api.PatientProfiles.get(customer.patientProfileId));
                }

                Promise.all(requests)
                    .then(response => {
                        if (isMounted.current) {
                            const noteData = (response[0].data.result ? response[0].data.result : []).concat(appointmentNotes);
                            const patientProfileData = (customer.patientProfileId && response[1] && response[1].data ? response[1].data : null);
                            const notes = noteData.sort((a, b) => { return new Date(a.createdDateUtc).getTime() - new Date(b.createdDateUtc).getTime() });

                            customerData.patientProfile = patientProfileData;
                            customerData.notes = notes;

                            newAppointmentCustomerFilterRef.current.isLoading(false);

                            setExistingCustomerData(customerData);
                            setNewCustomerData(null);
                            setAppointmentNotes([]);

                            appointment.data.customerId = customerData.id;
                            appointment.hasUnsavedChanges = true;
                        }
                    })
            })
    }

    const handleServiceSelection = (tag, metadata) => {
        switch (metadata.action) {
            case 'insert':
                if (!appointment.data.services.some(s => s.id === metadata.dataItem.id)) {
                    appointment.data.services.push({
                        id: metadata.dataItem.id,
                        code: metadata.dataItem.code,
                        name: metadata.dataItem.code,
                        duration: metadata.dataItem.duration,
                        isRepeatable: metadata.dataItem.isRepeatable,
                    });
                }
                break;

            case 'remove':
                const index = appointment.data.services.findIndex(s => s.id === metadata.dataItem.id);
                if (index !== -1) {
                    appointment.data.services.splice(index, 1);
                }
                break;

            default:
                break;
        }

        appointment.data.duration = appointment.recommendedDuration;
        appointment.hasUnsavedChanges = true;
        checkConflicts();
        checkEligibility();
    }

    const handleDurationChange = event => {
        appointment.data.duration = parseInt(event.target.value, 10);
        appointment.hasUnsavedChanges = true;
        checkConflicts();
    }

    const handleRepeatTypeChange = event => {
        const repeatType = event.target.value;

        appointment.data.repeatType = repeatType;

        if (repeatType) {
            switch (repeatType.toLowerCase()) {
                case 'weekly':
                    appointment.data.repeatUntil = appointment.data.start.clone().add(1, 'weeks');
                    appointment.data.repeatTotal = 2;
                    break;

                case 'biweekly':
                    appointment.data.repeatUntil = appointment.data.start.clone().add(2, 'weeks');
                    appointment.data.repeatTotal = 2;
                    break;

                case 'monthly':
                    appointment.data.repeatUntil = appointment.data.start.clone().add(1, 'months');
                    appointment.data.repeatTotal = 2;
                    break;

                case 'yearly':
                    appointment.data.repeatUntil = appointment.data.start.clone().add(1, 'years');
                    appointment.data.repeatTotal = 2;
                    break;

                default:
                    appointment.data.repeatUntil = null;
                    appointment.data.repeatTotal = null;
            }
        } else {
            appointment.data.repeatUntil = null;
            appointment.data.repeatTotal = null;
        }
        appointment.hasUnsavedChanges = true;
    }

    const handleRepeatUtilChange = value => {
        let total = 2;
        const start = isMoment(appointment.data.start) ? appointment.data.start.clone() : moment(appointment.data.start);
        const until = value ? moment(value) : start.clone();

        if (appointment.data.repeatType) {
            switch (appointment.data.repeatType.toLowerCase()) {
                case 'weekly':
                    total = Math.floor(until.clone().diff(start.clone(), 'week')) + 1;
                    break;

                case 'biweekly':
                    total = Math.floor((until.clone().diff(start.clone(), 'week')) / 2) + 1;
                    break;

                case 'monthly':
                    total = Math.floor(until.clone().diff(start.clone(), 'month')) + 1;
                    break;

                case 'yearly':
                    total = Math.floor(until.clone().diff(start.clone(), 'year')) + 1;
                    break;

                default:
                    break;
            }
        }

        appointment.data.repeatUntil = until;
        appointment.data.repeatTotal = total;
        appointment.hasUnsavedChanges = true;
    }

    const handleRepeatTotalChange = event => {
        let until = null;
        const total = event.target.value ? parseInt(event.target.value) : 0;

        if (total && appointment.data.repeatType) {
            switch (appointment.data.repeatType.toLowerCase()) {
                case 'weekly':
                    until = appointment.data.start.clone().add((total - 1), 'weeks');
                    break;

                case 'biweekly':
                    until = appointment.data.start.clone().add(((total - 1) * 2), 'weeks');
                    break;

                case 'monthly':
                    until = appointment.data.start.clone().add((total - 1), 'months');
                    break;

                case 'yearly':
                    until = appointment.data.start.clone().add((total - 1), 'years');
                    break;

                default:
                    break;
            }
        }

        appointment.data.repeatUntil = until;
        appointment.data.repeatTotal = total;
        appointment.hasUnsavedChanges = true;
    }

    const handleSubmit = event => {
        event.preventDefault();

        if (fn.validateForm(validateRef.current)) {
            const customer = getCustomer();

            appointment.checkEligibility(customer, true)
                .then(eligibilityData => {
                    if (isMounted.current) {
                        const eligibleData = eligibilityData && eligibilityData.some(d => d.isEligible) ? eligibilityData.filter(d => d.isEligible) : null;
                        const ineligibleData = eligibilityData && eligibilityData.some(d => !d.isEligible) ? eligibilityData.filter(d => !d.isEligible) : null;

                        setEligibles(eligibleData);
                        setIneligibles(ineligibleData);

                        if (!ineligibleData) {
                            appointment.checkConflicts(true)
                                .then(conflictData => {
                                    if (isMounted.current) {
                                        if (!conflictData) {
                                            handleCommitUpdate(event);
                                        } else {
                                            setConfirmConflicts(true);
                                        }
                                    }
                                })
                        } else {
                            setConfirmIneligibles(true);
                        }
                    }
                })
        }
    }

    const handleCommitUpdate = event => {
        if (newCustomerData) {
            newCustomer.saveToServer = true;
            newCustomer.hasUnsavedChanges = true;

            if (newCustomer.saveToServer && hasNewAttachments(newCustomerData)) {
                setIsUploadingAttachments(true);
            }

            newCustomer.save(true)
                .then(() => {
                    if (isMounted.current) {
                        appointment.data.customerId = newCustomer.data.id;
                        appointment.save(true)
                            .then(data => {
                                if (isMounted.current) {
                                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                                        props.onSuccess(event, { updated: true, data: data });
                                    }
                                    setIsUploadingAttachments(false);
                                }
                            })
                    }
                })
                .finally(() => {
                    if (isMounted.current) {
                        newCustomer.saveToServer = false;
                    }
                })
        } else if (existingCustomerData) {
            if (hasNewAttachments(existingCustomerData)) {
                setIsUploadingAttachments(true);
            }

            appointment.data.customerId = existingCustomerData.id;
            appointment.hasUnsavedChanges = true;
            appointment.save(true)
                .then(data => {
                    if (isMounted.current) {
                        if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                            props.onSuccess(event, { updated: true, data: data });
                        }
                        setIsUploadingAttachments(false);
                    }
                })
        }
    }

    const handleIneligibleConfirm = event => {
        appointment.checkConflicts(true)
            .then(conflicts => {
                if (isMounted.current) {
                    if (!conflicts) {
                        handleCommitUpdate(event);
                    } else {
                        setConfirmConflicts(true);
                    }
                }
            })

        setConfirmIneligibles(false);
    }

    const handleIneligibleCancel = event => {
        setConfirmIneligibles(false);
    }

    const handleConflictsConfirm = event => {
        handleCommitUpdate(event);
        setConfirmConflicts(false);
    }

    const handleConflictsCancel = event => {
        setConfirmConflicts(false);
    }

    const hasNewAttachments = customer => {
        return customer.notes && customer.notes.length > 0 && customer.notes.some(n => n.attachments && n.attachments.length > 0 && n.attachments.some(a => a.base64));
    }

    const getCustomer = () => {
        return (newCustomerData ? newCustomerData : existingCustomerData);
    }

    const getAppointmentDateTime = () => {
        if (!appointment.data.start) return null;

        const start = appointment.data.start.clone();
        const end = appointment.end ? appointment.end.clone() : null;
        const hasEndTime = !!end;
        const weekday = appointment.data.start.format('dddd');
        const day = start.format('D');
        const ordinal = start.format('Do').replace(day, '');
        const dateHtml = `${start.format('MMMM D')}<sup>${ordinal}</sup>${((start.year() !== moment().year()) ? `, ${start.format('YYYY')}` : '')}`;
        const startTimeHtml = `${start.format('h:mm')}${(!hasEndTime || start.format('a') !== end.format('a') ? ` ${start.format('a')}` : '')}`;
        const endTimeHtml = hasEndTime ? `${end.format('h:mm')} ${end.format('a')}` : '';

        return <ul className='list-inline no-style m-0'>
            <li className='list-inline-item m-0' onClick={handleDatePickerDisplay}>
                <div className='control transparent'>
                    <div
                        className={'control-overlay' + (datePickerOpen ? '' : ' d-none')}
                        onClick={() => { setDatePickerOpen(false) }}
                    ></div>
                    <DateTimePicker
                        id='datePicker'
                        defaultOpen={false}
                        open={datePickerOpen}
                        dateFormat={dt => String(dt.getDate())}
                        dayFormat={day => ['S', 'M', 'T', 'W', 'T', 'F', 'S'][day.getDay()]}
                        views={['month', 'year']}
                        value={appointment.data.start.toDate()}
                        footer={false}
                        date={true}
                        time={false}
                        onSelect={handleDatePickerChange}
                    />
                </div>
                <div className={'text' + (datePickerOpen ? ' active' : '')}>
                    <small className='weekday'>{weekday}</small>
                    <span className='date' dangerouslySetInnerHTML={{ __html: dateHtml }}></span>
                </div>
            </li>
            <li className='list-inline-item my-0 mx-1'><small>@</small></li>
            <li className='list-inline-item m-0' onClick={handleStartTimePickerDisplay}>
                <div className='control transparent'>
                    <div
                        className={'control-overlay' + (startTimePickerOpen ? '' : ' d-none')}
                        onClick={() => { setStartTimePickerOpen(false) }}
                    ></div>
                    <DateTimePicker
                        id='startTimePicker'
                        defaultOpen={false}
                        open={startTimePickerOpen}
                        value={appointment.startTime.toDate()}
                        step={5}
                        date={false}
                        time={true}
                        onSelect={handleStartTimePickerChange}
                    />
                </div>
                <div className={'text' + (startTimePickerOpen ? ' active' : '')}>
                    <span className='time' dangerouslySetInnerHTML={{ __html: startTimeHtml }}></span>
                </div>
            </li>
            {
                hasEndTime ? <>
                    <li className='list-inline-item my-0 mx-1'><small>to</small></li>
                    <li className='list-inline-item m-0' onClick={handleEndTimePickerDisplay}>
                        <div className='control transparent'>
                            <div
                                className={'control-overlay' + (endTimePickerOpen ? '' : ' d-none')}
                                onClick={() => { setEndTimePickerOpen(false) }}
                            ></div>
                            <DateTimePicker
                                id='endTimePicker'
                                defaultOpen={false}
                                open={endTimePickerOpen}
                                value={appointment.endTime.toDate()}
                                step={5}
                                date={false}
                                time={true}
                                onSelect={handleEndTimePickerChange}
                            />
                        </div>
                        <div className={'text' + (endTimePickerOpen ? ' active' : '')}>
                            <span className='time' dangerouslySetInnerHTML={{ __html: endTimeHtml }}></span>
                        </div>
                    </li>
                </> : null
            }
        </ul>
    }

    const getRepeatDescription = ({ key, value }) => {
        if (!key) return null;

        switch (key.toLowerCase()) {
            case 'weekly':
                return `Every ${appointment.data.start.format('dddd')}`;

            case 'biweekly':
                return `Every other ${appointment.data.start.format('dddd')}`;

            case 'monthly':
                return `Every month on the first ${appointment.data.start.format('dddd')} after ${appointment.data.start.format('Do')}`;

            case 'yearly':
                return `Every year on the first ${appointment.data.start.format('dddd')} after ${appointment.data.start.format('MMMM Do')}`;

            default:
                return value;
        }
    }

    const renderNewCustomer = () => {
        return <div
            className='profile-wrapper pr-0'
        >
            <div className='profile'>
                <span
                    className={`profile-image profile-initials rounded-circle d-flex text-white bg-info-700 fw-500`}
                    title={ph.getFullName(newCustomerData)}
                >
                    {`${newCustomerData.firstName[0]}${newCustomerData.lastName[0]}`.toUpperCase()}
                </span>
            </div>
            <div className='description flex-1'>
                <div>{bh.renderAppointmentNew()}</div>
                <span
                    className='name text-gray-700'
                >
                    {ph.getFullName(newCustomerData, true)}
                    {
                        newCustomerData.dateOfBirth || newCustomerData.sex || newCustomerData.gender || newCustomerData.pronoun ?
                            <small className='text-nowrap ml-2'>({`${ph.getAge(newCustomerData.dateOfBirth, moment(appointment.data.start))} ${ph.getSexGenderPronounDisplay(newCustomerData)}`.trim()})</small> : null
                    }
                </span>
                {
                    newCustomerData.address && newCustomerData.address.text ?
                        <div className='info text-truncate text-truncate-md'>{newCustomerData.address.text}</div> : null
                }
                {
                    newCustomerData.emailAddress ?
                        <div className='info'>
                            <a
                                href={`mailto:${newCustomerData.emailAddress}`}
                            >{newCustomerData.emailAddress}
                            </a>
                        </div> : null
                }
                {
                    newCustomerData.phone ?
                        <div className='info'>
                            <a
                                href={`tel:${newCustomerData.phone}`}
                            >{sys.getFormattedPhoneNumber(newCustomerData.phone)}
                            </a>
                        </div> : null
                }
                {
                    newCustomerData.primaryContactPerson ?
                        <div className='mt-2 border-left border-3 border-gray-300 pl-3 py-1'>
                            <small className='text-primary-400 fs-75 text-uppercase d-block'>Primary Contact</small>
                            {
                                newCustomerData.primaryContactPerson.id ?
                                    <span
                                        className='d-block fs-lg text-gray-700 text-info-hover'
                                    >
                                        {ph.getPreferredFirstLastName(newCustomerData.primaryContactPerson)}
                                        {
                                            newCustomerData.primaryContactPerson.relationship ?
                                                <small className='ml-1'>(<span className='fw-500 fs-90 text-success-700'>{newCustomerData.primaryContactPerson.relationship}</span>)</small> : null
                                        }
                                    </span> :
                                    <div className='fs-lg text-gray-700 '>{ph.getPreferredFirstLastName(newCustomerData.primaryContactPerson)}
                                        {
                                            newCustomerData.primaryContactPerson.relationship ?
                                                <small className='ml-1'>(<span className='fw-500 fs-90 text-success-700'>{newCustomerData.primaryContactPerson.relationship}</span>)</small> : null
                                        }
                                    </div>
                            }
                            {
                                newCustomerData.primaryContactPerson.emailAddress ?
                                    <div className='info'>
                                        <a
                                            href={`mailto:${newCustomerData.primaryContactPerson.emailAddress}`}
                                        >{newCustomerData.primaryContactPerson.emailAddress}
                                        </a>
                                    </div> : null
                            }
                            {
                                newCustomerData.primaryContactPerson.phone ?
                                    <div className='info'>
                                        <a
                                            href={`tel:${newCustomerData.primaryContactPerson.phone}`}
                                        >{sys.getFormattedPhoneNumber(newCustomerData.primaryContactPerson.phone)}
                                        </a>
                                    </div> : null
                            }
                        </div> : null
                }
            </div>
            <div className='fs-sm pl-2'>
                <div className='mb-2'>
                    <button
                        type='button'
                        className='btn btn-icon line-height-1 mr-n2'
                        title={`Update ${ph.getFullName(newCustomerData, true)}`}
                        onClick={handleNewCustomer}
                    >
                        <i className='fal fa-pen fs-lg'></i>
                    </button>
                </div>
                <div>
                    <button
                        type='button'
                        className='btn btn-icon line-height-1 mr-n2'
                        title='Change customer'
                        onClick={() => { setNewCustomerData(null) }}
                    >
                        <i className='fal fa-times fs-lg text-danger'></i>
                    </button>
                </div>
            </div>
        </div>
    }

    const renderExistingCustomer = () => {
        return <div
            className='profile-wrapper pr-0'
        >
            <div className='profile'>
                <span
                    className={`profile-image profile-initials rounded-circle d-flex text-white ${ch.getProfileColor(existingCustomerData)} fw-500`}
                    title={ph.getFullName(existingCustomerData)}
                >
                    {existingCustomerData.initials}
                </span>
            </div>
            <div className='description flex-1'>
                <span
                    className='name text-gray-700'
                >
                    {ph.getFullName(existingCustomerData, true)}
                    {
                        existingCustomerData.dateOfBirth || existingCustomerData.sex || existingCustomerData.gender || existingCustomerData.pronoun ?
                            <small className='text-nowrap ml-2'>({`${ph.getAge(existingCustomerData.dateOfBirth, moment(appointment.data.start))} ${ph.getSexGenderPronounDisplay(existingCustomerData)}`.trim()})</small> : null
                    }
                </span>
                {
                    existingCustomerData.address && existingCustomerData.address.country ?
                        <div className='info'>{ah.getAddressHtml(existingCustomerData.address)}</div> : null
                }
                {
                    existingCustomerData.emailAddress ?
                        <div className='info'>
                            <a
                                href={`mailto:${existingCustomerData.emailAddress}`}
                            >{existingCustomerData.emailAddress}
                            </a>
                        </div> : null
                }
                {
                    existingCustomerData.phoneNumber ?
                        <div className='info'>
                            <a
                                href={`tel:${existingCustomerData.phoneNumber}`}
                            >{sys.getFormattedPhoneNumber(existingCustomerData.phoneNumber)}
                            </a>
                        </div> : null
                }
                {
                    existingCustomerData.primaryContactPerson ?
                        <div className='mt-2 border-left border-3 border-gray-300 pl-3 py-1'>
                            <small className='text-primary-400 fs-75 text-uppercase d-block'>Primary Contact</small>
                            {
                                existingCustomerData.primaryContactPerson.id ?
                                    <span
                                        className='d-block fs-lg text-gray-700 text-info-hover'
                                    >
                                        {ph.getPreferredFirstLastName(existingCustomerData.primaryContactPerson)}
                                        {
                                            existingCustomerData.primaryContactPerson.relationship ?
                                                <small className='ml-1'>(<span className='fw-500 fs-90 text-success-700'>{existingCustomerData.primaryContactPerson.relationship}</span>)</small> : null
                                        }
                                    </span> :
                                    <div className='fs-lg text-gray-700 '>{ph.getPreferredFirstLastName(existingCustomerData.primaryContactPerson)}
                                        {
                                            existingCustomerData.primaryContactPerson.relationship ?
                                                <small className='ml-1'>(<span className='fw-500 fs-90 text-success-700'>{existingCustomerData.primaryContactPerson.relationship}</span>)</small> : null
                                        }
                                    </div>
                            }
                            {
                                existingCustomerData.primaryContactPerson.emailAddress ?
                                    <div className='info'>
                                        <a
                                            href={`mailto:${existingCustomerData.primaryContactPerson.emailAddress}`}
                                        >{existingCustomerData.primaryContactPerson.emailAddress}
                                        </a>
                                    </div> : null
                            }
                            {
                                existingCustomerData.primaryContactPerson.phoneNumber ?
                                    <div className='info'>
                                        <a
                                            href={`tel:${existingCustomerData.primaryContactPerson.phoneNumber}`}
                                        >{sys.getFormattedPhoneNumber(existingCustomerData.primaryContactPerson.phoneNumber)}
                                        </a>
                                    </div> : null
                            }
                        </div> : null
                }
            </div>
            <div className='fs-sm pl-2'>
                <div className='mb-2'>
                    <button
                        type='button'
                        className='btn btn-icon line-height-1 mr-n2'
                        title={`Update ${ph.getFullName(existingCustomerData, true)}`}
                        onClick={handleUpdateExistingCustomer}
                    >
                        <i className='fal fa-pen fs-lg'></i>
                    </button>
                </div>
                <div>
                    <button
                        type='button'
                        className='btn btn-icon line-height-1 mr-n2'
                        title='Change customer'
                        onClick={() => { setExistingCustomerData(null) }}
                    >
                        <i className='fal fa-times fs-lg text-danger'></i>
                    </button>
                </div>
            </div>
        </div>
    }

    const handleDeleteBooking = () => {
        setConfirmDelete(true);
    }

    const handleConfirmDeleteBooking = async (e) => {
        updateBooking.initialize(appointment.booking);
        updateBooking.delete();
        setConfirmDelete(null);
        if (fn.isFunction(props.onCancel)) {
            props.onCancel();
        }
    }

    const handleConfirmDeleteBookingCancel = () => {
        setConfirmDelete(null);
    }

    const renderServices = () => {
        const filteredServices = services.filter(s =>
            (!s.activeStartingDate || moment(s.activeStartingDate).isSameOrBefore(moment(appointment.data.start))) &&
            appointment.data.services.filter(a => a.id === s.id).length === 0);

        return filteredServices.map(s => {
            return {
                id: s.id,
                name: `${s.code} - ${s.name}`,
                code: s.code,
                colorHexValue: s.colorHexValue,
                duration: (s.defaultDurationInMinutes ? s.defaultDurationInMinutes : 0),
                isRepeatable: s.isRepeatable,
            }
        })
    }

    return <>
        {
            (datePickerOpen || startTimePickerOpen || endTimePickerOpen) ?
                <GlobalHotKeys
                    keyMap={{
                        closeAllPickers: ['esc'],
                    }}
                    handlers={{
                        closeAllPickers: event => {
                            setDatePickerOpen(false);
                            setStartTimePickerOpen(false);
                            setEndTimePickerOpen(false);
                        }
                    }}
                    allowChanges={true}
                /> :
                <>
                    {
                        (props.drawer === quickDrawer.drawerOpened) && !confirmConflicts ?
                            <GlobalHotKeys
                                keyMap={{
                                    close: ['esc'],
                                }}
                                handlers={{
                                    close: event => {
                                        handleCancel(event)
                                    },
                                }}
                                allowChanges={true}
                            /> : null
                    }
                </>
        }
        <form ref={validateRef} onSubmit={handleSubmit}>
            <fieldset disabled={newCustomer.isSaving || appointment.isSaving}>
                <div className='quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={oh.getIcon('appointment', 'new')}
                        action='Convert Booking To'
                        category='Appointment'
                        className='appointments'
                        onCancel={handleCancel}
                    />
                    <div className='quick-drawer-body'>
                        {
                            isReady ?
                                <FadeIn>
                                    <div className='new-appointment body-content'>
                                        {
                                            eligibles && cache.resources.filter(r => r.id === appointment.data.userId)[0] ?
                                                <section>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            {
                                                                mohReady === true ?
                                                                    <div className='alert alert-info p-3 mb-0' role='alert'>
                                                                        <strong className='d-block mb-2'>Information</strong>
                                                                        <ul className='pl-3 mb-0'>
                                                                            {
                                                                                eligibles.map((i, ii) => {
                                                                                    return <li key={`new_appointment_eligible_${ii}`}>{bh.renderServiceCode(i.service, 'float-left mr-1')} Eligibility confirmed.</li>
                                                                                })
                                                                            }
                                                                        </ul>
                                                                    </div> :
                                                                    <>
                                                                        {
                                                                            mohReady === false ?
                                                                                <div className={'alert alert-warning p-3 mb-0'} role='alert'>
                                                                                    <strong className='d-block mb-2'>Warning</strong>
                                                                                    <ul className='pl-3 mb-0'>
                                                                                        <li><strong className='text-warning-900'>{auth.currentTenant.publicInsuranceUnitId} verification has not been setup.</strong></li>
                                                                                        {
                                                                                            eligibles.filter(e => e.isAgeChecked).map((i, ii) => {
                                                                                                return <li key={`view_appointment_eligible_${ii}`}>{bh.renderServiceCode(i.service, 'float-left mr-1')} Age eligibility confirmed.</li>
                                                                                            })
                                                                                        }
                                                                                        {
                                                                                            conflicts ?
                                                                                                <>
                                                                                                    {
                                                                                                        !!getCustomer() && conflicts.upcoming ?
                                                                                                            <li>{ph.getPreferredFirstName(getCustomer())} has an upcoming paid appointment.</li> : null
                                                                                                    }
                                                                                                    {
                                                                                                        conflicts.appointment ?
                                                                                                            <li>{cache.resources.filter(r => r.id === appointment.data.userId)[0].shortName} has an appointment at the same time.</li> : null
                                                                                                    }
                                                                                                    {
                                                                                                        conflicts.schedule ?
                                                                                                            <li>{cache.resources.filter(r => r.id === appointment.data.userId)[0].shortName} is not scheduled to work at this time.</li> : null
                                                                                                    }
                                                                                                    {
                                                                                                        !conflicts.schedule && conflicts.businessDay ?
                                                                                                            <li>This is outside of business hours.</li> : null
                                                                                                    }
                                                                                                    {
                                                                                                        conflicts.break ?
                                                                                                            <li>{cache.resources.filter(r => r.id === appointment.data.userId)[0].shortName} is scheduled to be on break at this time.</li> : null
                                                                                                    }
                                                                                                    {
                                                                                                        conflicts.timeOff ?
                                                                                                            <li>{cache.resources.filter(r => r.id === appointment.data.userId)[0].shortName} is scheduled to be away on this day.</li> : null
                                                                                                    }

                                                                                                </> : null
                                                                                        }
                                                                                    </ul>
                                                                                </div> : null
                                                                        }
                                                                    </>
                                                            }
                                                        </div>
                                                    </div>
                                                </section> : null
                                        }
                                        {
                                            (conflicts || ineligibles) && mohReady !== false && cache.resources.filter(r => r.id === appointment.data.userId)[0] ?
                                                <section>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='alert alert-warning p-3 mb-0' role='alert'>
                                                                <strong className='d-block mb-2'>Warning</strong>
                                                                <ul className='pl-3 mb-0'>
                                                                    {
                                                                        ineligibles ?
                                                                            <>
                                                                                {
                                                                                    ineligibles.map((i, ii) => {
                                                                                        return <li key={`new_appointment_ineligible_${ii}`}>{bh.renderServiceCode(i.service, 'float-left mr-1')} {i.earliestEligibleDate ? <>Not eligible until <span className='tt-underline'>{moment(i.earliestEligibleDate).format('YYYY-MM-DD')}</span>.</> : (i.ineligibilityReason ? `${(!!i.ineligibilityCode ? `(Error: ${i.ineligibilityCode}) ` : '')}${i.ineligibilityReason}` : 'Not eligible. Reason unknown.')}</li>
                                                                                    })
                                                                                }
                                                                            </> : null
                                                                    }
                                                                    {
                                                                        conflicts ?
                                                                            <>
                                                                                {
                                                                                    !!getCustomer() && conflicts.upcoming ?
                                                                                        <li>{ph.getPreferredFirstName(getCustomer())} has an upcoming paid appointment.</li> : null
                                                                                }
                                                                                {
                                                                                    conflicts.appointment ?
                                                                                        <li>{cache.resources.filter(r => r.id === appointment.data.userId)[0].shortName} has another appointment at the same time.</li> : null
                                                                                }
                                                                                {
                                                                                    conflicts.schedule ?
                                                                                        <li>{cache.resources.filter(r => r.id === appointment.data.userId)[0].shortName} is not scheduled to work at this time.</li> : null
                                                                                }
                                                                                {
                                                                                    !conflicts.schedule && conflicts.businessDay ?
                                                                                        <li>This is outside of business hours.</li> : null
                                                                                }
                                                                                {
                                                                                    conflicts.break ?
                                                                                        <li>{cache.resources.filter(r => r.id === appointment.data.userId)[0].shortName} is scheduled to be on break at this time.</li> : null
                                                                                }
                                                                                {
                                                                                    conflicts.timeOff ?
                                                                                        <li>{cache.resources.filter(r => r.id === appointment.data.userId)[0].shortName} is scheduled to be away on this day.</li> : null
                                                                                }

                                                                            </> : null
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section> : null
                                        }
                                        {
                                            existingCustomerData && existingCustomerData.hasOutstandingBalance ?
                                                <section>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='alert alert-danger p-3 mb-0' role='alert'>
                                                                <strong className='d-block mb-2'>Important</strong>
                                                                <ul className='pl-3 mb-0'>
                                                                    <li>{ph.getPreferredFirstName(existingCustomerData)} an outstanding balance on file.</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section> : null
                                        }
                                        <section className='date-time'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <Observer>{() => getAppointmentDateTime()}</Observer>
                                                </div>
                                            </div>
                                        </section>
                                        <section className='resource'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-0'>
                                                        <label><small>With</small></label>
                                                        <Observer>{() =>
                                                            <DropdownList
                                                                data={cache.resources}
                                                                valueField='id'
                                                                value={appointment.data.userId}
                                                                itemComponent={({ item }) => (
                                                                    <div
                                                                        className='profile-wrapper'
                                                                    >
                                                                        <div className='profile'>
                                                                            <span
                                                                                className={`profile-image rounded-circle d-block fw-500` + (item && !item.profilePictureUri ? ` profile-initials bg-color${item.color}-500` : '')}
                                                                                style={item && item.profilePictureUri ? {
                                                                                    backgroundImage: `url(${item.profilePictureUri})`,
                                                                                    backgroundSize: 'cover',
                                                                                } : null}
                                                                                title={item ? item.fullName : 'System'}>
                                                                                {!item.profilePictureUri ? <div className='d-initials fs-xs'>{item.initials}</div> : null}
                                                                            </span>
                                                                        </div>
                                                                        <span className='description'>{item.fullName}</span>
                                                                    </div>
                                                                )}
                                                                valueComponent={({ item }) => (
                                                                    item ?
                                                                        <div
                                                                            className='profile-wrapper'
                                                                        >
                                                                            <div className='profile'>
                                                                                <span
                                                                                    className={`profile-image rounded-circle d-block fw-500` + (item && !item.profilePictureUri ? ` profile-initials bg-color${item.color}-500` : '')}
                                                                                    style={item && item.profilePictureUri ? {
                                                                                        backgroundImage: `url(${item.profilePictureUri})`,
                                                                                        backgroundSize: 'cover',
                                                                                    } : null}
                                                                                    title={item ? item.fullName : 'System'}>
                                                                                    {item && !item.profilePictureUri ? <div className='d-initials fs-xs'>{item.initials}</div> : null}
                                                                                </span>
                                                                            </div>
                                                                            <span className='description'>{item.fullName}</span>
                                                                        </div> : null
                                                                )}
                                                                onChange={handleResourceChange}
                                                            />}</Observer>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section className='booking-info'>
                                            <div className='row mb-2'>
                                                <div className='col-6'>
                                                    <span className='category'>Booking Information</span>
                                                </div>
                                            </div>
                                            <div className='row mb-2'>
                                                <div className='col-6'>
                                                    <small className="text-gray-500 text-uppercase fw-500 d-block">First Name</small>
                                                    {bk.getBookingPrimaryContact(appointment.booking).firstName}
                                                </div>
                                                <div className='col-6'>
                                                    <small className="text-gray-500 text-uppercase fw-500 d-block">Last Name</small>
                                                    {bk.getBookingPrimaryContact(appointment.booking).lastName}
                                                </div>
                                            </div>
                                            {
                                                (
                                                    bk.getBookingPrimaryContact(appointment.booking).dateOfBirth ||
                                                    bk.getBookingPrimaryContact(appointment.booking).sex ||
                                                    bk.getBookingPrimaryContact(appointment.booking).gender ||
                                                    bk.getBookingPrimaryContact(appointment.booking).pronoun
                                                ) ?
                                                    <>
                                                        <div className='row mb-2'>
                                                            {
                                                                bk.getBookingPrimaryContact(appointment.booking).dateOfBirth ?
                                                                    <div className='col-6'>
                                                                        <small className="text-gray-500 text-uppercase fw-500 d-block">Date of Birth</small>
                                                                        {sys.getFormattedLongDate(bk.getBookingPrimaryContact(appointment.booking).dateOfBirth)}
                                                                    </div> : null
                                                            }
                                                            {
                                                                (
                                                                    (
                                                                        bk.getBookingPrimaryContact(appointment.booking).sex ||
                                                                        bk.getBookingPrimaryContact(appointment.booking).gender ||
                                                                        bk.getBookingPrimaryContact(appointment.booking).pronoun
                                                                    ) ?
                                                                        <div className='col'>
                                                                            <small className="text-gray-500 text-uppercase fw-500 d-block">Sex | Gender | Pronoun</small>
                                                                            {bk.getBookingPrimaryContact(appointment.booking).sex ? bk.getBookingPrimaryContact(appointment.booking).sex : '--'} | {bk.getBookingPrimaryContact(appointment.booking).gender ? bk.getBookingPrimaryContact(appointment.booking).gender : '--'} | {bk.getBookingPrimaryContact(appointment.booking).pronoun ? bk.getBookingPrimaryContact(appointment.booking).pronoun : '--'}
                                                                        </div> : null
                                                                )
                                                            }
                                                        </div>
                                                    </> : null
                                            }
                                            <div className='row mb-2'>
                                                <div className='col-12'>
                                                    <small className="text-gray-500 text-uppercase fw-500 d-block">Type of Appointment</small>
                                                    {bk.getBookingPrimaryContact(appointment.booking).appointmentType.name}
                                                </div>
                                            </div>
                                            <div className='row mb-2'>
                                                <div className='col'>
                                                    <small className="text-gray-500 text-uppercase fw-500 d-block">Email</small>
                                                    {bk.getBookingPrimaryContact(appointment.booking).emailAddress}
                                                </div>
                                                {
                                                    bk.getBookingPrimaryContact(appointment.booking).phoneNumber ?
                                                        <div className='col'>
                                                            <small className="text-gray-500 text-uppercase fw-500 d-block">Phone</small>
                                                            {sys.getFormattedPhoneNumber(bk.getBookingPrimaryContact(appointment.booking).phoneNumber)}
                                                        </div> : null
                                                }
                                            </div>
                                            <div className='row mb-2'>
                                                <div className='col-6'>
                                                    <small className="text-gray-500 text-uppercase fw-500 d-block">New or Return</small>
                                                    {bk.getBookingPrimaryContact(appointment.booking).isNewOrReturn ? bk.getBookingPrimaryContact(appointment.booking).isNewOrReturn : 'NotSure'}
                                                </div>
                                            </div>
                                            <div className='row mb-2'>
                                                <div className='col-6'>
                                                    <small className="text-gray-500 text-uppercase fw-500 d-block">Message</small>
                                                    <span dangerouslySetInnerHTML={{
                                                        __html:
                                                            bk.getBookingPrimaryContact(appointment.booking).message ?
                                                                fn.plainTextToHtml(bk.getBookingPrimaryContact(appointment.booking).message) : '--'
                                                    }}></span>
                                                </div>
                                            </div>


                                            {   // User-defined questions and answers
                                                appointment.booking.items[0].userDefinedQuestionAnswers ?
                                                    appointment.booking.items[0].userDefinedQuestionAnswers.filter(a => a.value).map(ans =>
                                                        <div className='row mb-2' key={ans.id}>
                                                            <div className='col-12'>
                                                                <small className="text-gray-500 text-uppercase fw-500 d-block">{ans.descriptionHtml}</small>
                                                                {Array.isArray(ans.value) ?
                                                                    <ul style={{ margin: '0 0 0 1em', padding: '0' }}>
                                                                        {ans.value.map((v) => <li>{v}</li>)}
                                                                    </ul> : ans.value}
                                                            </div>
                                                            <div>
                                                                {ans.otherText}
                                                            </div>
                                                        </div>
                                                    )
                                                    : null
                                            }
                                            {   // Additional Info
                                                appointment.booking.note ?
                                                    <div className='row mb-2'>
                                                        <div className='col-12'>
                                                            <small className="text-gray-500 text-uppercase fw-500 d-block">Additional Info</small>
                                                            {appointment.booking.note}
                                                        </div>
                                                    </div> : null
                                            }
                                        </section>
                                        <section className='customer'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <Observer>{() =>
                                                        <div className='form-group mb-0 validate validate-required'>
                                                            <label className='required' htmlFor='new-appointment-input'><small>For</small></label>
                                                            {
                                                                !newCustomerData && !existingCustomerData ?
                                                                    <>
                                                                        <div className='dropdown'>
                                                                            <CustomerFilter
                                                                                ref={newAppointmentCustomerFilterRef}
                                                                                id='new-appointment-input'
                                                                                delay={500}
                                                                                onChange={handleCustomerSearchChange}
                                                                            />
                                                                            <ul className='dropdown-menu show' data-customer-search-result>
                                                                                {
                                                                                    customerSearchResult && customerSearchResult.length > 0 ?
                                                                                        customerSearchResult.slice(0, 5).map((s, si) => {
                                                                                            return <li
                                                                                                key={`search_result_${si}`}
                                                                                                className={'dropdown-menu-item'}>
                                                                                                <div
                                                                                                    className='profile-wrapper'
                                                                                                    onClick={() => handleCustomerSearchClick(s)}
                                                                                                >
                                                                                                    <div className='profile d-flex flex-column justify-content-center align-content-center'>
                                                                                                        <span
                                                                                                            className={`profile-image profile-image-md profile-initials rounded-circle fs-xs text-white ${ch.getProfileColor(s)}`}
                                                                                                            title={ph.getPreferredFullName(s)}
                                                                                                        >
                                                                                                            {s.initials}
                                                                                                        </span>
                                                                                                    </div>
                                                                                                    <div className='description'>
                                                                                                        <div
                                                                                                            className='fw-500 text-gray-700'
                                                                                                            title={s.dateOfBirth ? `DOB: ${moment(s.dateOfBirth).format('YYYY-MM-DD')}` : null}
                                                                                                        >
                                                                                                            {ph.getLastFirstName(s, true)}
                                                                                                            {
                                                                                                                s.dateOfBirth || s.sex || s.gender || s.pronoun ?
                                                                                                                    <small className='ml-2 text-gray'>({`${ph.getAge(s.dateOfBirth)} ${ph.getSexGenderPronounDisplay(s)}`.trim()})</small> : null
                                                                                                            }
                                                                                                        </div>
                                                                                                        {
                                                                                                            s.address && s.address.country ?
                                                                                                                <div className='fs-95 info text-truncate text-truncate-xl'>{s.address.fullLine}</div> : null
                                                                                                        }
                                                                                                        {
                                                                                                            s.dateOfBirth ?
                                                                                                                <div className='fs-90 info text-truncate text-truncate-xl'>DOB: {fn.formatDate(s.dateOfBirth)}</div> : null
                                                                                                        }
                                                                                                        {
                                                                                                            s.emailAddress ?
                                                                                                                <div className='fs-90 text-info text-truncate text-truncate-xl'>{s.emailAddress}</div> : null
                                                                                                        }
                                                                                                        {
                                                                                                            s.primaryPhoneNumber ?
                                                                                                                <div className='fs-90 text-info text-truncate text-truncate-xl'>{sys.getFormattedPhoneNumber(s.primaryPhoneNumber.number)}</div> : null
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            </li>
                                                                                        }) : null
                                                                                }
                                                                            </ul>
                                                                        </div>
                                                                        <button
                                                                            type='button'
                                                                            className='btn btn-link px-0'
                                                                            onClick={handleNewCustomer}
                                                                        >Add new customer</button>
                                                                    </> : null
                                                            }
                                                            {
                                                                newCustomerData && !existingCustomerData ?
                                                                    <>
                                                                        {renderNewCustomer()}
                                                                    </> : null
                                                            }
                                                            {
                                                                !newCustomerData && existingCustomerData ?
                                                                    <>
                                                                        {renderExistingCustomer()}
                                                                    </> : null
                                                            }
                                                        </div>
                                                    }</Observer>
                                                </div>
                                            </div>
                                        </section>
                                        <section className='services'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <Observer>{() =>
                                                        <div className='form-group mb-0 validate validate-required'>
                                                            <label className='required' htmlFor='new-appointment-services'><small>Services</small></label>
                                                            <Multiselect
                                                                data-appointment-services
                                                                allowCreate={false}
                                                                data={renderServices()}
                                                                defaultValue={appointment.data.services}
                                                                valueField='id'
                                                                textField='name'
                                                                tagComponent={({ item }) => (
                                                                    <span
                                                                        className='tag'
                                                                        style={{
                                                                            backgroundColor: item.colorHexValue,
                                                                            borderColor: item.colorHexValue,
                                                                        }}
                                                                    >
                                                                        <strong>{item.code}</strong>
                                                                    </span>
                                                                )}
                                                                onChange={handleServiceSelection}
                                                            />
                                                        </div>
                                                    }</Observer>
                                                </div>
                                            </div>
                                        </section>
                                        <section>
                                            <div className='row'>
                                                <div className='col-5'>
                                                    <Observer>{() =>
                                                        <div className='form-group mb-0 validate validate-required'>
                                                            <label className='required' htmlFor='new-appointment-duration'><small>Duration</small></label>
                                                            <div className='input-group'>
                                                                <input
                                                                    id='new-appointment-duration'
                                                                    type='number'
                                                                    className='form-control'
                                                                    min={5}
                                                                    step={5}
                                                                    value={(appointment.data.duration ? appointment.data.duration : '')}
                                                                    onChange={handleDurationChange}
                                                                />
                                                                <div className='input-group-append'>
                                                                    <span className='input-group-text'>min.</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }</Observer>
                                                </div>
                                            </div>
                                        </section>
                                        <Observer>{() =>
                                            <>
                                                {
                                                    appointment.isRepeatable ?
                                                        <>
                                                            <section>
                                                                <div className='row'>
                                                                    <div className='col-12'>
                                                                        <Observer>{() =>
                                                                            <div className='form-group mb-0'>
                                                                                <label htmlFor='new-appointment-repeat'><small>Repeat</small></label>
                                                                                <select
                                                                                    id='new-appointment-repeat'
                                                                                    className='custom-select form-control'
                                                                                    value={appointment.data.repeatType ? appointment.data.repeatType : ''}
                                                                                    onChange={handleRepeatTypeChange}
                                                                                >
                                                                                    <option value=''>No repeat</option>
                                                                                    {
                                                                                        cache.referenceData.filter(d => d.name === 'AppointmentRepeatType')[0].options.map((option, di) => {
                                                                                            return <option key={`appointment_repeat_type_${di}`} value={option.key}>{getRepeatDescription(option)}</option>
                                                                                        })
                                                                                    }
                                                                                </select>
                                                                            </div>
                                                                        }</Observer>
                                                                    </div>
                                                                </div>
                                                            </section>
                                                            {
                                                                appointment.data.repeatType ?
                                                                    <section>
                                                                        <div className='row'>
                                                                            <div className='col-6'>
                                                                                <div className='form-group mb-0 validate validate-required'>
                                                                                    <label className='required' htmlFor='new-appointment-repeat-until'><small>Until (YYYY-MM-DD)</small></label>
                                                                                    <Observer>{() =>
                                                                                        <DateTimePicker
                                                                                            dateFormat={dt => String(dt.getDate())}
                                                                                            dayFormat={day => ['M', 'T', 'W', 'T', 'F', 'S'][day.getDay()]}
                                                                                            format={'YYYY-MM-DD'}
                                                                                            views={['month', 'year']}
                                                                                            footer={false}
                                                                                            date={true}
                                                                                            time={false}
                                                                                            dropUp={true}
                                                                                            min={appointment.data.start.toDate()}
                                                                                            value={appointment.data.repeatUntil ? appointment.data.repeatUntil.toDate() : moment().toDate()}
                                                                                            onChange={handleRepeatUtilChange}
                                                                                        />
                                                                                    }</Observer>
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-6'>
                                                                                <div className='form-group mb-0 validate validate-required'>
                                                                                    <label htmlFor='new-appointment-repeat-total'><small>&nbsp;</small></label>
                                                                                    <Observer>{() =>
                                                                                        <div className='input-group'>
                                                                                            <input
                                                                                                id='new-appointment-repeat-total'
                                                                                                type='number'
                                                                                                className='form-control'
                                                                                                min={1}
                                                                                                max={100}
                                                                                                step={1}
                                                                                                value={(appointment.data.repeatTotal ? appointment.data.repeatTotal : '')}
                                                                                                onChange={handleRepeatTotalChange}
                                                                                            />
                                                                                            <div className='input-group-append'>
                                                                                                <span className='input-group-text'>appt.</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    }</Observer>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </section> : null
                                                            }
                                                        </> : null
                                                }
                                            </>
                                        }</Observer>
                                    </div>
                                </FadeIn> : null
                        }
                    </div>
                    <div className='quick-drawer-action pl-3'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='float-right'>
                                    <button
                                        type='button'
                                        className='btn btn-danger mr-2'
                                        onClick={handleDeleteBooking}
                                    >Delete</button>
                                    <button
                                        type='submit'
                                        className='btn btn-success'
                                        disabled={!newCustomerData && !existingCustomerData}
                                    >Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form >
        <BodyEnd>
            <Observer>{() =>
                <LoadingOverlay isLoading={isUploadingAttachments} message={nh.UPLOAD_MESSAGE} />
            }</Observer>
            <ConfirmModal
                icon={<i className={`${oh.getIcon('appointment', 'new')} text-warning mr-2`}></i>}
                message={<>{getCustomer() ? ph.getPreferredFirstName(getCustomer()) : ''}&nbsp;<strong>might not be eligible</strong>&nbsp;for the service(s).  Continue?</>}
                option1ClassName={'btn btn-warning shadow-0 bootbox-accept'}
                show={confirmIneligibles}
                onOption1Click={handleIneligibleConfirm}
                onCancel={handleIneligibleCancel}
            />
            <ConfirmModal
                icon={<i className={`${oh.getIcon('appointment', 'new')} text-warning mr-2`}></i>}
                message={<><span data-warn-appointment-conflict>Appointment&nbsp;<strong>conflict(s) detected</strong>.  Continue?</span></>}
                option1ClassName={'btn btn-warning shadow-0 bootbox-accept'}
                show={confirmConflicts}
                onOption1Click={handleConflictsConfirm}
                onCancel={handleConflictsCancel}
            />
            <ConfirmModal
                icon={<i className='fal fa-calendar-times text-danger mr-2'></i>}
                message={<>Continue to delete booking from {appointment.booking && appointment.booking.items ? appointment.booking.items[0].firstName + ' ' + appointment.booking.items[0].lastName : ''}? </>}
                show={confirmDelete}
                onOption1Click={handleConfirmDeleteBooking}
                onCancel={handleConfirmDeleteBookingCancel}
            />
        </BodyEnd>
    </>
}

export default ConvertBookingToAppointment;
