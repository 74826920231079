import moment from 'moment';

import * as dg from './dx/dataGridHelper';
import * as uh from './userHelper';
import * as sys from './systemHelper';

import api from '../api';

export const GROUPS = [
    { key: null, value: 'Default' },
    { key: 'A', value: 'Blue' },
    { key: 'B', value: 'Yellow' },
    { key: 'C', value: 'Red' },
    { key: 'D', value: 'Purple' },
    { key: 'E', value: 'Green' },
    { key: 'F', value: 'Orange' },
    { key: 'G', value: 'Teal' },
    { key: 'H', value: 'Brown' },
    { key: 'I', value: 'Pink' },
    { key: 'J', value: 'Black' }
];

export const checkConflicts = (data) => {
    return new Promise((resolve, reject) => {
        if (!data) reject();

        Promise.all([
            api.Customers.search({
                parameters: [
                    { field: 'FirstName', value: data.firstName },
                    { field: 'LastName', value: data.lastName, },
                    { field: 'DeactivatedDateUtc', value: null },
                    { field: 'IsSystemCustomer', value: false },
                ],
                includeTotalCount: true,
                includeResult: false,
            }),
            api.Customers.search({
                parameters: [
                    { field: 'PreferredFirstName', value: data.firstName },
                    { field: 'PreferredFirstName', value: null, operator: '!=' },
                    { field: 'PreferredFirstName', value: '', operator: '!=' },
                    { field: 'LastName', value: data.lastName, },
                    { field: 'DeactivatedDateUtc', value: null },
                    { field: 'IsSystemCustomer', value: false },
                ],
                includeTotalCount: true,
                includeResult: false,
            }),
            api.Customers.search({
                parameters: [
                    { field: 'PreferredFirstName', value: data.preferredFirstName },
                    { field: 'PreferredFirstName', value: null, operator: '!=' },
                    { field: 'PreferredFirstName', value: '', operator: '!=' },
                    { field: 'LastName', value: data.lastName, },
                    { field: 'DeactivatedDateUtc', value: null },
                    { field: 'IsSystemCustomer', value: false },
                ],
                includeTotalCount: true,
                includeResult: false,
            }),
            api.PatientProfiles.search({
                parameters: [
                    { field: 'HealthCardNumber', value: data.patientProfile && data.patientProfile.healthCardNumber ? data.patientProfile.healthCardNumber : '' },
                    { field: 'HealthCardNumber', value: null, operator: '!=' },
                    { field: 'HealthCardNumber', value: '', operator: '!=' },
                ],
                includeTotalCount: true,
                includeResult: false,
            }),
        ])
            .then(response => {
                const hasNameConflict = response[0].data.total > 0 || response[1].data.total > 0 || response[2].data.total > 0;
                const hasHealthCardConflict = response[3].data.total > 0;

                if (hasNameConflict || hasHealthCardConflict) {
                    resolve({
                        name: hasNameConflict || hasHealthCardConflict,
                        healthCard: hasHealthCardConflict,
                    });
                } else {
                    resolve();
                }
            })
            .catch(error => {
                reject(error);
            })
    })
}

export const getDeactivatedReason = (status) => {
    if (!!status) {
        switch (status.toLowerCase()) {
            case "removed":
                return 'Asked to be removed by patient';

            case "moved":
                return 'Moved away to a different city / country';

            case "transferred":
                return 'Transferred to a different clinic';

            case "inactive":
                return 'Customer has not return for a while';

            case "duplicate":
                return 'Duplicate customer file';

            case "deceased":
                return 'Customer has passed away';

            default:
                break;
        }
    }

    return status;
}

export const checkCanUpdate = (customer) => {
    return !!customer && customer.isActive;
}

export const checkCanReactivate = (customer) => {
    return !!customer && !customer.isActive && !customer.isMerged;
}

export const getStatusFilters = () => {
    return [
        {
            text: 'Active',
            value: 'Active'
        },
        {
            text: 'Moved',
            value: 'Moved'
        },

        {
            text: 'Transferred',
            value: 'Transferred'
        },
        {
            text: 'Deceased',
            value: 'Deceased'
        },
        {
            text: 'Duplicate',
            value: 'Duplicate'
        },
        {
            text: 'Inactive',
            value: 'Inactive'
        },
    ];
}

export const getNextAppointmentDateFilters = () => {
    return dg.getDefaultNextDateFilters('NextAppointmentStart');
}

export const getLastAppointmentDateFilters = () => {
    return dg.getDefaultLastDateFilters('LastAppointmentStart');
}

export const getLastUpdatedDateFilters = () => {
    return dg.getDefaultLastDateFilters('LastUpdatedDateUtc');
}

export const getLastPurchaseDateFilters = () => {
    return dg.getDefaultLastDateFilters('LastPurchaseDateUtc');
}

export const getLastFullExamDateFilters = () => {
    return dg.getDefaultLastDateFilters('LastFullExamStart');
}

export const getLastPartialExamDateFilters = () => {
    return dg.getDefaultLastDateFilters('LastPartialExamStart');
}

export const getLastRecallFilters = () => {
    return [
        {
            text: 'Never recalled before',
            value: [['HasBeenRecalled', '=', false]]
        },
        {
            text: 'Recalled previously',
            value: [['HasBeenRecalled', '=', true]]
        },
    ]
}

export const getNextRecallDateFilters = () => {
    return [
        {
            text: 'Next 30 days',
            value: [['NextRecallDate', '>=', moment().startOf('day').toDate()], 'and', ['NextRecallDate', '<=', moment().add(30, 'days').startOf('day').toDate()]]
        },
        {
            text: 'Within last month',
            value: [['NextRecallDate', '>=', moment().add(-1, 'months').startOf('day').toDate()], 'and', ['NextRecallDate', '<=', moment().startOf('day').toDate()]]
        },
        {
            text: 'Within last 6 months',
            value: [['NextRecallDate', '>=', moment().add(-6, 'months').startOf('day').toDate()], 'and', ['NextRecallDate', '<=', moment().startOf('day').toDate()]]
        },
        {
            text: 'Within last year',
            value: [['NextRecallDate', '>=', moment().add(-1, 'years').startOf('day').toDate()], 'and', ['NextRecallDate', '<=', moment().startOf('day').toDate()]]
        },
        {
            text: 'Within last 2 years',
            value: [['NextRecallDate', '>=', moment().add(-2, 'years').startOf('day').toDate()], 'and', ['NextRecallDate', '<=', moment().startOf('day').toDate()]]
        },
        {
            text: 'Within last 3 years',
            value: [['NextRecallDate', '>=', moment().add(-3, 'years').startOf('day').toDate()], 'and', ['NextRecallDate', '<=', moment().startOf('day').toDate()]]
        },
    ]
}

export const getQuickFilterDescription = (type) => {
    if (type) {
        switch (type) {
            case 'RecallReady':
                return 'Ready for recall';

            case 'RecallReadyAgain':
                return 'Ready for recall (Next attempt)';

            case 'UpcomingAppointment':
                return 'Have upcoming appointment';

            case 'RecentUpdated':
                return 'Recently updated';

            case 'RecentVisited':
                return 'Recently visited';

            case 'RecentPurchased':
                return 'Recently purchased';

            case 'RecentFullExam':
                return `Recently had full exam`;

            case 'RecentPartialExam':
                return `Recently had partial exam`;

            default:
                return null;
        }
    }
    return null;
}

export const getQuickFilterDefinition = (type) => {
    if (type) {
        const lastRecallFilters = getLastRecallFilters();
        const nextRecallFilters = getNextRecallDateFilters();
        const nextAppointmentFilters = getNextAppointmentDateFilters();
        const lastAppointmentFilters = getLastAppointmentDateFilters();
        const lastPurchaseFilters = getLastPurchaseDateFilters();
        const lastFullExamFilters = getLastFullExamDateFilters();
        const lastPartialExamFilters = getLastPartialExamDateFilters();
        const lastUpdatedFilters = getLastUpdatedDateFilters();
        const isRecallEnabled = sys.configuration.recall.isEnabled;
        const recallHeaderFilters = [
            nextRecallFilters.filter(u => u.text === 'Next 30 days')[0].value,
            nextRecallFilters.filter(u => u.text === 'Within last month')[0].value,
            nextRecallFilters.filter(u => u.text === 'Within last 6 months')[0].value,
            nextRecallFilters.filter(u => u.text === 'Within last year')[0].value
        ];
        
        let recallReady = [{
            column: 'NextRecallDate',
            headerFilters: recallHeaderFilters,
        }, {
            column: 'NextRecallDate',
            sortOrder: 'asc'
        }, {
            column: 'NextRecallDate',
            sortOrder: 'asc'
        }];
        
        const recallReadyAgain = [{
            column: 'NextRecallDate',
            headerFilters: recallHeaderFilters,
        }, {
            column: 'HasBeenRecalled',
            headerFilters: [lastRecallFilters.filter(u => u.text === 'Recalled previously')[0].value],
        }, {
            column: 'NextRecallDate',
            sortOrder: 'asc'
        }];

        if (isRecallEnabled) {
            const recallRangeStart = moment().add((sys.configuration.recall.yearsInRear * -1), 'years').startOf('day').toDate();
            const recallRangeEnd = moment().add(sys.configuration.recall.daysInAdvance, 'days').startOf('day').toDate();

            recallReady = [{
                column: 'NextRecallDate',
                filter: {
                    operator: '<=',
                    value: recallRangeEnd
                },
                sortOrder: 'desc'
            }, {
                column: 'LastFullExam',
                filter: {
                    operator: '>=',
                    value: recallRangeStart,
                }
            }];
        }

        switch (type) {
            case 'RecallReady':
                return recallReady;

            case 'RecallReadyAgain':
                return recallReadyAgain;

            case 'UpcomingAppointment':
                return [{
                    column: 'NextAppointment',
                    headerFilters: [nextAppointmentFilters.filter(u => u.text === 'Next 30 days')[0].value],
                    sortOrder: 'asc'
                }]

            case 'RecentUpdated':
                return [{
                    column: 'LastUpdated',
                    headerFilters: [lastUpdatedFilters.filter(u => u.text === 'Last 30 days')[0].value],
                    sortOrder: 'desc'
                }]

            case 'RecentVisited':
                return [{
                    column: 'LastAppointment',
                    headerFilters: [lastAppointmentFilters.filter(u => u.text === 'Last 30 days')[0].value],
                    sortOrder: 'desc'
                }]

            case 'RecentPurchased':
                return [{
                    column: 'LastUpdated',
                    headerFilters: [lastPurchaseFilters.filter(u => u.text === 'Last 30 days')[0].value],
                    sortOrder: 'desc'
                }]

            case 'RecentFullExam':
                return [{
                    column: 'LastFullExam',
                    headerFilters: [lastFullExamFilters.filter(u => u.text === 'Last 30 days')[0].value],
                    sortOrder: 'desc'
                }]

            case 'RecentPartialExam':
                return [{
                    column: 'LastPartialExam',
                    headerFilters: [lastPartialExamFilters.filter(u => u.text === 'Last 30 days')[0].value],
                    sortOrder: 'desc'
                }]

            default:
                return;
        }
    }
}

export const getQuickFilterVisibleColumns = (type) => {
    if (type) {
        switch (type) {
            case 'RecallReady':
                return ['LastFullExam', 'LastRecall', 'NextRecall'];

            case 'RecallReadyAgain':
                return ['LastFullExam', 'LastRecall', 'NextRecall'];

            case 'UpcomingAppointment':
                return ['NextAppointment', 'LastFullExam', 'LastPurchase'];

            case 'RecentUpdated':
                return ['LastAppointment', 'NextAppointment', 'LastPurchase'];

            case 'RecentVisited':
                return ['LastAppointment', 'LastPurchase'];

            case 'RecentPurchased':
                return ['LastAppointment', 'LastPurchase'];

            case 'RecentFullExam':
                return ['LastFullExam', 'LastPurchase'];

            case 'RecentPartialExam':
                return ['LastPartialExam', 'LastPurchase'];

            default:
                return;
        }
    }
}

export const getRecalledDescription = (recallMethod, recalledById, recalledDateUtc) => {
    if (recallMethod) {
        const recalledBy = recalledById ? uh.getDisplayShortNameById(recalledById) : 'System';

        switch (recallMethod) {
            case 'Email':
            case 'SMS':
                return `Recalled via ${recallMethod} by ${recalledBy} on ${sys.getFormattedLongDate(moment.utc(recalledDateUtc).local(), true)}`;

            case 'Manual':
                return `Recalled manually by ${recalledBy} on ${sys.getFormattedLongDate(moment.utc(recalledDateUtc).local(), true)}`;

            default:
                break;
        }
    }

    return '';
}

export const getProfileColor = (customer) => {
    if (customer && customer.isSystemCustomer) {
        return 'bg-primary';
    }
    if (customer && customer.color && GROUPS.some(c => c.value.toLowerCase() === customer.color.toLowerCase() && c.key)) {
        return `profile-group-${GROUPS.filter(c => c.value.toLowerCase() === customer.color.toLowerCase())[0].key.toLowerCase()}`;
    }

    return 'profile-group-default';
}

export const getSpokenLanguage = (customer) => {
    const spokenLanguages = sys.configuration.customer.spokenLanguages;

    if (customer && customer.spokenLanguage && spokenLanguages && spokenLanguages.length > 0 && spokenLanguages.some(l => l.key === customer.spokenLanguage)) {
        const spokenLanguage = spokenLanguages.filter(l => l.key === customer.spokenLanguage)[0];
        return spokenLanguage;
    }

    return null;
}

export const getCommunicationLanguage = (customer) => {
    const communicationLanguages = sys.configuration.customer.communicationLanguages;

    if (customer && customer.communicationLanguage && communicationLanguages && communicationLanguages.length > 0 && communicationLanguages.some(l => l.key === customer.communicationLanguage)) {
        const communicationLanguage = communicationLanguages.filter(l => l.key === customer.communicationLanguage)[0];
        return communicationLanguage;
    }

    return null;
}