import React, { useRef, useContext, useEffect } from 'react';
import { useObserver } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';

import QuickDrawerAccessDenied from './QuickDrawerAccessDenied';

// Appointments
import NewAppointment from '../appointments/_quickDrawer/NewAppointment';
import ViewAppointment from '../appointments/_quickDrawer/ViewAppointment';
import UpdateAppointment from '../appointments/_quickDrawer/UpdateAppointment';
import DeleteAppointment from '../appointments/_quickDrawer/DeleteAppointment';
import NewGroupAppointment from '../appointments/_quickDrawer/NewGroupAppointment';
import ViewGroupAppointment from '../appointments/_quickDrawer/ViewGroupAppointment';
import UpdateGroupAppointment from '../appointments/_quickDrawer/UpdateGroupAppointment';
import DeleteGroupAppointment from '../appointments/_quickDrawer/DeleteGroupAppointment';
import ViewInvoices from '../appointments/_quickDrawer/ViewInvoices';

// Customers
import NewCustomer from '../customers/_quickDrawer/NewCustomer';
import NewPrimaryContact from '../customers/_quickDrawer/NewPrimaryContact';
import UpdateCustomer from '../customers/_quickDrawer/UpdateCustomer';
import ReactivateCustomer from '../customers/_quickDrawer/ReactivateCustomer';
import DeactivateCustomer from '../customers/_quickDrawer/DeactivateCustomer';
import UpdateAddress from '../customers/_quickDrawer/UpdateAddress';
import UpdateContactInformation from '../customers/_quickDrawer/UpdateContactInformation';
import UpdatePatient from '../customers/_quickDrawer/UpdatePatient';
import AddRelationship from '../customers/_quickDrawer/AddRelationship';
import UpdateRelationships from '../customers/_quickDrawer/UpdateRelationships';
import NewPrivateInsurance from '../customers/_quickDrawer/NewPrivateInsurance';
import ListPrivateInsurance from '../customers/_quickDrawer/ListPrivateInsurance';
import UpdatePrivateInsurance from '../customers/_quickDrawer/UpdatePrivateInsurance';
import DeletePrivateInsurance from '../customers/_quickDrawer/DeletePrivateInsurance';
import StoreCreditTransfer from '../customers/_quickDrawer/StoreCreditTransfer';
import MigratePaperExam from '../customers/_quickDrawer/MigratePaperExam';

// Pretests
import NewPretest from '../pretests/_quickDrawer/NewPretest';

// Prescriptions
import StartPrescription from '../prescriptions/_quickDrawer/StartPrescription';

// Exams
import NewExam from '../exams/_quickDrawer/NewExam';
import DeleteExam from '../exams/_quickDrawer/DeleteExam';
import PrefillExam from '../exams/_quickDrawer/PrefillExam';
import PredefinedExam from '../exams/_quickDrawer/PredefinedExam';
import PreviousExam from '../exams/_quickDrawer/PreviousExam';
import UpdateServices from '../exams/_quickDrawer/UpdateServices';
import PretestImport from '../exams/_quickDrawer/PretestImport';
import ReopenExam from '../exams/_quickDrawer/ReopenExam';
import ViewReferrals from '../exams/_quickDrawer/ViewReferrals';
import ViewMedicalReports from '../exams/_quickDrawer/ViewMedicalReports';
import AddExamData from '../exams/_quickDrawer/AddExamData';
import ConfirmDiagnosticCodes from '../exams/_quickDrawer/ConfirmDiagnosticCodes';

// Notes
import NewNote from '../notes/_quickDrawer/NewNote';
import ViewNotes from '../notes/_quickDrawer/ViewNotes';
import ViewAttachments from '../notes/_quickDrawer/ViewAttachments';
import ViewGroupAppointmentNotes from '../notes/_quickDrawer/ViewGroupAppointmentNotes';

// Schedules
import NewSchedule from '../schedules/_quickDrawer/NewSchedule';
import DeleteSchedule from '../schedules/_quickDrawer/DeleteSchedule';
import NewTimeOff from '../schedules/_quickDrawer/NewTimeOff';

// Business Hours
import NewBusinessHour from '../settings/business-hours/_quickDrawer/NewBusinessHour';

// Business Day
import NewBusinessDay from '../settings/business-hours/_quickDrawer/NewBusinessDay';

// Purchases
import RefundPurchase from '../purchases/_quickDrawer/RefundPurchase';
import VoidPurchase from '../purchases/_quickDrawer/VoidPurchase';
import NoChargePurchase from '../purchases/_quickDrawer/NoChargePurchase';
import UncollectiblePurchase from '../purchases/_quickDrawer/UncollectiblePurchase';
import VerifyReturns from '../purchases/_quickDrawer/VerifyReturns';
import NewPayment from '../purchases/_quickDrawer/NewPayment';
import RefundPayment from '../purchases/_quickDrawer/RefundPayment';
import PaymentHistory from '../purchases/_quickDrawer/PaymentHistory';
import InsurancePayment from '../purchases/_quickDrawer/InsurancePayment';
import GovernmentProgramPayment from '../purchases/_quickDrawer/GovernmentProgramPayment';
import DeletePayment from '../purchases/_quickDrawer/DeletePayment';
import UpdateCost from '../purchases/_quickDrawer/UpdateCost';
import ViewWorkOrders from '../purchases/_quickDrawer/ViewWorkOrders';

// WorkOrders
import CreateWorkOrder from '../work-orders/_quickDrawer/CreateWorkOrder';
import UpdateWorkOrder from '../work-orders/_quickDrawer/UpdateWorkOrder';
import DeleteWorkOrder from '../work-orders/_quickDrawer/DeleteWorkOrder';
import LinkWorkOrders from '../work-orders/_quickDrawer/LinkWorkOrders';
import AddWorkOrderItem from '../work-orders/_quickDrawer/AddWorkOrderItem';
import UpdateWorkOrderStatus from '../work-orders/_quickDrawer/UpdateWorkOrderStatus';
import UpdateWorkOrderItem from '../work-orders/_quickDrawer/UpdateWorkOrderItem';

// Private Insurances
import AddPrivateInsuranceProvider from '../private-insurances/_quickDrawer/AddPrivateInsuranceProvider';
import UpdatePrivateInsuranceProvider from '../private-insurances/_quickDrawer/UpdatePrivateInsuranceProvider';

// External Clinics
import AddExternalClinic from '../external-clinics/_quickDrawer/AddExternalClinic';
import UpdateExternalClinic from '../external-clinics/_quickDrawer/UpdateExternalClinic';

// External Doctors
import AddExternalDoctor from '../external-doctors/_quickDrawer/AddExternalDoctor';
import UpdateExternalDoctor from '../external-doctors/_quickDrawer/UpdateExternalDoctor';

// Products
import NewProduct from '../products/_quickDrawer/NewProduct';
import ViewProduct from '../products/_quickDrawer/ViewProduct';
import UpdateProduct from '../products/_quickDrawer/UpdateProduct';
import DeleteProduct from '../products/_quickDrawer/DeleteProduct';
import NewProductType from '../products/_quickDrawer/NewProductType';
import NewProductSupplier from '../products/_quickDrawer/NewProductSupplier';
import NewProductBrand from '../products/_quickDrawer/NewProductBrand';
import ViewInventoryHistory from '../products/_quickDrawer/ViewInventoryHistory';

// Services
import UpdateServiceAttribute from '../services/_quickDrawer/UpdateServiceAttribute';
import UpdateServiceOverride from '../services/_quickDrawer/UpdateServiceOverride';
import NewService from '../services/_quickDrawer/NewService';

// Todo Items
import NewTodo from '../todo/_quickDrawer/NewTodo';
import UpdateTodo from '../todo/_quickDrawer/UpdateTodo';

// Internal Forms
import StartInternalForm from '../internal-forms/_quickDrawer/StartInternalForm';

// Contact
import AddContact from '../contacts/_quickDrawer/AddContact';
import UpdateContact from '../contacts/_quickDrawer/UpdateContact';

// System Settings
import NewExamInput from '../settings/exam/inputs/_quickDrawer/NewExamInput';
import UpdateExamInput from '../settings/exam/inputs/_quickDrawer/UpdateExamInput';
import UpdateExistingExamText from '../settings/exam/inputs/_quickDrawer/UpdateExistingExamText';
import UpdateExistingExamControl from '../settings/exam/inputs/_quickDrawer/UpdateExistingExamControl';
import UpdateExistingExamElement from '../settings/exam/inputs/_quickDrawer/UpdateExistingExamElement';
import NewExamTemplate from '../settings/exam/templates/_quickDrawer/NewExamTemplate';
import NewExamSection from '../settings/exam/templates/_quickDrawer/NewExamSection';
import UpdateExamSection from '../settings/exam/templates/_quickDrawer/UpdateExamSection';
import ReorderExamSection from '../settings/exam/templates/_quickDrawer/ReorderExamSection';
import CloneExamTemplate from '../settings/exam/templates/_quickDrawer/CloneExamTemplate';
import NewExamDataset from '../settings/exam/datasets/_quickDrawer/NewExamDataset';
import UpdateExamDataset from '../settings/exam/datasets/_quickDrawer/UpdateExamDataset';

import NewWorkOrderInput from '../settings/work-order/inputs/_quickDrawer/NewWorkOrderInput';
import UpdateWorkOrderInput from '../settings/work-order/inputs/_quickDrawer/UpdateWorkOrderInput';
import MapWorkOrderInput from '../settings/work-order/inputs/_quickDrawer/MapWorkOrderInput';
import NewWorkOrderTemplate from '../settings/work-order/templates/_quickDrawer/NewWorkOrderTemplate';
import UpdateExistingWorkOrderText from '../settings/work-order/inputs/_quickDrawer/UpdateExistingWorkOrderText';
import UpdateExistingWorkOrderControl from '../settings/work-order/inputs/_quickDrawer/UpdateExistingWorkOrderControl';
import UpdateExistingWorkOrderElement from '../settings/work-order/inputs/_quickDrawer/UpdateExistingWorkOrderElement';

import NewPrescriptionInput from '../settings/prescription/inputs/_quickDrawer/NewPrescriptionInput';
import UpdatePrescriptionInput from '../settings/prescription/inputs/_quickDrawer/UpdatePrescriptionInput';
import UpdateExistingPrescriptionText from '../settings/prescription/inputs/_quickDrawer/UpdateExistingPrescriptionText';
import UpdateExistingPrescriptionControl from '../settings/prescription/inputs/_quickDrawer/UpdateExistingPrescriptionControl';
import UpdateExistingPrescriptionElement from '../settings/prescription/inputs/_quickDrawer/UpdateExistingPrescriptionElement';
import NewPrescriptionTemplate from '../settings/prescription/templates/_quickDrawer/NewPrescriptionTemplate';

import NewPretestInput from '../settings/pretest/inputs/_quickDrawer/NewPretestInput';
import UpdatePretestInput from '../settings/pretest/inputs/_quickDrawer/UpdatePretestInput';
import UpdateExistingPretestText from '../settings/pretest/inputs/_quickDrawer/UpdateExistingPretestText';
import UpdateExistingPretestControl from '../settings/pretest/inputs/_quickDrawer/UpdateExistingPretestControl';
import UpdateExistingPretestElement from '../settings/pretest/inputs/_quickDrawer/UpdateExistingPretestElement';
import NewPretestTemplate from '../settings/pretest/templates/_quickDrawer/NewPretestTemplate';

import NewLogicalDevice from '../settings/pretest/logicalDevices/_quickDrawer/NewLogicalDevice';
import UpdateLogicalDevice from '../settings/pretest/logicalDevices/_quickDrawer/UpdateLogicalDevice';

import UpdateExistingInvoiceElement from '../settings/invoice/inputs/_quickDrawer/UpdateExistingInvoiceElement';
import UpdateExistingPaymentReceiptElement from '../settings/payment-receipt/inputs/_quickDrawer/UpdateExistingPaymentReceiptElement';
import UpdateExistingFaxCoverPageElement from '../settings/fax-cover-page/inputs/_quickDrawer/UpdateExistingFaxCoverPageElement';

import UpdateExistingPrescriptionPrintElement from '../settings/prescription-print/inputs/_quickDrawer/UpdateExistingPrescriptionPrintElement';
import NewPrescriptionPrintTemplate from '../settings/prescription-print/templates/_quickDrawer/NewPrescriptionPrintTemplate';
import UpdatePrescriptionPrintTemplate from '../settings/prescription-print/templates/_quickDrawer/UpdatePrescriptionPrintTemplate';

import NewFormInput from '../settings/form/inputs/_quickDrawer/NewFormInput';
import UpdateFormInput from '../settings/form/inputs/_quickDrawer/UpdateFormInput';
import UpdateExistingFormElement from '../settings/form/inputs/_quickDrawer/UpdateExistingFormElement';
import UpdateExistingFormText from '../settings/form/inputs/_quickDrawer/UpdateExistingFormText';
import UpdateExistingFormControl from '../settings/form/inputs/_quickDrawer/UpdateExistingFormControl';
import NewFormTemplate from '../settings/form/templates/_quickDrawer/NewFormTemplate';
import UpdateFormTemplate from '../settings/form/templates/_quickDrawer/UpdateFormTemplate';
import CloneFormTemplate from '../settings/form/templates/_quickDrawer/CloneFormTemplate';
import NewFormPage from '../settings/form/templates/_quickDrawer/NewFormPage';
import UpdateFormPage from '../settings/form/templates/_quickDrawer/UpdateFormPage';
import ReorderFormPage from '../settings/form/templates/_quickDrawer/ReorderFormPage';

import NewInternalFormInput from '../settings/internal-form/inputs/_quickDrawer/NewInternalFormInput';
import UpdateInternalFormInput from '../settings/internal-form/inputs/_quickDrawer/UpdateInternalFormInput';
import UpdateExistingInternalFormElement from '../settings/internal-form/inputs/_quickDrawer/UpdateExistingInternalFormElement';
import UpdateExistingInternalFormControl from '../settings/internal-form/inputs/_quickDrawer/UpdateExistingInternalFormControl';
import NewInternalFormTemplate from '../settings/internal-form/templates/_quickDrawer/NewInternalFormTemplate';
import UpdateInternalFormTemplate from '../settings/internal-form/templates/_quickDrawer/UpdateInternalFormTemplate';
import NewInternalFormPage from '../settings/internal-form/templates/_quickDrawer/NewInternalFormPage';
import UpdateInternalFormPage from '../settings/internal-form/templates/_quickDrawer/UpdateInternalFormPage';
import ReorderInternalFormPage from '../settings/internal-form/templates/_quickDrawer/ReorderInternalFormPage';
import ImportPdf from '../settings/internal-form/templates/_quickDrawer/ImportPdf';

import NewReferralTemplate from '../settings/referral/templates/_quickDrawer/NewReferralTemplate';
import UpdateExistingReferralElement from '../settings/referral/inputs/_quickDrawer/UpdateExistingReferralElement';
import UpdateReferralTemplate from '../settings/referral/templates/_quickDrawer/UpdateReferralTemplate';
import NewReferralTenantExamDatasetGroup from '../settings/referral/datasetGroups/_quickDrawer/NewReferralTenantExamDatasetGroup';
import UpdateReferralTenantExamDatasetGroup from '../settings/referral/datasetGroups/_quickDrawer/UpdateReferralTenantExamDatasetGroup';

import NewMedicalReportTemplate from '../settings/medical-report/templates/_quickDrawer/NewMedicalReportTemplate';
import UpdateExistingMedicalReportElement from '../settings/medical-report/inputs/_quickDrawer/UpdateExistingMedicalReportElement';
import UpdateMedicalReportTemplate from '../settings/medical-report/templates/_quickDrawer/UpdateMedicalReportTemplate';
import NewMedicalReportTenantExamDatasetGroup from '../settings/medical-report/datasetGroups/_quickDrawer/NewMedicalReportTenantExamDatasetGroup';
import UpdateMedicalReportTenantExamDatasetGroup from '../settings/medical-report/datasetGroups/_quickDrawer/UpdateMedicalReportTenantExamDatasetGroup';

import CreateUser from '../admin/organization/user-management/users/_quickDrawer/CreateUser';
import CreateUpdateGroup from '../admin/organization/user-management/groups/_quickDrawer/CreateUpdateGroup';

import QuickDrawerStore from '../../../stores/QuickDrawerStore';
import * as AccessType from '../../../constants/accessTypes';
import * as fn from '../../../utilities/_functions';
import * as ah from '../../../utilities/accessHelper';

import './QuickDrawer.scss';
import CreateUpdateTenant from '../admin/organization/tenants/_quickDrawer/CreateUpdateTenant';
import UpdateTenantAddress from '../admin/organization/tenants/_quickDrawer/UpdateTenantAddress';
import CreateUpdateOrganization from '../admin/organization/organizations/_quickDrawer/CreateUpdateOrganization';
import UpdateOrganizationAddress from '../admin/organization/organizations/_quickDrawer/UpdateOrganizationAddress';
import UpdateServiceTimelineItems from '../services/_quickDrawer/UpdateServiceTimelineItems';
import CorrespondenceAttachments from '../correspondence/CorrespondenceAttachments';
import NewTimeslotSchedule from '../schedules/_quickDrawer/NewTimeslotSchedule';
import ConvertBookingToAppointment from '../bookings/_quickDrawer/ConvertBookingToAppointment';
import DeleteBooking from '../bookings/_quickDrawer/DeleteBooking';

const getQuickDrawerId = (id) => {
    return `__quick-drawer-${id}__`;
}

const renderQuickDrawerLoading = () => {
    return <div className='d-flex h-100 justify-content-center align-items-center'>
        <div className='spinner-container'>
            <div className='spinner-horizontal'>
                <div className='spinner'></div>
            </div>
        </div>
    </div>
}

const quickDrawerFocus = (id) => {
    const quickDrawerElement = document.querySelector(`#${getQuickDrawerId(id)}`);

    if (quickDrawerElement) {
        const found = quickDrawerElement.querySelectorAll('input[type="text"],input[type="file"], textarea, select');

        if (found && found.length > 0) {
            const notEmpty = Array.from(found).filter(el => {
                switch (el.tagName.toLowerCase()) {
                    case 'input':
                        if (el.type === 'text' || el.type === 'file') {
                            return el;
                        }
                        break;

                    case 'select':
                    case 'textarea':
                        return el;

                    default:
                        return null;
                }
                return null;
            })

            if (notEmpty && notEmpty.length > 0) {
                notEmpty[0].focus();
            } else {
                found[0].focus();
            }
        }
    }
}

function QuickDrawer(props) {
    const isMounted = useRef(true);
    const quickDrawer = useContext(QuickDrawerStore);
    let location = useLocation();

    useEffect(() => {
        // if location changes, like the user pressing the 'back' button, close the drawer
        if (quickDrawer.drawerOpened > 0) {
            quickDrawer.deactivateAll();
        }
    }, [location]); // eslint-disable-line

    useEffect(() => {
        return () => {
            isMounted.current = false;
            quickDrawer.clear();
        }
    }, []) // eslint-disable-line

    const handleSuccessDrawer = (event, drawer, data) => {
        switch (drawer) {
            case 1:
                handleSuccessDrawer1(event, data);
                break;

            case 2:
                handleSuccessDrawer2(event, data);
                break;

            case 3:
                handleSuccessDrawer3(event, data);
                break;

            case 4:
                handleSuccessDrawer4(event, data);
                break;

            case 5:
                handleSuccessDrawer5(event, data);
                break;

            case 6:
                handleSuccessDrawer6(event, data);
                break;

            default:
                break;
        }
    }

    const handleSuccessDrawer1 = (event, data) => {
        const successCallback = quickDrawer.success1Callback;

        quickDrawer.deactivateQuickDrawer1()
            .then(() => {
                document.querySelector('html').classList.remove('no-scroll');

                if (fn.isFunction(successCallback)) {
                    successCallback(data);
                }
            })
    }

    const handleSuccessDrawer2 = (event, data) => {
        const successCallback = quickDrawer.success2Callback;

        quickDrawer.deactivateQuickDrawer2()
            .then(() => {
                if (fn.isFunction(successCallback)) {
                    successCallback(data);
                }
                setTimeout(() => {
                    quickDrawerFocus(1);
                }, 100)
            })
    }

    const handleSuccessDrawer3 = (event, data) => {
        const successCallback = quickDrawer.success3Callback;

        quickDrawer.deactivateQuickDrawer3()
            .then(() => {
                if (fn.isFunction(successCallback)) {
                    successCallback(data);
                }
                setTimeout(() => {
                    quickDrawerFocus(2);
                }, 100)
            })
    }

    const handleSuccessDrawer4 = (event, data) => {
        const successCallback = quickDrawer.success4Callback;

        quickDrawer.deactivateQuickDrawer4()
            .then(() => {
                if (fn.isFunction(successCallback)) {
                    successCallback(data);
                }
                setTimeout(() => {
                    quickDrawerFocus(3);
                }, 100)
            })
    }

    const handleSuccessDrawer5 = (event, data) => {
        const successCallback = quickDrawer.success4Callback;

        quickDrawer.deactivateQuickDrawer4()
            .then(() => {
                if (fn.isFunction(successCallback)) {
                    successCallback(data);
                }
                setTimeout(() => {
                    quickDrawerFocus(4);
                }, 100)
            })
    }

    const handleSuccessDrawer6 = (event, data) => {
        const successCallback = quickDrawer.success4Callback;

        quickDrawer.deactivateQuickDrawer4()
            .then(() => {
                if (fn.isFunction(successCallback)) {
                    successCallback(data);
                }
                setTimeout(() => {
                    quickDrawerFocus(5);
                }, 100)
            })
    }

    const handleCancelDrawer = (event, drawer) => {
        switch (drawer) {
            case 1:
                handleCancelDrawer1(event);
                break;

            case 2:
                handleCancelDrawer2(event);
                break;

            case 3:
                handleCancelDrawer3(event);
                break;

            case 4:
                handleCancelDrawer4(event);
                break;

            case 5:
                handleCancelDrawer5(event);
                break;

            case 6:
                handleCancelDrawer6(event);
                break;

            default:
                break;
        }
    }

    const handleCancelDrawer1 = event => {
        const cancelCallback = quickDrawer.cancel1Callback;

        quickDrawer.deactivateQuickDrawer1()
            .then(() => {
                document.querySelector('html').classList.remove('no-scroll');

                if (fn.isFunction(cancelCallback)) {
                    cancelCallback();
                }
            })
    }

    const handleCancelDrawer2 = event => {
        const cancelCallback = quickDrawer.cancel2Callback;

        quickDrawer.deactivateQuickDrawer2()
            .then(() => {
                if (fn.isFunction(cancelCallback)) {
                    cancelCallback();
                }
                setTimeout(() => {
                    quickDrawerFocus(1);
                }, 100)
            })
    }

    const handleCancelDrawer3 = event => {
        const cancelCallback = quickDrawer.cancel3Callback;

        quickDrawer.deactivateQuickDrawer3()
            .then(() => {
                if (fn.isFunction(cancelCallback)) {
                    cancelCallback();
                }
                setTimeout(() => {
                    quickDrawerFocus(2);
                }, 100)
            })
    }

    const handleCancelDrawer4 = event => {
        const cancelCallback = quickDrawer.cancel4Callback;

        quickDrawer.deactivateQuickDrawer4()
            .then(() => {
                if (fn.isFunction(cancelCallback)) {
                    cancelCallback();
                }
                setTimeout(() => {
                    quickDrawerFocus(3);
                }, 100)
            })
    }

    const handleCancelDrawer5 = event => {
        const cancelCallback = quickDrawer.cancel5Callback;

        quickDrawer.deactivateQuickDrawer5()
            .then(() => {
                if (fn.isFunction(cancelCallback)) {
                    cancelCallback();
                }
                setTimeout(() => {
                    quickDrawerFocus(4);
                }, 100)
            })
    }

    const handleCancelDrawer6 = event => {
        const cancelCallback = quickDrawer.cancel6Callback;

        quickDrawer.deactivateQuickDrawer6()
            .then(() => {
                if (fn.isFunction(cancelCallback)) {
                    cancelCallback();
                }
                setTimeout(() => {
                    quickDrawerFocus(5);
                }, 100)
            })
    }

    const renderDrawer1Body = () => {
        if (quickDrawer.context1 && quickDrawer.action1) {
            document.querySelector('html').classList.add('no-scroll');
        }
        return renderDrawerBody(quickDrawer.context1, quickDrawer.action1, quickDrawer.extraProps1, 1);
    }

    const renderDrawer2Body = () => {
        return renderDrawerBody(quickDrawer.context2, quickDrawer.action2, quickDrawer.extraProps2, 2);
    }

    const renderDrawer3Body = () => {
        return renderDrawerBody(quickDrawer.context3, quickDrawer.action3, quickDrawer.extraProps3, 3);
    }

    const renderDrawer4Body = () => {
        return renderDrawerBody(quickDrawer.context4, quickDrawer.action4, quickDrawer.extraProps4, 4);
    }

    const renderDrawer5Body = () => {
        return renderDrawerBody(quickDrawer.context5, quickDrawer.action5, quickDrawer.extraProps5, 5);
    }

    const renderDrawer6Body = () => {
        return renderDrawerBody(quickDrawer.context6, quickDrawer.action6, quickDrawer.extraProps6, 6);
    }

    const renderDrawerBody = (context, action, extraProps, drawer) => {
        const contentProps = {
            drawer: drawer,
            extraProps: extraProps,
            onSuccess: (e, data) => handleSuccessDrawer(e, drawer, data),
            onCancel: e => handleCancelDrawer(e, drawer),
        };

        switch (context) {
            case 'appointment':
                switch (action) {
                    case 'create':
                        return ah.check(AccessType.UPDATE_APPOINTMENT) ?
                            <NewAppointment {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'view':
                        return ah.check(AccessType.VIEW_APPOINTMENT) ?
                            <ViewAppointment {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'update':
                        return ah.check(AccessType.UPDATE_APPOINTMENT) ?
                            <UpdateAppointment {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'delete':
                        return ah.check(AccessType.UPDATE_APPOINTMENT) ?
                            <DeleteAppointment {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'invoices':
                        return ah.check(AccessType.VIEW_APPOINTMENT) ?
                            <ViewInvoices {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    default:
                        return null;
                }

            case 'booking': {
                switch (action) {
                    case 'delete':
                        return ah.check(AccessType.UPDATE_APPOINTMENT) ?
                            <DeleteBooking {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'convert':
                        return ah.check(AccessType.UPDATE_APPOINTMENT) ?
                            <ConvertBookingToAppointment {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                        default:
                            return null;
                }
            }
            case 'group-appointment':
                switch (action) {
                    case 'create':
                        return ah.check(AccessType.UPDATE_APPOINTMENT) ?
                            <NewGroupAppointment {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'view':
                        return ah.check(AccessType.VIEW_APPOINTMENT) ?
                            <ViewGroupAppointment {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'update':
                        return ah.check(AccessType.UPDATE_APPOINTMENT) ?
                            <UpdateGroupAppointment {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'delete':
                        return ah.check(AccessType.UPDATE_APPOINTMENT) ?
                            <DeleteGroupAppointment {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'note':
                        return ah.check(AccessType.VIEW_APPOINTMENT) ?
                            <ViewGroupAppointmentNotes {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    default:
                        return null;
                }

            case 'customer':
                switch (action) {
                    case 'create':
                        return ah.check(AccessType.UPDATE_CUSTOMER) ?
                            <NewCustomer {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'personal':
                        return ah.check(AccessType.UPDATE_CUSTOMER) ?
                            <UpdateCustomer {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'address':
                        return ah.check(AccessType.UPDATE_CUSTOMER) ?
                            <UpdateAddress {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'contact':
                        return ah.check(AccessType.UPDATE_CUSTOMER) ?
                            <UpdateContactInformation {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'patient':
                        return ah.check(AccessType.UPDATE_CUSTOMER) ?
                            <UpdatePatient {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'relationship-create':
                        return ah.check(AccessType.UPDATE_CUSTOMER) ?
                            <AddRelationship {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'relationship-update':
                        return ah.check(AccessType.UPDATE_CUSTOMER) ?
                            <UpdateRelationships {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'private-insurance':
                        return ah.check(AccessType.UPDATE_CUSTOMER) ?
                            <ListPrivateInsurance {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'private-insurance-create':
                        return ah.check(AccessType.UPDATE_CUSTOMER) ?
                            <NewPrivateInsurance {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'private-insurance-update':
                        return ah.check(AccessType.UPDATE_CUSTOMER) ?
                            <UpdatePrivateInsurance {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'private-insurance-delete':
                        return ah.check(AccessType.UPDATE_CUSTOMER) ?
                            <DeletePrivateInsurance {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'primary-contact-create':
                        return ah.check(AccessType.UPDATE_CUSTOMER) ?
                            <NewPrimaryContact {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'store-credit-transfer':
                        return ah.check(AccessType.UPDATE_CUSTOMER) ?
                            <StoreCreditTransfer {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'migrate-paper-exam':
                        return ah.check(AccessType.UPDATE_CUSTOMER) ?
                            <MigratePaperExam {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'reactivate':
                        return ah.check(AccessType.UPDATE_CUSTOMER) ?
                            <ReactivateCustomer {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'deactivate':
                        return ah.check(AccessType.UPDATE_CUSTOMER) ?
                            <DeactivateCustomer {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    default:
                        return null;
                }

            case 'pretest':
                switch (action) {
                    case 'create':
                        return ah.check(AccessType.UPDATE_EXAM_PRETEST) ?
                            <NewPretest {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    default:
                        return null;
                }

            case 'prescription':
                switch (action) {
                    case 'start':
                        return ah.check(AccessType.UPDATE_CUSTOMER) ?
                            <StartPrescription {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    default:
                        return null;
                }

            case 'exam':
                switch (action) {
                    case 'create':
                        return ah.check(AccessType.UPDATE_EXAM) ?
                            <NewExam {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'delete':
                        return ah.check(AccessType.UPDATE_EXAM) ?
                            <DeleteExam {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'prefill':
                        return ah.check(AccessType.UPDATE_EXAM) ?
                            <PrefillExam {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'predefined':
                        return ah.check(AccessType.UPDATE_EXAM) ?
                            <PredefinedExam {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'previous':
                        return ah.check(AccessType.UPDATE_EXAM) ?
                            <PreviousExam {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'services':
                        return ah.check(AccessType.UPDATE_EXAM) ?
                            <UpdateServices {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'pretest-import':
                        return ah.check(AccessType.UPDATE_EXAM) ?
                            <PretestImport {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'reopen':
                        return ah.check(AccessType.UPDATE_EXAM) ?
                            <ReopenExam {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'referrals':
                        return ah.check(AccessType.UPDATE_EXAM) && ah.check(AccessType.UPDATE_REFERRAL) ?
                            <ViewReferrals {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'medical-reports':
                        return ah.check(AccessType.UPDATE_EXAM) && ah.check(AccessType.UPDATE_MEDICAL_REPORT) ?
                            <ViewMedicalReports {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'diagnostic-codes':
                        return ah.check(AccessType.UPDATE_EXAM) && ah.check(AccessType.UPDATE_MEDICAL_REPORT) ?
                            <ConfirmDiagnosticCodes {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    default:
                        return null;
                }

            case 'note':
                switch (action) {
                    case 'create':
                        return <NewNote {...contentProps} />;

                    case 'view':
                        return <ViewNotes {...contentProps} />;

                    case 'attachment':
                        return <ViewAttachments {...contentProps} />;

                    default:
                        return null;
                }

            case 'schedule':
                switch (action) {
                    case 'create':
                        return ah.check(AccessType.UPDATE_SCHEDULE) ?
                            <NewSchedule {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'delete':
                        return ah.check(AccessType.UPDATE_SCHEDULE) ?
                            <DeleteSchedule {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    default:
                        return null;
                }

            case 'timeoff':
                switch (action) {
                    case 'create':
                        return ah.check(AccessType.UPDATE_SCHEDULE) ?
                            <NewTimeOff {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'delete':
                        return ah.check(AccessType.UPDATE_SCHEDULE) ?
                            <DeleteSchedule {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    default:
                        return null;
                }

            case 'timeslot':
                switch (action) {
                    case 'update':
                        return <NewTimeslotSchedule {...contentProps} />;

                    default:
                        return null;
                }
            case 'businessHours':
                switch (action) {
                    case 'update':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <NewBusinessHour {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    default:
                        return null;
                }

            case 'businessDay':
                switch (action) {
                    case 'update':
                        return ah.check(AccessType.UPDATE_BUSINESS_DAY) ?
                            <NewBusinessDay {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    default:
                        return null;
                }

            case 'purchase':
                switch (action) {
                    case 'refund-purchase':
                        return ah.check(AccessType.UPDATE_PURCHASE) ?
                            <RefundPurchase {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'void-purchase':
                        return ah.check(AccessType.UPDATE_PURCHASE) ?
                            <VoidPurchase {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'no-charge-purchase':
                        return ah.check(AccessType.UPDATE_PURCHASE) ?
                            <NoChargePurchase {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'uncollectible-purchase':
                        return ah.check(AccessType.UPDATE_PURCHASE) ?
                            <UncollectiblePurchase {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'verify-returns':
                        return ah.check(AccessType.UPDATE_PURCHASE) ?
                            <VerifyReturns {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'payment':
                        return ah.check(AccessType.UPDATE_PURCHASE) ?
                            <NewPayment {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'refund-payment':
                        return ah.check(AccessType.UPDATE_PURCHASE) ?
                            <RefundPayment {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'payment-history':
                        return ah.check(AccessType.UPDATE_PURCHASE) ?
                            <PaymentHistory {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'private-insurance-payment':
                        return ah.check(AccessType.UPDATE_PURCHASE) ?
                            <InsurancePayment {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'government-program-payment':
                        return ah.check(AccessType.UPDATE_PURCHASE) ?
                            <GovernmentProgramPayment {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'delete-payment':
                        return ah.check(AccessType.UPDATE_PURCHASE) ?
                            <DeletePayment {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'update-cost':
                        return ah.check(AccessType.UPDATE_PURCHASE) ?
                            <UpdateCost {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'work-orders':
                        return ah.check(AccessType.UPDATE_WORK_ORDER) ?
                            <ViewWorkOrders {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    default:
                        return null;
                }

            case 'order':
                switch (action) {
                    case 'create':
                        return ah.check(AccessType.UPDATE_WORK_ORDER) ?
                            <CreateWorkOrder {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'update':
                        return ah.check(AccessType.UPDATE_WORK_ORDER) ?
                            <UpdateWorkOrder {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'delete':
                        return ah.check(AccessType.UPDATE_WORK_ORDER) ?
                            <DeleteWorkOrder {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'link':
                        return ah.check(AccessType.UPDATE_WORK_ORDER) ?
                            <LinkWorkOrders {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'status':
                        return ah.check(AccessType.UPDATE_WORK_ORDER) ?
                            <UpdateWorkOrderStatus {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'add-item':
                        return ah.check(AccessType.UPDATE_WORK_ORDER) ?
                            <AddWorkOrderItem {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'update-item':
                        return ah.check(AccessType.UPDATE_WORK_ORDER) ?
                            <UpdateWorkOrderItem {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    default:
                        return null;
                }

            case 'service':
                switch (action) {
                    case 'update':
                        return <UpdateServiceAttribute {...contentProps} />;

                    case 'create':
                        return <NewService {...contentProps} />;

                    default:
                        return null;
                }

            case 'serviceTimelineItems':
                switch (action) {
                    case 'update':
                        return <UpdateServiceTimelineItems {...contentProps} />;

                    default:
                        return null;
                }

            case 'serviceUserOverride':
                switch (action) {
                    case 'update':
                        return <UpdateServiceOverride {...contentProps} />;

                    default:
                        return null;
                }

            case 'privateInsurance':
                switch (action) {
                    case 'create':
                        return ah.check(AccessType.UPDATE_ADDRESS_BOOK) ?
                            <AddPrivateInsuranceProvider {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'update':
                        return ah.check(AccessType.UPDATE_ADDRESS_BOOK) ?
                            <UpdatePrivateInsuranceProvider {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    default:
                        return null;
                }

            case 'externalClinic':
                switch (action) {
                    case 'create':
                        return ah.check(AccessType.UPDATE_ADDRESS_BOOK) ?
                            <AddExternalClinic {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'update':
                        return ah.check(AccessType.UPDATE_ADDRESS_BOOK) ?
                            <UpdateExternalClinic {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    default:
                        return null;
                }

            case 'externalDoctor':
                switch (action) {
                    case 'create':
                        return ah.check(AccessType.UPDATE_ADDRESS_BOOK) ?
                            <AddExternalDoctor {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'update':
                        return ah.check(AccessType.UPDATE_ADDRESS_BOOK) ?
                            <UpdateExternalDoctor {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    default:
                        return null;
                }

            case 'product':
                switch (action) {
                    case 'create':
                        return ah.check(AccessType.UPDATE_PRODUCT) ?
                            <NewProduct {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'view':
                        return <ViewProduct {...contentProps} />;

                    case 'update':
                        return ah.check(AccessType.UPDATE_PRODUCT) ?
                            <UpdateProduct {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'delete':
                        return ah.check(AccessType.UPDATE_PRODUCT) ?
                            <DeleteProduct {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'inventory-history':
                        return ah.check(AccessType.UPDATE_PRODUCT) ?
                            <ViewInventoryHistory {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    default:
                        return null;
                }

            case 'productType':
                switch (action) {
                    case 'create-type':
                        return ah.check(AccessType.UPDATE_PRODUCT) ?
                            <NewProductType {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;
                    default:
                        return null;
                }

            case 'productSupplier':
                switch (action) {
                    case 'create':
                        return ah.check(AccessType.UPDATE_PRODUCT) ?
                            <NewProductSupplier {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    default:
                        return null;
                }

            case 'productBrand':
                switch (action) {
                    case 'create':
                        return ah.check(AccessType.UPDATE_PRODUCT) ?
                            <NewProductBrand {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    default:
                        return null;
                }

            case 'contact':
                switch (action) {
                    case 'create':
                        return ah.check(AccessType.UPDATE_ADDRESS_BOOK) ?
                            <AddContact {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'update':
                        return ah.check(AccessType.UPDATE_ADDRESS_BOOK) ?
                            <UpdateContact {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    default:
                        return null;
                }

            case 'examInput':
                switch (action) {
                    case 'create':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <NewExamInput {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'update':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <UpdateExamInput {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'text':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <UpdateExistingExamText {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'control':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <UpdateExistingExamControl {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    default:
                        return null;
                }

            case 'examTemplate':
                switch (action) {
                    case 'create':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <NewExamTemplate {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'clone':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <CloneExamTemplate {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'element':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <UpdateExistingExamElement {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    default:
                        return null;
                }

            case 'examSection':
                switch (action) {
                    case 'add':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <NewExamSection {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'update':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <UpdateExamSection {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'reorder':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <ReorderExamSection {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    default:
                        return null;
                }

            case 'examDataset':
                switch (action) {
                    case 'create':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <NewExamDataset {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'update':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <UpdateExamDataset {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    default:
                        return null;
                }

            case 'workOrderInput':
                switch (action) {
                    case 'create':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <NewWorkOrderInput {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'update':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <UpdateWorkOrderInput {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'text':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <UpdateExistingWorkOrderText {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'control':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <UpdateExistingWorkOrderControl {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'element':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <UpdateExistingWorkOrderElement {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'datasource':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <MapWorkOrderInput {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    default:
                        return null;
                }

            case 'workOrderTemplate':
                switch (action) {
                    case 'create':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <NewWorkOrderTemplate {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    default:
                        return null;
                }

            case 'prescriptionInput':
                switch (action) {
                    case 'create':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <NewPrescriptionInput {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'update':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <UpdatePrescriptionInput {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'text':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <UpdateExistingPrescriptionText {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'control':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <UpdateExistingPrescriptionControl {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    default:
                        return null;
                }

            case 'prescriptionTemplate':
                switch (action) {
                    case 'create':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <NewPrescriptionTemplate {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'element':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <UpdateExistingPrescriptionElement {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    default:
                        return null;
                }

            case 'pretestInput':
                switch (action) {
                    case 'create':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <NewPretestInput {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'update':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <UpdatePretestInput {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'text':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <UpdateExistingPretestText {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'control':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <UpdateExistingPretestControl {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    default:
                        return null;
                }

            case 'pretestTemplate':
                switch (action) {
                    case 'create':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <NewPretestTemplate {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'element':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <UpdateExistingPretestElement {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    default:
                        return null;
                }

            case 'logicalDevice':
                switch (action) {
                    case 'create':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <NewLogicalDevice {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'update':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <UpdateLogicalDevice {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    default:
                        return null;
                }

            case 'invoiceTemplate':
                switch (action) {
                    case 'element':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <UpdateExistingInvoiceElement {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    default:
                        return null;
                }

            case 'paymentReceiptTemplate':
                switch (action) {
                    case 'element':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <UpdateExistingPaymentReceiptElement {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    default:
                        return null;
                }

            case 'faxCoverPageTemplate':
                switch (action) {
                    case 'element':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <UpdateExistingFaxCoverPageElement {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    default:
                        return null;
                }

            case 'formInput':
                switch (action) {
                    case 'create':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <NewFormInput {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'update':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <UpdateFormInput {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'text':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <UpdateExistingFormText {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'control':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <UpdateExistingFormControl {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    default:
                        return null;
                }

            case 'formTemplate':
                switch (action) {
                    case 'create':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <NewFormTemplate {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'update':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <UpdateFormTemplate {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'clone':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <CloneFormTemplate {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'element':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <UpdateExistingFormElement {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    default:
                        return null;
                }

            case 'formPage':
                switch (action) {
                    case 'add':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <NewFormPage {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'update':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <UpdateFormPage {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'reorder':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <ReorderFormPage {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    default:
                        return null;
                }

            case 'internalForm':
                switch (action) {
                    case 'start':
                        return ah.check(AccessType.UPDATE_CUSTOMER) ?
                            <StartInternalForm {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;
                    default:
                        return null;
                }

            case 'internalFormInput':
                switch (action) {
                    case 'create':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <NewInternalFormInput {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'update':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <UpdateInternalFormInput {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'control':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <UpdateExistingInternalFormControl {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'importPdf':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <ImportPdf {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    default:
                        return null;
                }

            case 'internalFormTemplate':
                switch (action) {
                    case 'create':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <NewInternalFormTemplate {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'update':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <UpdateInternalFormTemplate {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'element':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <UpdateExistingInternalFormElement {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    default:
                        return null;
                }

            case 'internalFormPage':
                switch (action) {
                    case 'add':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <NewInternalFormPage {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'update':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <UpdateInternalFormPage {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'reorder':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <ReorderInternalFormPage {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    default:
                        return null;
                }

            case 'referral':
                switch (action) {
                    case 'exam-data':
                        return ah.check(AccessType.UPDATE_REFERRAL) ?
                            <AddExamData {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'correspondence-attachment':
                        return ah.check(AccessType.UPDATE_REFERRAL) ?
                            <CorrespondenceAttachments {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    default:
                        return null;
                }

            case 'referralTemplate':
                switch (action) {
                    case 'create':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <NewReferralTemplate {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'update':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <UpdateReferralTemplate {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'element':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <UpdateExistingReferralElement {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    default:
                        return null;
                }

            case 'referralTenantExamDatasetGroup':
                switch (action) {
                    case 'create':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <NewReferralTenantExamDatasetGroup {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'update':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <UpdateReferralTenantExamDatasetGroup {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    default:
                        return null;
                }

            case 'referralUserExamDatasetGroup':
                switch (action) {
                    case 'create':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <NewReferralTemplate {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'update':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <UpdateReferralTemplate {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    default:
                        return null;
                }

            case 'medicalReport':
                switch (action) {
                    case 'exam-data':
                        return ah.check(AccessType.UPDATE_MEDICAL_REPORT) ?
                            <AddExamData {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'correspondence-attachment':
                        return ah.check(AccessType.UPDATE_REFERRAL) ?
                            <CorrespondenceAttachments {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    default:
                        return null;
                }

            case 'medicalReportTemplate':
                switch (action) {
                    case 'create':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <NewMedicalReportTemplate {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'update':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <UpdateMedicalReportTemplate {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'element':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <UpdateExistingMedicalReportElement {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    default:
                        return null;
                }

            case 'medicalReportTenantExamDatasetGroup':
                switch (action) {
                    case 'create':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <NewMedicalReportTenantExamDatasetGroup {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'update':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <UpdateMedicalReportTenantExamDatasetGroup {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    default:
                        return null;
                }

            case 'medicalReportUserExamDatasetGroup':
                switch (action) {
                    case 'create':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <NewMedicalReportTemplate {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'update':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <UpdateMedicalReportTemplate {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    default:
                        return null;
                }

            case 'prescriptionPrintTemplate':
                switch (action) {
                    case 'create':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <NewPrescriptionPrintTemplate {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'update':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <UpdatePrescriptionPrintTemplate {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'element':
                        return ah.check(AccessType.UPDATE_SYSTEM_SETTING) ?
                            <UpdateExistingPrescriptionPrintElement {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    default:
                        return null;
                }

            case 'todo':
                switch (action) {
                    case 'create':
                        return <NewTodo {...contentProps} />;

                    case 'update':
                        return <UpdateTodo {...contentProps} />;

                    default:
                        return null;
                }

            case 'user':
                switch (action) {
                    case 'create':
                        return ah.check(AccessType.UPDATE_USER_GROUP) ?
                            <CreateUser {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    default:
                        return null;
                }

            case 'group':
                switch (action) {
                    case 'create':
                        return ah.check(AccessType.UPDATE_USER_GROUP) ?
                            <CreateUpdateGroup {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'update':
                        return ah.check(AccessType.UPDATE_USER_GROUP) ?
                            <CreateUpdateGroup {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;
                    default:

                        return null;
                }

            case 'tenant':
                switch (action) {
                    case 'create':
                        return ah.check(AccessType.UPDATE_USER_GROUP) ?
                            <CreateUpdateTenant {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'update':
                        return ah.check(AccessType.UPDATE_USER_GROUP) ?
                            <CreateUpdateTenant {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'address':
                        return ah.check(AccessType.UPDATE_USER_GROUP) ?
                            <UpdateTenantAddress {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    default:
                        return null;
                }

            case 'organization':
                switch (action) {
                    case 'create':
                        return ah.check(AccessType.UPDATE_USER_GROUP) ?
                            <CreateUpdateOrganization {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'update':
                        return ah.check(AccessType.UPDATE_USER_GROUP) ?
                            <CreateUpdateOrganization {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    case 'address':
                        return ah.check(AccessType.UPDATE_USER_GROUP) ?
                            <UpdateOrganizationAddress {...contentProps} /> : <QuickDrawerAccessDenied {...contentProps} />;

                    default:
                        return null;
                }
            default:
                return null;
        }
    }

    const renderContent = () => {
        const { quickDrawer1ZIndex, quickDrawer2ZIndex, quickDrawer3ZIndex, quickDrawer4ZIndex, quickDrawer5ZIndex, quickDrawer6ZIndex } = quickDrawer;
        const quickDrawer1OverlayZIndex = quickDrawer1ZIndex - 10;
        const quickDrawer2OverlayZIndex = quickDrawer2ZIndex - 10;
        const quickDrawer3OverlayZIndex = quickDrawer3ZIndex - 10;
        const quickDrawer4OverlayZIndex = quickDrawer4ZIndex - 10;
        const quickDrawer5OverlayZIndex = quickDrawer5ZIndex - 10;
        const quickDrawer6OverlayZIndex = quickDrawer6ZIndex - 10;

        return <>
            <div id={getQuickDrawerId(1)} className='quick-drawer-container drawer-1'>
                <div className='off-canvas off-canvas-right'>
                    <div className={'off-canvas-sidebar' + (quickDrawer.isQuickDrawer1Activated ? ' active' : '') + (quickDrawer.isQuickDrawer2Activated ? ' no-shadow' : '')} style={{ zIndex: quickDrawer1ZIndex }}>
                        {renderDrawer1Body()}
                    </div>
                    <div className='off-canvas-overlay' style={{ zIndex: quickDrawer1OverlayZIndex }}></div>
                </div>
            </div>
            <div id={getQuickDrawerId(2)} className='quick-drawer-container drawer-2'>
                <div className='off-canvas off-canvas-right'>
                    <div className={'off-canvas-sidebar' + (quickDrawer.isQuickDrawer2Activated ? ' active' : '') + (quickDrawer.isQuickDrawer3Activated ? ' no-shadow' : '')} style={{ zIndex: quickDrawer2ZIndex }}>
                        {renderDrawer2Body()}
                    </div>
                    <div className='off-canvas-overlay' style={{ zIndex: quickDrawer2OverlayZIndex }}></div>
                </div>
            </div>
            <div id={getQuickDrawerId(3)} className='quick-drawer-container drawer-3'>
                <div className='off-canvas off-canvas-right'>
                    <div className={'off-canvas-sidebar' + (quickDrawer.isQuickDrawer3Activated ? ' active' : '') + (quickDrawer.isQuickDrawer4Activated ? ' no-shadow' : '')} style={{ zIndex: quickDrawer3ZIndex }}>
                        {renderDrawer3Body()}
                    </div>
                    <div className='off-canvas-overlay' style={{ zIndex: quickDrawer3OverlayZIndex }}></div>
                </div>
            </div>
            <div id={getQuickDrawerId(4)} className='quick-drawer-container drawer-4'>
                <div className='off-canvas off-canvas-right'>
                    <div className={'off-canvas-sidebar' + (quickDrawer.isQuickDrawer4Activated ? ' active' : '')} style={{ zIndex: quickDrawer4ZIndex }}>
                        {renderDrawer4Body()}
                    </div>
                    <div className='off-canvas-overlay' style={{ zIndex: quickDrawer4OverlayZIndex }}></div>
                </div>
            </div>
            <div id={getQuickDrawerId(5)} className='quick-drawer-container drawer-5'>
                <div className='off-canvas off-canvas-right'>
                    <div className={'off-canvas-sidebar' + (quickDrawer.isQuickDrawer5Activated ? ' active' : '')} style={{ zIndex: quickDrawer5ZIndex }}>
                        {renderDrawer5Body()}
                    </div>
                    <div className='off-canvas-overlay' style={{ zIndex: quickDrawer5OverlayZIndex }}></div>
                </div>
            </div>
            <div id={getQuickDrawerId(6)} className='quick-drawer-container drawer-6'>
                <div className='off-canvas off-canvas-right'>
                    <div className={'off-canvas-sidebar' + (quickDrawer.isQuickDrawer6Activated ? ' active' : '')} style={{ zIndex: quickDrawer6ZIndex }}>
                        {renderDrawer6Body()}
                    </div>
                    <div className='off-canvas-overlay' style={{ zIndex: quickDrawer6OverlayZIndex }}></div>
                </div>
            </div>
        </>;
    }

    return useObserver(() => <>{renderContent()}</>)
}

export { quickDrawerFocus, renderQuickDrawerLoading };
export default QuickDrawer;
