import React, { Component, useEffect } from 'react';
import { Popup } from 'devextreme-react';
import useSignalR from '../components/hooks/useSignalR';
import { ATTACHED_DEVICES, ATTACHED_DEVICES_LAST_UPDATED, CURRENT_TENANT } from "../constants/storageKeys";
import * as fn from './_functions'

const DEVICE_SYNC_PROTOCOL = 'hgdvsync';
const DEVICE_SYNC_TRIGGER_ID_PREFIX = '__d_sync_tri__';
const DEVICE_SYNC_DETECT_MODAL_ID = '__d_sync_detect_modal__';

export const getDeviceSyncCancel = () => {
    return `${DEVICE_SYNC_PROTOCOL}://`;
}

export const getDeviceSyncInfoBase64 = (connectionId) => {
    const currentTenant = JSON.parse(window.localStorage.getItem(CURRENT_TENANT));

    const variables = [
        '--id', connectionId, // this is the SignalR hub connection Id; which will be used to send down the info from .net core
        '--tenantId', currentTenant.id,
        '--command', 'info',
        '--apiRoot', process.env.REACT_APP_APP_API_DOMAIN
    ]

    const barVars = variables.join('|');
    const base64 = Buffer.from(barVars).toString('base64');

    console.log(`DEVICESYNC!!!! ${DEVICE_SYNC_PROTOCOL}://${base64}`);
    return `${DEVICE_SYNC_PROTOCOL}://${base64}`;
}

export const getDeviceSyncReadWriteBase64 = (device, connectionId, command, data) => {
    if (!device) return '';

    const currentTenant = JSON.parse(window.localStorage.getItem(CURRENT_TENANT));

    const variables = [
        '--id', connectionId,
        '--tenantId', currentTenant.id,
        '--deviceId', device.deviceId,
        '--apiRoot', process.env.REACT_APP_APP_API_DOMAIN
    ]

    if (command === 'write') {
        const base64Data = Buffer.from(data).toString('base64');
        variables.push('--command', 'write');
        variables.push('--base64Data', base64Data);
    }

    const barVars = variables.join('|');

    const base64 = Buffer.from(barVars).toString('base64');

    console.log(`DEVICESYNC!!!! ${DEVICE_SYNC_PROTOCOL}://${base64}`);
    return `${DEVICE_SYNC_PROTOCOL}://${base64}`;
}

export const renderDeviceSyncTrigger = (id) => {
    if (!id) return null;
    const triggerLinkId = id.startsWith(DEVICE_SYNC_TRIGGER_ID_PREFIX) ? id : getDeviceSyncTriggerId(id);
    return <a id={triggerLinkId} href='/' style={{ position: 'absolute', top: -99999, opacity: 0 }}>Start DeviceSync</a>
}

export const getDeviceSyncTrigger = (id) => {
    if (!id) return null;
    const triggerLinkId = id.startsWith(DEVICE_SYNC_TRIGGER_ID_PREFIX) ? id : getDeviceSyncTriggerId(id);
    return document.getElementById(triggerLinkId);
}

export const getAttachedDevices = () => {
    const attachedDevicesLastUpdatedJson = window.localStorage.getItem(ATTACHED_DEVICES_LAST_UPDATED);

    if (attachedDevicesLastUpdatedJson) {
        const attachedDevicesLastUpdated = JSON.parse(attachedDevicesLastUpdatedJson);
        const attachedDevicesJson = window.localStorage.getItem(ATTACHED_DEVICES);
        let attachedDevices = [];

        if (attachedDevicesJson) {
            attachedDevices = JSON.parse(attachedDevicesJson);
        }

        return {
            lastUpdated: attachedDevicesLastUpdated,
            devices: attachedDevices && attachedDevices.length > 0 ? attachedDevices : [],
        };
    }

    return {
        lastUpdated: null,
        devices: []
    };
}

export class DetectAttachedDevicesModal extends Component {
    constructor(props) {
        super(props);
        this.detectAttachedDevicesButtonRef = React.createRef(null);
        this.state = {
            display: false,
            zIndex: null,
            connectionId: null,
        };

        this.handleClose = this.handleClose.bind(this);
    }

    componentWillUnmount() {
        if (this.props.onClose) { this.props.onClose(); }
        if (this.detectAttachedDevicesButtonRef.current) {
            this.detectAttachedDevicesButtonRef.current.disconnectFromBroadcast();
        }
    }

    show = () => {
        const zIndex = fn.getHighestZIndex() + 1;
        this.setState({ display: true, zIndex: zIndex })
    }

    handleShowing = event => {
        const popup = document.getElementById(DEVICE_SYNC_DETECT_MODAL_ID);
        if (popup) {
            // const { zIndex } = this.state;
            // popup.style.zIndex = zIndex;
        }
    }

    handleClose = event => {
        const popup = document.getElementById(DEVICE_SYNC_DETECT_MODAL_ID);

        if (popup) {
            // popup.style.zIndex = null;
        }

        this.setState({ display: false, zIndex: null });
    }

    renderTitle = () => {
        return <div className='popup-title-draggable'></div>
    }

    render() {
        return <>{
            <Popup
                wrapperAttr={{ id: DEVICE_SYNC_DETECT_MODAL_ID, class: 'dx-popup-detect-attached-devices' }}
                animation={{
                    show: {
                        type: 'pop',
                        duration: 200,
                        from: { opacity: 0, scale: 0 },
                        to: { opacity: 1, scale: 1 }
                    },
                    hide: {
                        type: 'pop',
                        duration: 200,
                        from: { opacity: 1, scale: 1 },
                        to: { opacity: 0, scale: 0 }
                    }
                }}
                visible={this.state.display}
                shading={true}
                shadingColor="rgba(0, 0, 0, 0.2)"
                width={500}
                height={'auto'}
                titleRender={() => { return <div className='popup-title-draggable'></div> }}
                contentRender={() => {
                    return <div className='d-flex justify-content-center align-items-center p-4'>
                        <div className='text-center'>
                            <DetectAttachedDevicesButton ref={this.detectAttachedDevicesButtonRef} className='btn btn-xl btn-primary mb-4' onClick={this.handleClose} onSuccess={this.props.onSuccess} />
                            <br />
                            <button type='button' className='btn btn-link btn-sm p-0' onClick={this.handleClose}>Cancel</button>
                        </div>
                    </div>
                }}
                onShowing={this.handleShowing}
                onShown={this.handleShowing}
                onHiding={this.handleClose}
            />
        }</>
    }
}

export function DetectAttachedDevicesButton(props) {
    const signalR = useSignalR();

    useEffect(() => {
        signalR.on('AttachedDevices', (devices) => {
            const attachedDevicesJson = devices && devices.length > 0 ? JSON.stringify(devices) : '';
            window.localStorage.setItem(ATTACHED_DEVICES_LAST_UPDATED, JSON.stringify(new Date()));
            window.localStorage.setItem(ATTACHED_DEVICES, attachedDevicesJson);

            if (fn.isFunction(props.onSuccess)) {
                props.onSuccess();
            }
        });
    })

    return <a
            href={`${getDeviceSyncInfoBase64(signalR.connectionId)}`}
            className={props.className}
            onClick={props.onClick}
        >
            Detect attached devices
        </a>
}

const getDeviceSyncTriggerId = (id) => {
    return `${DEVICE_SYNC_TRIGGER_ID_PREFIX}${id}`;
}